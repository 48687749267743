import React, { useState } from 'react'
import { CloseButton, Form, Modal, Stack } from 'react-bootstrap'
import { Input } from '../../../common/input/Input'
import { Button } from '../../../common/button/Button'
import { PDFandCSVUploader } from '../creation/PDFandCSVUploader'
import { handleChange, isFormValid } from '../../../../utils/utils'

export const NewAgreement = ({ show, handleClose }) => {

    const [formData, setFormData] = useState({
        nameOfAgreement: '',
        additionalDetails: '',
        documents: []
    })
    const [errors, setErrors] = useState({
        nameOfAgreement: '',
        additionalDetails: '',
        documents: ''
    })

    const validateField = (name, value) => {
        var error = ''

        if(name === 'nameOfAgreement' && !value) error = 'Name of Agreement is required'
        if(name === 'additionalDetails' && !value) error = 'Additional details is required'

        return error
    }

    const handleInputChange = (e) => {
        handleChange(e, setFormData, setErrors, validateField)
    }

    const onDrop = (files) => {
        setFormData(prev => ({
            ...prev,
            documents: files
        }))
    }
    
    const isAgreementFormValid = isFormValid(errors, formData)
    
    const handleProceed = (e) => {
        e.preventDefault()
        
        if(isAgreementFormValid) {
            console.log('formData', formData)
        }
    }

    const handleAgreementClose = () => {
        setFormData({
            nameOfAgreement: '',
            additionalDetails: '',
            documents: []
        })
        setErrors({
            nameOfAgreement: '',
            additionalDetails: '',
            documents: ''
        })
        handleClose()
    }

    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleAgreementClose} centered>
            <Modal.Body>
                <Stack direction='horizontal' className='justify-content-between align-items-start'>
                    <div className='mb-3'>
                        <h5 className='text-black fw-semibold'>New Agreement</h5>
                        <p>You are creating a new agreement with DocuSign</p>
                    </div>
                    <CloseButton onClick={handleAgreementClose} />
                </Stack>
                <Form onSubmit={handleProceed}>
                    <Input
                        label='Name of Agreement'
                        placeholder='Enter name'
                        name='nameOfAgreement'
                        className='mb-2'
                    value={formData?.nameOfAgreement}
                    error={errors.nameOfAgreement}
                    onChange={handleInputChange}
                    />
                    <Input
                        multiline
                        rows={5}
                        label='Additional Details'
                        placeholder='Enter your address'
                        htmlFor='additionalDetails'
                        id='additionalDetails'
                        name='additionalDetails'
                        value={formData?.additionalDetails}
                        error={errors.additionalDetails}
                        onChange={handleInputChange}
                        className='mb-2'
                    />
                    <PDFandCSVUploader name='documents' onDrop={onDrop} className='mb-3' />
                    <Stack direction='horizontal' className='justify-content-between'>
                        <Button
                            type='button'
                            variant='outline'
                            className='color-ebony padded-border-button py-14px'
                            onClick={handleAgreementClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            disabled={!isAgreementFormValid}
                            className='py-14px'
                        >
                            Proceed
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
