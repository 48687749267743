import React, { useState } from "react";
import { Downloader } from "../../../../components/common/downloader/Downloader";
import { AiOutlineSetting } from "react-icons/ai";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { Offcanvas } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { FiltersFormForPayments } from "./FiltersFormForPayments";

export const PaymentCommonHeader = () => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <select
        style={{ width: "100px" }}
        className="sort-applicant padding-select-box font-14"
        name=""
        id=""
      >
        <option>Short by</option>
        <option value="">Completion Date</option>
        <option value="">Initiation Date</option>
        <option value="">Project Title</option>
      </select>

      <div className="d-flex gap-4">
        <button
          type="button"
          onClick={() => setShowFilters(true)}
          className="filterButton"
        >
          <AiOutlineSetting className="me-1" />
          <span>Filter</span>
        </button>
        <Downloader isCSV={true} />
      </div>

      <RightOffcanvas show={showFilters} onHide={setShowFilters}>
        <div className="p-3 d-flex align-items-center justify-content-between">
          <h5 className="text-black fw-semibold font-20">Filters</h5>
          <RxCross1
            size={20}
            onClick={() => setShowFilters(false)}
            className="cursor-pointer"
          />
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <FiltersFormForPayments setShowFilters={setShowFilters} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </div>
  );
};
