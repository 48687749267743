import React from "react";
import { CV } from "../../../../assets/images.assets";

export const Resume = () => {
  return (
    <>
      <img src={CV} width={"100%"}/>
    </>
  );
};
