import PostRequest from '../../../services/PostRequest';
import { showToaster } from '../../../utils/utils';

export const FETCH_TEAM_MEMBERS_LOADING = 'FETCH_TEAM_MEMBERS_LOADING';
export const LIST_TEAM_MEMBERS = 'LIST_TEAM_MEMBERS';
export const INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';

export const listTeamMembers = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_TEAM_MEMBERS_LOADING, value: true });
        try {
            const response = await PostRequest('/buyer/team-members/list');
            dispatch({
                type: LIST_TEAM_MEMBERS,
                value: response
            });
        } catch (error) {
            console.log('error', error);
        } finally {
            dispatch({ type: FETCH_TEAM_MEMBERS_LOADING, value: false });
        }
    };
};

export const inviteTeamMember = (data, options) => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/buyer/team-member/invite', data, options);
            dispatch({
                type: INVITE_TEAM_MEMBER,
                value: response
            });
            showToaster('Team member invited successfully', 'success');
        } catch (error) {
            console.log('error', error);
            showToaster(error.message, 'error');
        }
    };
};

export const updateTeamMember = (data, options) => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/buyer/team-member/update', data, options);
            dispatch({
                type: UPDATE_TEAM_MEMBER,
                value: response
            });
            showToaster('Team member updated successfully', 'success');
        } catch (error) {
            console.log('error', error);
            showToaster(error.message, 'error');
        }
    };
};

export const deleteTeamMember = (teamMember) => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/buyer/team-member/delete', { member: teamMember });
            dispatch({
                type: DELETE_TEAM_MEMBER,
                value: teamMember
            });
            showToaster(response, 'success');
        } catch (error) {
            console.log('error', error);
            showToaster(error.message, 'error');
        }
    };
};