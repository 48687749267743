import { ProfileWrapper } from '../../../../components/common/Default'
import { SkillsForm } from '../../../../components/authentication/profiling/SkillsForm'

const INFO = {
    title: <>What work are you <br /> here to do?</>,
    description: 'Your skills show clients what you can offer'
}

export const SkillsInputSection = () => {

    return (
        <ProfileWrapper isProvider email info={INFO}>
            <SkillsForm />
        </ProfileWrapper>
    )
}
