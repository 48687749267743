import React, { useState } from "react";
import { MainDefault } from "../../../../components/common/Default";
import { Button } from "../../../../components/common/button/Button";
import { ReactComponent as UserCircle } from "../../../../assets/images/user.svg";
import { Form, Stack } from "react-bootstrap";
import { Input } from "../../../../components/common/input/Input";
import MyPhoneInput from "../../../../components/common/my-phone-input/MyPhoneInput";
import { Select } from "../../../../components/common/select/Select";
import "../myProfile.css";

export const MyContactInformation = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    workNumber: "",
    timezone: null,
    country: null,
    address: "",
    state: "",
    zipCode: "",
  });

  const [errors, setErrors] = useState({});

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const zipCodePattern = /^\d{5}$/;

  const validateField = (name, value) => {
    let error = "";

    if (!value || (typeof value === "string" && value.trim() === "")) {
      error = `${name.replace(/([A-Z])/g, " $1")} is required.`;
    } else {
      switch (name) {
        case "emailAddress":
          if (!emailPattern.test(value)) {
            error = "Please enter a valid email address.";
          }
          break;
        case "workNumber":
          if (!/^\d+$/.test(value)) {
            error = "Work number must contain only digits.";
          }
          break;
        case "zipCode":
          if (!zipCodePattern.test(value)) {
            error = "Zip code must be a 5-digit number.";
          }
          break;
        default:
          break;
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const error = validateField(name, value);

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      !Object.values(errors).some((error) => error) &&
      Object.values(formData).every((value) => value.trim() !== "")
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    Object.entries(formData).forEach(([name, value]) => {
      validationErrors[name] = validateField(name, value);
    });

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error) && isFormValid()) {
      console.log("Form submitted successfully:", formData);
    }
  };

  return (
    <MainDefault>
      <div className="top-nav py-3">
        <h4 className="mb-0">Contact Information</h4>
      </div>
      <Form onSubmit={handleFormSubmit} className="mt-3">
        <div className="w-50">
          <div className="mb-2">
            <label className="form-label line-height-160">Change Picture</label>
          </div>
          <div className="mb-2">
            <UserCircle />
            <Button
              variant="outline"
              className="py-2 ms-3 font-14 rounded fw-medium"
            >
              Change Picture
            </Button>
          </div>
          <Input
            label="First Name"
            type="text"
            name="firstName"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={errors.firstName}
            placeholder="Enter your first name"
            className="mb-2"
          />
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={errors.lastName}
            placeholder="Enter your last name"
            className="mb-2"
          />
          <Input
            label="Email Address"
            type="text"
            name="emailAddress"
            id="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            error={errors.emailAddress}
            placeholder="Enter a valid email address"
            className="mb-2"
          />
          <MyPhoneInput
            label="Work Number"
            value={formData.workNumber}
            onChange={(phone) => {
              handleChange({ target: { name: "workNumber", value: phone } });
            }}
            error={errors.workNumber}
            className="mb-2"
          />
          <Select
            label="Timezone"
            name="timezone"
            inputId="timezone"
            placeholder="Select one"
            className="mb-2"
            value={formData.timezone}
            onChange={(timezone) =>
              handleChange({ target: { name: "timezone", value: timezone } })
            }
            error={errors.timezone}
          />
          <Select
            label="Country"
            name="country"
            inputId="country"
            placeholder="Select one"
            className="mb-2"
            value={formData.country}
            onChange={(country) =>
              handleChange({ target: { name: "country", value: country } })
            }
            error={errors.country}
          />
          <Input
            label="Address"
            type="text"
            name="address"
            id="address"
            value={formData.address}
            onChange={handleChange}
            error={errors.address}
            placeholder="Enter your address"
            className="mb-2"
          />
          <div className="d-flex gap-3">
            <Input
              type="text"
              name="state"
              id="state"
              value={formData.state}
              onChange={handleChange}
              error={errors.state}
              placeholder="State"
              className="mb-2 w-100"
            />
            <Input
              type="text"
              name="zipCode"
              id="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              error={errors.zipCode}
              placeholder="Zip code"
              className="mb-2 w-100"
            />
          </div>
        </div>
        <hr className="border-botticelli opacity-100" />
        <div className="mb-2">
          <label className="form-label line-height-160">Contact Privacy</label>
          <div className="d-flex gap-4 align-items-center">
            <p className="line-height-160 font-16">
              Make contact information visible for buyer
            </p>
            <Form.Check
              type="switch"
              id="custom-switch"
              className="switch-enable"
            />
          </div>
        </div>
        <hr className="border-botticelli opacity-100" />
        <Stack direction="horizontal" gap={4}>
          <Button
            variant="outline"
            className="color-ebony border-ebony fw-semibold"
            style={{ paddingBlock: 14 }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            type="submit"
            className="fw-semibold"
            style={{ paddingBlock: 14 }}
          >
            Update Company Profile
          </Button>
        </Stack>
      </Form>
    </MainDefault>
  );
};
