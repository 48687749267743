import React, { useMemo } from "react";
import { CommonHeader } from "../../../../components/buyer-provider/common-header/CommonHeader";
import { Table } from "../../../../components/common/table/Table";

export const DocuSignAgreements = () => {
  const Columns = useMemo(
    () => [
      {
        accessorKey: "agreementTitle",
        header: () => "Agreement Title",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => (
          <span className="font-10 line-height-160 badge-status__active badge-status__active_text">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "lastUpdated",
        header: () => "Last Updated",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "createdBy",
        header: () => "Created by",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
    ],
    []
  );

  const Rows = [
    {
      agreementTitle: "Ademola Lookman",
      status: "SIGNED",
      lastUpdated: "Feb 24, 2024",
      createdBy: "Iristech Inc",
    },
  ];
  return (
    <>
      <CommonHeader />

      <div className="mt-2 p-3">
        <Table columns={Columns} data={Rows} />
      </div>
    </>
  );
};
