import React, { useMemo } from "react";
import { Table } from "../../../../../common/table/Table";
import { CardWrapper } from "../../../../../common/Default";
import Documents from "../../../../../buyer-provider/Documents";
import { Badge } from "react-bootstrap";
import WorkTableDefault from "../WorkTableDefault";
import Underline from "../../../../../buyer-provider/Underline";
import { WideButton } from "../../../../../common/wideButton/WideButton";

const WorkDetailsContent = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: () => "Type",
        cell: (info) => <span className="font-14">{info.renderValue()}</span>,
      },
      {
        accessorKey: "name",
        header: () => "Name",
        cell: (info) => (
          <span className="font-14 fw-300">{info.renderValue()}</span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => {
          const status = info.renderValue();
          return (
            <Badge
              className={`rounded-3 text-uppercase ${
                status == "Available"
                  ? "badge-status__error"
                  : "badge-status__available"
              }`}
            >
              {info.renderValue()}
            </Badge>
          );
        },
        // enableSorting: false
      },

      {
        accessorKey: "action",
        header: () => "Action",
        cell: (info) => (
          <span className="color-primary font-14">Add Profile</span>
        ),
        // enableSorting: false
      },
    ],
    []
  );

  const rows = [
    {
      type: "Qualification",
      name: "Certificate",
      status: "Missing",
    },
    {
      type: "Qualification",
      name: "Certificate",
      status: "Missing",
    },

    {
      type: "Skill",
      name: "Plumbing",
      status: "Missing",
    },

    {
      type: "Tools",
      name: "Pliers",
      status: "Available",
    },
  ];

  return (
    <WorkTableDefault>
      <CardWrapper>
        <div className="description-heading">
          <h6>Job description</h6>
          <p className="mt-4">
            As a seasoned plumber dedicated to excellence in my craft, I bring a
            wealth of experience and a passion for delivering top-notch plumbing
            solutions. With a [insert number of years] years' track record in
            the field, I have honed my skills in diagnosing, repairing, and
            maintaining plumbing systems with precision and efficiency.
          </p>
          <p className="mt-3">
            My commitment to quality craftsmanship is evident in my attention to
            detail, ensuring that every installation and repair meets the
            highest industry standards. I am adept at handling a diverse range
            of plumbing tasks, from routine maintenance to troubleshooting
            complex issues.
          </p>
          <Underline />
        </div>
        <div className="description-heading mt-4">
          <h6>Requirements</h6>

          <Table columns={columns} data={rows} />

          {/* <Underline />

          <h6 className="mt-4">Approve provider timesheet</h6>
          <div className="color-fiord mb-3">Yes</div> */}
          <WideButton btnName="See More" className="color-primary mt-4" />
        </div>
      </CardWrapper>

      <div className="mt-4 mb-4">
        <CardWrapper>
          <h6 className="">Job Type</h6>
          <span className="color-fiord fw-400">Onsite</span>

          <Underline />

          <h6 className="mt-4">Project</h6>
          <h5 className="font-18">Construction of Pipeline</h5>

          <p className="color-fiord font-14 mb-2">
            As a seasoned plumber dedicated to excellence in my craft, I bring a
            wealth of experience and a passion for delivering top-notch plumbing
            solutions. With a [insert number of years] years' track record in
            the field, I have honed my skills in diagnosing, repairing, and
            maintaining plumbing systems with precision and efficiency.
          </p>

          <p className="color-fiord font-14 mt-4 mb-2">Heurty Inc</p>
          <p className="color-fiord font-14 mt-3 mb-2">24-07-2024</p>
        </CardWrapper>
      </div>

      <Documents />
    </WorkTableDefault>
  );
};

export default WorkDetailsContent;
