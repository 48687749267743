import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const useProfileCompletion = () => {

    const [showProfileSetup, setShowProfileSetup] = useState(false)

    const buyerDetails = useSelector(state => state.buyerReducer.buyerDetails)

    const TASKS = useMemo(() => [
        { label: 'Personal Information', url: '/profile/company', completed: buyerDetails?.isCompletedPersonalInformation },
        { label: 'Tax Information', url: '/profile/tax-information', completed: buyerDetails?.isCompletedTaxDetails },
        { label: 'Add Team Members', url: '/profile/manage-members', completed: buyerDetails?.isAddedTeamMembers },
        { label: 'Add Payment Method', url: '/profile/payment-method', completed: buyerDetails?.isAddedPaymentMethods },
        { label: 'Set Spending Limit', url: '/profile/spending-limit', completed: buyerDetails?.isSetSpendingLimit }
    ], [buyerDetails]);

    // Calculate completion percentage based on completed tasks
    const completedTasksCount = TASKS.filter(task => task.completed).length || 0;
    const completionPercentage = (completedTasksCount / TASKS.length) * 100

    return { showProfileSetup, setShowProfileSetup, TASKS, completionPercentage }
}
