import { Col, Form, Row } from 'react-bootstrap'
import { Input } from '../../../components/common/input/Input'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/common/button/Button'
import { useEffect, useState } from 'react'
import PostRequest from '../../../services/PostRequest'
import { showToaster } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBuyerDetails } from '../../../store/buyer/buyer.action'
import MyPhoneInput from '../../../components/common/my-phone-input/MyPhoneInput'
import { emailPattern } from '../../../data/constants'

export const PersonOfContactForm = ({ fromWO = false, className }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const buyerDetails = useSelector(state => state.buyerReducer.buyerDetails)
    const contactDetails = buyerDetails?.contactDetails

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    })

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    })

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName') {
            if (!value.trim()) {
                error = 'First name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'First name cannot contains special characters';
            }
        } else if (name === 'lastName') {
            if (!value.trim()) {
                error = 'Last name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Last name cannot contains special characters';
            }
        } else if (name === 'email') {
            if (!value.trim()) {
                error = 'Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
        } else if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        } else if (name === 'password') {
            if (!value) {
                error = 'Password is required';
            } else if (value.length < 8) {
                error = 'Your password is not strong enough. Use at least 8 characters';
            } else if (!/[0-9]/.test(value)) {
                error = 'Use at least 1 digit'
            } else if (!/[A-Z]/.test(value)) {
                error = 'Use at least 1 Uppercase letter'
            } else if (!/[a-z]/.test(value)) {
                error = 'Use at least 1 Lowercase letter'
            }
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedValue = name === 'phoneNumber' ? value.replace(/[^0-9]/g, '') : value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        }

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            const data = {
                contactDetails: JSON.stringify(formData) || '{}'
            }
            try {
                const response = await PostRequest('/buyer/contact-details/update', data, { showLoader: 'done-button' })
                console.log('buyer contact-details response', response)
                window.location.replace('/dashboard')
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if (contactDetails) {
            setFormData({
                firstName: contactDetails?.firstName || '',
                lastName: contactDetails?.lastName || '',
                email: contactDetails?.email || '',
                phoneNumber: contactDetails?.phoneNumber || '',
            });
        }
    }, [contactDetails])

    useEffect(() => {
        dispatch(fetchBuyerDetails())
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value);

    return (
        <Form className={className} onSubmit={handleSubmit}>
            <Row>
                <Col md={6}>
                    <Input
                        label='First name'
                        name='firstName'
                        id='firstName'
                        htmlFor='firstName'
                        placeholder='First name'
                        value={formData.firstName}
                        onChange={handleChange}
                        error={errors.firstName}
                        className='mb-2'
                    />
                </Col>
                <Col md={6}>
                    <Input
                        label='Last name'
                        name='lastName'
                        id='lastName'
                        htmlFor='lastName'
                        placeholder='Last name'
                        value={formData.lastName}
                        onChange={handleChange}
                        error={errors.lastName}
                        className='mb-2'
                    />
                </Col>
            </Row>
            <Input
                label='Email Address'
                name='email'
                id='email'
                htmlFor='email'
                placeholder='example@company.com'
                type='email'
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
                className='mb-2'
            />
            <MyPhoneInput
                label='Phone Number'
                value={formData.phoneNumber}
                onChange={(phone) => {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        phoneNumber: phone
                    }))
                }}
                error={errors.phoneNumber}
                className='mb-3'
            />
            {!fromWO && (
                <Row className='mb-1 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Button
                            onClick={() => navigate(-1)}
                            type='button'
                            variant='outline'
                            className='w-100 color-ebony border-ebony py-3'
                        >
                            Back
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type='submit'
                            id='done-button'
                            disabled={!isFormValid()}
                            className='w-100 py-3'
                        >
                            Done
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    )
}
