import React from "react";
import {
  BUYER_PROVIDER_WORK_ORDERS_navITEMS,
  BUYER_PROVIDER_WORK_ORDERS_tabPanes,
} from "../../../../data/constants";
import { JobUpdates } from "../../../../components/dashboard/job-updates/JobUpdates";

export const WorkOrders = () => {
  return (
    <>
      <JobUpdates
        navItems={BUYER_PROVIDER_WORK_ORDERS_navITEMS}
        tabPanes={BUYER_PROVIDER_WORK_ORDERS_tabPanes}
      ></JobUpdates>
    </>
  );
};
