import React, { useState } from 'react'
import { Col, Form, Modal, Row, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Input } from '../../../common/input/Input'
import { Select } from '../../../common/select/Select'

export const AddLocation = ({ show, handleClose }) => {

    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);

    const handleInvite = (e) => {
        e.preventDefault()
    }

    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Add Location</h5>
                    <p>You can add location of the work order</p>
                </div>
                <Form onSubmit={handleInvite}>
                    <Input
                        label='Address'
                        placeholder='Address'
                        htmlFor='address'
                        id='address'
                        name='address'
                        // value={formData?.address}
                        // error={errors.address}
                        // onChange={handleChange}
                        className='mb-2'
                    />
                    <Select
                        label='Country'
                        name='country'
                        inputId='countrySelect'
                        htmlFor='countrySelect'
                        placeholder='Country'
                        className='mb-2'
                        options={countries}
                        // value={formData?.country}
                        // error={errors.country}
                        isSearchable
                        // onChange={(country) => handleSelectChange(country, 'country')}
                        required
                    />
                    <Row className='row-gap-2 mb-2'>
                        <Col md={6}>
                            <Select
                                label='State'
                                name='state'
                                inputId='stateSelect'
                                htmlFor='stateSelect'
                                placeholder='State'
                                options={states}
                                isSearchable
                            // value={formData?.state}
                            // onChange={(state) => handleSelectChange(state, 'state')}
                            // error={errors?.state}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label='Suite/Floor'
                                placeholder='Suite/Floor'
                                name='floor'
                            // value={formData?.floor}
                            // error={errors.floor}
                            // onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label='City'
                                placeholder='City'
                                name='city'
                            // value={formData?.city}
                            // error={errors.city}
                            // onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label='Zip code'
                                placeholder='Zip code'
                                name='zipCode'
                            // value={formData?.zipCode}
                            // error={errors.zipCode}
                            // onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Form.Check
                        type='checkbox'
                        label='Save this location for future use'
                        className='buyer-checkbox mb-2'
                        name='save-location'
                        id='save-location-checkbox'
                    />
                    <Input
                        label='Number of Providers'
                        placeholder='Enter Value'
                        name='numberOfProviders'
                        className='mb-3'
                    // value={formData?.zipCode}
                    // error={errors.zipCode}
                    // onChange={handleChange}
                    />
                    <Stack direction='horizontal' className='justify-content-between'>
                        <Button
                            type='button'
                            variant='outline'
                            className='color-ebony padded-border-button py-14px'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='py-14px'
                        >
                            Add Location
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
