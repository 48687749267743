import React, { useState } from "react";
import { CardWrapper, MainDefault } from "../../../components/common/Default";
import { Col, Row, Stack } from "react-bootstrap";
import { LayoutSwitcher } from "../../../components/dashboard/job-updates/LayoutSwitcher";
import { AiOutlineSetting } from "react-icons/ai";
import { ReactComponent as Business } from "../../../assets/images/building.svg";
import { Badge, Nav, Tab } from "react-bootstrap";
import { CiBookmark } from "react-icons/ci";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineCalendarToday } from "react-icons/md";
import { Button } from "../../../components/common/button/Button";
import { FaBookmark } from "react-icons/fa6";
import "./find-work.css";
import { COMMON_IMG, commonIcon } from "../../../assets/images.assets";
import { useNavigate } from "react-router-dom";
import { Filters } from "../../../components/buyer/work-orders/offcanvas/Filters";
export const FindWork = () => {
  const TabFindWork = ["All Work Orders", "Saved Work Orders"];

  const navigate = useNavigate();
  const [bookMark, serBookMark] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  return (
    <MainDefault>
      <div className="ms-3">
        <h4 className="mb-0 fw-bold">All Work Orders </h4>

        <div className="d-flex align-items-center justify-content-between">
          <div className="find-work">
            <Tab.Container defaultActiveKey={"All Work Orders"}>
              <Nav variant="tabs" className="my-3 work-order__tabs fw-bold">
                {TabFindWork?.map((label) => {
                  return (
                    <Nav.Item key={label}>
                      <Nav.Link eventKey={label} as="button">
                        <span>{label}</span>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Tab.Container>
          </div>

          <div
            // style={{ width: "24%" }}
            className="d-flex align-items-center justify-content-around w-25"
          >
            <LayoutSwitcher showCalendar />

            <select
              style={{ width: "100px" }}
              className="form-select padding-select-box font-14"
              name=""
              id=""
            >
              <option value="">Show 10</option>
              <option value="">Show 20</option>
            </select>

            <button
              type="button"
              className="filterButton"
              onClick={() => setShowFilter(true)}
              // onClick={() => setShowFilters(true)}
            >
              <AiOutlineSetting className="me-1" />
              <span className="font-14">Filter</span>
            </button>
          </div>
        </div>

        <Tab.Content>
          <Row className="mt-3">
            {Array.from({ length: 3 }).map(() => {
              return (
                <Col
                  onClick={() => navigate("/provider/work-orders/details")}
                  md={4}
                >
                  <CardWrapper>
                    <Stack
                      direction="horizontal"
                      className="justify-content-between align-items-center mb-2"
                    >
                      {/* <Business /> */}
                      <img src={COMMON_IMG} alt="" />

                      {bookMark ? (
                        <FaBookmark
                          onClick={() => serBookMark(false)}
                          className="color-primary"
                        />
                      ) : (
                        <CiBookmark
                          onClick={() => serBookMark(true)}
                          size={20}
                          className="color-grey-500 cursor-pointer"
                        />
                      )}
                    </Stack>
                    <span className="color-grey-500 font-10 fw-medium">
                      #1233944
                    </span>
                    <p className="font-14 fw-semibold">
                      Work that create money that move the world and control ...
                    </p>
                    <Stack
                      direction="horizontal"
                      className="justify-content-between my-2"
                    >
                      <div className="d-flex align-items-center color-grey-500 gap-1">
                        <HiOutlineLocationMarker />
                        <span className="font-12 lh-1">
                          Birmingham, United Kingdom
                        </span>
                      </div>
                      <span className="job-status badge-status__active">ACTIVE</span>
                    </Stack>
                    <p className="font-12 color-grayscale-500">
                      Water purification and drilling of borehole for a 5
                      bedroom duplex...
                    </p>
                    <Stack direction="horizontal" className="gap-1 w-fit">
                      <h4 className="color-primary fw-bold my-3">
                        $3,500
                        <span className="color-pale-sky fw-normal">/m</span>
                      </h4>
                      <span className="rounded-pill card-tag__fixed">
                        FIXED
                      </span>
                    </Stack>
                    <div className="d-flex align-items-center color-grey-500 gap-1 mb-1">
                      <HiOutlineLocationMarker size={14} />
                      <span className="font-10 lh-1">
                        24, Amandos Avenue, Birmingham | 12 miles
                      </span>
                    </div>
                    <div className="d-flex align-items-center color-grey-500 gap-1 mb-3">
                      <MdOutlineCalendarToday size={14} />
                      <span className="font-10 lh-1">
                        Sun 24, Mar 2024 | 10:20AM
                      </span>
                      <span className="font-10 lh-1">-</span>
                      <span className="font-10 lh-1">
                        Sun 24, Mar 2024 | 10:20AM
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="font-12 color-grey-500">
                        10 Candidates{" "}
                        <span
                          style={{ color: "black" }}
                          className="color-black"
                        >
                          applied
                        </span>
                      </div>
                      <div className="font-12 color-grey-500">
                        Posted 3 days ago
                      </div>
                    </div>
                  </CardWrapper>
                </Col>
              );
            })}
          </Row>
        </Tab.Content>

        {/* filter  site-bar*/}
      </div>
    </MainDefault>
  );
};
