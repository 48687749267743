import { useEffect, useRef } from "react";
import {
  Modal,
  Stack,
  CloseButton,
  ProgressBar,
  Badge,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useProfileCompletion } from "../../../../hooks/useProfileCompletion";
import './profile-setup.css'

export const ProfileSetupModal = ({ show, handleClose }) => {
  const { TASKS, completionPercentage } = useProfileCompletion();

  // Create an array of refs for checkboxes
  const checkboxRefs = useRef([]);

  useEffect(() => {
    // Loop through checkboxes and programmatically set them to be read-only if the task is completed
    checkboxRefs.current.forEach((checkbox, index) => {
      if (checkbox) {
        if (TASKS[index]?.completed) {
          checkbox.disabled = true; // Disable the checkbox programmatically
        } else {
          checkbox.disabled = false; // Enable the checkbox if not completed
        }
      }
    });
  }, [TASKS]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Stack direction="horizontal" className="justify-content-between">
          <Modal.Title>Welcome to WorkRobin</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Stack>
        <p className="font-14 color-fiord">
          Your profile seems to be not complete, Update now to enjoy amazing
          features
        </p>
        <hr className="opacity-100 border-gray-100" />
        <Stack direction="horizontal" className="justify-content-between">
          <p className="font-14 fw-medium color-gray-700 profile-completion-text">
            Your Profile Completion
          </p>
          <ProgressBar
            className="w-50 ms-auto me-2 progressBar"
            now={completionPercentage}
          />
          <span className="font-14 fw-medium">{completionPercentage}%</span>
        </Stack>
        <hr className="opacity-100 border-gray-100" />
        <p className="fw-semibold font-14 color-grey-500">
          OUTSTANDING TASKS
        </p>
        {TASKS?.map((task, index) => {
          return (
            <Stack
              direction="horizontal"
              className="justify-content-between itemBaseWrapper mt-2"
              key={index}
            >
              <Form.Check
                type="checkbox"
                checked={task.completed}
                isValid={task.completed}
                onChange={() => {}}
                ref={(el) => (checkboxRefs.current[index] = el)}
              />
              {task.completed ? (
                <p
                  className="font-14 fw-medium ms-3 color-grey-400 me-auto"
                >
                  {task.label}
                </p>
              ) : (
                <div className="ms-3 me-auto">
                  <p className="font-14 fw-medium color-gray-700">
                    {task.label}
                  </p>
                  <p className="color-fiord font-10">
                    You need to submit more info.{" "}
                    <Link
                      to={task.url}
                      className="color-primary text-decoration-none fw-semibold"
                    >
                      Complete here
                    </Link>
                  </p>
                </div>
              )}
              <Badge
                className={`rounded-3 text-uppercase ${
                  task.completed
                    ? "badge-status__active border-secondary"
                    : "bg-transparent color-secondary"
                }`}
              >
                {task.completed ? "COMPLETED" : "Not Complete"}
              </Badge>
            </Stack>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};
