import { Col, Row, Stack } from 'react-bootstrap'
import { CHECKR } from '../../../../assets/images.assets'
import { CardWrapper, MainDefault } from '../../../../components/common/Default'
import { Button } from '../../../../components/common/button/Button'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Order } from '../../../../components/profile/offcanvas/screenings/Order'

export const MyScreenings = () => {

    const SCREENINGS = [
        { id: 'background-check', title: 'Background Check', to: '/profile/screenings/background-check' },
        { id: 'drug-test', title: 'Drug Test', to: '/profile/screenings/drug-test' },
        { id: 'check-check', title: 'Check Check' }
    ]

    const [showOrder, setShowOrder] = useState(false)
    const [selectedScreeningId, setSelectedScreeningId] = useState(null)

    const handleOrderClick = (id) => {
        setSelectedScreeningId(id);
        setShowOrder(true);
    }

    return (
        <MainDefault>
            <div className="top-nav py-3 mb-4 ">
                <h4 className="mb-0">Screenings</h4>
            </div>
            <Row className='row-gap-3'>
                {SCREENINGS.map((screening, i) => (
                    <Col md={4} key={i}>
                        <PayableCard
                            onOrderClick={screening.id === 'check-check' ? null : (
                                () => handleOrderClick(screening.id)
                            )}
                            {...screening}
                        />
                    </Col>
                ))}
                <Col sm={12}>
                    <CardWrapper>
                        <h6 className='fw-700'>Screening Process</h6>
                        <div className='mb-2 font-14'>
                            <p className='fw-700'>Start Screening: </p>
                            <p className='color-fiord fw-500'>
                                Click "Order Now" to begin your selected screening.
                            </p>
                        </div>
                        <div className='mb-2 font-14'>
                            <p className='fw-700'>Finalize Order: </p>
                            <p className='color-fiord fw-500'>
                                Fill out the form and complete payment to finalize your order.
                            </p>
                        </div>
                        <div className='mb-2 font-14'>
                            <p className='fw-700'>Guidance from Partners: </p>
                            <ul className="mb-1">
                                <li className="color-fiord font-14 fw-500">
                                    Follow Instructions: Ensure successful completion of the screening process by adhering to emailed instructions.
                                </li>
                                <li className="color-fiord font-14 fw-500">
                                    Receive Notifications: Stay informed about the status of your screening results through notifications.
                                </li>
                                <li className="color-fiord font-14 fw-500">
                                    Automatic Profile Update: Your profile will be automatically updated upon successful completion of screenings.
                                </li>
                            </ul>
                            <p className='color-fiord fw-500'>
                                Expect detailed instructions from our trusted screening partners via email.
                            </p>
                        </div>
                        <div className='mb-2 font-14'>
                            <p className='fw-700'>Follow Instructions: </p>
                            <p className='color-fiord fw-500'>
                                Ensure successful completion of the screening process by adhering to emailed instructions.
                            </p>
                        </div>
                        <div className='mb-2 font-14'>
                            <p className='fw-700'>Receive Notifications: </p>
                            <p className='color-fiord fw-500'>
                                Stay informed about the status of your screening results through notifications.
                            </p>
                        </div>
                        <div className='font-14'>
                            <p className='fw-700'>Automatic Profile Update: </p>
                            <p className='color-fiord fw-500'>
                                Your profile will be automatically updated upon successful completion of screenings.
                            </p>
                        </div>
                    </CardWrapper>
                </Col>
            </Row>
            <Order
                show={showOrder}
                handleClose={() => setShowOrder(false)}
                screeningId={selectedScreeningId}
            />
        </MainDefault>
    )
}

const PayableCard = ({ onOrderClick, ...screening }) => {
    return (
        <CardWrapper>
            <Stack direction='horizontal' className='justify-content-between mb-3'>
                <img src={CHECKR} alt="checkr" className='checkr-image' />
                {screening.id !== 'check-check' && <Link to={screening?.to} className='color-primary font-14 fw-600'>View status</Link>}
            </Stack>
            <Stack direction='horizontal' className='justify-content-between text-black'>
                <p>{screening.title}</p>
                <h4 className='mb-0 fw-700'>$34.00</h4>
            </Stack>
            <hr className='opacity-100 border-botticelli' />
            <Stack direction='horizontal' className='justify-content-between'>
                <Button type='button' className='py-14px rounded-4' onClick={onOrderClick}>Order Now</Button>
                <p className='color-custom-grey-500'>Learn more</p>
            </Stack>
        </CardWrapper>
    )
}