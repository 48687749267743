import React, { useState } from "react";
import { MainDefault } from "../../../../common/Default";
import { MdOutlineDateRange, MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FiUser, FiUsers } from "react-icons/fi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FilterButton } from "../../../../common/filter-button/FilterButton";
import { IoCloseOutline, IoLocationOutline } from "react-icons/io5";
import { CiCoins1 } from "react-icons/ci";
import { ScheduleDetailsCard } from "../../../../common/schedule-detalis-card/ScheduleDetailsCard";
import { HiDotsVertical } from "react-icons/hi";
import "./providerMyWorkOrderDetails.css";
import { JobUpdates } from "../../JobUpdates";
import {
  PROVIDER_MY_WORK_DETAILS_navITEMS,
  PROVIDER_MY_WORK_DETAILS_tabPANELS,
} from "../../../../../data/constants";
import ConfirmationModal from "../../buyer/modal/ConfirmationModal";
import { Button } from "../../../../common/button/Button";
import { MESSAGE_IMG } from "../../../../../assets/images.assets";

export const ProviderMyWorkOrderDetails = () => {
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [finalMessageApply, setFinalMessageApply] = useState(false);

  const navigate = useNavigate();

  const handelApply = () => {
    try {
      setFinalMessageApply(true);

      setTimeout(() => {
        setShowApplyModal(false);
        // setFinalMessageApply(false);
      }, 1000);
    } catch (error) {}
  };

  return (
    <MainDefault>
      <div className="px-3">
        <div className="d-flex align-items-center gap-2 cursor-pointer">
          <MdOutlineKeyboardBackspace onClick={() => navigate(-1)} size={32} />
          <label className="back-slide-label">Back to Work Orders</label>
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex align-items-center gap-2">
              <div className="">
                <label className="job-name">
                  Plumbing is the best role for you
                </label>
              </div>
              <div className="applied-provider color-black fw-500 font-12">
                APPLIED
              </div>

              <div className="menuWrapper d-flex align-items-center justify-content-center">
                <HiDotsVertical
                  className="color-grey-500 cursor-pointer"
                  size={15}
                />
              </div>
            </div>
            <div className="mt-2 d-flex align-items-center gap-2 candidate-count">
              <div className="candidate-count">#1233944</div>
              <span>|</span>
              <FiUsers size={14} />
              <div>10 Candidates Applied</div>
              <span>|</span>
              <HiOutlineBuildingOffice2 size={14} />
              <div>Century Factories</div>
            </div>
          </div>
          <FilterButton
            onClick={() => setShowApplyModal(true)}
            className={
              "fw-600 font-16 fw-bold line-height-160 color-error color-bg-error border-none px-3"
            }
          >
            Cancel Application
          </FilterButton>
        </div>

        <div className="mt-3 job-details">
          <div className="d-flex" style={{ gap: "250px" }}>
            <ScheduleDetailsCard
              fieldName="LOCATION"
              fieldIcon={<IoLocationOutline size={13} />}
            >
              <div className="state-name mt-1">Birmingham, United Kingdom</div>
            </ScheduleDetailsCard>
            <ScheduleDetailsCard
              fieldName="SCHEDULE"
              fieldIcon={<MdOutlineDateRange size={13} />}
            >
              <div className="deadline-commitment mt-1">Fixed</div>
              <div className="work-commit-date mt-1">03/04/2023 | 11:20PM</div>
            </ScheduleDetailsCard>
            <ScheduleDetailsCard
              fieldName="PROVIDER ASSIGNED"
              fieldIcon={<FiUser size={13} />}
            >
              <div className="deadline-commitment mt-1">NOT ASSIGNED</div>
            </ScheduleDetailsCard>
            <ScheduleDetailsCard
              fieldName="PAY RATE"
              fieldIcon={<CiCoins1 size={13} />}
            >
              <div className="mt-1">
                <div className="rate">
                  $500
                  <span className="ps-2">
                    Send
                    <span>Robin</span>
                  </span>
                </div>
                <div className="platform-and-sales-fees">
                  Platform Fees: $15
                </div>
                <div className="platform-and-sales-fees">Sales Tax: $11</div>
              </div>
            </ScheduleDetailsCard>
          </div>
        </div>

        <JobUpdates
          navItems={PROVIDER_MY_WORK_DETAILS_navITEMS}
          tabPanes={PROVIDER_MY_WORK_DETAILS_tabPANELS}
        />
      </div>

      {/* apply modal */}

      <ConfirmationModal
        confirmationShow={showApplyModal}
        handelClose={() => setShowApplyModal(false)}
      >
        <div className="mb-3">
          <div className="text-center">
            <img src={MESSAGE_IMG} alt="" />
          </div>
          <h5 className="text-black text-center fw-semibold font-20 mb-2 mt-3">
            {finalMessageApply
              ? "Application Cancelled"
              : " You are about to Cancel your application"}
          </h5>
          <p className="font-16 text-center fw-regular color-gray-700 mb-3">
            {finalMessageApply ? (
              <span>Your work application has Canceled</span>
            ) : (
              <span>
                You are Canceling your invitation. Do you want to <br />
                proceed?
              </span>
            )}
          </p>

          {!finalMessageApply && (
            <div className="d-flex justify-content-between mt-4">
              <Button
                variant={"outline"}
                onClick={() => setShowApplyModal(false)}
                className="px-3 py-3 padded-border-button color-ebony"
                children={"Cancel"}
              />
              <Button
                onClick={handelApply}
                className={" px-3 py-3"}
                children={"Proceed"}
              />
            </div>
          )}
        </div>
      </ConfirmationModal>

      {/* decline modal */}
    </MainDefault>
  );
};
