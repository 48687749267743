import React, { useMemo } from 'react'
import { Col, Row, Stack } from 'react-bootstrap'
import { CardWrapper } from '../../common/Default'
import { CommonHeader } from '../../buyer-provider/common-header/CommonHeader'
import { LuShare2 } from 'react-icons/lu'
import { Table } from '../../common/table/Table'
import { HiInformationCircle } from 'react-icons/hi2'
import Avatar from 'react-avatar'
import { Pie } from 'react-chartjs-2'
import { MyTooltip } from '../../common/tooltip/MyTooltip'

export const WorkOrdersContent = () => {

  const labels = ['Over 4.0', 'Over 3.0', 'Over 2.0']
  const data = {
    labels,
    datasets: [
      {
        // label: '# of Votes',
        data: [54, 26, 20],
        backgroundColor: [
          '#338AFF',
          '#F36B39',
          '#FFB222',
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          generateLabels: (chart) => {
            const data = chart.data;
            return data.labels.map((label, index) => {
              const dataset = data.datasets[0];
              const value = dataset.data[index];
              return {
                text: `${label}: ${value}`,
                fillStyle: dataset.backgroundColor[index],
                strokeStyle: dataset.backgroundColor[index],
              };
            });
          },
        },
      },
    }
  }

  const columns = useMemo(() => [
    {
      accessorKey: "initiationDate",
      header: () => "Initiation Date",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "completionDate",
      header: () => "Completion Date",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "projectTitle",
      header: () => "Project Title",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "description",
      header: () => "Description",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "provider",
      header: () => "Provider",
      cell: ({ row }) => (
        <Stack direction='horizontal' className='gap-2'>
          <Avatar round size='30' name={row.original.provider} />
          <span>{row.original.provider}</span>
        </Stack>
      ),
    },
    {
      accessorKey: "providerNet",
      header: () => "Provider’s Net",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "totalCost",
      header: () => (
        <span>
          Total Cost
          <MyTooltip 
            content={
              <>
                <p className='fw-600 font-14'>Note!</p>
                <p className='font-14'>4% platform fee is charged on the total cost</p>
              </>
            }
          >
            <HiInformationCircle size={16} className='mb-2px ms-1' />
          </MyTooltip>
        </span>
      ),
      cell: (info) => info.renderValue(),
    },
  ], [])

  const rows = [
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
  ]

  return (
    <>
      <Row className='row-gap-3 mb-4'>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Total Work Order Created</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are number of providers you have assigned work orders
            </p>
            <h3 className='text-black fw-700 mb-0'>60</h3>
          </CardWrapper>
        </Col>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Completed Work Orders</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are number of providers rejected on different work
            </p>
            <h3 className='text-black fw-700 mb-0'>200</h3>
          </CardWrapper>
        </Col>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Unassigned Work Order</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are providers you have saved in your pool.
            </p>
            <h3 className='text-black fw-700 mb-0'>20</h3>
          </CardWrapper>
        </Col>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Total Spent</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are providers you have saved in your pool.
            </p>
            <h3 className='text-black fw-700 mb-0'>$42,600.00</h3>
          </CardWrapper>
        </Col>

        <Col md={6}>
          <CardWrapper className='h-100'>
            <h6 className='text-black font-18 mb-3'>Work order by location</h6>
            <Stack className='justify-content-center map-image__wrapper'>
              <img src={require('../../../assets/images/MAP.png')} className='w-100' alt="" />
            </Stack>
          </CardWrapper>
        </Col>
        <Col md={6}>
          <CardWrapper>
            <h6 className='text-black font-18'>Work Order Ratings</h6>
            <Pie data={data} options={options} />
          </CardWrapper>
        </Col>
      </Row>

      <h6 className='text-black font-18 mb-3'>Work Order Expenses Breakdown</h6>
      <CommonHeader filterIcon={LuShare2} filterButtonText='Export' showUnderLine={false} />
      <div className='mt-3'>
        <Table data={rows} columns={columns} />
      </div>
    </>
  )
}
