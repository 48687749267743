import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Input } from '../../../common/input/Input'

export const CreatePool = ({ show, handleClose }) => {

    const handleCreatePool = (e) => {
        e.preventDefault()
    }

    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Create Pool</h5>
                    <p>You can create new pool to add provider to.</p>
                </div>
                <Form onSubmit={handleCreatePool}>
                    <Input
                        label='Pool Name'
                        placeholder='Enter Pool name'
                        className='mb-3'
                    />
                    <Button className='py-3 w-100'>
                        Create Pool
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
