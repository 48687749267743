import React from 'react'
import { MainDefault } from '../../../../../components/common/Default'
import { NewWODefault } from '../../../../../components/buyer/work-orders/creation/Default'
import { AgreementsForm } from '../../../../../components/buyer/work-orders/creation/agreements/AgreementsForm'
import { useLocation } from 'react-router-dom'

export const WOAgreements = () => {

    const location = useLocation()
    const mode = location.state?.mode

    return (
        <MainDefault>
            <NewWODefault mode={mode}>
                <AgreementsForm />
            </NewWODefault>
        </MainDefault>
    )
}
