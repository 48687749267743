import { ReactComponent as Lock } from '../../../../../assets/images/lock.svg'
import { Col, Form, Row } from 'react-bootstrap'
import { useOTPValidation } from '../../../../../hooks/useOTPValidation'
import { MyOTPInput } from '../../../../../components/common/my-otp-input/MyOTPInput'
import { Button } from '../../../../../components/common/button/Button'
import { MainDefault } from '../../../../../components/common/Default'
import PostRequest from '../../../../../services/PostRequest'
import { showToaster } from '../../../../../utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'

export const BuyerProfileInformationProtection = () => {

    const location = useLocation()
    const navigate = useNavigate()
    
    const { buyerId, ...buyerTaxDetails } = location?.state || {}

    const { otp, setOtp, isOTPValid, error, handleSubmit } = useOTPValidation()

    const handleFormSubmit = async (e) => {
        const formResult = handleSubmit(e)
        if (!formResult) return

        try {
            const data = {
                buyerId,
                otp,
                type: 'update-tax-details'
            }
            await PostRequest('/buyer/verify-otp/email', data)
            await PostRequest('/buyer/tax-details/update', buyerTaxDetails, { showLoader: 'update-tax-details' })
            navigate(-2)
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    return (
        <MainDefault>
            <Row className='justify-content-center align-items-center' style={{ height: 'calc(100vh - 200px)' }}>
                <Col xs={11} sm={8} md={4}>
                    <div className='text-center'>
                        <Lock className='mb-4' />
                        <div className='mb-3'>
                            <h5 className='welcome-heading fs-3 line-height-125'>Information Protected</h5>
                            <p className='get-started fw-normal line-height-150 mx-auto' style={{ maxWidth: '350px' }}>
                                Enter the code sent to your email to have access
                            </p>
                        </div>
                    </div>
                    <Form onSubmit={handleFormSubmit}>
                        <MyOTPInput
                            label='Enter OTP'
                            value={otp}
                            onChange={setOtp}
                            inputType='number'
                            error={error}
                            wrapperClasses='hide__arrows'
                        />
                        <Button 
                            type='submit' 
                            id='update-tax-details' 
                            disabled={!isOTPValid} 
                            className='w-100 py-3 mb-4'
                        >
                            Update Details
                        </Button>
                    </Form>
                </Col>
            </Row>
        </MainDefault>
    )
}
