import { Offcanvas, Stack } from 'react-bootstrap'
import { ReactComponent as Message } from '../../../../assets/images/message.svg'
import { Button } from '../../../common/button/Button'

export const PaidSuccessful = ({ show, handleClose, screeningId }) => {
    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            className='timesheet-offcanvas'
            placement='end'
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='fw-bold'>Order for Background Check</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body as={Stack} className='justify-content-center'>
                <div className="text-center">
                    <Message />
                    <h5 className='text-black'>Paid Successful</h5>
                    <p className='color-gray-700'>
                        You have paid successfully for the order of the {screeningId === 'background-check' ? 'background check' : 'drug test'}
                    </p>
                    <Button
                        type="button"
                        className="fw-semibold py-3 px-3 mt-4"
                        onClick={handleClose}
                    >
                        Return
                    </Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
