import { Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { ReactComponent as Message } from '../../../../assets/images/message.svg'
import { Fragment, useState } from 'react'
import { ApprovalModificationSuccess } from './ApprovalModificationSuccess'

export const ApprovalModificationPrompt = ({ show, handleClose, pocRequest }) => {

    const [showApprovalModificationSuccess, setShowApprovalModificationSuccess] = useState(false)

    return (
        <Fragment>
            <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <Stack gap={2} className='align-items-center text-center mb-3'>
                        <Message />
                        <h5 className='mb-0 text-black fw-medium'>Resending request for {pocRequest === 'approval' ? 'approval' : 'modification'}</h5>
                        <p>
                            Do you want to proceed?
                        </p>
                    </Stack>

                    <Stack direction='horizontal' className='justify-content-between'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            onClick={() => setShowApprovalModificationSuccess(true)}
                            className='fw-semibold py-14px'
                        >
                            Yes, Proceed
                        </Button>
                    </Stack>
                </Modal.Body>
            </Modal>
            <ApprovalModificationSuccess
                show={showApprovalModificationSuccess}
                handleClose={() => setShowApprovalModificationSuccess(false)}
                pocRequest={pocRequest}
            />
        </Fragment>
    )
}
