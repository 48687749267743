import React from "react";
import { CardWrapper } from "../../../../components/common/Default";
import { PiStarBold, PiStarFill } from "react-icons/pi";

export const Reviews = () => {
  return (
    <>
      <CardWrapper className="mt-3">
        <p className="font-16 line-height-160 color-neutrals-100">
          Plumbing Installation and Repairs
        </p>
        <p className="font-14 line-height-160 color-neutrals-60">
          Nov 1 - Nov 30, 2023
        </p>

        <div className="d-flex align-items-center gap-1 mt-2">
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />

          <p className="ms-1 pt-1 font-14 line-height-160 fw-500 color-gray-700">
            5.0
          </p>

          {/*<MdOutlineStar size={16} color="D9D9D9" /> */}
        </div>

        <p className="font-16 line-height-160 color-gray-700 pt-2">
          “Adeleke did an amazing work on this project. He was attentive to all
          the requirements snd ensured the project was completed in due time.”
        </p>
      </CardWrapper>

      <CardWrapper className="mt-3">
        <p className="font-16 line-height-160 color-neutrals-100">
          Plumbing Installation and Repairs
        </p>
        <p className="font-14 line-height-160 color-neutrals-60">
          Nov 1 - Nov 5, 2023
        </p>

        <p className="font-16 line-height-160 color-gray-700 pt-2">
          No feedback given
        </p>
      </CardWrapper>

      <CardWrapper className="mt-3">
        <p className="font-16 line-height-160 color-neutrals-100">
          Plumbing Installation and Repairs
        </p>
        <p className="font-14 line-height-160 color-neutrals-60">
          Nov 1 - Nov 30, 2023
        </p>

        <div className="d-flex align-items-center gap-1 mt-2">
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />
          <PiStarFill size={16} color="FB9905" />
          <PiStarBold size={16} color="D9D9D9" />

          <p className="ms-1 pt-1 font-14 line-height-160 fw-500 color-gray-700">
            4.0
          </p>
        </div>

        <p className="font-16 line-height-160 color-gray-700 pt-2">
          “Excellent work Adeleke!”
        </p>
      </CardWrapper>
    </>
  );
};
