import React from "react";
import "./deliverables.css";
import { CardWrapper } from "../../common/Default";

export const AboutDeliverables = () => {
  return (
    <CardWrapper className="deliverables-card">
      <h6>Deliverables</h6>

      <ul className="mt-4 list-items__deliverables">
        <li>As a seasoned plumber dedicated to excellence in my craft</li>
        <li>
          I bring a wealth of experience and a passion for delivering top-notch
          plumbing solutions.
        </li>
        <li>
          With a [insert number of years] years' track record in the field,
        </li>
        <li>
          I have honed my skills in diagnosing, repairing, and maintaining
          plumbing systems with precision and efficiency.
        </li>
      </ul>
    </CardWrapper>
  );
};
