import React from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Select } from '../../../common/select/Select'

export const Invite = ({ show, handleClose }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Invite Multiple</h5>
                    <p>You are about to invite this provider to a work order</p>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Select
                        label='Work order ID'
                        name='woId'
                        inputId='woId'
                        htmlFor='woId'
                        placeholder='Select one'
                        className='mb-5'
                    />
                    <div className='font-14 mb-3'>
                        <p className='text-black fw-medium'>Work Title</p>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <p className='color-grey-500'>Plumber</p>
                    </div>
                    <div className='font-14 mb-3'>
                        <p className='text-black fw-medium'>City</p>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <p className='color-grey-500'>Birmingham</p>
                    </div>
                    <div className='font-14 mb-3'>
                        <p className='text-black fw-medium'>Country</p>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <p className='color-grey-500'>United Kingdom</p>
                    </div>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <Button
                            type='button'
                            variant='outline'
                            className='color-ebony padded-border-button py-14px'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='py-14px'
                        >
                            Invite
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
