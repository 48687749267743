import React, { useMemo } from "react";
import { MainDefault } from "../../../../components/common/Default";
import { Table } from "../../../../components/common/table/Table";
import "../buyerProfile.css";

export const CompanyTaxDocumentation = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "documentType",
        header: () => "Document Type",
        cell: (info) => <span>W9 NEC Report</span>,
        // enableSorting: false
      },
      {
        accessorKey: "creator",
        header: () => "Creator",
        cell: (info) => <span className="">Omolola Wawu</span>,
        // enableSorting: false
      },
      {
        accessorKey: "dateCreator",
        header: () => "Date Creator",
        cell: (info) => <span>August 12, 2023</span>,
      },
      {
        accessorKey: "taxStatus",
        header: () => "Tax Status",
        cell: (info) => <span className="">Issued</span>,
        // enableSorting: false
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => <span className="">Active</span>,
      },
      {
        accessorKey: "type",
        header: () => "Type",
        cell: (info) => <span className="typeOfOccupation">Electronic</span>,
      },

      {
        accessorKey: "action",
        header: () => "Action",
        cell: (info) => <span className="file-downloader">Download</span>,
      },
    ],
    []
  );

  const rows = [
    {
      documentType: "Plumber",
      creator: "Birmingham, United Kingdom",
      dateCreator: "Active",
      taxStatus: " 5",
      status: "30/200",
      price: "$500",
      type: "$500",
      action: "$500",
    },
  ];

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4">
        <h4 className="mb-0">Tax Documentation</h4>
      </div>
      <Table columns={columns} data={rows} />
    </MainDefault>
  );
};
