import React from "react";
import { Form } from "react-bootstrap";
import { IoLockClosed } from "react-icons/io5";
import Underline from "../../../../components/buyer-provider/Underline";

export const ProviderGoToSettings = () => {
  return (
    <>
      <div className="top-nav py-3 mt-4 mb-4">
        <h4 className="mb-0">Two-Factor Authentication</h4>
      </div>

      <div className="d-flex gap-2 mt-5">
        <IoLockClosed size={38} color="#920af2" />
        <p className="primary-text-color">Enabled</p>
      </div>

      <div className="d-flex align-items-center gap-2 mt-3">
        <Form.Check
          type="checkbox"
          className="buyer-checkbox mt-1 defaultChecked"
          id="twoFactorAuthCheckbox"
        />
        <label
          htmlFor="twoFactorAuthCheckbox"
          className="login-restriction-policy"
        >
          Two-Factor Authentication has been enabled
        </label>
      </div>

      <Underline />

    </>
  );
};
