import React from "react";
import "./custom-tab.css";
import { LayoutSwitcher } from "../../dashboard/job-updates/LayoutSwitcher";
const CustomTab = ({
  headers,
  activeTabCustom,
  setActiveTabCustom,
  showTab = false,
}) => {
  return (
    <div className="tab-bottom d-flex">
      {headers?.map((item, index) => {
        return (
          <span
            onClick={() => setActiveTabCustom(item)}
            className={`font-14 custom-tab-style ${
              activeTabCustom == item && "custom-active-tab"
            }`}
            key={index}
          >
            {item}
          </span>
        );
      })}
      {showTab && (
        <div className="ms-auto d-flex gap-3">
          <LayoutSwitcher showCalendar />
        </div>
      )}
    </div>
  );
};

export default CustomTab;
