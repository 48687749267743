import { useMemo, useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { NotFoundCard } from "../../../components/profile/not-found/NotFoundCard";
import { UploadAgreement } from "../../../components/buyer/agreements/offcanvas/UploadAgreement";
import { RightOffcanvas } from "../../../components/buyer-provider/RightOffcanvas";
import {
  Form,
  ListGroup,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { Table } from "../../../components/common/table/Table";
import { IoSearch } from "react-icons/io5";
import { Downloader } from "../../../components/common/downloader/Downloader";
import { AiOutlineSetting } from "react-icons/ai";
import Underline from "../../../components/buyer-provider/Underline";
import { JobUpdates } from "../../../components/dashboard/job-updates/JobUpdates";
import {
  AGREEMENT_navITEMS,
  AGREEMENT_tabPanes,
} from "../../../data/constants";
import { Button } from "../../../components/common/button/Button";
import { FiPlus } from "react-icons/fi";

export const Agreements = () => {

  const [uploadAgreement, setUploadAgreement] = useState(false);

  return (
    <MainDefault>
      <div className="px-3 d-flex align-items-center justify-content-between">
        <h6 className="font-24 line-height-130 fw-bold">Agreements</h6>

        {[''].length > 0 && (
          <Button
            className="px-3"
            style={{ paddingBlock: 12 }}
            onClick={() => setUploadAgreement(true)}
          >
            <FiPlus size={20} className="me-2" />
            <span>Upload Agreement</span>
          </Button>
        )}
      </div>

      <JobUpdates
        navItems={AGREEMENT_navITEMS}
        tabPanes={AGREEMENT_tabPanes}
      ></JobUpdates>

      <UploadAgreement show={uploadAgreement} handleClose={() => setUploadAgreement(false)} />
    </MainDefault>
  );
};
