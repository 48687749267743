import { Input } from '../../common/input/Input'
import { Form, Stack } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../common/button/Button'
import { useEffect, useState } from 'react'
import PostRequest from '../../../services/PostRequest'
import { showToaster } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProviderDetails } from '../../../store/provider/provider.action'

export const BioForm = ({
    fromModal = false,
    handleClose = () => {},
}) => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [bio, setBio] = useState('')
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()

        setError('')

        const bioInput = e.target.elements.bio.value
        if (!bioInput || bioInput === '') return setError('Bio is required')

        try {
            await PostRequest('/provider/profile/update', { bio }, { showLoader: 'bio-submit' })
            if(fromModal) {
                handleClose()
                dispatch(fetchProviderDetails())
            } else {
                navigate('/submit', { state: location?.state })
            }
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        if (providerDetails) {
            setBio(providerDetails?.bio || '')
        }
    }, [providerDetails])

    useEffect(() => {
        dispatch(fetchProviderDetails())
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                multiline
                label='Your Bio'
                placeholder='Your Bio'
                name='bio'
                htmlFor='bio'
                id='bio'
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                error={error}
                rows={4}
                className='mb-3'
            />
            <Stack direction='horizontal' gap={4}>
                <Button
                    onClick={() => {
                        fromModal ? handleClose() : navigate(-1)
                    }}
                    type='button'
                    variant='outline'
                    className='w-100 color-ebony border-ebony py-3'
                >
                    {fromModal ? 'Close' : 'Back'}
                </Button>
                <Button type='submit' id='bio-submit' disabled={!bio || !!error} className='w-100 py-3'>
                    {fromModal ? 'Update' : 'Continue'}
                </Button>
            </Stack>
        </Form>
    )
}
