import React, { useState } from "react";
import { RightOffcanvas } from "../../../../buyer-provider/RightOffcanvas";
import { Select } from "../../../../common/select/Select";
import { Input } from "../../../../common/input/Input";
import { Button } from "../../../../common/button/Button";
import { MESSAGE_IMG } from "../../../../../assets/images.assets";
import ConfirmationModal from "../../buyer/modal/ConfirmationModal";
import { RxCross1 } from "react-icons/rx";
import { Form } from "react-bootstrap";
import Underline from "../../../../buyer-provider/Underline";
import JoditEditor from "jodit-react";

const CountFilter = ({ showCount, setShowCount }) => {
  const [filterApplySuccessModal, setFilterApplySuccessModal] = useState(false);

  const [formData, setFormData] = useState({
    paymentType: "",
    initialRate: "",
    maxHours: "",
    additionalRate: "",
    additionalHours: "",
    total: "",
    date: "",
    time: "",
    expirationTime: "",
    expirationDate: "",
    counterOffer: "",
  });

  const [errors, setErrors] = useState({
    paymentType: "",
    initialRate: "",
    maxHours: "",
    additionalRate: "",
    additionalHours: "",
    total: "",
    date: "",
    time: "",
    expirationTime: "",
    expirationDate: "",
    counterOffer: "",
  });

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "paymentType":
        if (!value) error = "Payment type is required.";
        break;
      case "initialRate":
      case "maxHours":
      case "additionalRate":
      case "additionalHours":
      case "total":
        if (!value) error = `${name.replace(/([A-Z])/g, " $1")} is required.`;
        else if (isNaN(value) || Number(value) < 0)
          error = `${name.replace(
            /([A-Z])/g,
            " $1"
          )} must be a positive number.`;
        break;
      case "date":
        if (!value) error = "Date is required.";
        break;
      case "time":
      case "expirationDate":
        if (!value) error = "Expiration Date is required.";
        break;
      case "expirationTime":
        if (!value) error = `${name.replace(/([A-Z])/g, " $1")} is required.`;
        else if (isNaN(value) || Number(value) < 0 || Number(value) > 24)
          error = `${name.replace(
            /([A-Z])/g,
            " $1"
          )} must be between 0 and 24.`;
        break;
      case "counterOffer":
        if (!value) error = "Reason for counter offer is required.";
        else if (value.length < 10)
          error = "Reason for counter offer must be at least 10 characters.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e, customName = null) => {
    const name = customName || e.target.name; // Use customName for custom components
    const value = e.target ? e.target.value : e; // Use `e` directly for custom components

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = Object.keys(formData).reduce((acc, field) => {
      acc[field] = validateField(field, formData[field]);
      return acc;
    }, {});

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      console.log("Submitted Data:", formData);
    }
  };

  const paymentType = [
    {
      label: "Blended",
      value: "Blended",
    },
    {
      label: "Blended",
      value: "Blended",
    },
    {
      label: "Blended",
      value: "Blended",
    },
    {
      label: "Blended",
      value: "Blended",
    },
  ];

  return (
    <RightOffcanvas
      show={showCount}
      handleClose={() => setShowCount(false)}
      className="w-25"
    >
      <div className="p-4 input_data_style">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Counter Offer
          </h6>
          <RxCross1
            size={20}
            onClick={() => setShowCount(false)}
            className="cursor-pointer"
          />
        </div>
        <h6 className="mt-4 f-16">Current Offer</h6>
        <div className="fw-300 font-14 mt-3 color-grey-500">
          <div className="d-flex">
            <div className="w-50">Payment Terms</div>
            <div>Hourly</div>
          </div>
          <div className="d-flex mt-3">
            <div className="w-50">Initial Rate</div>
            <div>$44.3</div>
          </div>
          <div className="d-flex mt-3">
            <div className="w-50">Initial Hours</div>
            <div>4</div>
          </div>
          <div className="d-flex mt-3 fw-600 text-black">
            <div className="w-50">Total</div>
            <div>$88.4</div>
          </div>
        </div>

        <h6 className="f-16 mt-4">Counter Offer</h6>
        <Underline className="mt-2 mb-2" />
        <Form onSubmit={handleSubmit}>
          <div className="">
            <Select
              label="Payment Type"
              name="paymentType"
              inputId="paymentType"
              htmlFor="paymentType"
              // placeholder="Select City"
              options={paymentType}
              value={formData?.paymentType}
              error={errors.paymentType}
              onChange={(value) => handleChange(value, "paymentType")}
              required
            />
            <Input
              className={"mt-2"}
              type="number"
              label={"Initial Rate"}
              placeholder="Enter rate"
              name="initialRate"
              value={formData.initialRate}
              onChange={handleChange}
              error={errors.initialRate}
            />
            <Input
              className={"mt-2"}
              type="number"
              label={"Max. Hours"}
              placeholder="Enter hours"
              name="maxHours"
              value={formData.maxHours}
              onChange={handleChange}
              error={errors.maxHours}
            />
            <Input
              className={"mt-2"}
              type="number"
              label={"Additional Rate"}
              placeholder="Enter additional rate"
              name="additionalRate"
              value={formData.additionalRate}
              onChange={handleChange}
              error={errors.additionalRate}
            />
            <Input
              className={"mt-2"}
              type="number"
              label={"Additional Hours"}
              placeholder="Enter additional hours"
              name="additionalHours"
              value={formData.additionalHours}
              onChange={handleChange}
              error={errors.additionalHours}
            />
            <Input
              className={"mt-2"}
              type="number"
              label={"Total"}
              placeholder="$0.00"
              name="total"
              value={formData.total}
              onChange={handleChange}
              error={errors.total}
            />
          </div>

          <Underline className="mb-3 mt-4" />

          <div className="d-flex align-items-center gap-2 mt-4">
            <Form.Check type="checkbox" className="buyer-checkbox" />
            <span className="font-16 color-gray-700">
              Request a different date or time
            </span>
          </div>
          <div className="color-grey-500 font-14 mt-3">
            <span className="me-3 fw-600">Current Schedule</span>
            <span>Tue, 24 Jan, 2024 | 11:23PM</span>
          </div>

          <div className="d-flex gap-2 mt-3 mb-3">
            <div className="d-flex gap-2">
              <Form.Check type="radio" />
              <span className="fw-500 font-16 color-gray-700">
                Specific Date & Time
              </span>
            </div>
            <div className="d-flex gap-2">
              <Form.Check type="radio" />
              <span className="fw-500 font-16 color-gray-700">
                Set Arrival Window
              </span>
            </div>
          </div>

          <Input
            className={"mt-2"}
            type="date"
            label="Date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            error={errors.date}
          />

          <Input
            className={"mt-3"}
            type="number"
            label="Time"
            placeholder="Enter hours"
            name="time"
            value={formData.time}
            onChange={handleChange}
            error={errors.time}
          />

          <Underline className="mt-4 mb-4" />

          <div className="d-flex align-items-center gap-2 mb-3">
            <Form.Check type="checkbox" className="buyer-checkbox" />
            <span className="font-16 color-gray-700">
              Set an offer expiration date
            </span>
          </div>

          <Input
            type="date"
            label="Expiration Date"
            name="expirationDate"
            value={formData.expirationDate}
            onChange={handleChange}
            error={errors.expirationDate}
          />

          <Input
            className={"mt-3"}
            type="number"
            label="Expiration Time"
            placeholder="Enter hours"
            name="expirationTime"
            value={formData.expirationTime}
            onChange={handleChange}
            error={errors.expirationTime}
          />
          <Input
            className={"mt-3"}
            type="text"
            label="Reason for Counter Offer"
            placeholder="Enter job deliverables"
            name="counterOffer"
            value={formData.counterOffer}
            onChange={handleChange}
            error={errors.counterOffer}
            multiline="h"
            style={{ height: "140px" }}
          />

          {/* <JoditEditor /> */}

          <div className="d-flex justify-content-between mt-5">
            {/* <Button className={"py-3"} children={"Cancel"} />
          <Button className={"py-3"} children={"Submit Offer"} /> */}

            <Button
              type="button"
              variant="outline"
              onClick={() => setShowCount(false)}
              className="fw-semibold px-3 padded-border-button color-ebony"
            >
              Cancel
            </Button>
            <Button
              disabled={!isFormValid()}
              onClick={() => {
                setFilterApplySuccessModal(true);
              }}
              type="submit"
              className="fw-semibold p-3"
            >
              Submit Offer
            </Button>
          </div>
        </Form>
      </div>
      <ConfirmationModal
        confirmationShow={filterApplySuccessModal}
        handelClose={() => setFilterApplySuccessModal(false)}
      >
        <div className="mb-3">
          <div className="text-center">
            <img src={MESSAGE_IMG} alt="" />
          </div>
          <h5 className="text-black text-center fw-semibold font-20 mb-2 mt-3">
            You have successfully submitted <br />
            your counter offer
          </h5>
          <p className="font-16 text-center fw-regular color-gray-700 mb-3">
            Your work application has submitted. You <br /> will get a notice
            once accepted
          </p>
        </div>
      </ConfirmationModal>
    </RightOffcanvas>
  );
};

export default CountFilter;
