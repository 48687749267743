import { Modal } from 'react-bootstrap'
import { ResumeForm } from '../ResumeForm'

export const ResumeModal = ({ show, handleClose }) => {
    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                {/* <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Tell the world about yourself with your bio</h5>
                    <p>Help people to get to know you at a glance</p>
                </div> */}
                <ResumeForm
                    fromModal
                    handleClose={handleClose}
                />
            </Modal.Body>
        </Modal>
    )
}
