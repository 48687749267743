import Dropzone from 'react-dropzone'
import { ReactComponent as Gallery } from '../../assets/images/gallery.svg'
import { Stack } from 'react-bootstrap'

export const ImageUploader = ({ name, className, ...rest }) => {
    return (
        <Dropzone
            accept={{
                'image/svg+xml': ['.svg'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/gif': ['.gif']
            }}
            {...rest}
        >
            {({ getRootProps, getInputProps }) => (
                <Stack gap={2} {...getRootProps({ className: `dropzone__wrapper cursor-pointer align-items-center ${className}` })}>
                    <input name={name} className='position-static' {...getInputProps()} />
                    <Gallery />
                    <span className='text-center dropzone__instruction'>
                        <span className='color-brand-primary fw-500'>Click to replace</span> or drag and drop
                    </span>
                    <span className='text-center font-14 dropzone__accepted-formats'>
                        SVG, PNG, JPG or GIF (max. 400 x 400px)
                    </span>
                </Stack>
            )}
        </Dropzone>
    )
}
