import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const MyTooltip = ({ id, children, tooltipClassName, content, ...rest }) => {

    const renderTooltip = (props) => {
        return (
            <Tooltip
                id={id}
                className={`shadow rounded-3 px-3 py-2 color-gray-700 ${tooltipClassName}`}
                data-bs-theme='dark'
                {...props}
            >
                {content || 'This is a default tooltip'}
            </Tooltip>
        );
    };

    return (
        <OverlayTrigger placement="auto" overlay={renderTooltip} {...rest}>
            {children}
        </OverlayTrigger>
    )
}
