import React, { useState } from "react";
import { MainDefaultSettingSideBar } from "../../../components/common/Default";
import { Form, Stack } from "react-bootstrap";
import { Input } from "../../../components/common/input/Input";
import { Button } from "../../../components/common/button/Button";
import Underline from "../../../components/buyer-provider/Underline";

export const Password = () => {
  const [formData, setFormData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const validateField = (name, value) => {
    var error = "";
    if (name === "password") {
      if (!value) {
        error = "Old password is required";
      }
    } else if (name === "newPassword") {
      if (!value) {
        error = "New password is required";
      } else if (value.length < 8) {
        error = "New password must be at least 8 characters";
      }
    } else if (name === "confirmPassword") {
      if (value !== formData.newPassword) {
        error = "Passwords do not match";
      }
    }
    return error;
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      !Object.values(errors).some((err) => err !== "") &&
      Object.values(formData).every((val) => val !== "")
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
    if (!formData.password) {
      newErrors.password = "Old password is required";
    }
    if (!formData.newPassword) {
      newErrors.newPassword = "New password is required";
    } else if (formData.newPassword.length < 8) {
      newErrors.newPassword = "New password must be at least 8 characters";
    }
    if (formData.confirmPassword !== formData.newPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
  };

  return (
    <MainDefaultSettingSideBar>
      <div className="top-nav py-3 mb-3">
        <h4 className="mb-0">Password</h4>
      </div>

      <Form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit}>
        <div className="mt-4">
          <Input
            label="Old password"
            type="password"
            name="password"
            id="password"
            htmlFor="password"
            value={formData.password}
            onChange={handlePasswordChange}
            error={errors.password}
            placeholder="***************"
            className="w-50"
          />
        </div>
        <div className="mt-4">
          <Input
            label="New password"
            type="password"
            name="newPassword"
            id="new_password"
            htmlFor="new_password"
            value={formData.newPassword}
            onChange={handlePasswordChange}
            error={errors.newPassword}
            placeholder="***************"
            className="w-50"
          />
        </div>
        <div className="mt-4">
          <Input
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            id="confirm_password"
            htmlFor="confirm_password"
            value={formData.confirmPassword}
            onChange={handlePasswordChange}
            error={errors.confirmPassword}
            placeholder="***************"
            className="w-50"
          />
        </div>

        <Underline />
        <Stack direction="horizontal" className=" mt-4" gap={4}>
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="fw-semibold p-3"
            disabled={!isFormValid()}
          >
            Save Changes
          </Button>
        </Stack>
      </Form>
    </MainDefaultSettingSideBar>
  );
};
