import React, { useMemo, useState } from "react";
import { Table } from "../../../../components/common/table/Table";
import { ListGroup, NavDropdown, Offcanvas } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { AddEditProject } from "../add-edit-project/AddEditProject";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { ProjectDetails } from "../project-details/ProjectDetails";
import { RxCross1 } from "react-icons/rx";
import { ReactComponent as CancelWO } from '../../../../assets/images/cancel-wo.svg'
import { AlertModal } from "../../../../components/buyer/work-orders/modals/AlertModal";

export const ProjectTable = () => {
  const [isEditProject, setIsEditProject] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const Columns = useMemo(
    () => [
      {
        accessorKey: "projectTitle",
        header: () => "Project Title",
        cell: (info) => (
          <span
            className="font-12 line-height-160 color-grayscale-900 cursor-pointer"
            onClick={() => setProjectDetails(true)}
          >
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "description",
        header: () => "Description",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "projectOwner",
        header: () => "Project Owner",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },

      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => setIsEditProject(true)}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Edit Project
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => setShowDeleteAlert(true)}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Delete
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const Rows = [
    {
      projectTitle: "Brooklyn, London",
      description: "Brooklyn, London",
      projectOwner: "Brooklyn, London",
    },
  ];
  return (
    <>
      <div className="mt-2 p-3">
        <Table columns={Columns} data={Rows} />
      </div>

      <RightOffcanvas
        show={isEditProject}
        onHide={() => setIsEditProject(false)}
      >
        <div className="p-3">
          <h5 className="text-black fw-semibold font-20">Edit Project</h5>
          <p>You can edit project details</p>
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <AddEditProject
            setIsCreateProject={setIsEditProject}
            type="Edit Project"
            key="Edit"
          />
        </Offcanvas.Body>
      </RightOffcanvas>
      <RightOffcanvas
        show={projectDetails}
        onHide={() => setProjectDetails(false)}
      >
        <div className="p-3 d-flex align-items-center justify-content-between">
          <h5 className="text-black fw-semibold font-20">Project Details</h5>
          <RxCross1 size={20} onClick={() => setProjectDetails(false)} className="cursor-pointer"/>
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <ProjectDetails setProjectDetails={setProjectDetails} />
        </Offcanvas.Body>
      </RightOffcanvas>
      <AlertModal 
        show={showDeleteAlert} 
        handleClose={() => setShowDeleteAlert(false)} 
        image={CancelWO} 
        title="Project cannot be deleted"
        subtitle="This project is on an active work order"
      />
    </>
  );
};
