import { ResetPasswordWrapper } from '../../../../components/common/Default'
import { ReactComponent as Lock } from '../../../../assets/images/lock.svg'
import { Col, Form, Row } from 'react-bootstrap'
import { Button as MyButton } from '../../../../components/common/button/Button'
import { Input } from '../../../../components/common/input/Input'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'

export const ResetPassword = () => {

    const navigate = useNavigate()
    const params = useParams()

    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [confirmPasswordError, setConfirmPasswordError] = useState('')
    const [isValid, setIsValid] = useState({
        minLength: false,
        hasNumber: false,
        hasUppercase: false,
        hasLowercase: false,
    });

    const handlePasswordChange = (e) => {
        const { name, value } = e.target
        if (name === 'password') {
            // Password validation checks
            setIsValid({
                minLength: value.length >= 8,
                hasNumber: /[0-9]/.test(value),
                hasUppercase: /[A-Z]/.test(value),
                hasLowercase: /[a-z]/.test(value),
            });
        } else if (name === 'confirmPassword') {
            var error = '';
            if (!value) {
                error = 'Confirm Password is required';
            } else if (value !== formData?.password) {
                error = 'Passwords does not match'
            }
            setConfirmPasswordError(error)
        }

        const newFormData = {
            ...formData,
            [name]: value
        }
        setFormData(newFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        setConfirmPasswordError('')

        if(!Object.values(isValid).every(e => e)) {
            return showToaster('Password is invalid. Please fix the issues.', 'error')
        }
        
        if(!formData?.confirmPassword) {
            return setConfirmPasswordError('Confirm Password is required')
        } else if (formData?.password !== formData?.confirmPassword) {
            return setConfirmPasswordError('Passwords does not match')
        }
        
        const data = {
            token: params?.token,
            password: formData?.password
        }

        try {
            await PostRequest('/buyer/reset-password', data, { showLoader: 'reset-password-button' })
            navigate('/reset-password-success')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        const verifyResetPasswordToken = async () => {
            try {
                const response = await PostRequest('/buyer/reset-password/verify-token', { token: params?.token })
                console.log('response', response)
            } catch (error) {
                setIsError(true)
                // showToaster(error.message, 'error')
            } finally {
                setIsLoading(false)
            }
        }
        verifyResetPasswordToken()
    }, [params?.token])

    // Check if the form is valid
    const isFormValid = Object.values(isValid).every(e => e) && Object.values(formData).every(v => v) && confirmPasswordError === ''

    if(isLoading) return <div>Loading</div>
    
    if(isError && !isLoading) return <div>404 - Not Found</div>

    return (
        <ResetPasswordWrapper>
            <div className='text-center'>
                <Lock className='mb-4' />
                <div className='mb-3'>
                    <h5 className='welcome-heading fs-3 line-height-125'>Create a new password</h5>
                    <p
                        className='get-started fw-normal line-height-150 mx-auto'
                        style={{ maxWidth: '300px' }}
                    >
                        Set your new password with minimum 8 characters with a combination of letters and numbers
                    </p>
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <Input
                    label='New password'
                    type='password'
                    name='password'
                    id='password'
                    htmlFor='password'
                    value={formData?.password}
                    onChange={handlePasswordChange}
                    placeholder='Enter your new password'
                    className='mb-2'
                />
                <Row className='mb-2'>
                    <Col md={6}>
                        {isValid?.minLength ? (
                            <FaCircleCheck className='color-green' />
                        ) : (
                            <FaCircleXmark className='color-thunderbird' />
                        )}
                        <span className={`ms-2 font-14 line-height-160 ${isValid?.minLength ? 'color-green' : 'color-thunderbird'}`}>8 characters</span>
                    </Col>
                    <Col md={6}>
                        {isValid?.hasNumber ? (
                            <FaCircleCheck className='color-green' />
                        ) : (
                            <FaCircleXmark className='color-thunderbird' />
                        )}
                        <span className={`ms-2 font-14 line-height-160 ${isValid?.hasNumber ? 'color-green' : 'color-thunderbird'}`}>Number (0-9)</span>
                    </Col>
                    <Col md={6}>
                        {isValid?.hasUppercase ? (
                            <FaCircleCheck className='color-green' />
                        ) : (
                            <FaCircleXmark className='color-thunderbird' />
                        )}
                        <span
                            className={`ms-2 font-14 line-height-160 ${isValid?.hasUppercase ? 'color-green' : 'color-thunderbird'}`}>
                            Uppercase letter (A-Z)
                        </span>
                    </Col>
                    <Col md={6}>
                        {isValid?.hasLowercase ? (
                            <FaCircleCheck className='color-green' />
                        ) : (
                            <FaCircleXmark className='color-thunderbird' />
                        )}
                        <span
                            className={`ms-2 font-14 line-height-160 ${isValid?.hasLowercase ? 'color-green' : 'color-thunderbird'}`}>
                            Lowercase letter (a-z)
                        </span>
                    </Col>
                </Row>
                <Input
                    label='Confirm new password'
                    type='password'
                    name='confirmPassword'
                    id='c_password'
                    htmlFor='c_password'
                    value={formData?.confirmPassword}
                    onChange={handlePasswordChange}
                    error={confirmPasswordError}
                    placeholder='Re-type your new password'
                    className='mb-3'
                />
                <MyButton type='submit' id='reset-password-button' disabled={!isFormValid} className='w-100 mb-4'>Submit</MyButton>
            </Form>
            <div className='login-text line-height-160 text-black'>
                Don't have an account? {' '}
                <Link to='/buyer/welcome' className='text-decoration-none color-primary'>Sign Up</Link>
            </div>
        </ResetPasswordWrapper>
    )
}
