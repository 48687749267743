import React, { useState } from "react";
import {
  CardWrapper,
  MainDefault,
} from "../../../../components/common/Default";
import "../myProfile.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Col, Form, Offcanvas, Row } from "react-bootstrap";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { RxCross1 } from "react-icons/rx";
import { MyAddPayment } from "./my-add-payment/MyAddPayment";
import { BsBank2 } from "react-icons/bs";
import { Button } from "../../../../components/common/button/Button";

export const MyPaymentDetails = () => {
  const [addPayment, setAddPayment] = useState(false);

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4">
        <h4 className="mb-0">Payment Details</h4>
      </div>

      <Row>
        <Col sm={3}>
          <CardWrapper className="p-3 h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <BsBank2 className="color-primary" size={24} />

              <div className="default-badge font-12">DEFAULT</div>
            </div>
            <p className="font-14 line-height-160 fw-500 mb-1">
              RN: ***********2821
            </p>
            <p className="font-12 line-height-160 color-grey-500">
              Omotayo Heurets | **********234 | Checking
            </p>

            <hr className="border-botticelli opacity-100" />

            <div className="d-flex gap-2 align-items-center">
              <Button
                type="button"
                variant="outline"
                className="fw-semibold color-ebony edit-btn"
              >
                Edit
              </Button>
              <Button
                type="button"
                variant="outline"
                className="fw-semibold cancel-btn"
              >
                Clear
              </Button>
            </div>
          </CardWrapper>
        </Col>
        <Col sm={3}>
          <CardWrapper className="p-3 h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <BsBank2 className="color-primary" size={24} />

              <div className="set-as-default-badge font-12">SET AS DEFAULT</div>
            </div>
            <p className="font-14 line-height-160 fw-500 mb-1">
              m***********e@gmail.com
            </p>

            <hr className="border-botticelli opacity-100" />

            <div className="d-flex gap-2 align-items-center">
              <Button
                type="button"
                variant="outline"
                className="fw-semibold color-ebony edit-btn"
              >
                Edit
              </Button>
              <Button
                type="button"
                variant="outline"
                className="fw-semibold cancel-btn"
              >
                Clear
              </Button>
            </div>
          </CardWrapper>
        </Col>
        <Col sm={3}>
          <div
            className="add-payment d-flex flex-column align-items-center justify-content-center cursor-pointer h-100"
            onClick={() => setAddPayment(true)}
          >
            <IoIosAddCircleOutline size={24} color="#667185" />
            <p className="mt-2 font-12 line-height-160 fw-500 --custom-grey-800">
              Add new
            </p>
          </div>
        </Col>
      </Row>

      <CardWrapper className="mt-5">
        <div className="w-50">
          <div className="d-flex gap-2">
            <div>
              <p className="font-16 fw-bold header-color mb-2">
                Enable SendRobin
              </p>
              <p className="font-12 color-fiord line-height-20 w-50">
                SendRobin is a fast payment platform used by WorkRobin. You will
                pay a 3% fee to take out money with this service. This fee pays
                for the transaction and keeps the service running
              </p>
            </div>

            <Form.Check type="switch" className="switch-enable" />
          </div>
        </div>
      </CardWrapper>

      <RightOffcanvas show={addPayment} onHide={setAddPayment} className="w-25">
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
              Add Payment
            </h6>
            <RxCross1
              size={20}
              onClick={() => setAddPayment(false)}
              className="cursor-pointer"
            />
          </div>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <MyAddPayment setAddPayment={setAddPayment} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </MainDefault>
  );
};
