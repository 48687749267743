import {
  ACTIVE_LAYOUT,
  CHECKED_ITEMS,
  FETCH_BUSINESS_DETAILS,
  FETCH_BUYER_DETAILS,
  FETCH_INDUSTRIES,
  FETCH_TAX_DETAILS,
  FETCH_TEAM_MEMBERS,
  FETCH_WALLET_DETAILS,
} from "./buyer.action";

const initialState = {
  buyerDetails: null,
  businessDetails: null,
  industries: [],
  taxDetails: null,
  walletDetails: null,
  teamMembers: null,
  activeTab: "",
  checkedItems: []
};

export const buyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUYER_DETAILS:
      return { ...state, buyerDetails: action.value };
    case FETCH_BUSINESS_DETAILS:
      return { ...state, businessDetails: action.value };
    case FETCH_INDUSTRIES:
      return { ...state, industries: action.value };
    case FETCH_TAX_DETAILS:
      return { ...state, taxDetails: action.value };
    case FETCH_WALLET_DETAILS:
      return { ...state, walletDetails: action.value };
    case FETCH_TEAM_MEMBERS:
      return { ...state, teamMembers: action.value };
    case ACTIVE_LAYOUT:
      return { ...state, activeTab: action.value };
    case CHECKED_ITEMS:
      return { ...state, checkedItems: action.value };

    default:
      return state;
  }
};
