import React from "react";
import {
  CardWrapper,
  MainDefault,
} from "../../../components/common/Default";
import { Col, Row } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";

export const ProviderSyncCalendar = () => {
  return (
    <MainDefault>
      <div className="top-nav py-3 mb-3 px-3">
        <h4 className="mb-0">Sync Calendar</h4>
      </div>

      <Row className="px-3">
        <Col sm={2}>
          <CardWrapper>
            <FcGoogle size={24} />

            <div className="d-flex align-items-center justify-content-between mt-2">
              <p className="font-18 line-height-160 fw-500">Google</p>
              <p className="font-14 line-height-160 color-primary">Sync now</p>
            </div>
          </CardWrapper>
        </Col>
        <Col sm={2}>
          <CardWrapper>
            <FaApple size={24} color="#98a2b3" />

            <div className="d-flex align-items-center justify-content-between mt-2">
              <p className="font-18 line-height-160 fw-500">Apple</p>
              <p className="font-14 line-height-160 color-primary">Sync now</p>
            </div>
          </CardWrapper>
        </Col>
      </Row>
    </MainDefault>
  );
};
