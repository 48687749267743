import { ReactComponent as Lock } from '../../../../../assets/images/lock.svg'
import { Col, Row, Stack } from 'react-bootstrap'
import {
    // HiDevicePhoneMobile, 
    HiEnvelope
} from 'react-icons/hi2'
import { MainDefault } from '../../../../../components/common/Default'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuthBuyer, showToaster } from '../../../../../utils/utils'
import PostRequest from '../../../../../services/PostRequest'

export const BuyerProfileIdentityConfirmation = () => {
    
    const navigate = useNavigate()
    const location = useLocation()

    const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer()
    
    const handleSendMail = async () => {
        try {
            const data = {
                businessEmail: parsedAuthBuyer?.businessEmail,
                type: 'update-tax-details'
            }
            const response = await PostRequest('/buyer/send-otp/email', data)
            navigate('/profile/tax-information/information-protection', { state: { ...response, ...location.state  } })
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }
    
    return (
        <MainDefault>
            <Row className='justify-content-center align-items-center' style={{ height: 'calc(100vh - 200px)' }}>
                <Col xs={11} sm={8} md={4}>
                    <div className='text-center'>
                        <Lock className='mb-4' />
                        <div className='mb-3'>
                            <h5 className='welcome-heading fs-3 line-height-125'>Verify your Identity</h5>
                            <p className='get-started fw-normal line-height-150 mx-auto' style={{ maxWidth: '350px' }}>
                                Choose a method to receive a code
                            </p>
                        </div>
                    </div>
                    <hr className='border-botticelli opacity-100' />
                    <Stack direction='horizontal' onClick={handleSendMail} className='gap-2 cursor-pointer'>
                        <HiEnvelope size={20} className='color-primary' />
                        <span className='font-14 text-black fw-medium'>Email: *************@gmail.com</span>
                    </Stack>
                    <hr className='border-botticelli opacity-100' />
                    {/* <Stack direction='horizontal' className='gap-2'>
                        <HiDevicePhoneMobile size={20} className='color-primary' />
                        <span className='font-14 text-black fw-medium'>Text: *****1234</span>
                    </Stack>
                    <hr className='border-botticelli opacity-100' /> */}
                </Col>
            </Row>
        </MainDefault>
    )
}
