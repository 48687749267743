import React, { useRef, useState } from "react";
import { MainDefault } from "../../../../components/common/Default";
import { Input } from "../../../../components/common/input/Input";
import Dropzone from "react-dropzone";
import { ReactComponent as Gallery } from "../../../../assets/images/gallery.svg";
import { Stack } from "react-bootstrap";
import { Button } from "../../../../components/common/button/Button";
import { BiSolidFilePdf } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { UploadResume } from "../general/upload-resume/UploadResume";

export const MyBioAndResume = () => {
  const inputRef = useRef(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const onDrop = (files) => {
    console.log("Accepted Files:", files);
    setAcceptedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4 ">
        <h4 className="mb-0">Bio & Resume</h4>
      </div>

      <div className="w-50">
        <Input
          label="Company Bio"
          type="text"
          placeholder="john.doe@gmail.com"
          name="companyBio"
          // value={formData.companyBio}
          // onChange={handleChange}
          // error={errors.companyBio}
          className="mb-3"
          multiline="h"
          style={{
            height: "140px",
          }}
        />
      </div>

      <div className=" ">
        <p className="form-label line-height-160 mb-2">My Resume</p>
        {acceptedFiles.length > 0 && (
          <UploadResume
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
          />
        )}
        <Dropzone
          maxFiles={1}
          accept={{
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
          }}
          onDrop={onDrop}
          //   onDrop={(acceptedFiles) => {
          //     console.log("Accepted files:", acceptedFiles);
          //   }}
        >
          {({ getInputProps }) => (
            <>
              <input
                name="resume"
                style={{ display: "none" }}
                {...getInputProps({
                  ref: (node) => {
                    inputRef.current = node;
                  },
                })}
              />
              <Button
                type="button"
                className="py-3 upload-resume-btn"
                onClick={() => inputRef.current?.click()}
              >
                Upload your resume
              </Button>
            </>
          )}
        </Dropzone>
      </div>

      <div className="d-flex gap-4 mt-5">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-3 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          // onClick={() => setUploadBackground(false)}
        >
          Cancel
        </Button>

        <Button
          // disabled={!isFormValid()}
          type="submit"
          className="fw-semibold py-3 px-3"
          //   onClick={() => setPaymentSuccessful(true)}
        >
          Save Changes
        </Button>
      </div>
    </MainDefault>
  );
};
