import React from "react";
import { CardWrapper } from "../../../../components/common/Default";
import { FUND_ADDED, MASTER_CARD } from "../../../../assets/images.assets";

export const PaymentSuccessful = ({ setPaymentSuccessful }) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <img src={FUND_ADDED} height={70} width={85} />

          <div className="mt-4">
            <h6 className="font-20 latter_spacing-1 fw-600">
              Payment Successful
            </h6>

            <p className="font-14 color-gray-700">
              You have paid successfully for the order of the <br /> background
              check
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
