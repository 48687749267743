import moment from "moment";
import React, { useMemo } from "react";
import { Badge, ListGroup, NavDropdown } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { Table } from "../../../../components/common/table/Table";

const MyWWorkInvitesTable = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "work_order_title",
        header: () => "Work Order Title",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },

      {
        accessorKey: "work_type",
        header: () => "Work Type",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => {
          const status = info.renderValue();
          console.log("status", status);

          return (
            <Badge
              className={`rounded-3 text-uppercase ${
                status == "Active"
                  ? "badge-status__active"
                  : "badge-status__inactive"
              }`}
            >
              {info.renderValue()}
            </Badge>
          );
        },
        // enableSorting: false
      },
      {
        accessorKey: "start",
        header: () => "Start",
        cell: (info) => <span>{moment(info.getValue()).format("LL")}</span>,
        // enableSorting: false
      },
      {
        accessorKey: "end_date",
        header: () => "End Date",
        cell: (info) => (
          <span>{moment(info.getValue()).format("MMMM D, YYYY")}</span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "invited_by",
        header: () => "Invited by",
        cell: (info) => (
          <div className="d-flex align-items-center">
            <div className="assigned-avatar color-green">MO</div>

            <div className="ms-2">
              <span className="f-18">Miracle Obafemi Paul</span>
              <p className="font-12 color-fiord">Amsterdam, United Kingdom</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "price",
        header: () => "Price",
        cell: (info) => (
          <span className="color-grey-900 fw-semibold">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Manage order
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Modify
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700"
                >
                  Cancel order
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      work_order_title: "Plumber",
      work_type: "Internal",
      status: "Active",
      price: "$500",
    },
    {
      work_order_title: "Plumber",
      work_type: "Internal",
      status: "Active",
      price: "$500",
    },
    {
      work_order_title: "Plumber",
      work_type: "Internal",
      status: "Active",
      price: "$500",
    },
    {
      work_order_title: "Plumber",
      work_type: "Internal",
      status: "Active",
      price: "$500",
    },
    {
      work_order_title: "Plumber",
      work_type: "Internal",
      status: "Active",
      price: "$500",
    },
  ];

  return <Table columns={columns} data={rows} />;
};

export default MyWWorkInvitesTable;
