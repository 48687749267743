import React, { Fragment, useState } from 'react'
import { Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { DOCU_SIGN } from '../../../../assets/images.assets'
import { NewAgreement } from './NewAgreement'

export const DocuSign = ({ show, handleClose }) => {
    
    const [showNewAgreement, setShowNewAgreement] = useState(false)
    
    return (
        <Fragment>
            <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
                <Modal.Header className='docusign_modal-header' closeButton />
                <Modal.Body>
                    <Stack gap={3} className='align-items-center mb-3'>
                        <img src={DOCU_SIGN} className='docusign__img' alt="" />
                        <div className='text-center'>
                            <p className='font-14 text-black fw-medium mb-2'>DocuSign</p>
                            <p className='color-gray-700'>
                                Send, Sign, Success
                            </p>
                        </div>
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <Button
                            type='button'
                            onClick={() => setShowNewAgreement(true)}
                            className='fw-semibold font-14 py-2'
                        >
                            Sign In
                        </Button>
                        <Button
                            type='button'
                            variant='outline'
                            className='color-ebony padded-border-button font-14 py-2'
                            onClick={handleClose}
                        >
                            Start a free account
                        </Button>
                    </Stack>
                </Modal.Body>
            </Modal>
            <NewAgreement show={showNewAgreement} handleClose={() => setShowNewAgreement(false)} />
        </Fragment>
    )
}
