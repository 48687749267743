import React, { Fragment } from 'react'
import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'

const FILTER_OPTIONS = [
    {
        title: 'General',
        children: [
            'Jobs Completed',
            'Minimum Rating',
            'Maximum Rating',
            'Skill Set',
            'Equipment',
            'Work Type',
            'Country',
            'Drug Test',
            'Licenses & Certifications',
            'Background Check',
            'Resume (CV)'
        ]
    },
    {
        title: 'Specific',
        children: [
            'Assigned',
            'Active Work Orders',
            'Talent Pool',
            'Talent Type',
            'Saved Location'
        ]
    }
]

export const Filters = ({ show, handleClose }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='d-flex flex-column'>
                <Form className='d-flex flex-column flex-grow-1'>
                    {FILTER_OPTIONS.map((filterOption, i) => {
                        const isLastOption = i === FILTER_OPTIONS.length - 1
                        return (
                            <Fragment key={i}>
                                <p className='text-black font-14 fw-medium mb-2'>{filterOption?.title}</p>
                                {filterOption?.children?.map((option, index) => (
                                    <Form.Check
                                        key={option}
                                        type='checkbox'
                                        id={`checkbox-${i}-${index}`}
                                        className='buyer-checkbox filter-wo__checkbox'
                                        label={option}
                                    />
                                ))}
                                {!isLastOption && <hr className='opacity-100 border-grayscale-100' />}
                            </Fragment>
                        )
                    })}
                    <Stack direction='horizontal' className='justify-content-between mt-auto' gap={4}>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 padded-border-button color-ebony'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='fw-semibold p-3'
                        >
                            Apply
                        </Button>
                    </Stack>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
