import { Col, Row, Stack } from "react-bootstrap";
import { CardWrapper } from "../../../common/Default";
import { ReactComponent as Business } from "../../../../assets/images/business.svg";
import { HiDotsVertical, HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import PublishedContentTable from "./PublishedContentTable";
import { ViewCalendar } from "../../../buyer-provider/view-calendar/ViewCalendar";

export const DraftsContent = () => {

  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);

  const events = [
    {
      title: "Plumber",
      start: new Date().toISOString().split("T")[0] + "T07:00:00",
      location: "Birmingham, United Kingdom",
      charge: "$500",
    },
  ];

  return (
    <Fragment>
      {_activeTabLayout === "list" ? (
        <PublishedContentTable />
      ) : _activeTabLayout === "cards" ? (
        <Row className="row-gap-4">
          <Col md={3}>
            <Link to="/work-orders/details">
              <DraftsCard />
            </Link>
          </Col >
          <Col md={3}>
            <Link to="/work-orders/details">
              <DraftsCard />
            </Link>
          </Col>
          <Col md={3}>
            <Link to="/work-orders/details">
              <DraftsCard />
            </Link>
          </Col>
          <Col md={3}>
            <Link to="/work-orders/details">
              <DraftsCard />
            </Link>
          </Col>
        </Row >
      ) : (
        <ViewCalendar events={events} headerToolbar={false} />
      )}
    </Fragment >
  );
};

const DraftsCard = () => {
  return (
    <CardWrapper>
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-center mb-2"
      >
        <Business />
        <HiDotsVertical className="color-grey-500 cursor-pointer" />
      </Stack>
      <span className="color-grey-500 font-10 fw-medium">#1233944</span>
      <p className="font-14 fw-semibold line-clamp-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio accusamus,
        fugit quaerat rem qui obcaecati.
      </p>
      <div className="d-flex align-items-center color-grey-500 my-2">
        <HiOutlineLocationMarker />
        <span className="font-12 lh-1">Birmingham, United Kingdom</span>
      </div>
      <p className="font-12 color-grayscale-500 line-clamp-2">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam placeat
        expedita consequuntur repudiandae optio necessitatibus.
      </p>
      <span className="card-tag__fixed rounded color-grey-900">DRAFT</span>
    </CardWrapper>
  );
};
