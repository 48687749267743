import React, { useState } from "react";
import { CardWrapper } from "../../common/Default";
import { RightOffcanvas } from "../RightOffcanvas";
import { Offcanvas } from "react-bootstrap";
import { AddEditTicket } from "./add-edit-ticket/AddEditTicket";
import { Button } from "../../common/button/Button";

export const SupportCard = ({
  children,
  heading,
  description,
  image,
  flexColReverse,
  isTicket,
  setCreateTicket,
}) => {
  return (
    <>
      <CardWrapper>
        <div
          className={`${
            flexColReverse
              ? "d-flex flex-column-reverse"
              : "d-flex justify-content-between"
          }`}
        >
          <div className={`${flexColReverse ? "mt-3" : ""}`}>
            <h6 className="font-18 line-height-160v fw-500">{heading}</h6>
            <p className="font-14 line-height-160 color-grey-500 w-75">
              {description}
            </p>

            {isTicket ? (
              <Button
                type="submit"
                className="fw-semibold py-3 px-3 mt-5"
                onClick={setCreateTicket}
              >
                Create Ticket
              </Button>
            ) : (
              children
            )}
          </div>
          <img src={image} height={205} width={205} />
        </div>
      </CardWrapper>
    </>
  );
};
