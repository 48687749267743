import React from "react";
import { CardWrapper } from "../common/Default";
import Underline from "../buyer-provider/Underline";

export const PaymentDetails = () => {
  return (
    <CardWrapper className='additional-information'>
      <div className="d-flex align-items-center gap-2">
        <h6 className="pt-2">Payment Details</h6>

        <div className="status-in-hours">HOURLY</div>
      </div>
      <Underline className="mt-2" />

      <div className="mt-4">
        <h6>Payment Type</h6>
        <div className="payment-type">
          <p className="pt-2">
            D+7 Type (processed on the Friday following work order approval)
          </p>
          <p className="pt-2">Approval Review Period: 2 days</p>
        </div>
        <Underline />
      </div>

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="payment-type">
            <p>Rate</p>
            <p className="pt-2">$20.00</p>
          </div>
          <div className="payment-type">
            <p>Hours</p>
            <p className="pt-2"> Max</p>
          </div>
          <div>
            <p className="fee-wrapper">Labor Fee</p>
            <p className="pt-2 amount-wrapper">$100.00</p>
          </div>
        </div>
      </div>
      <Underline />

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fee-wrapper">Expense</div>
          <div className="amount-wrapper">$0.00</div>
        </div>
        <Underline />
        <div className="mt-4 d-flex justify-content-between align-items-center">
          <div className="fee-wrapper">Tax Estimate</div>
          <div className="amount-wrapper">$0.00</div>
        </div>
        <Underline />
        <div className="mt-4 d-flex justify-content-between align-items-center">
          <div className="fee-wrapper">Total</div>
          <div className="amount-wrapper">$100.00</div>
        </div>
      </div>
    </CardWrapper>
  );
};
