import React, { Fragment } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { PaymentDetails } from "../../../../job-info-tab/PaymentDetails";
import { CardWrapper } from "../../../../common/Default";
import { FaLessThan } from "react-icons/fa6";
import LocationCommon from "../../../../buyer-provider/LocationCommon";
import "./work-table-default.css";
import Underline from "../../../../buyer-provider/Underline";


const WorkTableDefault = (props) => {
  return (
    <Row>
      <Col sm={8}>{props.children}</Col>
      <Col sm={4}>
        <CardWrapper>
          <h6 className="mt-4">Company Report</h6>
          <Underline />
          <div className="mt-4 d-flex justify-content-between">
            <div className="color-fiord">Satisfaction Rating</div>
            <div className="color-green fw-400">89%</div>
          </div>

          <div className="mt-2 d-flex justify-content-between">
            <div className="color-fiord">Satisfaction Rating</div>
            <div className="color-green fw-400">
              <Badge className="color-green badge-status__in-progress px-2 py-2 fw-400">
                ON TIME
              </Badge>
            </div>
          </div>

          <div className="mt-2 d-flex justify-content-between">
            <div className="color-fiord">Satisfaction Rating</div>
            <div className="fw-400 color-primary  font-14">
              <FaLessThan size={12} /> 24 hours
            </div>
          </div>
        </CardWrapper>

        <div className="mt-4 mb-4">
          <PaymentDetails />
        </div>
        <div className="mb-4">
          <CardWrapper>
            <h6 className="mb-3">SendRobin</h6>
            <span className="font-12 color-fiord applied-provider color-gray-700 fw-500">ENABLED</span>
          </CardWrapper>
        </div>

        <LocationCommon />
      </Col>
    </Row>
  );
};

export default WorkTableDefault;
