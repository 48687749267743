import React from "react";
import { CardWrapper } from "../../../../../components/common/Default";
import { COMMON_IMG } from "../../../../../assets/images.assets";

export const InProgress = () => {
  return (
    <>
      {Array.from({ length: 3 }).map(() => {
        return (
          <CardWrapper className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <div>
                  <img src={COMMON_IMG} />
                </div>
                <div>
                  <p className="font-16 line-height-160 fw-500 color-neutrals-100">
                    Plumbing in the long quene
                  </p>
                  <p className="font-14 line-height-160 color-neutrals-60">
                    Crysta Room, United Kingdom
                  </p>
                  <p className="font-14 line-height-160 color-neutrals-60">
                    Jan 13 - Jan 14
                  </p>
                </div>
              </div>
              <div className="in-progress font-10 line-height-160 fw-500">
                IN-PROGRESS
              </div>
            </div>
          </CardWrapper>
        );
      })}
    </>
  );
};
