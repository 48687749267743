import React, { useState } from "react";
import Underline from "../../../../components/buyer-provider/Underline";
import { CardWrapper } from "../../../../components/common/Default";
import { Form, Offcanvas, Stack } from "react-bootstrap";
import { Button } from "../../../../components/common/button/Button";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { RxCross1 } from "react-icons/rx";
import { InputYourAmount } from "./InputYourAmount";
import { CreditCardInputYourAmount } from "./CreditCardInputYourAmount";

export const ChooseYourFundingMethod = ({ setShowFundingMethod }) => {
  const [inputYourAmount, setInputYourAmount] = useState(false);
  const [fundingMethod, setFundingMethod] = useState(null)

  const handleFundingMethodChange = (e) => {
    setFundingMethod(e.target.id)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Form className="form-wrapper" onSubmit={handleSubmit}>
        <div className="mt-3">
          <p className="font-14 line-height-160 fw-600">
            Choose your funding method
          </p>
          <Underline className="mt-3" />
        </div>

        <div className="mt-4">
          <CardWrapper className={`cursor-pointer ${fundingMethod === 'eCheck' ? 'border-primary' : ''}`}>
            <label className="d-flex gap-2 cursor-pointer" htmlFor="eCheck">
              <Form.Check
                name="group1"
                id='eCheck'
                type="radio"
                checked={fundingMethod === 'eCheck'}
                onChange={handleFundingMethodChange}
                className="mt-1"
              />
              <div>
                <p className="font-18 line-height-160 fw-600">eCheck</p>
                <p className="font-14 line-height-160 color-custom-grey-500 pt-1">
                  Deposit via Checking or Saving Accountto make funds
                  immediately vaailable. Processing Fees are waived for all
                  deposits b echeck
                </p>
              </div>
            </label>
          </CardWrapper>
          <CardWrapper className={`mt-4 cursor-pointer ${fundingMethod === 'creditCard' ? 'border-primary' : ''}`}>
            <label htmlFor="creditCard" className="d-flex gap-2 cursor-pointer">
              <Form.Check
                name="group1"
                id='creditCard'
                type="radio"
                checked={fundingMethod === 'creditCard'}
                onChange={handleFundingMethodChange}
                className="mt-1"
              />
              <div>
                <p className="font-18 line-height-160 fw-600">Credit Card</p>
                <p className="font-14 line-height-160 color-custom-grey-500 pt-1">
                  Deposit via Mastercard, Visa, Discover or American Express to
                  make funds immediately available
                </p>
              </div>
            </label>
          </CardWrapper>

          <div className="button-bottom-side d-flex">
            <Button
              type="button"
              variant="outline"
              className="fw-semibold px-3 color-ebony padded-border-button"
              onClick={() => {
                setFundingMethod(null)
                setShowFundingMethod(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="fw-semibold py-3 px-3"
              disabled={!fundingMethod || fundingMethod === null}
              onClick={() => setInputYourAmount(true)}
            >
              Next Step
            </Button>
          </div>
        </div>
      </Form>

      <RightOffcanvas
        show={inputYourAmount}
        handleClose={() => setInputYourAmount(false)}
        className={fundingMethod === 'eCheck' ? '' : 'timesheet-offcanvas'}
      >
        <div className="p-3 pb-0">
          <Stack direction="horizontal" className="justify-content-between">
            <h5 className="text-black fw-semibold font-20">Add Funds</h5>
            <RxCross1
              size={20}
              onClick={() => setInputYourAmount(false)}
              className="cursor-pointer"
            />
          </Stack>
          <h6 className="font-16 line-height-160 fw-600">Input your amount</h6>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          {fundingMethod === 'eCheck' ? (
            <InputYourAmount setInputYourAmount={setInputYourAmount} setShowFundingMethod={setShowFundingMethod} />
          ) : (
            <CreditCardInputYourAmount setInputYourAmount={setInputYourAmount} setShowFundingMethod={setShowFundingMethod} />
          )}
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
