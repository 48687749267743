import { MainDefault } from '../../../../components/common/Default'
import { Col, Form, Row, Stack } from 'react-bootstrap'
import { Input } from '../../../../components/common/input/Input'
import { Button } from '../../../../components/common/button/Button'

export const MyRatesAndLocation = () => {

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <MainDefault>
            <div className="top-nav py-3 mb-4 ">
                <h4 className="mb-0">Rates & Location</h4>
            </div>

            <Form className='w-50' onSubmit={handleSubmit}>
                <Stack direction='horizontal' className='justify-content-between mb-2 gap-3'>
                    <Input label='Onsite Hourly Rate' placeholder='$0.00' className='w-100' />
                    <Input label='Virtual Hourly Rate' placeholder='$0.00' className='w-100' />
                </Stack>
                <div className='mb-2'>
                    <p className='form-label line-height-160 mb-1'>Location</p>
                    <Row className='row-gap-2'>
                        <Col md={6}>
                            <Form.Check
                                type='checkbox'
                                id='commercial'
                                label='Commercial'
                                className='font-14 color-gray-700 fw-medium buyer-checkbox'
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Check
                                type='checkbox'
                                id='government'
                                label='Government'
                                className='font-14 color-gray-700 fw-medium buyer-checkbox'
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Check
                                type='checkbox'
                                id='residential'
                                label='Residential'
                                className='font-14 color-gray-700 fw-medium buyer-checkbox'
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Check
                                type='checkbox'
                                id='education'
                                label='Education'
                                className='font-14 color-gray-700 fw-medium buyer-checkbox'
                            />
                        </Col>
                    </Row>
                </div>
                <div className='mb-3'>
                    <p className='form-label line-height-160 mb-1'>Maximum Travel Distance</p>
                    <Input placeholder='$0.00' className='d-inline-block' />
                    <p className='ms-2 d-inline-block color-gray-700 fw-500'>miles from my zip code</p>
                </div>
                <Stack direction="horizontal" gap={4}>
                    <Button
                        variant="outline"
                        className="color-ebony fw-semibold padded-border-button"
                    >
                        Cancel
                    </Button>
                    <Button
                        // disabled={!isFormValid()}
                        type="submit"
                        className="fw-semibold py-14px"
                    >
                        Save Changes
                    </Button>
                </Stack>
            </Form>
        </MainDefault>
    )
}
