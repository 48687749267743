import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useCallback } from 'react'
import './richtexteditor.css'
import Link from '@tiptap/extension-link'
import { AiOutlineBold, AiOutlineItalic, AiOutlineOrderedList, AiOutlineUnorderedList } from 'react-icons/ai'
import { HiOutlineLink } from 'react-icons/hi2'
import Placeholder from '@tiptap/extension-placeholder'
import { Stack } from 'react-bootstrap'
import CharacterCount from '@tiptap/extension-character-count'

export const RichTextEditor = ({ limit = 500, placeholder, ...rest }) => {

    const extensions = [
        StarterKit.configure({
            bulletList: {
                keepMarks: true,
                keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
            },
            orderedList: {
                keepMarks: true,
                keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
            },
        }),
        Link.configure({
            openOnClick: false,
            autolink: true,
            defaultProtocol: 'https',
            protocols: ['http', 'https'],
            isAllowedUri: (url, ctx) => {
                try {
                    // construct URL
                    const parsedUrl = url.includes(':') ? new URL(url) : new URL(`${ctx.defaultProtocol}://${url}`)

                    // use default validation
                    if (!ctx.defaultValidate(parsedUrl.href)) {
                        return false
                    }

                    // disallowed protocols
                    const disallowedProtocols = ['ftp', 'file', 'mailto']
                    const protocol = parsedUrl.protocol.replace(':', '')

                    if (disallowedProtocols.includes(protocol)) {
                        return false
                    }

                    // only allow protocols specified in ctx.protocols
                    const allowedProtocols = ctx.protocols.map(p => (typeof p === 'string' ? p : p.scheme))

                    if (!allowedProtocols.includes(protocol)) {
                        return false
                    }

                    // disallowed domains
                    const disallowedDomains = ['example-phishing.com', 'malicious-site.net']
                    const domain = parsedUrl.hostname

                    if (disallowedDomains.includes(domain)) {
                        return false
                    }

                    // all checks have passed
                    return true
                } catch {
                    return false
                }
            },
            shouldAutoLink: url => {
                try {
                    // construct URL
                    const parsedUrl = url.includes(':') ? new URL(url) : new URL(`https://${url}`)

                    // only auto-link if the domain is not in the disallowed list
                    const disallowedDomains = ['example-no-autolink.com', 'another-no-autolink.com']
                    const domain = parsedUrl.hostname

                    return !disallowedDomains.includes(domain)
                } catch {
                    return false
                }
            },

        }),
        Placeholder.configure({
            placeholder,
        }),
        CharacterCount.configure({
            limit,
        })
    ]

    const content = '<p></p>'

    return (
        <EditorProvider
            slotAfter={<Menubar limit={limit} />}
            extensions={extensions}
            content={content}
            onUpdate={({ editor }) => {
                const charCount = editor.storage.characterCount?.characters()
                if (charCount >= limit) {
                    editor.commands.setContent(editor.getHTML())
                }
            }}
            {...rest}
        />
    )
}

const Menubar = ({ limit }) => {
    const { editor } = useCurrentEditor()

    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()

            return
        }

        // update link
        try {
            editor.chain().focus().extendMarkRange('link').setLink({ href: url })
                .run()
        } catch (e) {
            alert(e.message)
        }
    }, [editor])

    if (!editor) {
        return null
    }

    return (
        <div>
            <div className="mt-2 pb-2 border-wrapper">
                <button
                    type='button'
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                    }
                    className={`${editor.isActive('bold') ? 'is-active' : 'bg-transparent'} border-0`}
                >
                    <AiOutlineBold />
                </button>
                <button
                    type='button'
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    }
                    className={`${editor.isActive('italic') ? 'is-active' : 'bg-transparent'} border-0`}
                >
                    <AiOutlineItalic />
                </button>
                <button
                    type='button'
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={`${editor.isActive('orderedList') ? 'is-active' : 'bg-transparent'} border-0`}
                >
                    <AiOutlineOrderedList />
                </button>
                <button
                    type='button'
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={`${editor.isActive('bulletList') ? 'is-active' : 'bg-transparent'} border-0`}
                >
                    <AiOutlineUnorderedList />
                </button>
                <button
                    type='button'
                    onClick={setLink}
                    className={`${editor.isActive('link') ? 'is-active' : 'bg-transparent'} border-0`}
                >
                    <HiOutlineLink />
                </button>
            </div>
            <Stack direction='horizontal' className='justify-content-between pt-2'>
                <p className='color-grey-400'>Maximum {limit} characters</p>
                <p className='color-gray-700'>
                    {editor.storage.characterCount?.characters()} / {limit}
                </p>
            </Stack>
        </div>
    )
}
