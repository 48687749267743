import React from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { BuyerTaxInformationForm } from '../../../../components/profile/TaxInformationForm'
import { useNavigate } from 'react-router-dom'

export const CompanyTaxInformation = () => {

  const navigate = useNavigate()
  
  const handleSubmit = (onFormSubmit) => {
    const formSubmitResult = onFormSubmit()
    if (!formSubmitResult) return

    navigate('/profile/tax-information/identity-verification', { state: formSubmitResult })
  }

  return (
    <MainDefault>
      <div className='top-nav py-3 mb-3'>
        <h4 className='mb-0'>Tax Information</h4>
      </div>
      <BuyerTaxInformationForm
        // linkTo='/dashboard'
        // linkText='Cancel'
        // buttonText='Update Tax Information'
        wrapperClasses='w-50'
        fromProfile
        onSubmit={handleSubmit}
      />
    </MainDefault>
  )
}
