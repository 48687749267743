import { Form } from "react-bootstrap";
import { Input } from "../../../../components/common/input/Input";
import { Button } from "../../../../components/common/button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { AuthWrapper } from "../../../../components/common/Default";
import PostRequest from "../../../../services/PostRequest";
import { getAuthBuyer, showToaster } from "../../../../utils/utils";
import { emailPattern } from "../../../../data/constants";

export const Login = () => {
  const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    businessEmail: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    businessEmail: "",
    password: "",
  });

  const validateField = (name, value) => {
    var error = "";
    if (name === "businessEmail") {
      if (!value) {
        error = "Email is required";
      } else if (
        !emailPattern.test(value)
      ) {
        error = "Email is invalid";
      }
    } else if (name === "password") {
      if (!value) {
        error = "Password is required";
      } else if (value.length < 8) {
        error = "Your password is not strong enough. Use at least 8 characters";
      } else if (!/[0-9]/.test(value)) {
        error = "Use at least 1 digit";
      } else if (!/[A-Z]/.test(value)) {
        error = "Use at least 1 Uppercase letter";
      } else if (!/[a-z]/.test(value)) {
        error = "Use at least 1 Lowercase letter";
      }
    }
    return error;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () =>
    Object.values(errors).every((err) => err === "") &&
    Object.values(formData).every((value) => value);

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formErrors = {};

    if (!formData.businessEmail) {
      formErrors.businessEmail = "Email is required";
    } else if (
      !emailPattern.test(
        formData.businessEmail
      )
    ) {
      formErrors.businessEmail = "Email is invalid";
    }

    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      formErrors.password =
        "Your password is not strong enough. Use at least 8 characters";
    } else if (!/[0-9]/.test(formData.password)) {
      formErrors.password = "Use at least 1 digit";
    } else if (!/[A-Z]/.test(formData.password)) {
      formErrors.password = "Use at least 1 Uppercase letter";
    } else if (!/[a-z]/.test(formData.password)) {
      formErrors.password = "Use at least 1 Lowercase letter";
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await PostRequest("/buyer/login", formData, {
          showLoader: "login-button",
        });
        if (response?.isProfileCompleted) {
          navigate("/dashboard", { replace: true });
        } else {
          navigate("/get-started", { state: response?.firstName });
        }

        window.localStorage.setItem("TOKEN", response.token);
        window.localStorage.setItem("AUTH_BUYER", JSON.stringify(response));
      } catch (error) {
        console.log("error", error);
        showToaster(error.message, "error");
      }
    }
  };

  return (
    <AuthWrapper>
      <p className="get-started line-height-125 color-grey-500">LOGIN</p>
      <h5 className="welcome-heading line-height-125 mb-3">
        Welcome back
        {parsedAuthBuyer?.firstName ? `, ${parsedAuthBuyer?.firstName}` : ""}
      </h5>
      <Form onSubmit={handleSubmit}>
        <Input
          label="Email  Address"
          placeholder="example@company.com"
          type="email"
          name="businessEmail"
          htmlFor="email"
          id="email"
          autoComplete="email"
          value={formData.businessEmail}
          onChange={handleChange}
          error={errors.businessEmail}
          className="mb-2"
        />
        <Input
          label="Password"
          placeholder="Enter your password"
          type="password"
          name="password"
          htmlFor="password"
          id="password"
          value={formData.password}
          onChange={handleChange}
          error={errors.password}
          className="mb-2"
        />
        <div className="text-end mb-3">
          <Link
            to="/forgot-password"
            className="text-decoration-none color-blue font-14 fw-medium"
          >
            Forgot Password
          </Link>
        </div>
        <Button
          type="submit"
          id="login-button"
          disabled={!isFormValid()}
          className="w-100 mb-4"
        >
          Login
        </Button>
      </Form>
      <div className="login-text line-height-160 color-secondary">
        Don't have an account?{" "}
        <Link
          to="/buyer/welcome"
          className="text-decoration-none color-primary"
        >
          Sign Up
        </Link>
      </div>
    </AuthWrapper>
  );
};
