import { NotFoundCard } from '../../../../../components/profile/not-found/NotFoundCard'
import { Table } from '../../../../../components/common/table/Table'
import { useMemo, useState } from 'react'
import moment from 'moment'
import { NavDropdownWrapper } from '../../../../../components/common/header/nav-dropdown/Wrapper'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { ListGroup } from 'react-bootstrap'
import { AddInsurance } from '../../../../../components/profile/offcanvas/AddInsurance'

export const InsuranceContent = () => {

    const [showAddInsurance, setShowAddInsurance] = useState(false)

    const columns = useMemo(() => [
        {
            accessorKey: 'insuranceType',
            header: () => 'Insurance Type',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'policyNumber',
            header: () => 'Policy Number',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'provider',
            header: () => 'Provider',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'amount',
            header: () => 'Amount',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            header: 'Issue Date',
            accessorKey: 'issueDate',
            cell: (info) => moment(info.renderValue()).format('DD/MM/YYYY')
        },
        {
            header: 'Expiry Date',
            accessorKey: 'expiryDate',
            cell: (info) => moment(info.renderValue()).format('DD/MM/YYYY')
        },
        {
            id: "actions",
            header: () => "Action",
            cell: ({ row }) => {
                return (
                    <NavDropdownWrapper title={<MdOutlineMoreHoriz size={24} />}>
                        <ListGroup.Item
                            action
                            // onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            View document
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            // onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Update
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Delete
                        </ListGroup.Item>
                    </NavDropdownWrapper>
                );
            },
        },
    ], [])

    const rows = [
        {
            insuranceType: 'Health Insurance',
            policyNumber: '123456',
            provider: 'Blue Cross Blue Shield',
            amount: '$1000',
            issueDate: '2021-09-01',
            expiryDate: '2022-09-01'
        },
        {
            insuranceType: 'Health Insurance',
            policyNumber: '123456',
            provider: 'Blue Cross Blue Shield',
            amount: '$1000',
            issueDate: '2021-09-01',
            expiryDate: '2022-09-01'
        },
        {
            insuranceType: 'Health Insurance',
            policyNumber: '123456',
            provider: 'Blue Cross Blue Shield',
            amount: '$1000',
            issueDate: '2021-09-01',
            expiryDate: '2022-09-01'
        },
        {
            insuranceType: 'Health Insurance',
            policyNumber: '123456',
            provider: 'Blue Cross Blue Shield',
            amount: '$1000',
            issueDate: '2021-09-01',
            expiryDate: '2022-09-01'
        }
    ]

    return (
        <>
            {[''].length <= 0 ? (
                <>
                    <NotFoundCard
                        title="No insurance added"
                        subTitle="You have not add any insurance here"
                        buttonText="Add Insurance"
                        onButtonClick={() => setShowAddInsurance(true)}
                    />
                    <AddInsurance show={showAddInsurance} handleClose={() => setShowAddInsurance(false)} />
                </>
            ) : (
                <Table data={rows} columns={columns} />
            )}
        </>
    )
}
