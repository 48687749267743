import { PhotoAndLocationForm } from '../../../../components/authentication/profiling/PhotoAndLocationForm'
import { ProfileWrapper } from '../../../../components/common/Default'

const INFO = {
    title: 'Your photo and details',
    description: 'Getting your account more personalized'
}

export const PhotoAndLocation = () => {

    return (
        <ProfileWrapper isProvider email info={INFO}>
            <PhotoAndLocationForm  />
        </ProfileWrapper>
    )
}
