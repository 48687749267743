import { Col, ListGroup, NavDropdown, Row, Stack } from 'react-bootstrap'
import { CardWrapper } from '../../../common/Default'
import { ReactComponent as Business } from '../../../../assets/images/business.svg'
import { HiDotsVertical, HiOutlineLocationMarker } from 'react-icons/hi'
import { NavDropdownWrapper } from '../../../common/header/nav-dropdown/Wrapper'
import { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../common/table/Table'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import moment from 'moment'
import { ViewCalendar } from '../../../buyer-provider/view-calendar/ViewCalendar'

export const ApprovedContent = () => {

    const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);
    const events = [
        {
            title: "Plumber",
            start: new Date().toISOString().split("T")[0] + "T07:00:00",
            location: "Birmingham, United Kingdom",
            charge: "$500",
        },
    ];

    return (
        <Fragment>
            {_activeTabLayout === "list" ? (
                <ApprovedContentTable />
            ) : _activeTabLayout === "cards" ? (
                <Row className='row-gap-4'>
                    <Col md={3}>
                        <ApprovedCard />
                    </Col>
                    <Col md={3}>
                        <ApprovedCard />
                    </Col>
                    <Col md={3}>
                        <ApprovedCard />
                    </Col>
                    <Col md={3}>
                        <ApprovedCard />
                    </Col>
                </Row>
            ) : (
                <ViewCalendar events={events} headerToolbar={false} />
            )}
        </Fragment>
    )
}

const ApprovedCard = () => {
    return <CardWrapper>
        <Stack direction='horizontal' className='justify-content-between align-items-center mb-2'>
            <Business />
            <NavDropdownWrapper
                title={<HiDotsVertical className='color-grey-500 cursor-pointer' />}
            >
                <ListGroup.Item
                    action
                    onClick={() => { }}
                    className='cursor-pointer font-14 color-gray-700 border-bottom-0'
                >
                    Raise a dispute
                </ListGroup.Item>
            </NavDropdownWrapper>
        </Stack>
        <span className='color-grey-500 font-10 fw-medium'>#1233944</span>
        <p className='font-14 fw-semibold line-clamp-2'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Voluptate reiciendis ex doloremque ratione vel iste?
        </p>
        <Stack direction='horizontal' className='justify-content-between my-2'>
            <div className='d-flex align-items-center color-grey-500 gap-1'>
                <HiOutlineLocationMarker />
                <span className='font-12 lh-1 text-truncate' style={{ maxWidth: 120 }}>Birmingham, United Kingdom</span>
            </div>
            <span className='job-status badge-status__active'>COMPLETED</span>
        </Stack>
        <hr className='opacity-100 border-grayscale-100' />
        <div>
            <p className='color-pale-sky font-10 mb-1'>Amount to be paid</p>
            <h6 className='text-black fw-bold mb-0'>$500</h6>
        </div>
        <hr className='opacity-100 border-grayscale-100' />
        <div>
            <p className='color-pale-sky font-10 mb-1'>To be paid to</p>
            <Stack direction='horizontal' className='gap-2'>
                <img src='https://contentwriter.co/images/avatars/1708227404.jpg' className='card-image' alt='' />
                <div>
                    <p className='color-ebony fw-semibold font-12'>Ademola Adetunji</p>
                    <p className='font-10'>Amsterdam, United Kingdom</p>
                </div>
            </Stack>
        </div>
    </CardWrapper>
}

const ApprovedContentTable = () => {

    const columns = useMemo(
        () => [
            {
                accessorKey: "title",
                header: () => "Work Order Title",
                cell: (info) => info.renderValue(),
                // enableSorting: false
            },
            {
                accessorKey: "location",
                header: () => "Location",
                cell: (info) => info.renderValue(),
                // enableSorting: false
            },
            {
                accessorKey: "price",
                header: () => "Amount Approved",
                cell: (info) => (
                    <span className="color-grey-900 fw-semibold">
                        {info.renderValue()}
                    </span>
                ),
            },
            {
                accessorKey: "schedule",
                header: () => "Approved Date",
                cell: (info) => (
                    <span>{moment(info.getValue()).format('MMMM D, YYYY | hh:mm A')}</span>
                ),
            },
            {
                accessorKey: "provider",
                header: () => "Provider",
                cell: (info) => (
                    <div className="d-flex align-items-center">
                        <div className="assigned-avatar color-green">MO</div>

                        <div className="ms-2">
                            <span className="f-18">Miracle Obafemi Paul</span>
                            <p className="font-12 color-fiord">Amsterdam, United Kingdom</p>
                        </div>
                    </div>
                ),
                // enableSorting: false
            },
            {
                id: "actions",
                header: () => "Action",
                cell: ({ row }) => {
                    return (
                        <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
                            <ListGroup variant="flush">
                                <ListGroup.Item
                                    action
                                    onClick={() => { }}
                                    className='cursor-pointer font-14 color-gray-700 border-bottom-0'
                                >
                                    Raise a dispute
                                </ListGroup.Item>
                            </ListGroup>
                        </NavDropdown>
                    );
                },
            },
        ],
        []
    );

    const rows = [
        {
            title: "Plumber",
            location: "Birmingham, United Kingdom",
            status: "Active",
            datePosted: new Date(),
            price: "$500",
        },
        {
            title: "Plumber",
            location: "Birmingham, United Kingdom",
            status: "Active",
            datePosted: new Date(),
            price: "$500",
        },
        {
            title: "Plumber",
            location: "Birmingham, United Kingdom",
            status: "Active",
            datePosted: new Date(),
            price: "$500",
        },
        {
            title: "Plumber",
            location: "Birmingham, United Kingdom",
            status: "Active",
            datePosted: new Date(),
            price: "$500",
        },
    ]

    return (
        <Table columns={columns} data={rows} />
    )
}