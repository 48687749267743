import React, { useMemo } from "react";
import { Table } from "../../../../components/common/table/Table";
import { MainDefault } from "../../../../components/common/Default";

export const ActivityLog = () => {
  const Columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: () => "Date",
        cell: (info) => <span>{info.renderValue()}</span>,
        // enableSorting: false
      },
      {
        accessorKey: "events",
        header: () => "Events",
        cell: (info) => <span className="">{info.renderValue()}</span>,
        // enableSorting: false
      },
      {
        accessorKey: "author",
        header: () => "Author",
        cell: (info) => <span>{info.renderValue()}</span>,
      },
      {
        accessorKey: "ipAddress",
        header: () => "IP Address",
        cell: (info) => <span className="">{info.renderValue()}</span>,
        // enableSorting: false
      },
      {
        accessorKey: "subjectId",
        header: () => "Subject ID",
        cell: (info) => <span className="">{info.renderValue()}</span>,
        // enableSorting: false
      },
    ],
    []
  );

  const Rows = [
    {
      date: "September 12, 2004 | 11:23AM",
      events: "User logged in",
      author: "john.doe@doenet.com",
      ipAddress: "0.0.245.17",
      subjectId: "1674hh8-2361hh-73783bbj",
    },
  ];
  return (
    <>
      <MainDefault>
        <div className="top-nav py-3 mb-4">
          <h4 className="mb-0">Tax Documentation</h4>
        </div>
        <Table columns={Columns} data={Rows} />
      </MainDefault>
    </>
  );
};
