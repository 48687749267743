import { Form, Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { CustomDateInput } from '../../../buyer-provider/CustomDateInput'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import { Input } from '../../../common/input/Input'
import { handleChange } from '../../../../utils/utils'

export const CheckInOutSchedule = ({
    show,
    handleClose,
    type = 'change-schedule',
    modalTitle = 'Change Schedule',
    primaryButtonText = 'Change Schedule',
    onPrimaryButtonClick,
    secondaryButtonText = 'Cancel',
    onSecondaryButtonClick = handleClose,
    isOutlineStyle = false
}) => {

    const [formData, setFormData] = useState({
        startDate: null,
        checkInTime: null,
        endDate: null,
        checkOutTime: null,
        comment: ''
    })
    const [errors, setErrors] = useState({
        startDate: '',
        checkInTime: '',
        endDate: '',
        checkOutTime: '',
        comment: ''
    })

    const validateField = (name, value) => {
        var error = '';

        if (name === 'startDate' && !value) error = 'Start date is required'
        if (name === 'endDate' && !value) error = 'End date is required'
        if (name === 'checkInTime' && !value) error = 'Check-In Time is required'
        if (name === 'checkOutTime' && !value) error = 'Check-Out Time is required'

        if (name === 'comment') {
            if (!value.trim()) {
                error = 'Comment is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Comment cannot contains special characters';
            }
        }

        return error
    }

    const handleDateChange = (selectedDate, name) => {
        setFormData(prev => ({
            ...prev,
            [name]: selectedDate
        }))

        const error = validateField(name, selectedDate);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Header className='border-bottom-0 pb-0'>
                <Modal.Title className='color-grayscale-900 fw-700'>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {(type === 'change-schedule' || type === 'check-in') && (
                        <>
                            <DatePicker
                                selected={formData?.startDate}
                                onChange={(date) => handleDateChange(date, 'startDate')}
                                dateFormat='dd/MM/yy'
                                placeholderText='DD/MM/YY'
                                customInput={<CustomDateInput label='Start Date' name='startDate' error={errors.startDate} className='mb-2' />}
                            />
                            <DatePicker
                                selected={formData?.checkInTime}
                                onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='HH:mm'
                                timeFormat='HH:mm'
                                placeholderText='HH:MM'
                                customInput={<Input label='Check-In Time' name='checkInTime' error={errors.checkInTime} className='mb-2' />}
                            />
                        </>
                    )}
                    {(type === 'change-schedule' || type === 'check-out') && (
                        <>
                            <DatePicker
                                selected={formData?.endDate}
                                onChange={(date) => handleDateChange(date, 'endDate')}
                                dateFormat='dd/MM/yy'
                                placeholderText='DD/MM/YY'
                                customInput={<CustomDateInput label='End Date' name='endDate' error={errors.endDate} className='mb-2' />}
                            />
                            <DatePicker
                                selected={formData?.checkOutTime}
                                onChange={(date) => handleDateChange(date, 'checkOutTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='HH:mm'
                                timeFormat='HH:mm'
                                placeholderText='HH:MM'
                                customInput={<Input label='Check-Out Time' name='checkOutTime' error={errors.checkOutTime} className='mb-2' />}
                            />
                        </>
                    )}
                    {type !== 'change-schedule' && (
                        <Input
                            label='Comment'
                            name='comment'
                            multiline
                            rows={5}
                            placeHolder='Write your message here'
                            value={formData?.comment}
                            className='mb-2'
                            onChange={(e) => {
                                handleChange(e, setFormData, setErrors, validateField)
                            }}
                        />
                    )}
                    <Stack direction='horizontal' className='justify-content-between mt-3'>
                        <Button
                            type='button'
                            variant={!isOutlineStyle ? 'outline' : 'primary'}
                            className={`py-14px ${!isOutlineStyle ? 'px-3 color-ebony padded-border-button' : ''}`}
                            onClick={onSecondaryButtonClick}
                        >
                            {secondaryButtonText}
                        </Button>
                        <Button
                            type='submit'
                            variant={isOutlineStyle ? 'outline' : 'primary'}
                            className={`py-14px ${isOutlineStyle ? 'px-3 color-ebony padded-border-button' : ''}`}
                            onClick={onPrimaryButtonClick}
                        >
                            {primaryButtonText}
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
