import React, { useMemo } from "react";
import "./counterList.css";
import { JobInfoHeader } from "../JobInfoHeader";
import { Table } from "../../common/table/Table";
import { Form } from "react-bootstrap";
import { USER } from "../../../assets/images.assets";
import { MdOutlineStar } from "react-icons/md";

export const CounterList = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: () => "Name",
        cell: (info) => (
          <div className="d-flex align-items-center gap-2">
            <Form.Check type="checkbox" className="buyer-checkbox" id={`checkbox-${info?.cell.id}`} />
            <img src={USER} className="applicant-profile" />
            <label htmlFor={`checkbox-${info?.cell.id}`} className="applicant-name">Adeleke Fajuyi Olayiwola</label>
          </div>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "emailAddress",
        header: () => "Email Address",
        cell: (info) => (
          <span className="about-applicant">adelekefajuyi@gmail.com</span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "rating",
        header: () => "Rating",
        cell: (info) => (
          <div className="d-flex align-items-center gap-1">
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="D9D9D9" />
          </div>
        ),
      },
      {
        accessorKey: "level",
        header: () => "Level",
        cell: (info) => <span className="about-applicant">Professional</span>,
        // enableSorting: false
      },
      {
        accessorKey: "jobsCompleted",
        header: () => "Jobs Completed",
        cell: (info) => (
          <span className="about-applicant job-completed">40</span>
        ),
      },
      {
        accessorKey: "currentOffer",
        header: () => "Current Offer",
        cell: (info) => (
          <div className="current-offer">
            <div>$30</div>
            <div className="mt-1">BLENDED</div>
          </div>
        ),
      },
      {
        accessorKey: "counterOffer",
        header: () => "Counter Offer",
        cell: (info) => (
          <div className="counter-offer">
            <div>$50</div>
            <div className="mt-1">BLENDED</div>
          </div>
        ),
      },
    ],
    []
  );

  const rows = [
    {
      name: "Plumber",
      emailAddress: "Birmingham, United Kingdom",
      rating: "Active",
      rating: " 5",
      jobsCompleted: "30/200",
      price: "$500",
    },
    {
      name: "Plumber",
      emailAddress: "Birmingham, United Kingdom",
      rating: "Active",
      rating: " 5",
      jobsCompleted: "30/200",
      price: "$500",
    },
  ];
  return (
    <>
      <JobInfoHeader isCSV={true} isSortDropDownLeft={true} />

      <Table columns={columns} data={rows} />
    </>
  );
};
