import React from "react";
import { HiDevicePhoneMobile, HiEnvelope } from "react-icons/hi2";
import { Col, Row, Stack } from "react-bootstrap";
import { ReactComponent as Lock } from "../../../../../assets/images/lock.svg";
import { useNavigate } from "react-router-dom";

export const MyIdentityConfirmation = () => {
  const navigate = useNavigate();

  const handleSendMail = async () => {
    navigate("/profile/tax-information/information-protection");
  };

  return (
    <>
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Col xs={11} sm={8} md={4}>
          <div className="text-center">
            <Lock className="mb-5" />
            <div className="mb-4 mt-2">
              <h5 className="welcome-heading fs-3 line-height-125">
                Verify your Identity
              </h5>
              <p
                className="get-started fw-normal line-height-150 mx-auto"
                style={{ maxWidth: "350px" }}
              >
                Choose a method to receive a code
              </p>
            </div>
          </div>
          <hr className="border-botticelli opacity-100 mb-4" />
          <Stack
            direction="horizontal"
            onClick={handleSendMail}
            className="gap-2 cursor-pointer"
          >
            <HiEnvelope size={20} className="color-primary" />
            <span className="font-14 text-black fw-medium">
              Email: *************@gmail.com
            </span>
          </Stack>
          <hr className="border-botticelli opacity-100 mt-4 mb-4" />
          <Stack direction="horizontal" className="gap-2">
            <HiDevicePhoneMobile size={20} className="color-primary" />
            <span className="font-14 text-black fw-medium">
              Text: *****1234
            </span>
          </Stack>
          <hr className="border-botticelli opacity-100 mt-4" />
        </Col>
      </Row>
    </>
  );
};
