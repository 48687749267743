import React, { useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { Button } from "../../../components/common/button/Button";
import { IoAddOutline } from "react-icons/io5";
import "./clients.css";
import { ClientSearchBar } from "./ClientSearchBar";
import Underline from "../../../components/buyer-provider/Underline";
import { ClientDetailsTable } from "./client-details-table/ClientDetailsTable";
import { AddEditClientForm } from "./add-edit-client-form/AddEditClientForm";
import { FiPlus } from "react-icons/fi";
import { RightOffcanvas } from "../../../components/buyer-provider/RightOffcanvas";
import { Offcanvas } from "react-bootstrap";
import { NotFoundCard } from "../../../components/profile/not-found/NotFoundCard";

export const Clients = () => {
  const [addClientShowModel, setAddClientShowModel] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic for adding a client
  };
  return (
    <>
      <MainDefault containerClasses={[""].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
        <div className="py-2 px-3">
          {[""].length <= 0 ? (
            <>
            <h4 className="mb-0 fw-bold mt-3 topHead">
              Clients
            </h4>
            <NotFoundCard
              title="No client"
              subTitle="Add a client to start managing their information."
              buttonText="Add client"
              onButtonClick={() => setAddClientShowModel(true)}
            />
          </>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between client-heading">
            <h5>Clients</h5>
            <Button
              className="d-flex align-items-center px-3"
              style={{ paddingBlock: 12 }}
              onClick={() => setAddClientShowModel(true)}
            >
              <FiPlus size={20} className="me-2" />
              <span>Add Client</span>
            </Button>
          </div>

          <div className="mt-4">
            <ClientSearchBar />
          </div>
          <Underline />

          <div className="mt-4">
            <ClientDetailsTable />
          </div>
            </>
          )} 

          <RightOffcanvas
            show={addClientShowModel}
            handleClose={() => setAddClientShowModel(false)}
          >
            <div className="form-heading p-3">
              <h6>Add Client</h6>
              <p>You can add client details here</p>
            </div>
            <Offcanvas.Body className="d-flex flex-column">
              <AddEditClientForm
                handleSubmit={handleSubmit}
                setAddClientShowModel={setAddClientShowModel}
                actionLabel="Add Client"
              />
            </Offcanvas.Body>
          </RightOffcanvas>
        </div>
      </MainDefault>
    </>
  );
};
