import React from "react";
import { CardWrapper } from "../../../../components/common/Default";
import {
  BUYER_PROVIDER_DETAILS_navITEMS,
  BUYER_PROVIDER_DETAILS_tabPanes,
} from "../../../../data/constants";
import { JobUpdates } from "../../../../components/dashboard/job-updates/JobUpdates";

export const ProviderOverview = () => {
  return (
    <CardWrapper>
      <JobUpdates
        navItems={BUYER_PROVIDER_DETAILS_navITEMS}
        tabPanes={BUYER_PROVIDER_DETAILS_tabPanes}
      ></JobUpdates>
    </CardWrapper>
  );
};
