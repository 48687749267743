import { Col, Form, Row, Stack } from 'react-bootstrap'
import { MainDefault } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { Button } from '../../../../components/common/button/Button'
import { useEffect, useRef, useState } from 'react'
import { Select } from '../../../../components/common/select/Select'
import { useSelector } from 'react-redux'
import { employeeOptions } from '../../../../data/custom-options'
import { useBusinessDetailsValidation } from '../../../../hooks/useBusinessDetailsValidation'
import { ASSET_URL } from '../../../../config/API_URL'
import { showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'
import MyPhoneInput from '../../../../components/common/my-phone-input/MyPhoneInput'
import { emailPattern } from '../../../../data/constants'

export const CompanyProfile = () => {

    const {
        formData: businessFormData,
        errors: businessErrors,
        setFormData: setBusinessFormData,
        handleChange: handleBusinessChange,
        handleSelectChange,
        handleSubmit
    } = useBusinessDetailsValidation()

    const buyerDetails = useSelector(state => state.buyerReducer.buyerDetails)
    var industryOptions = useSelector(state => state.buyerReducer.industries)
    industryOptions = industryOptions?.map(industry => ({
        label: industry?.name,
        value: industry?._id
    }))

    const fileInputRef = useRef(null);

    const [formData, setFormData] = useState({
        imageSrc: null,
        bio: '',
        businessEmail: '',
        contactDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumberPOC: ''
        }
    })

    const [errors, setErrors] = useState({
        imageSrc: '',
        bio: '',
        contactDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumberPOC: ''
        }
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setErrors(prevErrors => ({
                ...prevErrors,
                imageSrc: ''
            }))
            if(file.size > 2 * 1024 * 1024) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    imageSrc: 'Image size must be less than 2MB'
                }))
            }
            else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prev => ({
                    ...prev,
                    imageSrc: reader.result
                }))
            }
            reader.readAsDataURL(file)
            }
        }
    };

    
    const validateField = (name, value) => {
        var error = '';
        if (name === 'contactDetails.firstName' && !value) {
            error = 'First name is required';
        } else if (name === 'contactDetails.lastName' && !value) {
            error = 'Last name is required';
        } else if (name === 'contactDetails.phoneNumberPOC' && !value) {
            error = 'Phone number is required';
        } else if (name === 'contactDetails.email') {
            if(!value) {
                error = 'Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
        } else if (name === 'bio' && !value) {
            error = 'Bio is required';
        } else if (name === 'businessEmail') {
            if(!value) {
                error = 'Business Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid business email';
            }
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedValue = name === 'contactDetails.phoneNumberPOC' ? value.replace(/[^0-9]/g, '') : value;
        const error = validateField(name, value);

        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            setFormData(prevFormData => ({
                ...prevFormData,
                [parent]: {
                    ...prevFormData[parent],
                    [child]: updatedValue
                }
            }))
            setErrors((prevErrors) => ({
                ...prevErrors,
                [parent]: {
                    ...prevErrors[parent],
                    [child]: error
                }
            }))
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: updatedValue
            }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error
            }));
        }
    };

    const isFormValid = () => {
        const checkErrors = (errorObj) => {
            return Object.values(errorObj).some(error =>
                typeof error === 'object' ? checkErrors(error) : error !== ''
            );
        }
        const checkMissingFields = (fieldObj) => {
            if (!fieldObj || typeof fieldObj !== 'object') return true
            return Object.values(fieldObj).some(field =>
                typeof field === 'object' ? checkMissingFields(field) : field === null || field === ''
            );
        }

        const { imageSrc: formDataImageSrc, ...restFormData } = formData
        const hasErrors = checkErrors(errors) || checkErrors(businessErrors);;
        const hasMissingFields = checkMissingFields(restFormData) || checkMissingFields(businessFormData)
        return !(hasErrors || hasMissingFields);
    }

    const handleFormSubmit = async (e) => {
        const formResult = handleSubmit(e)
        if (!formResult) return

        const newErrors = {
            bio: '',
            businessEmail: '',
            contactDetails: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumberPOC: '',
            }
        }

        const file = fileInputRef.current.files[0];
        if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
            setErrors(prevErrors => ({
                ...prevErrors,
                imageSrc: 'Image size must be less than 2MB'
            }));
            return; // Prevent form submission if the image size is too large
        }

        if (!formData?.bio) newErrors.bio = 'Bio is required';
        if (!formData?.businessEmail) {
            newErrors.businessEmail = 'Business Email is required';
        } else if(!emailPattern.test(formData?.businessEmail)) {
            newErrors.businessEmail = 'Please enter a valid business email';
        }
        if (!formData?.contactDetails?.firstName) newErrors.contactDetails.firstName = 'First name is required';
        if (!formData?.contactDetails?.lastName) newErrors.contactDetails.lastName = 'Last name is required';
        if (!formData?.contactDetails?.email) {
            newErrors.contactDetails.email = 'Email is required';
        } else if(!emailPattern.test(formData?.contactDetails?.email)) {
            newErrors.contactDetails.email = 'Please enter a valid email address';
        }
        if (!formData?.contactDetails?.phoneNumberPOC) newErrors.contactDetails.phoneNumberPOC = 'Phone number is required';

        setErrors(newErrors);

        var profileImage;
        var formDataObj = new FormData(e.target)
        profileImage = formDataObj.get('profileImage');

        if (isFormValid()) {
            try {
                const data = {
                    ...formData,
                    contactDetails: {
                        ...formData?.contactDetails,
                        phoneNumber: formData?.contactDetails?.phoneNumberPOC,
                    },
                    profileImage
                }
                delete data?.imageSrc

                const PROFILE_UPDATE = await PostRequest('/buyer/profile/update', data, { isMultiMedia: true })
                const BUSINESS_DETAILS_UPDATE = await PostRequest('/buyer/business-details/update', formResult)

                await Promise.all([PROFILE_UPDATE, BUSINESS_DETAILS_UPDATE])
                showToaster('Company Profile Updated Successfully!')

            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if (buyerDetails) {
            setFormData({
                imageSrc: buyerDetails?.profileImage ? `${ASSET_URL}/${buyerDetails?.profileImage}` : null,
                bio: buyerDetails?.bio || '',
                businessEmail: buyerDetails?.businessEmail || '',
                contactDetails: {
                    firstName: buyerDetails?.contactDetails?.firstName || '',
                    lastName: buyerDetails?.contactDetails?.lastName || '',
                    email: buyerDetails?.contactDetails?.email || '',
                    phoneNumberPOC: buyerDetails?.contactDetails?.phoneNumber || '',
                }
            })
        }
    }, [buyerDetails])

    return (
        <MainDefault>
            <div className='top-nav py-3'>
                <h4 className='mb-0'>Company Profile</h4>
            </div>
            <Form onSubmit={handleFormSubmit} className='mt-3'>
                <div className='w-50'>
                    <div className='mb-2'>
                        <label className='form-label line-height-160'>Change Logo (Max. 2MB)</label>
                        <div>
                            {formData?.imageSrc ? (
                                <img
                                    src={formData?.imageSrc}
                                    alt='Uploaded'
                                    className='rounded-circle object-fit-cover'
                                    style={{ height: 96, width: 96 }}
                                />
                            ) : (
                                <UserCircle />
                            )}
                            <Button
                                variant='outline'
                                onClick={(e) => {
                                    e.preventDefault()
                                    fileInputRef.current.click()
                                }}
                                className='py-2 ms-3 font-14 rounded fw-medium'
                            >
                                {formData?.imageSrc ? 'Change' : 'Upload'} logo
                            </Button>
                        </div>
                        <input
                            type='file'
                            ref={fileInputRef}
                            name='profileImage'
                            className='d-none'
                            accept='image/png, image/jpeg'
                            onChange={handleFileChange}
                        />
                        {errors?.imageSrc && <span className='error-text line-height-160 mt-2'>{errors?.imageSrc}</span>}
                    </div>
                    <Input
                        label='Business Bio'
                        name='bio'
                        id='bio'
                        htmlFor='bio'
                        value={formData?.bio}
                        error={errors?.bio}
                        onChange={handleChange}
                        multiline
                        className='mb-2'
                        rows={5}
                    />
                    <Input
                        label='Business Email'
                        name='businessEmail'
                        value={formData?.businessEmail}
                        error={errors?.businessEmail}
                        onChange={handleChange}
                        type='email'
                        placeholder='john.doe@gmail.com'
                        className='mb-2'
                    />
                    <MyPhoneInput
                        label='Phone Number'
                        value={businessFormData?.phoneNumber}
                        onChange={(phone) => {
                            setBusinessFormData(prevFormData => ({
                                ...prevFormData,
                                phoneNumber: phone
                            }))
                        }}
                        error={businessErrors?.phoneNumber}
                        className='mb-2'
                    />
                    <Input
                        label='Address'
                        name='address'
                        id='address'
                        htmlFor='address'
                        placeholder='adeleke.tobi@email.com'
                        className='mb-2'
                        value={businessFormData?.address}
                        error={businessErrors?.address}
                        onChange={handleBusinessChange}
                    />
                    <Select
                        label='Industry'
                        name='industry'
                        inputId='industry'
                        htmlFor='industry'
                        placeholder='Select one'
                        className='mb-2'
                        options={industryOptions}
                        value={businessFormData?.industry}
                        error={businessErrors?.industry}
                        onChange={(industry) => handleSelectChange(industry, 'industry')}
                    />
                    <Input
                        label='Website'
                        name='website'
                        id='website'
                        htmlFor='website'
                        placeholder='https://www.janedoe.com'
                        value={businessFormData?.website}
                        error={businessErrors?.website}
                        onChange={handleBusinessChange}
                        className='mb-2'
                    />
                    <Select
                        label='Number of Employees'
                        name='employees'
                        inputId='employees'
                        htmlFor='employees'
                        placeholder='Select one'
                        className='mb-2'
                        options={employeeOptions}
                        value={businessFormData?.totalEmployees}
                        error={businessErrors?.totalEmployees}
                        onChange={(employees) => handleSelectChange(employees, 'totalEmployees')}
                    />
                </div>
                <div className='top-nav py-3'>
                    <h6 className='mb-0'>Contact Person</h6>
                </div>
                <div className='w-50 mt-3'>
                    <Row>
                        <Col md={6}>
                            <Input
                                label='First name'
                                name='contactDetails.firstName'
                                id='firstName'
                                htmlFor='firstName'
                                placeholder='First name'
                                value={formData?.contactDetails?.firstName}
                                onChange={handleChange}
                                error={errors?.contactDetails?.firstName}
                                className='mb-2'
                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                label='Last name'
                                name='contactDetails.lastName'
                                id='lastName'
                                htmlFor='lastName'
                                placeholder='Last name'
                                value={formData?.contactDetails?.lastName}
                                onChange={handleChange}
                                error={errors?.contactDetails?.lastName}
                                className='mb-2'
                            />
                        </Col>
                    </Row>
                    <Input
                        label='Email Address'
                        name='contactDetails.email'
                        id='email'
                        htmlFor='email'
                        placeholder='example@company.com'
                        type='email'
                        value={formData?.contactDetails?.email}
                        onChange={handleChange}
                        error={errors?.contactDetails?.email}
                        className='mb-2'
                    />
                    <MyPhoneInput
                        label='Phone Number'
                        name='contactDetails.phoneNumberPOC'
                        htmlFor='phoneNumberPOC'
                        value={formData?.contactDetails?.phoneNumberPOC}
                        onChange={(phone, country) => {
                            // country {
                            //     "name": "United States",
                            //     "dialCode": "1",
                            //     "countryCode": "us",
                            //     "format": "+. (...) ...-...."
                            // }
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                contactDetails: {
                                    ...prevFormData?.contactDetails,
                                    phoneNumber: phone
                                }
                            }))
                        }}
                        error={errors?.contactDetails?.phoneNumberPOC}
                        className='mb-3'
                    />
                </div>
                <hr className='border-botticelli opacity-100' />
                <Stack direction='horizontal' gap={4}>
                    <Button
                        variant='outline'
                        onClick={() => window.history.back()}
                        className='color-ebony border-ebony fw-semibold'
                        style={{ paddingBlock: 14 }}
                    >
                        Cancel
                    </Button>
                    <Button type='submit' disabled={!isFormValid()} className='fw-semibold' style={{ paddingBlock: 14 }}>Update Company Profile</Button>
                </Stack>
            </Form>
        </MainDefault>
    )
}
