import { FaRegUser } from 'react-icons/fa'
import { Form } from 'react-bootstrap'
import { Input } from '../../../../components/common/input/Input'
import { Button } from '../../../../components/common/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AuthWrapper } from '../../../../components/common/Default'
import PostRequest from '../../../../services/PostRequest'
import { getAuthProvider, showToaster } from '../../../../utils/utils'

export const AccountSetup = () => {

    const navigate = useNavigate()
    const { parsedAuthData: providerDetails } = getAuthProvider()

    const email = window.localStorage.getItem('PROVIDER_EMAIL')

    const [formData, setFormData] = useState({
        firstName: providerDetails?.firstName || '',
        lastName: providerDetails?.lastName || '',
        password: providerDetails?.password || '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        password: '',
    });

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName') {
            if(!value.trim()) {
                error = 'First name is required';
            } else if(!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'First name cannot contains special characters';
            }
        } else if (name === 'lastName') {
            if(!value.trim()) {
                error = 'Last name is required';
            } else if(!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Last name cannot contains special characters';
            }
        } else if (name === 'password') {
            if (!value) {
                error = 'Password is required';
            } else if (value.length < 8) {
                error = 'Your password is not strong enough. Use at least 8 characters';
            } else if (!/[0-9]/.test(value)) {
                error = 'Use at least 1 digit'
            } else if (!/[A-Z]/.test(value)) {
                error = 'Use at least 1 Uppercase letter'
            } else if (!/[a-z]/.test(value)) {
                error = 'Use at least 1 Lowercase letter'
            }
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const newFormData = {
            ...formData,
            [name]: value
        }

        setFormData(newFormData);

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = { firstName: '', lastName: '', password: '' };

        if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
        if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 8) {
            newErrors.password = 'Your password is not strong enough. Use at least 8 characters';
        } else if (!/[0-9]/.test(formData.password)) {
            newErrors.password = 'Use at least 1 digit'
        } else if (!/[A-Z]/.test(formData.password)) {
            newErrors.password = 'Use at least 1 Uppercase letter'
        } else if (!/[a-z]/.test(formData.password)) {
            newErrors.password = 'Use at least 1 Lowercase letter'
        }

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            // window.localStorage.setItem('PROVIDER_DETAILS', JSON.stringify({ ...providerDetails, ...formData }))

            try {
                const data = {
                    email,
                    ...formData
                }
                const response = await PostRequest('/provider/register', data)
                console.log('response', response)

                window.localStorage.setItem('TOKEN', response?.token);
                window.localStorage.setItem('AUTH_PROVIDER', JSON.stringify(response));
                navigate('/get-started')
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    };

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value);

    return (
        <AuthWrapper>
            <div className='text-center'>
                <p className='get-started color-grey-500'>GET STARTED</p>
                <h5 className='welcome-heading'>Complete your free <br /> account setup</h5>
            </div>
            {email && <div className='mt-3 mb-2 user-profile-card'>
                <span>{email}</span>
                <FaRegUser size={12} className='text-black ms-1' />
            </div>}
            <Form onSubmit={handleSubmit}>
                <Input
                    label='First name'
                    name='firstName'
                    id='firstName'
                    htmlFor='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                    error={errors.firstName}
                    className='mb-2'
                />
                <Input
                    label='Last name'
                    name='lastName'
                    id='lastName'
                    htmlFor='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                    error={errors.lastName}
                    className='mb-2'
                />
                <Input
                    label='Password'
                    name='password'
                    id='password'
                    htmlFor='password'
                    value={formData.password}
                    onChange={handleChange}
                    error={errors.password}
                    className='mb-2'
                    type='password'
                />
                <Form.Group className='mb-2'>
                    <Form.Check
                        type='checkbox'
                        required
                        label={
                            <span className='checkbox-label line-height-160 color-primary'>
                                By creating an account means you agree to the {' '}
                                <span className='fw-semibold color-ebony'>Terms & Conditions</span> and our {' '}
                                <span className='fw-semibold color-ebony'>Privacy Policy</span>
                            </span>
                        }
                        name='terms'
                        id='terms-checkbox'
                        className='provider-checkbox'
                    />
                </Form.Group>
                <Button type='submit' disabled={!isFormValid()} className='w-100 mb-2'>Create Account</Button>
            </Form>
            <div className='login-text line-height-160 color-secondary'>
                Already have an account? {' '}
                <Link to='/login' className='text-decoration-none color-primary'>Login Here</Link>
            </div>
        </AuthWrapper>
    )
}
