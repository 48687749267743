import { Fragment } from "react";
import { MainDefault } from "../../../components/common/Default";
import { ProfileUpdateStatus } from "../../../components/dashboard/ProfileUpdateStatus";
import { ProfileSetupModal } from "../../../components/profile/modals/profile-setup/ProfileSetupModal";
import { NotFoundCard } from "../../../components/profile/not-found/NotFoundCard";
import { useProfileCompletion } from "../../../hooks/useProfileCompletion";
import { Stack } from "react-bootstrap";
import { Button } from "../../../components/common/button/Button";
import React from "react";
import { FiPlus } from "react-icons/fi";
import { JobUpdates } from "../../../components/dashboard/job-updates/JobUpdates";
import { BUYER_navITEMS, BUYER_tabPanes } from "../../../data/constants";
import { LayoutSwitcher } from "../../../components/dashboard/job-updates/LayoutSwitcher";
import { AiOutlineSetting } from "react-icons/ai";
import { useState } from "react";
import { Filters } from "../../../components/buyer/work-orders/offcanvas/Filters";
import { useLocation, useNavigate } from "react-router-dom";

export const WorkOrders = () => {
  const navigate = useNavigate();
  const location = useLocation()
  
  const { showProfileSetup, setShowProfileSetup } = useProfileCompletion();

  const [showFilters, setShowFilters] = useState(false);

  const defaultActiveKey = location.state?.eventKey

  return (
    <MainDefault containerClasses={[""].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
      <ProfileUpdateStatus onUpdateProfile={() => setShowProfileSetup(true)} />
      {[""].length <= 0 ? (
        <Fragment>
          <h4 className="mb-0 fw-bold mt-3 topHead">
            Work Orders
          </h4>
          <NotFoundCard
            title="No work order"
            subTitle="Create a work order to start receiving applications."
            buttonText="Create work order"
            onButtonClick={() => navigate("/work-orders/basic-information")}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Stack
            direction="horizontal"
            className="justify-content-between py-3 mb-3"
          >
            <h4 className="mb-0 fw-bold topHead">
              Work Orders
            </h4>
            <Button
              className="px-3"
              style={{ paddingBlock: 12 }}
              onClick={() => navigate('/work-orders/basic-information')}
            >
              <FiPlus size={20} className="me-2" />
              {/* <span>Invite member</span> */}
              <span>Create work order</span>
            </Button>
          </Stack>
          <JobUpdates 
            navItems={BUYER_navITEMS} 
            tabPanes={BUYER_tabPanes} 
            defaultActiveKey={defaultActiveKey}
          >
            <LayoutSwitcher showCalendar />
            <button
              type="button"
              onClick={() => setShowFilters(true)}
              className='filterButton'
            >
              <AiOutlineSetting className="me-1" />
              <span>Filter</span>
            </button>
          </JobUpdates>
        </Fragment>
      )}
      <Filters show={showFilters} handleClose={() => setShowFilters(false)} />
      <ProfileSetupModal
        show={showProfileSetup}
        handleClose={() => setShowProfileSetup(false)}
      />
    </MainDefault>
  );
};
