import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UserInformationCard } from "../../../../components/buyer-provider/user-information-card/UserInformationCard";
import { useNavigate } from "react-router-dom";
import { Message } from "../../../../components/buyer/providers/modal/Message";
import { AddToPool } from "../../../../components/buyer/work-orders/modals/AddToPool";
import { ConfirmationActionModal } from "../../../../components/buyer/work-orders/modals/ConfirmationActionModal";
import { ReactComponent as WOCancel } from '../../../../assets/images/cancel-work-order.svg'
import { Invite } from "../../../../components/buyer/providers/modal/Invite";
import { useDispatch, useSelector } from "react-redux";
import { setCheckedItems } from "../../../../store/buyer/buyer.action";

export const AllProviders = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch()

  const checkedItems = useSelector((state) => state.buyerReducer.checkedItems)

  const [showMessage, setShowMessage] = useState(false)
  const [showAddToPool, setShowAddToPool] = useState(false)
  const [showBlock, setShowBlock] = useState(false)
  const [showInvite, setShowInvite] = useState(false)

  const navigateToProviderDetails = (e) => {
    if (e.target.closest('.buyer-checkbox') || e.target.closest('.dropdown')) return
    
    navigator("/providers/details");
  };
  
  const handleCheckboxChange = (event, id) => {
      let updatedCheckedItems;
  
      if (event.target.checked) {
        // Add the id to the checked items list
        updatedCheckedItems = [...checkedItems, id];
      } else {
        // Remove the id from the checked items list
        updatedCheckedItems = checkedItems?.filter((itemId) => itemId !== id);
      }

      // Dispatch the action to update the Redux state
      dispatch(setCheckedItems(updatedCheckedItems));
    };
  
  const dropdownItems = [
    { name: "Message Provider", onClick: () => setShowMessage(true) },
    { name: "Add to a pool", onClick: () => setShowAddToPool(true) },
    { name: "Invite provider", onClick: () => setShowInvite(true) },
    { name: "Block provider", onClick: () => setShowBlock(true) },
  ];

  return (
    <>
      <Row className="row-gap-4">
        {Array.from({ length: 8 }).map((_, i) => {
          return (
            <Col 
              md={3} 
              onClick={navigateToProviderDetails} 
              className="cursor-pointer" 
              key={i}
            >
              <UserInformationCard
                userName="Ademola Adetunji"
                address="Amsterdam, UK Profeesional"
                dropdownItems={dropdownItems}
                index={i} 
                onCheckboxChange={handleCheckboxChange}
              />
            </Col>
          )
        })}
      </Row>
      <Message show={showMessage} handleClose={() => setShowMessage(false)} />
      <AddToPool show={showAddToPool} handleClose={() => setShowAddToPool(false)} fromProviders={true} />
      <Invite show={showInvite} handleClose={() => setShowInvite(false)} />
      <ConfirmationActionModal
        show={showBlock}
        handleClose={() => setShowBlock(false)}
        image={WOCancel}
        title='You are about to block this provider'
      />
    </>
  );
};
