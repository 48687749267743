import React from 'react'
import { MainDefault } from '../../../../../components/common/Default'
import { NewWODefault } from '../../../../../components/buyer/work-orders/creation/Default'
import { PricingForm } from '../../../../../components/buyer/work-orders/creation/pricing/PricingForm'
import { useLocation } from 'react-router-dom'

export const Pricing = () => {

    const location = useLocation()
    const mode = location.state?.mode

    return (
        <MainDefault>
            <NewWODefault mode={mode}>
                <PricingForm />
            </NewWODefault>
        </MainDefault>
    )
}
