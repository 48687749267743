import React from "react";
import { MainDefault } from "../../../../components/common/Default";
import { AllCards } from "./payment-method/AllCards";
import { AlleCheck } from "./payment-method/AlleCheck";
import "../buyerProfile.css";

export const CompanyPaymentMethod = () => {
  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4">
        <h4 className="mb-0">Payment Methods</h4>
      </div>

      <AllCards />
      <div className="mt-5">
        <AlleCheck />
      </div>
    </MainDefault>
  );
};
