import { ResetPasswordWrapper } from '../../../../components/common/Default'
import { ReactComponent as Lock } from '../../../../assets/images/lock.svg'
import { Form } from 'react-bootstrap'
import { Input } from '../../../../components/common/input/Input'
import { Button as MyButton } from '../../../../components/common/button/Button'
import { useNavigate } from 'react-router-dom'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'
import { useEmailValidation } from '../../../../hooks/useEmailValidation'

export const ForgotPassword = () => {

    const navigate = useNavigate()

    const { email, error, handleEmailChange, handleSubmit, isEmailValid } = useEmailValidation()

    const handleFormSubmit = async (e) => {

        const formResult = handleSubmit(e)
        if (!formResult) return

        const { email: formEmail } = formResult
        try {
            const data = {
                businessEmail: formEmail
            }
            const response = await PostRequest('/buyer/send-otp/email', data, { showLoader: 'send-reset-mail' })
            navigate('/reset-password-otp', { state: { email: formEmail, ...response } })
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    return (
        <ResetPasswordWrapper>
            <div className='text-center'>
                <Lock className='mb-4' />
                <div className='mb-3'>
                    <h5 className='welcome-heading fs-3 line-height-125'>Reset your password</h5>
                    <p className='get-started fw-normal line-height-150 mx-auto' style={{ maxWidth: '350px' }}>Enter your email address and we'll send you password reset instructions.</p>
                </div>
            </div>
            <Form onSubmit={handleFormSubmit}>
                <Input
                    label='Email address'
                    name='email'
                    id='email'
                    htmlFor='email'
                    autoComplete='email'
                    type='email'
                    value={email}
                    onChange={handleEmailChange}
                    error={error}
                    placeholder='Enter your email address'
                    className='mb-3'
                />
                <MyButton type='submit' id='send-reset-mail' disabled={!isEmailValid} className='w-100 mb-4'>Send Email</MyButton>
            </Form>
            {/* <div className='login-text line-height-160 color-secondary'>
                Didn't receive a code?  {' '}
                <Button variant='link' className='color-primary fs-inherit fw-inherit p-0'>Resend</Button>
            </div> */}
        </ResetPasswordWrapper>
    )
}
