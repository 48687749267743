import { Fragment, useMemo, useState } from 'react'
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import './table.css'
import { Table as BTable, Pagination, Stack, } from 'react-bootstrap'

export const Table = ({ data: defaultData, columns }) => {

    const data = useMemo(
        () => [...defaultData],
        []
    )

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    })
    const [sorting, setSorting] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        state: {
            pagination,
            sorting,
            globalFilter
        }
    })

    const totalPages = table.getPageCount();
    const currentPage = pagination.pageIndex + 1; // Convert to 1-based indexing for display
    const pageNumbers = [];

    const range = 2; // Show 3 pages before and after the current page

    // Generate a dynamic list of pages to display
    for (var i = 1; i <= totalPages; i++) {
        if (
            i !== 1 && // Always show the first page
            i !== totalPages && // Always show the last page
            (i >= currentPage - range && i <= currentPage + range) // Show pages around the current page
        ) {
            pageNumbers.push(i);
        }
    }

    return (
        <Fragment>
            <BTable>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : (
                                            <div
                                                className={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer user-select-none'
                                                        : ''
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                                ? 'Sort descending'
                                                                : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: ' 🔼',
                                                    desc: ' 🔽',
                                                }[header.column.getIsSorted()] ?? null}
                                            </div>
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </BTable>
            {/* <Stack direction='horizontal' className='justify-content-between'>
                <Pagination>
                    <Pagination.Prev
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className='page-item__prev'
                    />
                    <Pagination.Item
                        active={currentPage === 1}
                        onClick={() => table.setPageIndex(0)}
                        className='page-item__index'
                    >
                        {1}
                    </Pagination.Item>
                    {pageNumbers[0] > 2 && <Pagination.Ellipsis />}
                    {pageNumbers.map(pageNumber => {
                        return <Pagination.Item
                            key={pageNumber}
                            active={pageNumber === currentPage}
                            onClick={() => table.setPageIndex(pageNumber - 1)}
                            className='page-item__index'
                        >
                            {pageNumber}
                        </Pagination.Item>
                    })}
                    {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && <Pagination.Ellipsis />}
                    {totalPages > 1 && <Pagination.Item
                        active={currentPage === totalPages}
                        onClick={() => table.setPageIndex(totalPages - 1)}
                        className='page-item__index'
                    >
                        {totalPages}
                    </Pagination.Item>}
                    <Pagination.Next
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className='page-item__next'
                    />
                </Pagination>
                <Stack direction='horizontal' className='gap-3'>
                    <p className='color-pale-sky font-12 fw-medium'>Showing {table.getState().pagination.pageIndex + 1} to {table.getPageCount()} of {table.getRowCount()} entries</p>
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        className='table-select'
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Stack>
            </Stack> */}
        </Fragment>
    )
}
