import { MainDefault } from '../../../../components/common/Default'
import { Stack } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'
import { NotFoundCard } from '../../../../components/profile/not-found/NotFoundCard'
import { AddLanguage } from '../../../../components/profile/offcanvas/AddLanguage'
import { useMemo, useState } from 'react'
import { Table } from '../../../../components/common/table/Table'
import { MdOutlineMoreHoriz } from 'react-icons/md'

export const MyLanguages = () => {

    const [showAddLanguage, setShowAddLanguage] = useState(false)

    const columns = useMemo(() => [
        {
            accessorKey: 'language',
            header: () => 'Language',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'level',
            header: () => 'Level',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            id: "actions",
            header: () => "Action",
            cell: ({ row }) => {
                return <MdOutlineMoreHoriz size={24} className='cursor-pointer' />
            },
        },
    ], [])

    const rows = [
        {
            language: 'English',
            level: 'Expert',
        },
        {
            language: 'English',
            level: 'Expert',
        },
        {
            language: 'English',
            level: 'Expert',
        },
        {
            language: 'English',
            level: 'Expert',
        },
        {
            language: 'English',
            level: 'Expert',
        },
    ]

    return (
        <MainDefault>
            <Stack
                direction="horizontal"
                className="justify-content-between top-nav py-3 mb-3"
            >
                <h4 className="mb-0">
                    Languages
                </h4>
                {[''].length > 0 && (
                    <Button
                        className="px-3"
                        style={{ paddingBlock: 12 }}
                        onClick={() => setShowAddLanguage(true)}
                    >
                        Add Language
                    </Button>
                )}
            </Stack>
            {[''].length <= 0 ? (
                <NotFoundCard
                    title="No language added"
                    subTitle="You have not add any language here"
                    buttonText="Add Language"
                    onButtonClick={() => setShowAddLanguage(true)}
                />
            ) : (
                <Table data={rows} columns={columns} />
            )}
            <AddLanguage show={showAddLanguage} handleClose={() => setShowAddLanguage(false)} />
        </MainDefault>
    )
}
