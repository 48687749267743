import React from "react";
import { Form, Navbar } from "react-bootstrap";
import { AiOutlineSetting } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { Downloader } from "../../common/downloader/Downloader";
import Underline from "../Underline";

export const CommonHeader = ({ 
  isFilterButton = true, 
  showUnderLine = true, 
  filterIcon: Icon = AiOutlineSetting, 
  filterButtonText = 'Filter' 
}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Navbar.Brand className="position-relative">
          <IoSearch className="form-control__search-icon color-grey-500" />
          <Form.Control
            type="text"
            placeholder="Search"
            className="form-control__search applicant-search-input"
          />
        </Navbar.Brand>
        <div className="d-flex gap-4">
          {isFilterButton && (
            <button
              type="button"
              // onClick={() => setShowFilters(true)}
              className="filterButton"
            >
              <Icon className="me-1" />
              <span>{filterButtonText}</span>
            </button>
          )}
          <Downloader isCSV={true} />
        </div>
      </div>

      {showUnderLine && <Underline className="mt-3" />}
    </>
  );
};
