import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Input } from '../../../common/input/Input'
import { Select } from '../../../common/select/Select'

export const AddProject = ({ show, handleClose }) => {

    const handleInvite = (e) => {
        e.preventDefault()
    }

    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Add Project</h5>
                    <p>You can add project details</p>
                </div>
                <Form onSubmit={handleInvite}>
                    <Input
                        label='Project Title'
                        placeholder='Enter title'
                        className='mb-2'
                    />
                    <Input
                        label='Description'
                        placeholder='Enter description'
                        className='mb-2'
                        multiline
                    />
                    <Select
                        label='Project Owner'
                        placeholder='Enter project owner'
                        className='mb-3'
                    />
                    <Button className='py-3 w-100'>
                        Add Project
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
