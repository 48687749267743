import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Input } from "../../../components/common/input/Input";
import { Select } from "../../../components/common/select/Select";
import { Button } from "../../../components/common/button/Button";
import Underline from "../../../components/buyer-provider/Underline";

export const WithDraw = ({ setWithdrawFunds }) => {
  const [formData, setFormData] = useState({
    amount: "",
    description: "",
    beneficiary: null,
  });

  const [errors, setErrors] = useState({
    amount: "",
    description: "",
    beneficiary: "",
  });

  const beneficiaryOptions = [
    { label: "User 1", value: "user1" },
    { label: "User 2", value: "user2" },
    { label: "User 3", value: "user3" },
  ];

  const validateField = (name, value) => {
    let error = "";

    if (name === "amount") {
      if (!value) {
        error = "Amount is required.";
      } else if (isNaN(value) || Number(value) <= 0) {
        error = "Amount must be a positive number.";
      }
    } else if (name === "beneficiary") {
      if (!value) {
        error = "Beneficiary selection is required.";
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption,
    }));

    const error = validateField(name, selectedOption?.value || null);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      formData.amount &&
      formData.beneficiary
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      amount: validateField("amount", formData.amount),
      beneficiary: validateField("beneficiary", formData.beneficiary?.value),
    };

    setErrors(newErrors);

    if (isFormValid()) {
      console.log("Submitted Data:", {
        amount: formData.amount,
        description: formData.description,
        beneficiary: formData.beneficiary.value,
      });
      setWithdrawFunds(false);
    }
  };

  return (
    <>
      <div className="text-center mt-3">
        <p className="fw-500 line-height-160 font-16">
          Amount available to withdraw
        </p>
        <h4 className="fw-bold line-height-160 font-36">$4,600</h4>
      </div>
      <Underline className="mt-2 mb-4" />

      <Form className="form-wrapper" onSubmit={handleSubmit}>
        <Input
          label="Amount to withdraw"
          type="number"
          placeholder="Enter amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          error={errors.amount}
          star
        />
        <p className="font-12 line-height-160 color-gray-700 pt-1 font-italic mb-3">
          You will pay a 3% fee to take out money with this service. This fee
          pays for the transaction and keeps the service running.
        </p>
        <Input
          label="Description"
          type="text"
          placeholder="Enter your project description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          error={errors.description}
          className="mb-3"
          multiline="h"
          style={{ height: "140px" }}
        />
        <Select
          label="Beneficiary"
          name="beneficiary"
          inputId="beneficiary"
          htmlFor="beneficiary"
          placeholder="Select one"
          options={beneficiaryOptions}
          value={formData.beneficiary}
          error={errors.beneficiary}
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption, "beneficiary")
          }
          required
          star
        />

        <div className="button-bottom-side d-flex">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setWithdrawFunds(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="fw-semibold py-3 px-3"
            disabled={!isFormValid()}
          >
            Withdraw Funds
          </Button>
        </div>
      </Form>
    </>
  );
};
