import { Form, Modal, Stack } from 'react-bootstrap'
import { Select } from '../../common/select/Select'
import { Button } from '../../common/button/Button'

export const AddLanguage = ({ show, handleClose }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Header className='border-bottom-0 pb-0' closeButton>
                <Modal.Title className='color-grayscale-900 fw-700'>Add Language</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} className='d-flex flex-column flex-grow-1'>
                    <Select
                        label='Language'
                        name='language'
                        inputId='language'
                        htmlFor='language'
                        placeholder='Select one'
                        className='mb-2'
                        required
                    // options={industryOptions}
                    // value={formData?.industry}
                    // error={errors?.industry}
                    // onChange={(industry) => handleSelectChange(industry, 'industry')}
                    />
                    <Select
                        label='Proficiency'
                        name='proficiency'
                        inputId='proficiency'
                        htmlFor='proficiency'
                        placeholder='Select one'
                        className='mb-3'
                        required
                    // options={industryOptions}
                    // value={formData?.industry}
                    // error={errors?.industry}
                    // onChange={(industry) => handleSelectChange(industry, 'industry')}
                    />
                    <Stack direction='horizontal' className='justify-content-between mt-auto'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='fw-semibold p-3'
                        // disabled={!isFormValid()}
                        >
                            Add Language
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
