import { Form, Stack } from 'react-bootstrap'
import { CardWrapper, MainDefault } from '../../../../components/common/Default'
import { Button } from '../../../../components/common/button/Button'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Input } from '../../../../components/common/input/Input'

export const MyWorkingHours = () => {

    const [selectedWorkingHours, setSelectedWorkingHours] = useState('always-available')

    const handleWorkingHoursChange = (e) => {
        setSelectedWorkingHours(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <MainDefault>
            <div className="top-nav py-3 mb-4 ">
                <h4 className="mb-0">Available Working hours</h4>
            </div>

            <Form className='w-50' onSubmit={handleSubmit}>
                <Stack direction='horizontal' gap={2} className='align-items-start mb-3'>
                    <Form.Check
                        type='radio'
                        name='workingHours'
                        id='always-available'
                        value='always-available'
                        checked={selectedWorkingHours === 'always-available'}
                        onChange={handleWorkingHoursChange}
                        className='color-grey-500'
                        defaultChecked
                    />
                    <div>
                        <label htmlFor="always-available" className='color-gray-700 fw-600'>Always available</label>
                        <p className='color-dark-gray'>Work anytime, with no limits.</p>
                    </div>
                </Stack>
                <Stack direction='horizontal' gap={2} className='align-items-start mb-3'>
                    <Form.Check
                        type='radio'
                        name='workingHours'
                        id='away'
                        value='away'
                        checked={selectedWorkingHours === 'away'}
                        onChange={handleWorkingHoursChange}
                        className='color-grey-500'
                    />
                    <div className='working-hours__description'>
                        <label htmlFor="away" className='color-gray-700 fw-600'>Away</label>
                        <p className='color-dark-gray'>
                            Take a break from work for a while, for example, for vacation, illness, or maintenance. You can also set a date when you will return.
                        </p>
                    </div>
                </Stack>
                <Stack direction='horizontal' gap={2} className='align-items-start mb-3'>
                    <Form.Check
                        type='radio'
                        name='workingHours'
                        id='custom-availability'
                        value='custom-availability'
                        checked={selectedWorkingHours === 'custom-availability'}
                        onChange={handleWorkingHoursChange}
                        className='color-grey-500'
                    />
                    <div className='working-hours__description'>
                        <label htmlFor="custom-availability" className='color-gray-700 fw-600'>Custom Availability</label>
                        <p className='color-dark-gray'>
                            Set specific days and hours for your work. You can do this for each product or service if you have more than one.
                        </p>
                    </div>
                </Stack>
                {selectedWorkingHours === 'custom-availability' && (
                    <>
                        <Stack direction='horizontal' gap={2} className=' mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='sunday'
                                id='sunday'
                                value='sunday'
                                label='Sunday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <div>
                                <p className='form-label line-height-160 mb-1'>From</p>
                                <DatePicker
                                    // selected={formData?.checkInTime}
                                    // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    showTimeCaption={false}
                                    dateFormat='hh:mm a'
                                    timeFormat='hh:mm a'
                                    placeholderText='9:00AM'
                                    customInput={<Input name='from' />}
                                />
                            </div>
                            <div>
                                <p className='form-label line-height-160 mb-1'>To</p>
                                <DatePicker
                                    // selected={formData?.checkInTime}
                                    // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    showTimeCaption={false}
                                    dateFormat='hh:mm a'
                                    timeFormat='hh:mm a'
                                    placeholderText='9:00AM'
                                    customInput={<Input name='from' />}
                                />
                            </div>
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='align-items-start mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='monday'
                                id='monday'
                                value='monday'
                                label='Monday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='align-items-start mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='tuesday'
                                id='tuesday'
                                value='tuesday'
                                label='Tuesday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='align-items-start mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='wednesday'
                                id='wednesday'
                                value='wednesday'
                                label='Wednesday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='align-items-start mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='thursday'
                                id='thursday'
                                value='thursday'
                                label='Thursday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='align-items-start mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='friday'
                                id='friday'
                                value='friday'
                                label='Friday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                        </Stack>
                        <Stack direction='horizontal' gap={2} className='align-items-start mb-2'>
                            <Form.Check
                                type='checkbox'
                                name='saturday'
                                id='saturday'
                                value='saturday'
                                label='Saturday'
                                className='buyer-checkbox min-w-20 color-gray-700'
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                            <DatePicker
                                // selected={formData?.checkInTime}
                                // onChange={(date) => handleDateChange(date, 'checkInTime')}
                                showTimeSelect
                                showTimeSelectOnly
                                showTimeCaption={false}
                                dateFormat='hh:mm a'
                                timeFormat='hh:mm a'
                                placeholderText='9:00AM'
                                customInput={<Input name='from' />}
                            />
                        </Stack>
                    </>
                )}
                <CardWrapper className='mb-3'>
                    <h6 className='fw-700 text-black'>Pause my account</h6>
                    <p className='color-dark-gray mb-2'>
                        When you pause your account, you will stop getting notification from work offers, and we can’t apply for any work offers.
                    </p>
                    <Form.Check
                        type='checkbox'
                        name='pauseAccount'
                        id='pause-account'
                        label='Confirm that I want to put my account on hold'
                        className='color-grey-500 buyer-checkbox mb-2'
                    />
                    <button type='button' className='btn__pause-account font-14 fw-600'>
                        Pause my account
                    </button>
                </CardWrapper>
                <Stack direction="horizontal" gap={4}>
                    <Button
                        variant="outline"
                        className="color-ebony fw-semibold padded-border-button"
                    >
                        Cancel
                    </Button>
                    <Button
                        // disabled={!isFormValid()}
                        type="submit"
                        className="fw-semibold py-14px"
                    >
                        Save Changes
                    </Button>
                </Stack>
            </Form>
        </MainDefault>
    )
}
