import { Form, Modal } from 'react-bootstrap'
import { Button } from '../../common/button/Button'
import { Input } from '../../common/input/Input'
import { useState } from 'react'

export const ChangePassword = ({ show, handleClose }) => {

    const [formData, setFormData] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    })

    const [errors, setErrors] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    })

    const validateField = (name, value) => {
        var error = ''
        if (name === 'password') {
            if (!value) {
                error = 'Old password is required'
            }
        } else if (name === 'newPassword') {
            if (!value) {
                error = 'New password is required'
            } else if (value.length < 8) {
                error = 'New password must be at least 8 characters'
            }
        } else if (name === 'confirmPassword') {
            if (value !== formData.newPassword) {
                error = 'Passwords do not match'
            }
        }
        return error
    }

    const handlePasswordChange = (e) => {
        const { name, value } = e.target
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))

        const error = validateField(name, value)
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const handleChangePasswordClose = () => {
        setFormData({
            password: '',
            newPassword: '',
            confirmPassword: ''
        })
        setErrors({
            password: '',
            newPassword: '',
            confirmPassword: ''
        })
        handleClose()
    }

    const isFormValid = () => {
        return !Object.values(errors).some(err => err !== '') && Object.values(formData).every(val => val !== '')
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = {
            password: '',
            newPassword: '',
            confirmPassword: ''
        }
        if (!formData.password) {
            newErrors.password = 'Old password is required'
        }
        if (!formData.newPassword) {
            newErrors.newPassword = 'New password is required'
        } else if (formData.newPassword.length < 8) {
            newErrors.newPassword = 'New password must be at least 8 characters'
        }
        if (formData.confirmPassword !== formData.newPassword) {
            newErrors.confirmPassword = 'Passwords do not match'
        }

        setErrors(newErrors)

        if (isFormValid()) {
            handleChangePasswordClose()
        }
    }

    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <h5 className='text-black fw-semibold mb-3'>Change Password</h5>
                <Form onSubmit={handleSubmit}>
                    <Input
                        label='Old password'
                        type='password'
                        name='password'
                        id='password'
                        htmlFor='password'
                        value={formData.password}
                        onChange={handlePasswordChange}
                        error={errors.password}
                        placeholder='***************'
                        className='mb-2'
                    />
                    <Input
                        label='New password'
                        type='password'
                        name='newPassword'
                        id='new_password'
                        htmlFor='new_password'
                        value={formData.newPassword}
                        onChange={handlePasswordChange}
                        error={errors.newPassword}
                        placeholder='***************'
                        className='mb-3'
                    />
                    <Input
                        label='Re-confirm Password'
                        type='password'
                        name='confirmPassword'
                        id='confirm_password'
                        htmlFor='confirm_password'
                        value={formData.confirmPassword}
                        onChange={handlePasswordChange}
                        error={errors.confirmPassword}
                        placeholder='***************'
                        className='mb-3'
                    />
                    <Button disabled={!isFormValid()} className='py-3 w-100'>
                        Change Password
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
