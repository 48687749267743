import { Form } from 'react-bootstrap'
import { AuthWrapper } from '../../../../components/common/Default'
import { MyOTPInput } from '../../../../components/common/my-otp-input/MyOTPInput'
import { showToaster } from '../../../../utils/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/common/button/Button';
import { useOTPValidation } from '../../../../hooks/useOTPValidation';
import PostRequest from '../../../../services/PostRequest';

export const EmailOTPVerification = () => {
    const providerEmail = window.localStorage.getItem('PROVIDER_EMAIL') || ''
    
    const location = useLocation();
    const navigate = useNavigate()

    const { otp, setOtp, isOTPValid, error, handleSubmit } = useOTPValidation()

    const email = location?.state || providerEmail

    const handleFormSubmit = async (e) => {
        const formResult = handleSubmit(e)
        if(!formResult) return
        
        const data = {
            otp,
            email
        }
        
        try {
            const response = await PostRequest('/provider/register/verify-otp/email', data, { showLoader: 'verify-email' })
            navigate('/account-setup', { state: response?.email || email })
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }
    return (
        <AuthWrapper>
            <div className=''>
                <p className='get-started line-height-125 color-grey-500'>GET STARTED</p>
                <h5 className='welcome-heading line-height-125 mb-2'>Welcome to WorkRobin</h5>
            </div>
            <p className='text-black mb-3'>
                We've sent an OTP to {email}. If it's not in your inbox, check your spam/junk folder.
            </p>
            <Form onSubmit={handleFormSubmit}>
                <MyOTPInput
                    value={otp}
                    onChange={setOtp}
                    inputType='number'
                    label='Enter OTP'
                    error={error}
                    wrapperClasses='hide__arrows'
                />
                <Button type='submit' id='verify-email' disabled={!isOTPValid} className='w-100 py-3 mb-3'>Create Account</Button>
            </Form>
            <div className='login-text line-height-160 color-secondary'>
                Already have an account? {' '}
                <Link to='/login' className='text-decoration-none color-primary'>Login Here</Link>
            </div>
        </AuthWrapper>
    )
}
