import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Stack } from 'react-bootstrap'
import { cardTypeOptions, monthOptions, yearOptions } from '../../../data/custom-options'
import PostRequest from '../../../services/PostRequest'
import { fetchWalletDetails } from '../../../store/buyer/buyer.action'
import { showToaster } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Select } from '../../common/select/Select'
import { Input } from '../../common/input/Input'
import { Button } from '../../common/button/Button'

export const AddFundsForm = ({ fromPayments = false, className, submitHandler }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const walletDetails = useSelector(state => state.buyerReducer.walletDetails)

    const [formData, setFormData] = useState({
        cardType: null,
        cardName: '',
        cardNumber: '',
        expMonth: null,
        expYear: null,
        cvv: ''
    })

    const [errors, setErrors] = useState({
        cardType: '',
        cardName: '',
        cardNumber: '',
        expDate: '',
        cvv: ''
    })

    const validateField = (name, value) => {
        var error = '';
        if (name === 'cardType' && !value) {
            error = 'Card Type is required';
        } else if (name === 'cardName') {
            if (!value.trim()) {
                error = 'Card Name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Card Name cannot contains special characters';
            }
        } else if (name === 'cardNumber' && !value) {
            error = 'Card Number is required';
        } else if (name === 'cvv') {
            if (!value) {
                error = 'CVV is required';
            } else if (value.length !== 3 && value.length !== 4) {
                error = 'CVV must be 3 digits';
            }
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const updatedValue = name === 'cvv' ? value.replace(/[^0-9]/g, '').slice(0, 3) : name === 'cardNumber' ? value.replace(/[^0-9]/g, '') : value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);

        if (name === 'expMonth' || name === 'expYear') {
            const expDateError = (!formData.expMonth || !formData.expYear) ? 'Expiration Date is required' : ''
            setErrors(prevErrors => ({
                ...prevErrors,
                expDate: expDateError
            }))
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error
            }));
        }
    };

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            cardType: '',
            cardName: '',
            cardNumber: '',
            expDate: '',
            cvv: ''
        }

        if (!formData.cardType) newErrors.cardType = 'Card Type is required';
        if (!formData.cardName) newErrors.cardName = 'Card Name is required';
        if (!formData.cardNumber) newErrors.cardNumber = 'Card Number is required';
        if (!formData.expMonth || !formData.expYear) newErrors.expDate = 'Expiration Date is required';
        if (!formData.cvv) newErrors.cvv = 'CVV is required';

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            console.log('formData', formData)
            const data = {
                ...formData,
                cardType: formData?.cardType?.value,
                expMonth: formData?.expMonth?.value,
                expYear: formData?.expYear?.value,
                cvv: Number(formData?.cvv),
            }
            if(fromPayments) {
                submitHandler()
            } else {
                try {
                    const response = await PostRequest('/buyer/wallet-details/update', data, { showLoader: 'wallet-details-button' })
                    console.log('buyer wallet-details response', response)
                    navigate('/invite-team-members')
                } catch (error) {
                    showToaster(error.message, 'error')
                }
            }
        }
    }

    useEffect(() => {
        if (walletDetails) {
            setFormData({
                cardType: cardTypeOptions.find(option => option.value === walletDetails?.cardType) || null,
                cardName: walletDetails?.cardName || '',
                cardNumber: walletDetails?.cardNumber || '',
                expMonth: monthOptions.find(option => option.value === walletDetails?.expMonth) || null,
                expYear: yearOptions.find(option => option.value === walletDetails?.expYear) || null,
                cvv: walletDetails?.cvv || ''
            });
        }
    }, [walletDetails])

    useEffect(() => {
        dispatch(fetchWalletDetails())
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value)

    return (
        <Form className={className} onSubmit={handleSubmit}>
            <Select
                label={<>Card Type <span className='color-primary-500'>*</span></>}
                name='cardType'
                inputId='card-type'
                htmlFor='card-type'
                placeholder='Select one'
                className='mb-2'
                options={cardTypeOptions}
                value={formData?.cardType}
                error={errors.cardType}
                onChange={(cardType) => handleSelectChange(cardType, 'cardType')}
            />
            <Input
                label='Card Name'
                placeholder='Enter name on the card'
                htmlFor='card-name'
                id='card-name'
                name='cardName'
                value={formData?.cardName}
                onChange={handleChange}
                error={errors.cardName}
                className='mb-2'
            />
            {/* <CreditCardInput
                    // cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                    //     <input
                    //         {...props}
                    //         onChange={handleCardNumberChange(e =>
                    //             console.log('number change', e)
                    //         )}
                    //     />
                    // )}
                    containerClassName='form-input'
                /> */}
            <Input
                label='Card Number'
                placeholder='**** **** **** 3527'
                htmlFor='card-number'
                id='card-number'
                name='cardNumber'
                type='password'
                value={formData?.cardNumber}
                onChange={handleChange}
                error={errors.cardNumber}
                className='mb-2'
            />
            <Stack direction='horizontal' className='justify-content-between align-items-center mb-4'>
                <div className='w-75'>
                    <label className='form-label line-height-160' htmlFor={''}>
                        Expiration Date
                        <span className='color-primary-500'>*</span>
                    </label>
                    <Stack direction='horizontal' className='gap-1 w-fit'>
                        <Select
                            name='expMonth'
                            placeholder='Month'
                            options={monthOptions}
                            value={formData?.expMonth}
                            onChange={(month) => handleSelectChange(month, 'expMonth')}
                        />
                        <Select
                            name='expYear'
                            placeholder='Year'
                            options={yearOptions}
                            value={formData?.expYear}
                            onChange={(year) => handleSelectChange(year, 'expYear')}
                        />
                    </Stack>
                    {errors?.expDate && <span className='error-text line-height-160 mt-2'>{errors?.expDate}</span>}
                </div>
                <Input
                    label={<>CVV <span className='color-primary-500'>*</span></>}
                    placeholder='CVV'
                    htmlFor='cvv'
                    id='cvv'
                    name='cvv'
                    value={formData?.cvv}
                    onChange={handleChange}
                    error={errors.cvv}
                />
            </Stack>
            <Form.Group className='mb-3'>
                <Form.Check
                    type='checkbox'
                    label='Save card details'
                    id='save-card-details-checkbox'
                    className='font-14 fw-medium buyer-checkbox'
                />
            </Form.Group>
            {!fromPayments && (
                <Row className='mb-3 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Button
                            onClick={() => navigate(-1)}
                            type='button'
                            variant='outline'
                            className='w-100 color-ebony border-ebony py-3'
                        >
                            Back
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type='submit'
                            disabled={!isFormValid()}
                            id='wallet-details-button'
                            className='w-100 py-3'
                        >
                            Continue
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    )
}
