import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { HiArrowLongLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { Stepper } from "../../../../components/common/Stepper";
import { ProviderRecoveryCodes } from "./ProviderRecoveryCodes";
import { ProviderConfigure } from "./ProviderConfigure";
import { ProviderTwoFAEnabled } from "./ProviderTwoFAEnabled";
import { ProviderGoToSettings } from "./ProviderGoToSettings";

export const TwoFAProvider = ({ setIsTwoFAOpen }) => {
  const [isTwoFA, setIsTwoFA] = useState("recoveryCodes");
  const steps = [
    { label: "Recovery Codes", value: "recoveryCodes" },
    { label: "Configure", value: "configure" },
    { label: "Two-Factor Enabled", value: "twoFAEnabled" },
  ];

  return (
    <>
      <div
        className="px-2 py-1 mb-3 w-fit"
        style={{ display: isTwoFA == "goToSettings" ? "none" : "block" }}
      >
        <Link onClick={() => setIsTwoFAOpen(false)}>
          <HiArrowLongLeft
            size={28}
            color="black"
            style={{ marginBottom: 2 }}
          />
        </Link>
        <span className="fw-medium ms-2">Back</span>
      </div>

      <div
        style={{ display: isTwoFA == "goToSettings" ? "none" : "block" }}
        className="mt-4"
      >
        <Row className="justify-content-center profile-wrapper mb-5">
          <Col xs={11} sm={8} md={2}>
            <Stepper steps={steps.map((s) => s.value)} currentStep={isTwoFA} />
          </Col>
        </Row>
      </div>

      <div style={{ display: isTwoFA == "recoveryCodes" ? "block" : "none" }}>
        <ProviderRecoveryCodes setIsTwoFA={setIsTwoFA} />
      </div>
      <div style={{ display: isTwoFA == "configure" ? "block" : "none" }}>
        <ProviderConfigure setIsTwoFA={setIsTwoFA} />
      </div>
      <div style={{ display: isTwoFA == "twoFAEnabled" ? "block" : "none" }}>
        <ProviderTwoFAEnabled setIsTwoFA={setIsTwoFA} />
      </div>
      <div style={{ display: isTwoFA == "goToSettings" ? "block" : "none" }}>
        <ProviderGoToSettings />
      </div>
    </>
  );
};
