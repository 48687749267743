import React from "react";
import { Form } from "react-bootstrap";
import { Button } from "../../../../components/common/button/Button";
import { Select } from "../../../../components/common/select/Select";
import DatePicker from "react-datepicker";
import { Input } from "../../../../components/common/input/Input";

export const FiltersFormForPayments = ({ setShowFilters }) => {
  return (
    <>
      <Form className="form-wrapper">
        <Select
          label="Transaction Type"
          name="transactionType"
          inputId="transactionType"
          htmlFor="transactionType"
          placeholder="Enter project owner"
          className="mb-3"
          // options={transactionType}
          // value={formData?.transactionType}
          // error={errors.transactionType}
          // onChange={(tax) => handleSelectChange(tax, 'tax')}
          required
        />

        <Input
          label="Form"
          type="date"
          // placeholder="Enter amount"
          className="mb-3"
          name="accountName"
        />

        <DatePicker
          // selected={checkInTime}
          // onChange={(date) => setCheckInTime(date)}
          showTimeSelect
          showTimeSelectOnly
          showTimeCaption={false}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          placeholderText="HH:MM"
          customInput={<Input label="To" />}
        />
        <div className="button-bottom-side d-flex">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setShowFilters(false)}
          >
            Cancel
          </Button>
          <Button
            // disabled={!isFormValid()}
            type="submit"
            className="fw-semibold py-3 px-3"
            // onClick={() => setReviewDetails(true)}
          >
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};
