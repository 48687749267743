import React, { Fragment, useEffect, useState } from 'react'
import PostRequest from '../../../services/PostRequest'
import { formatFileSize, showToaster } from '../../../utils/utils'
import Dropzone from 'react-dropzone'
import { Form, Stack } from 'react-bootstrap'
import { Button } from '../../common/button/Button'
import { IoCloseOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as Gallery } from '../../../assets/images/gallery.svg'
import { ReactComponent as PDF } from '../../../assets/images/PDF.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProviderDetails } from '../../../store/provider/provider.action'

export const ResumeForm = ({
    fromModal = false,
    handleClose = () => { }
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)

    const resume = JSON.parse(window.localStorage.getItem('RESUME')) || {}

    const [acceptedFiles, setAcceptedFiles] = useState([])
    const [fileName, setFileName] = useState('')

    const onDrop = (files) => {
        console.log('Accepted Files:', files);
        setAcceptedFiles(files)
    };

    const clearFile = () => {
        setAcceptedFiles([])
        setFileName('')
        window.localStorage.removeItem('RESUME')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (fileName !== '') {
            navigate('/skills')
            return
        }

        var data = {}

        var formData = new FormData(e.target)

        if (acceptedFiles?.length === 0) {
            alert('Please upload a resume PDF file.');
            return;
        }

        formData.append('resume', acceptedFiles[0]);
        formData.forEach((val, key) => data[key] = val)

        window.localStorage.setItem('RESUME', JSON.stringify({
            name: data?.resume?.name,
            size: data?.resume?.size,
            // type: data?.resume?.type,
            // lastModified: data?.resume?.lastModified,
            // file: acceptedFiles[0]
        }))

        try {
            await PostRequest('/provider/profile/update', data, { isMultiMedia: true, showLoader: 'resume-button' })
            if (fromModal) {
                handleClose()
                dispatch(fetchProviderDetails())
            } else {
                navigate('/skills', { state: data?.resume })
            }
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        dispatch(fetchProviderDetails())
    }, [])

    useEffect(() => {
        // const parsedResume = JSON.parse(window.localStorage.getItem('RESUME'))
        // if (parsedResume) {

        //     const fileInstance = new File(
        //         [parsedResume.file.path],
        //         parsedResume.name,
        //         { type: parsedResume.type, lastModified: parsedResume.lastModified }
        //     )
        //     setAcceptedFiles([fileInstance]);
        // }
    }, []);

    useEffect(() => {
        if (providerDetails) {
            setFileName(providerDetails?.resume?.split('/').pop())
        }
    }, [providerDetails])

    return (
        <Fragment>
            {acceptedFiles?.length === 0 && (!resume || Object.keys(resume).length === 0) && fileName === '' ? (
                <>
                    <Dropzone
                        maxFiles={1}
                        onDrop={onDrop}
                        accept={{
                            'application/pdf': ['.pdf'],
                            'application/msword': ['.doc'],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Stack gap={2} {...getRootProps({ className: 'dropzone__wrapper cursor-pointer align-items-center mb-3' })}>
                                <input name='resume' {...getInputProps()} />
                                <Gallery />
                                <span className='text-center text-secondary fw-medium'>
                                    drag and drop to upload your resume pdf, doc, docx
                                </span>
                            </Stack>
                        )}
                    </Dropzone>
                    <Button
                        type='button'
                        disabled={acceptedFiles?.length === 0}
                        className='w-100 py-3 mb-3'
                    >
                        Upload your resume
                    </Button>
                    <div className='text-center fw-bold'>
                        <Link
                            to='/skills'
                            className='text-decoration-none color-grayscale-900'
                        >
                            Skip for now
                        </Link>
                    </div>
                </>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <Stack
                        direction='horizontal'
                        className='dropzone__wrapper border-grey-100 justify-content-between mb-3'
                    >
                        <PDF className='flex-shrink-0' />
                        <div className='me-auto ms-2'>
                            <p className='pdf-title text-truncate' style={{ maxWidth: 200 }}>
                                {acceptedFiles[0]?.name || resume?.name || fileName}
                                {/* {acceptedFiles[0]?.name || resume?.file?.path?.substring(2)} */}
                            </p>
                            <span className='pdf-size'>{formatFileSize(acceptedFiles[0]?.size || resume?.size)}</span>
                        </div>
                        <button type='button' className='pdf-close__wrapper border-0' onClick={clearFile}>
                            <IoCloseOutline size={20} />
                        </button>
                    </Stack>
                    <Stack direction='horizontal' gap={4}>
                        <Button
                            onClick={() => {
                                fromModal ? handleClose() : navigate(-1)
                            }}
                            type='button'
                            variant='outline'
                            className='w-100 color-ebony border-ebony py-3'
                        >
                            {fromModal ? 'Close' : 'Back'}
                        </Button>
                        <Button type='submit' id='resume-button' className='w-100 py-3'>{fromModal ? 'Update' : 'Continue'}</Button>
                    </Stack>
                </Form>
            )}
        </Fragment>
    )
}
