import { HiOutlineCalendar } from "react-icons/hi2"
import { Input } from "../common/input/Input"

export const CustomDateInput = ({ ...props }) => {
    return (
        <div className='position-relative'>
            <Input {...props} />
            <HiOutlineCalendar color='#667185' strokeWidth={2} className='position-absolute calendar-icon' />
        </div>
    )
}