import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Button } from '../../../../components/common/button/Button'
import { formatFileSize, showToaster } from '../../../../utils/utils'
import { IoCloseOutline } from 'react-icons/io5'
import { ReactComponent as PDF } from '../../../../assets/images/PDF.svg'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import PostRequest from '../../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { Fragment, useEffect, useState } from 'react'
import { fetchProviderDetails, fetchTaxDetails } from '../../../../store/provider/provider.action'
import { ASSET_URL } from '../../../../config/API_URL'
import { getCountries } from 'country-state-picker'
import moment from 'moment'
import { PhotoAndLocation } from '../../../../components/authentication/profiling/modals/PhotoAndLocation'
import { BioModal } from '../../../../components/authentication/profiling/modals/BioModal'
import { SkillsModal } from '../../../../components/authentication/profiling/modals/SkillsModal'
import TaxInformationModal from '../../../../components/authentication/profiling/modals/TaxInformationModal'
import { ResumeModal } from '../../../../components/authentication/profiling/modals/ResumeModal'

export const SubmitProfile = () => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    const taxDetails = useSelector(state => state.providerReducer.taxDetails)
    const parsedResume = JSON.parse(window.localStorage.getItem('RESUME')) || {}

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const resume = location.state

    const [showPhotoAndLocation, setShowPhotoAndLocation] = useState(false)
    const [showBio, setShowBio] = useState(false)
    const [showSkills, setShowSkills] = useState(false)
    const [showTaxInformation, setShowTaxInformation] = useState(false)
    const [showResumeUploader, setShowResumeUploader] = useState(false)

    const [currentTime, setCurrentTime] = useState(moment().format('h:mmA'))
    
    var countries = getCountries()

    countries = countries.map(country => ({
        label: country?.name,
        value: country?.name,
        code: country?.code
    }))
    const authProviderCountry = countries?.find(option => option.code === taxDetails?.country) || null
    
    const imageSrc = (providerDetails?.profileImage && providerDetails?.profileImage !== '') ? `${ASSET_URL}/${providerDetails?.profileImage}` : ''

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            ...providerDetails,
            isProfileSubmit: true
        }
        
        const options = {
            isMultiMedia: true,
            showLoader: 'profile-submit-button'
        }

        try {
            const response = await PostRequest('/provider/profile/update', data, options)
            console.log('response', response)

            window.location.replace('/dashboard')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        dispatch(fetchProviderDetails())
        dispatch(fetchTaxDetails())
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentTime(moment().format('h:mmA'));
        }, 60000);
    
        return () => clearInterval(interval);
      }, [])

    return (
        <Fragment>
        <ProfileWrapper>
            <h5 className='welcome-heading line-height-125 text-center mb-3'>Submit your profile</h5>
            <Form onSubmit={handleSubmit}>
                <Stack direction='horizontal' gap={4} className='mb-3'>
                    {imageSrc ? <img
                        src={imageSrc}
                        alt='Uploaded'
                        className='rounded-circle object-fit-cover'
                        style={{ height: 96, width: 96 }}
                    /> : <UserCircle />}
                    <div>
                        <h6 className='profile_label line-height-125 mb-1'>{providerDetails?.firstName} {providerDetails?.lastName}</h6>
                        <p className='mb-1 profile_timezone'>{taxDetails?.state}, {authProviderCountry?.value}</p>
                        <p className='mb-1 profile_timezone'>{currentTime} Local Time</p>
                        <Button
                            type='button'
                            onClick={() => setShowPhotoAndLocation(true)}
                            variant='outline'
                            className='py-2 font-12 rounded fw-medium border'
                        >
                            Edit
                        </Button>
                    </div>
                </Stack>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>{taxDetails?.tax}</span>
                        <span 
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            onClick={() => setShowTaxInformation(true)}
                        >
                            Edit
                        </span>
                    </Stack>
                    <hr className='my-2' />
                    <span className='form-label'>{`*****${taxDetails?.taxNumberLastDigits}`}</span>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>My Bio</span>
                        <span 
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            onClick={() => setShowBio(true)}
                        >
                            Edit
                        </span>
                    </Stack>
                    <hr className='my-2' />
                    <span className='form-label'>
                        {providerDetails?.bio}
                    </span>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>Your skills</span>
                        <span 
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            onClick={() => setShowSkills(true)}
                        >
                            Edit
                        </span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className='flex-wrap' gap={2}>
                        {providerDetails?.skills?.map((skill, i) => {
                            return <span className='skill' key={i}>{skill}</span>
                        })}
                    </Stack>
                </div>
                {providerDetails?.tools && providerDetails?.tools?.length > 0 && <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>Your tools</span>
                        <span 
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            onClick={() => setShowSkills(true)}
                        >
                            Edit
                        </span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className='flex-wrap' gap={2}>
                        {providerDetails?.tools?.map((tool, i) => {
                            return <span className='skill' key={i}>{tool}</span>
                        })}
                    </Stack>
                </div>}
                {providerDetails?.equipments && providerDetails?.equipments?.length > 0 && <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>Your equipments</span>
                        <span 
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            onClick={() => setShowSkills(true)}
                        >
                            Edit
                        </span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className='flex-wrap' gap={2}>
                        {providerDetails?.equipments?.map((skill, i) => {
                            return <span className='skill' key={i}>{skill}</span>
                        })}
                    </Stack>
                </div>}
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>My resume</span>
                        <span
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            onClick={() => setShowResumeUploader(true)}
                        >Replace</span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className=' justify-content-between mb-3'>
                        <PDF className='flex-shrink-0' />
                        <div className='me-auto ms-2'>
                            <p className='pdf-title text-truncate' style={{ maxWidth: 200 }}>
                                {parsedResume?.name || providerDetails?.resume?.split('/').pop()}
                            </p>
                            <span className='pdf-size'>{formatFileSize(resume ? resume.size : parsedResume?.size)}</span>
                        </div>
                        <div className='pdf-close__wrapper'>
                            <IoCloseOutline size={20} />
                        </div>
                    </Stack>
                </div>
                <Button type='submit' id='profile-submit-button' disabled={false} className='w-100 py-3'>Submit profile</Button>
            </Form>
        </ProfileWrapper>
        <PhotoAndLocation 
            show={showPhotoAndLocation} 
            handleClose={() => setShowPhotoAndLocation(false)} 
        />
        <BioModal 
            show={showBio} 
            handleClose={() => setShowBio(false)}
        />
        <SkillsModal 
            show={showSkills} 
            handleClose={() => setShowSkills(false)}
        />
        <TaxInformationModal 
            show={showTaxInformation} 
            handleClose={() => setShowTaxInformation(false)} 
        />
        <ResumeModal 
            show={showResumeUploader} 
            handleClose={() => setShowResumeUploader(false)} 
        />
        </Fragment>
    )
}
