import React, { useMemo, useState } from "react";
import { Form, ListGroup, NavDropdown, Offcanvas } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { Table } from "../../../../components/common/table/Table";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { Button } from "../../../../components/common/button/Button";
import { Input } from "../../../../components/common/input/Input";
import { Select } from "../../../../components/common/select/Select";
import { CommonHeader } from "../../../../components/buyer-provider/common-header/CommonHeader";

export const AgreementsTable = () => {
  const [sendToProvider, setSendToProvider] = useState(false);

  const [formData, setFormData] = useState({
    user: null,
    agreementTitle: "",
  });

  const [errors, setErrors] = useState({
    user: "",
    agreementTitle: "",
  });

  const validateField = (name, value) => {
    let error = "";

    if (name === "agreementTitle") {
      if (!value) {
        error = "Agreement title is required.";
      }
    } else if (name === "user") {
      if (!value) {
        error = "Provider selection is required.";
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    const value = selectedOption?.value || null;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption || null,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      formData.user &&
      formData.agreementTitle
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      user: validateField("user", formData.user?.value),
      agreementTitle: validateField("agreementTitle", formData.agreementTitle),
    };

    setErrors(newErrors);

    if (isFormValid()) {
      console.log("Submitted Data:", {
        user: formData.user.value,
        agreementTitle: formData.agreementTitle,
      });
      setSendToProvider(false);
    }
  };

  const userOptions = [
    { label: "Provider 1", value: "provider1" },
    { label: "Provider 2", value: "provider2" },
    { label: "Provider 3", value: "provider3" },
  ];

  const Columns = useMemo(
    () => [
      {
        accessorKey: "agreementTitle",
        header: () => "Agreement Title",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "type",
        header: () => "Type",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "dateCreated",
        header: () => "Date Created",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },

      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => setSendToProvider(true)}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Send to Provider
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Delete
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const Rows = [
    {
      agreementTitle: "Ademola Lookman",
      type: "Iristech Inc",
      dateCreated: "Feb 24, 2024",
    },
  ];

  return (
    <>
      <CommonHeader />

      <div className="mt-2 p-3">
        <Table columns={Columns} data={Rows} />
      </div>

      <RightOffcanvas
        show={sendToProvider}
        handleClose={() => setSendToProvider(false)}
      >
        <div className="p-3">
          <h5 className="text-black fw-semibold font-20">Send to a provider</h5>
          <p>You can send agreements to your desired provider</p>
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <Form onSubmit={handleSubmit} className="form-wrapper mt-3">
            <Input
              label="Agreement you are sending"
              placeholder="Low of Agreements"
              className="mb-3"
              name="agreementTitle"
              id="agreementTitle"
              htmlFor="agreementTitle"
              value={formData?.agreementTitle}
              onChange={handleChange}
              error={errors?.agreementTitle}
            />
            <Select
              label="Provider"
              placeholder="Select provider"
              className="mb-3"
              options={userOptions}
              name="user"
              inputId="user"
              htmlFor="user"
              value={formData?.user}
              onChange={(user) => handleSelectChange(user, "user")}
              error={errors?.user}
            />
            <div className="button-bottom-side d-flex">
              <Button
                type="button"
                variant="outline"
                className="fw-semibold px-3 color-ebony cancel-button"
                onClick={() => setSendToProvider(false)}
              >
                Cancel
              </Button>
              <Button
                id="submit-button"
                disabled={!isFormValid()}
                type="submit"
                className="fw-semibold py-1 px-2 add-client-button"
              >
                Send Agreement
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
