import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/timegrid";
import timeGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { SlLocationPin } from "react-icons/sl";
import "./viewCalendar.css";

export const ViewCalendar = ({ events, isBtn, btnName, click, ...rest }) => {
  const renderEventContent = (eventInfo) => {
    const { title, extendedProps } = eventInfo.event;
    const { pinNumber, location, charge } = extendedProps;

    return (
      <div className="fc-event-wrapper">
        <h6>{title}</h6>
        {pinNumber && <div>{pinNumber}</div>}
        <div className="d-flex align-items-center mt-2">
          <SlLocationPin style={{ marginRight: "8px" }} size={12} />
          <span className="">{location}</span>
        </div>
        <p className="mt-2">{charge}</p>
      </div>
    );
  };

  const renderDayHeaderContent = (args) => {
    const date = new Date(args.date);
    const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
    const dayNumber = date.getDate();

    return (
      <div className="">
        <div className="fw-bold font-10 color-grey-500">{dayName}</div>
        <div className="font-22 fw-600 color-black pt-1">{dayNumber}</div>
      </div>
    );
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          start: "prev,today,next",
          center: "timeGridDay,timeGridWeek,dayGridMonth,year",
          end: isBtn ? "setAvailability" : "",
        }}
        {...(isBtn && {
          customButtons: {
            setAvailability: {
              text: btnName,
              click: click,
            },
          },
        })}
        views={{
          year: {
            type: "dayGrid",
            duration: { years: 1 },
            buttonText: "Year",
          },
        }}
        events={events}
        eventContent={renderEventContent}
        dayHeaderContent={renderDayHeaderContent}
        allDaySlot={false}
        slotMinTime="07:00:00"
        slotMaxTime="17:00:00"
        slotDuration="01:00:00"
        height="85vh"
        {...rest}
      />
    </>
  );
};
