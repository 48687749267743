import { Badge, Offcanvas, Stack } from 'react-bootstrap'
import { HiArrowLongLeft } from 'react-icons/hi2'
import { IoCloseOutline } from 'react-icons/io5'

export const SingleMessage = ({ show, handleClose }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header className='justify-content-between'>
                <Offcanvas.Title>
                    <HiArrowLongLeft className='cursor-pointer' onClick={handleClose} size={30} />
                    <span className='ms-2'>Back to Message</span>
                </Offcanvas.Title>
                <button className='border-0 pdf-close__wrapper' onClick={handleClose}>
                    <IoCloseOutline size={20} />
                </button>
            </Offcanvas.Header>
            <hr className='opacity-100 my-0 mx-3 border-grayscale-100' />
            <Offcanvas.Body>
                <>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <p className='font-14 fw-medium text-black'>Counter Offer Made</p>
                        <p className='font-12 color-grey-500'>3 mins ago</p>
                    </Stack>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <h6 className='font-14 fw-normal color-fiord mb-0'>
                        A counter offer for WO {' '}
                        <Badge pill className='dropdown__tag'>#12277899</Badge> {' '}
                        has been submitted
                    </h6>
                    <button className='dropdown__tag btn rounded-3 my-2'>View offer</button>
                    <hr className='opacity-100 my-0 border-grayscale-100' />
                </>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
