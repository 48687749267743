import React from "react";
import "./filterButton.css";

export const FilterButton = ({ children, className, onClick }) => {
  return (
    <>
      <button
        type="button"
        className={`d-flex align-items-center gap-1 filterButton ${className}`}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};
