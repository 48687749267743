import React, { Fragment, useMemo, useState } from 'react'
import { MainDefault } from '../../../components/common/Default'
import { NotFoundCard } from '../../../components/profile/not-found/NotFoundCard'
import { AddEditPOC } from '../../../components/buyer/poc/offcanvas/AddEditPOC'
import { ListGroup, NavDropdown, Stack } from 'react-bootstrap'
import { Button } from '../../../components/common/button/Button'
import { Table } from '../../../components/common/table/Table'
import { FiPlus } from 'react-icons/fi'
import { CommonHeader } from '../../../components/buyer-provider/common-header/CommonHeader'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { Link } from 'react-router-dom'

export const POC = () => {

    const [showAddPOC, setShowAddPOC] = useState(false)
    const [POCToEdit, setPOCToEdit] = useState(null)
    const [modalMode, setModalMode] = useState('create'); // 'create' or 'edit'

    const columns = useMemo(() => [
        {
            accessorKey: 'pocName',
            header: () => 'POC Name',
            cell: ({ row }) => <Link to={`/poc/${row.original.id}`} className='color-grayscale-900'>
                {row.original.pocName}
            </Link>,
            // enableSorting: false
        },
        {
            accessorKey: 'email',
            header: () => 'Email Address',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'clientName',
            header: () => 'Client Name',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'phoneNumber',
            header: () => 'Phone Number',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'assignedWO',
            header: () => 'Assigned WO',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            id: 'actions',
            header: () => 'Action',
            cell: ({ row }) => {
                // const rowIndex = row.original?._id

                return <NavDropdown
                    title={<MdOutlineMoreHoriz size={24} />}
                    style={{ width: 'fit-content' }}
                >
                    <ListGroup variant='flush'>
                        <ListGroup.Item
                            action
                            onClick={() => {
                                setPOCToEdit(row.original)
                                setModalMode('edit');
                                setShowAddPOC(true)
                            }}
                            className='cursor-pointer color-gray-700 border-bottom-0'
                        >
                            Edit
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            // onClick={() => handleDelete(rowIndex)}
                            className='cursor-pointer color-gray-700'
                        >
                            Delete
                        </ListGroup.Item>
                    </ListGroup>
                </NavDropdown>
            },
        },
    ], [])

    const rows = [
        {
            id: 1,
            pocName: 'Ademola Lookman',
            email: 'johnsonnamani@gmail.com',
            clientName: 'Iristech Inc',
            phoneNumber: '01-24356789',
            assignedWO: 'WO-#1234475889'
        },
        {
            id: 2,
            pocName: 'Ademola Lookman',
            email: 'johnsonnamani@gmail.com',
            clientName: 'Iristech Inc',
            phoneNumber: '01-24356789',
            assignedWO: 'WO-#1234475889'
        },
        {
            id: 3,
            pocName: 'Ademola Lookman',
            email: 'johnsonnamani@gmail.com',
            clientName: 'Iristech Inc',
            phoneNumber: '01-24356789',
            assignedWO: 'WO-#1234475889'
        },
        {
            id: 4,
            pocName: 'Ademola Lookman',
            email: 'johnsonnamani@gmail.com',
            clientName: 'Iristech Inc',
            phoneNumber: '01-24356789',
            assignedWO: 'WO-#1234475889'
        },
        {
            id: 5,
            pocName: 'Ademola Lookman',
            email: 'johnsonnamani@gmail.com',
            clientName: 'Iristech Inc',
            phoneNumber: '01-24356789',
            assignedWO: 'WO-#1234475889'
        },
    ]

    return (
        <MainDefault containerClasses={[''].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
            {[''].length <= 0 ? (
                <Fragment>
                    <h4 className="mb-0 fw-bold mt-3 topHead">
                        Person of Contact
                    </h4>
                    <NotFoundCard
                        title="No Person of Contact"
                        subTitle="Create person of contact to be used anytime you are creating your work order"
                        buttonText="Add Person of Contact"
                        onButtonClick={() => {
                            setModalMode('create');
                            setShowAddPOC(true);
                        }}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <Stack
                        direction='horizontal'
                        className=' justify-content-between py-3 '
                    >
                        <h4 className='mb-0 fw-bold'>Persons of Contact</h4>
                        <Button
                            className='px-3 py-14px'
                            onClick={() => {
                                setModalMode('create');
                                setShowAddPOC(true);
                            }}
                        >
                            <FiPlus size={20} className='me-2' />
                            <span className='lh-1'>Add POC</span>
                        </Button>
                    </Stack>
                    <CommonHeader />
                    <div className='mt-3'>
                        <Table data={rows} columns={columns} />
                    </div>
                </Fragment>
            )}
            <AddEditPOC
                show={showAddPOC}
                handleClose={() => {
                    setShowAddPOC(false)
                    setPOCToEdit(null)
                }}
                mode={modalMode}
                pocData={POCToEdit}
            />
        </MainDefault>
    )
}
