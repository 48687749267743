import React, { useMemo, useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { Button } from "../../../components/common/button/Button";
import { RightOffcanvas } from "../../../components/buyer-provider/RightOffcanvas";
import { AddEditTicket } from "../../../components/buyer-provider/common-support/add-edit-ticket/AddEditTicket";
import { FiPlus } from "react-icons/fi";
import { SupportTableDetails } from "../../../components/buyer-provider/common-support/support-table-details/SupportTableDetails";
import "./support.css";
import { useNavigate } from "react-router-dom";
import {
  WOMAN_TALKING_WITH_SUPPORT_CHATBOT,
  YOUNG_PEOPLE_WORKING,
} from "../../../assets/images.assets";
import { SupportCard } from "../../../components/buyer-provider/common-support/SupportCard";
import { useDispatch, useSelector } from "react-redux";
import { createTicket } from "../../../store/provider/provider.action";

export const Support = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const _createTicket = useSelector(
    (state) => state.providerReducer.createTicketOffCanvas
  );

  console.log("_createTicket", _createTicket);

  const navigateToSuggestions = () => {
    navigate("/support/suggestions");
  };

  const handleOpenCreateTicket = () => {
    console.log("hhhh");
    dispatch(createTicket(true));
  };
  const handleClose = () => {
    dispatch(createTicket(false));
  };

  return (
    <MainDefault>
      <div className="px-3">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="font-24 line-height-130 fw-bold">Support</h6>

          {/* <div className="d-flex gap-3 align-items-center">
            <Button
              type="button"
              variant="outline"
              className="fw-semibold py-3 px-3 color-ebony"
              style={{
                paddingBlock: 14,
                border: "1px solid #CCCCF5",
              }}
              onClick={navigateToSuggestions}
            >
              See Suggestions
            </Button>

            <div>
              <Button
                type="submit"
                className="fw-semibold py-3 px-3"
                onClick={handleOpenCreateTicket}
              >
                <FiPlus size={20} className="me-1" />
                <span className="lh-1">Create Ticket</span>
              </Button>
            </div>
          </div>  */}
        </div>

        {/* <SupportTableDetails /> */}

        <Row className="mt-4">
          <Col sm={6}>
            <SupportCard
              heading="Need an Issue fixed?"
              description="You can create support tickets where issues can be
                    fixed"
              image={WOMAN_TALKING_WITH_SUPPORT_CHATBOT}
              isTicket
              setCreateTicket={handleOpenCreateTicket}
            />
          </Col>
          <Col sm={6}>
            <SupportCard
              heading="Explore our community"
              description="Join the discussion and support with ideas that can help build a better product"
              image={YOUNG_PEOPLE_WORKING}
            >
              <Button
                type="button"
                variant="outline"
                className="fw-semibold px-3 color-ebony mt-5"
                style={{
                  paddingBlock: 14,
                  border: "1px solid #CCCCF5",
                }}
                onClick={navigateToSuggestions}
              >
                See Suggestions
              </Button>
            </SupportCard>
          </Col>
        </Row>

        <RightOffcanvas
          show={_createTicket}
          onHide={handleClose}
          className="w-25"
        >
          <div className="p-3">
            <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
              Create Ticket
            </h6>
            <p className="font-16 color-gray-700">
              You can create an open ticket here
            </p>
          </div>

          <Offcanvas.Body className="d-flex flex-column">
            <AddEditTicket handleClose={handleClose} mode="create" />
          </Offcanvas.Body>
        </RightOffcanvas>
      </div>
    </MainDefault>
  );
};
