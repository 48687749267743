import React, { useState } from 'react';
import { CreatePool } from '../../../../components/buyer/work-orders/modals/CreatePool';
import { NotFoundCard } from '../../../../components/profile/not-found/NotFoundCard';
import { Col, Row } from 'react-bootstrap';
import { MyProviderPoolSidebar } from '../../../../components/buyer/providers/sidebar/Sidebar';
import { UserInformationCard } from '../../../../components/buyer-provider/user-information-card/UserInformationCard';
import { MoveProvider } from '../../../../components/buyer/providers/modal/MoveProvider';
import { useDispatch, useSelector } from 'react-redux';
import { MyProviderPoolTable } from '../../../../components/buyer/providers/MyProviderPoolTable';
// import { setCheckedItems } from '../../../../store/buyer/buyer.action';

export const MyProviderPool = () => {

  // const dispatch = useDispatch()
  // const checkedItems = useSelector((state) => state.buyerReducer.checkedItems)
  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);

  const [showCreatePool, setShowCreatePool] = useState(false)
  const [showMoveProvider, setShowMoveProvider] = useState(false)

  const navigateToProviderDetails = (e) => {
    if (e.target.closest('.buyer-checkbox') || e.target.closest('.dropdown')) return

    navigator("/providers/details");
  };

  // const handleCheckboxChange = (event, id) => {
  //   let updatedCheckedItems;

  //   if (event.target.checked) {
  //     // Add the id to the checked items list
  //     updatedCheckedItems = [...checkedItems, id];
  //   } else {
  //     // Remove the id from the checked items list
  //     updatedCheckedItems = checkedItems?.filter((itemId) => itemId !== id);
  //   }

  //   // Dispatch the action to update the Redux state
  //   dispatch(setCheckedItems(updatedCheckedItems));
  // };

  const dropdownItems = [
    { name: 'Move', onClick: () => setShowMoveProvider(true) },
    { name: 'Delete' }
  ]

  return (
    <>
      {[''].length <= 0 ? (
        <Row className='bg-grayscale-50 mt-n3'>
          <NotFoundCard
            title='No Pool Created'
            subTitle='Add desired provider to a pool created by you'
            buttonText='Create a Pool'
            onButtonClick={() => setShowCreatePool(true)}
          />
        </Row>
      ) : (
        <div className="d-flex gap-3 overflow-x-hidden" id="wrapper">
          <MyProviderPoolSidebar />
          <div id="page-content-wrapper">
            {_activeTabLayout === "cards" ? (
              <Row className="row-gap-4">
                {Array.from({ length: 8 }).map((_, i) => {
                  return (
                    <Col
                      md={4}
                      className="cursor-pointer"
                      onClick={navigateToProviderDetails}
                      key={i}
                    >
                      <UserInformationCard
                        userName="Ademola Adetunji"
                        address="Amsterdam, UK Profeesional"
                        dropdownItems={dropdownItems}
                      // index={i} 
                      // onCheckboxChange={handleCheckboxChange}
                      />
                    </Col>
                  )
                })}
              </Row>
            ) : (
              <MyProviderPoolTable onMove={() => setShowMoveProvider(true)} />
            )}
          </div>
        </div>
      )}
      <CreatePool show={showCreatePool} handleClose={() => setShowCreatePool(false)} />
      <MoveProvider show={showMoveProvider} handleClose={() => setShowMoveProvider(false)} />
    </>
  )
}
