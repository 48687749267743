import React, { useMemo } from "react";
// import Table from "react-bootstrap/Table";
import { MdOutlineMoreHoriz } from "react-icons/md";
import moment from "moment";
import { ListGroup, NavDropdown } from "react-bootstrap";
import { Table } from "../../../common/table/Table";
const AssignedContentTable = ({ 
  onShowUnAssignWO, 
  onShowModifyWO, 
  onShowCancelWO, 
  onShowMarkAsInProgress,
  onShowChangeSchedule
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: () => "Work Order Title",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },
      {
        accessorKey: "location",
        header: () => "Location",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },
      {
        accessorKey: "schedule",
        header: () => "Schedule",
        cell: (info) => (
          <span>{moment(info.getValue()).format("MMMM D, YYYY")}</span>
        ),
      },
      {
        accessorKey: "assigned Provider",
        header: () => "Assigned Provider",
        cell: (info) => (
          <div className="d-flex align-items-center">
            <div className="assigned-avatar color-green">MO</div>

            <div className="ms-2">
              <span className="f-18">Miracle Obafemi Paul</span>
              <p className="font-12 color-fiord">Amsterdam, United Kingdom</p>
            </div>
          </div>
        ),
        // enableSorting: false
      },

      {
        accessorKey: "price",
        header: () => "Price",
        cell: (info) => (
          <span className="color-grey-900 fw-semibold">
            {info.renderValue()}
          </span>
        ),
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={onShowMarkAsInProgress}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Mark In Progress
                </ListGroup.Item>

                <ListGroup.Item
                  action
                  onClick={onShowUnAssignWO}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Unassign
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={onShowModifyWO}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Modify
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={onShowChangeSchedule}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Change Schedule
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={onShowCancelWO}
                  className="cursor-pointer font-14 color-gray-700"
                >
                  Cancel
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },
    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },

    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },

    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },
  ];

  return (
    <>
      <Table columns={columns} data={rows} />
    </>
  );
};

export default AssignedContentTable;
