import { Fragment, useState } from 'react';
import { Badge, Offcanvas, Stack } from 'react-bootstrap';
import { SingleNotification } from './SingleNotification';

export const AllNotifications = ({ show, handleClose }) => {

    const [showNotification, setShowNotification] = useState(false)

    return (
        <Fragment>
            <Offcanvas show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Header>
                    <Offcanvas.Title>All Notifications</Offcanvas.Title>
                </Offcanvas.Header>
                <hr className='opacity-100 my-0 mx-3 border-grayscale-100' />
                <Offcanvas.Body>
                    <>
                        <Stack direction='horizontal' className='justify-content-between'>
                            <p className='font-14 fw-medium text-black'>Counter Offer Made</p>
                            <p className='font-12 color-grey-500'>3 mins ago</p>
                        </Stack>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <h6 className='font-14 fw-normal color-fiord mb-0'>
                            A counter offer for WO {' '}
                            <Badge pill className='dropdown__tag'>#12277899</Badge> {' '}
                            has been submitted
                        </h6>
                        <button onClick={() => setShowNotification(true)} className='dropdown__tag btn rounded-3 my-2'>View offer</button>
                        <hr className='opacity-100 my-0 border-grayscale-100' />
                    </>
                    <>
                        <Stack direction='horizontal' className='justify-content-between mt-3'>
                            <p className='font-14 fw-medium text-black'>Counter Offer Made</p>
                            <p className='font-12 color-grey-500'>3 mins ago</p>
                        </Stack>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <h6 className='font-14 fw-normal color-fiord mb-0'>
                            A counter offer for WO {' '}
                            <Badge pill className='dropdown__tag'>#12277899</Badge> {' '}
                            has been submitted
                        </h6>
                        <button className='dropdown__tag btn rounded-3 my-2'>View offer</button>
                        <hr className='opacity-100 my-0 border-grayscale-100' />
                    </>
                    <>
                        <Stack direction='horizontal' className='justify-content-between mt-3'>
                            <p className='font-14 fw-medium text-black'>Counter Offer Made</p>
                            <p className='font-12 color-grey-500'>3 mins ago</p>
                        </Stack>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <h6 className='font-14 fw-normal color-fiord mb-0'>
                            A counter offer for WO {' '}
                            <Badge pill className='dropdown__tag'>#12277899</Badge> {' '}
                            has been submitted
                        </h6>
                        <button className='dropdown__tag btn rounded-3 my-2'>View offer</button>
                        <hr className='opacity-100 my-0 border-grayscale-100' />
                    </>
                </Offcanvas.Body>
            </Offcanvas>
            <SingleNotification
                show={showNotification}
                handleClose={() => setShowNotification(false)}
            />
        </Fragment>
    );
}
