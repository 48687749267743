import React, { useState } from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Creatable } from '../../../common/select/Select'
import { AlertModal } from '../../work-orders/modals/AlertModal'
import { ReactComponent as Message } from '../../../../assets/images/message.svg'
import { ReactComponent as Gallery } from '../../../../assets/images/gallery.svg'
import { ReactComponent as PDF } from '../../../../assets/images/PDF.svg'
import Dropzone from 'react-dropzone'
import { formatFileSize } from '../../../../utils/utils'
import { HiOutlineTrash } from 'react-icons/hi2'

export const InviteProviders = ({ show, handleClose }) => {

    const [inviteType, setInviteType] = useState('single')

    const [showAlertModal, setShowAlertModal] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowAlertModal(true)
    }

    return (
        <>
            <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
                <Modal.Header className='border-bottom-0 pb-0' closeButton>
                    <Modal.Title className='color-grayscale-900 fw-700'>Invite Providers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className='mb-3'>
                            <Form.Check
                                inline
                                type={'radio'}
                                name='inviteProvider'
                                id='single'
                                value='single'
                                label='Single invite'
                                className='color-grey-500'
                                checked={inviteType === 'single'}
                                onChange={(e) => setInviteType(e.target.value)}
                                defaultChecked
                            />
                            <Form.Check
                                inline
                                type={'radio'}
                                name='inviteProvider'
                                id='bulk'
                                value='bulk'
                                label='Bulk Invite'
                                className='color-grey-500'
                                checked={inviteType === 'bulk'}
                                onChange={(e) => setInviteType(e.target.value)}
                            />
                        </div>
                        {inviteType === 'single' ? (
                            <Creatable
                                label='Email Address'
                                name='emails'
                                inputId='emails-select'
                                className='mb-5'
                                placeholder='Enter recipient’s emails'
                            // value={formData?.skills}
                            // onChange={(selectedOptions) => handleSelectInputChange(selectedOptions, 'skills')}
                            // onCreateOption={(inputValue) => handleCreateOption(inputValue, 'skills')}
                            />
                        ) : (
                            <>
                                <label className='form-label line-height-160' htmlFor='emails'>Upload your file</label>
                                <div className='mb-5'>
                                    <>
                                        <Dropzone
                                            accept={{
                                                'text/csv': ['.csv'],
                                                'application/vnd.ms-excel': ['.xls'],
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <Stack gap={2} {...getRootProps({ className: `dropzone__wrapper cursor-pointer align-items-center` })}>
                                                    <input name='emails' className='position-static' {...getInputProps()} />
                                                    <Gallery />
                                                    <span className='text-center dropzone__instruction'>
                                                        Drag and drop
                                                    </span>
                                                    <span className='text-center dropzone__accepted-formats'>
                                                        Accepted format: csv, xls, xlsx
                                                    </span>
                                                </Stack>
                                            )}
                                        </Dropzone>
                                        <p className='color-primary fst-italic text-decoration-underline cursor-pointer mt-2'>Download Template</p>
                                    </>
                                    {/* <Stack
                                        direction='horizontal'
                                        className='justify-content-between'
                                    >
                                        <PDF className='flex-shrink-0' />
                                        <div className='me-auto ms-2'>
                                            <p className='pdf-title text-truncate' style={{ maxWidth: 200 }}>
                                                ProvidersList.pdf
                                                // {acceptedFiles[0]?.name || resume?.name || fileName}
                                            </p>
                                            <span className='pdf-size'>
                                                867 Kb
                                            //    {formatFileSize(acceptedFiles[0]?.size || resume?.size)}
                                            </span>
                                        </div>
                                        <button type='button' className='pdf-close__wrapper border-0'>
                                            <HiOutlineTrash size={20} className='mb-1' />
                                        </button>
                                    </Stack> */}
                                </div>
                            </>
                        )}
                        <Stack direction='horizontal' className='justify-content-between'>
                            <Button
                                type='button'
                                variant='outline'
                                className='color-ebony padded-border-button py-14px'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                className='py-14px'
                            >
                                Invite
                            </Button>
                        </Stack>
                    </Form>
                </Modal.Body>
            </Modal>
            <AlertModal
                show={showAlertModal}
                handleClose={() => setShowAlertModal(false)}
                image={Message}
            />
        </>
    )
}
