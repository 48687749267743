import { Form } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Select } from '../../../../components/common/select/Select'
import { employeeOptions } from '../../../../data/custom-options'
import { Button } from '../../../../components/common/button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuthBuyer, showToaster } from '../../../../utils/utils'
import { useEffect } from 'react'
import PostRequest from '../../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBusinessDetails, fetchIndustries } from '../../../../store/buyer/buyer.action'
import MyPhoneInput from '../../../../components/common/my-phone-input/MyPhoneInput'
import { useBusinessDetailsValidation } from '../../../../hooks/useBusinessDetailsValidation'
// import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

export const GetStarted = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer()

    const INFO = {
        title: `Welcome ${location.state || parsedAuthBuyer?.firstName || ''}!`,
        description: 'We\u2019d love to know a bit more about you...'
    }

    const {
        formData,
        setFormData,
        errors,
        handleChange,
        handleSelectChange,
        handleSubmit
    } = useBusinessDetailsValidation()

    const businessDetails = useSelector(state => state.buyerReducer.businessDetails)
    var industryOptions = useSelector(state => state.buyerReducer.industries)
    industryOptions = industryOptions?.map(industry => ({
        label: industry?.name,
        value: industry?._id
    }))

    // const handleAddress = ({ description }) => {
    //     geocodeByAddress(description)
    //         .then(results => getLatLng(results[0]))
    //         .then(({ lat, lng }) =>
    //             console.log('Successfully got latitude and longitude', { lat, lng })
    //         )
    //         .catch(error => console.error(error));
    // }

    const handleFormSubmit = async (e) => {
        const formResult = handleSubmit(e)
        if (!formResult) return

        try {
            const response = await PostRequest('/buyer/business-details/update', formResult, { showLoader: 'business-details-button' })
            console.log('buyer business-details response', response)
            navigate('/tax-information')
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        if (businessDetails) {
            setFormData({
                address: businessDetails?.address || '',
                industry: industryOptions?.find(option => option.value === businessDetails?.industry) || null,
                website: businessDetails?.website || '',
                phoneNumber: businessDetails?.phoneNumber || '',
                totalEmployees: employeeOptions.find(option => option.value === businessDetails?.totalEmployees) || null
            });
        }
    }, [businessDetails])

    useEffect(() => {
        dispatch(fetchIndustries())
        dispatch(fetchBusinessDetails())
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(formData).every(value => value)

    return (
        <ProfileWrapper info={INFO}>
            <Form onSubmit={handleFormSubmit}>
                <Input
                    label='Address'
                    placeholder='Address'
                    htmlFor='address'
                    id='address'
                    name='address'
                    value={formData?.address}
                    error={errors?.address}
                    onChange={handleChange}
                    className='mb-2'
                />
                {/* <GooglePlacesAutocomplete
                    debounce={800}
                    apiKey="AIzaSyBolbxxZRC2pJgG7EUPTJkgaudxRyszZ_U"
                    onSelect={handleAddress}
                    renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                        <div className="suggestions-container">
                            {suggestions.map(suggestion => (
                                <div
                                    className="suggestion"
                                    onClick={event => onSelectSuggestion(suggestion, event)}
                                >
                                    {suggestion.description}
                                </div>
                            ))}
                        </div>
                    )}
                /> */}
                {/* <GooglePlacesAutocomplete
                    apiKey=''
                    selectProps={{
                        value: formData?.address,
                        onChange: handleChange,
                        on
                    }}
                /> */}
                <Select
                    label='Industry'
                    name='industry'
                    inputId='industry'
                    htmlFor='industry'
                    placeholder='Select one'
                    className='mb-2'
                    required
                    options={industryOptions}
                    value={formData?.industry}
                    error={errors?.industry}
                    onChange={(industry) => handleSelectChange(industry, 'industry')}
                />
                <Input
                    label='Website'
                    placeholder='https://www.janedoe.com'
                    htmlFor='website'
                    id='website'
                    name='website'
                    value={formData?.website}
                    error={errors.website}
                    onChange={handleChange}
                    className='mb-2'
                />
                <MyPhoneInput
                    label='Phone number'
                    value={formData.phoneNumber}
                    onChange={(phone) => {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            phoneNumber: phone
                        }))
                    }}
                    error={errors.phoneNumber}
                    className='mb-2'
                />
                <Select
                    label='Number of Employees'
                    name='totalEmployees'
                    inputId='employees'
                    htmlFor='employees'
                    placeholder='Select one'
                    className='mb-2'
                    required
                    options={employeeOptions}
                    value={formData?.totalEmployees}
                    error={errors.totalEmployees}
                    onChange={(employees) => handleSelectChange(employees, 'totalEmployees')}
                />
                <Button
                    type='submit'
                    id='business-details-button'
                    className='w-100 mb-1 py-3'
                    disabled={!isFormValid()}
                >
                    Continue
                </Button>
            </Form>
        </ProfileWrapper>
    )
}
