import { Modal, Stack } from 'react-bootstrap'

export const AlertModal = ({ 
    show, 
    handleClose,
    image: Image,
    title = 'Invite Sent',
    subtitle = 'Your invitation message has sent to the emails entered'
}) => {
    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Stack gap={3} className='align-items-center mb-3'>
                    <Image />
                    <div className='text-center'>
                        <h5 className='text-black fw-medium'>{title}</h5>
                        <p className='text-center'>{subtitle}</p>
                    </div>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}
