import React, { useState } from "react";
import { MainDefault } from "../../../../../components/common/Default";
import { Form, Stack } from "react-bootstrap";
import { Select } from "../../../../../components/common/select/Select";
import { Input } from "../../../../../components/common/input/Input";
import "../../myProfile.css";
import { Button } from "../../../../../components/common/button/Button";

export const MyTaxInformationForm = () => {
  const [formData, setFormData] = useState({
    taxType: null,
    socialSecurityNumber: "",
    fullName: "",
    businessAS: "",
    address: "",
    floor: "",
    city: "",
    state: "",
    zipCode: "",
    country: null,
  });

  const [errors, setErrors] = useState({
    taxType: "",
    socialSecurityNumber: "",
    fullName: "",
    businessAS: "",
    address: "",
    floor: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "taxType":
        if (!value) error = "Tax type is required.";
        break;
      case "socialSecurityNumber":
        if (!value) error = "Social Security Number is required.";
        else if (!/^\d{9}$/.test(value))
          error = "Social Security Number must be 9 digits.";
        break;
      case "fullName":
        if (!value) error = "Full Name is required.";
        break;
      case "address":
        if (!value) error = "Address is required.";
        break;
      case "city":
        if (!value) error = "City is required.";
        break;
      case "state":
        if (!value) error = "State is required.";
        break;
      case "zipCode":
        if (!value) error = "Zip code is required.";
        else if (!/^\d{5}$/.test(value)) error = "Zip code must be 5 digits.";
        break;
      case "country":
        if (!value) error = "Country is required.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    const value = selectedOption?.value || null;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption || null,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      formData.taxType &&
      formData.socialSecurityNumber &&
      formData.fullName &&
      formData.address &&
      formData.city &&
      formData.state &&
      formData.zipCode &&
      formData.country
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      taxType: validateField("taxType", formData.taxType?.value),
      socialSecurityNumber: validateField(
        "socialSecurityNumber",
        formData.socialSecurityNumber
      ),
      fullName: validateField("fullName", formData.fullName),
      address: validateField("address", formData.address),
      city: validateField("city", formData.city),
      state: validateField("state", formData.state),
      zipCode: validateField("zipCode", formData.zipCode),
      country: validateField("country", formData.country?.value),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      console.log("Form Data Submitted:", formData);
    }
  };
  
  const countiesOption = [
    { label: "County 1", value: "county1" },
    { label: "County 2", value: "county2" },
    { label: "County 3", value: "county3" },
    { label: "County 4", value: "county4" },
    { label: "County 5", value: "county5" },
  ];

  const taxTypesOptions = [
    { label: "Type 1", value: "type1" },
    { label: "Type 2", value: "type2" },
    { label: "Type 3", value: "type3" },
    { label: "Type 4", value: "type4" },
    { label: "Type 5", value: "type5" },
  ];

  return (
    <MainDefault>
      <div className="top-nav py-3">
        <h4 className="mb-0">Tax Information</h4>
      </div>
      <Form className="mt-3" onSubmit={handleSubmit}>
        <div className="w-50">
          <Select
            label="Tax Type"
            name="taxType"
            inputId="taxType"
            placeholder="Select one"
            className="mb-2"
            options={taxTypesOptions}
            value={formData.taxType}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "taxType")
            }
            error={errors.taxType}
          />
          <Input
            label="Social Security Number"
            type="number"
            name="socialSecurityNumber"
            id="socialSecurityNumber"
            value={formData.socialSecurityNumber}
            onChange={handleChange}
            error={errors.socialSecurityNumber}
            placeholder="SSN"
            className="mb-2"
          />
          <Input
            label="Full Name associated with TIN"
            type="text"
            name="fullName"
            id="fullName"
            value={formData.fullName}
            onChange={handleChange}
            error={errors.fullName}
            placeholder="Enter Full Name"
            className="mb-2"
          />
          <Input
            label="Doing Business As - optional"
            type="text"
            name="businessAS"
            id="businessAS"
            value={formData.businessAS}
            onChange={handleChange}
            placeholder="Business Name"
            className="mb-2"
          />
          <Input
            label="Address"
            type="text"
            name="address"
            id="address"
            value={formData.address}
            onChange={handleChange}
            error={errors.address}
            placeholder="Address"
            className="mb-2"
          />
          <div className="d-flex gap-3">
            <Input
              type="text"
              name="floor"
              id="floor"
              value={formData.floor}
              onChange={handleChange}
              placeholder="Suite/Floor"
              className="mb-2 w-100"
            />
            <Input
              type="text"
              name="city"
              id="city"
              value={formData.city}
              onChange={handleChange}
              error={errors.city}
              placeholder="City"
              className="mb-2 w-100"
            />
          </div>
          <div className="d-flex gap-3">
            <Input
              type="text"
              name="state"
              id="state"
              value={formData.state}
              onChange={handleChange}
              error={errors.state}
              placeholder="State"
              className="mb-2 w-100"
            />
            <Input
              type="text"
              name="zipCode"
              id="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              error={errors.zipCode}
              placeholder="Zip code"
              className="mb-2 w-100"
            />
          </div>
          <Select
            label="Country"
            name="country"
            inputId="country"
            placeholder="Country"
            className="mb-3"
            options={countiesOption}
            value={formData.country}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "country")
            }
            error={errors.country}
          />
        </div>
        <Form.Check
          type="checkbox"
          label="Receive my 1099 electronically"
          id="receive-my-electronically"
          className="buyer-checkbox instructions-checkbox mb-2"
        />
        <Stack direction="horizontal" gap={4}>
          <Button
            variant="outline"
            className="color-ebony border-ebony fw-semibold"
            style={{ paddingBlock: 14 }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            type="submit"
            className="fw-semibold"
            style={{ paddingBlock: 14 }}
          >
            Update Tax Information
          </Button>
        </Stack>
      </Form>
    </MainDefault>
  );
};
