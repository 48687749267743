import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PaymentDetails } from "../PaymentDetails";
import { GoPaperclip } from "react-icons/go";
import "./deliverables.css";
import { CheckBoxWithIcon } from "../../common/checkbox-with-iconn/CheckBoxWithIcon";
import { CardWrapper } from "../../common/Default";
import Underline from "../../buyer-provider/Underline";
import { AboutDeliverables } from "./AboutDeliverables";
import { TasksDeliverables } from "./TasksDeliverables";

export const Deliverables = () => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <AboutDeliverables />

          <TasksDeliverables />
        </Col>
        <Col sm={6}>
          <PaymentDetails />
        </Col>
      </Row>
    </>
  );
};
