import React from "react";
import {
  MainDefault,
} from "../../../../components/common/Default";
import { Link } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";
import { Col, Row } from "react-bootstrap";
import { AboutProvider } from "./AboutProvider";
import { ProviderOverview } from "./ProviderOverview";

export const ProviderDetails = () => {
  return (
    <MainDefault>
      <div className="px-2 py-1">
        <div className="mb-3 w-fit">
          <Link to="/providers">
            <HiArrowLongLeft
              size={28}
              color="black"
              style={{ marginBottom: 2 }}
            />
          </Link>
          <span className="fw-medium ms-2">Back To Providers</span>
        </div>
        <h4 className="mb-0 fw-bold topHead">Provider Details</h4>
      </div>
      <Row className="mt-4">
        <Col sm={4}>
          <AboutProvider />
        </Col>
        <Col sm={8}>
          <ProviderOverview />
        </Col>
      </Row>
    </MainDefault>
  );
};
