import React from "react";
import WorkTableDefault from "../../provider-work-order-details/WorkTableDefault";
import { CardWrapper } from "../../../../../common/Default";
import { Form } from "react-bootstrap";

export const TermsAndConditionMyWork = () => {
  return (
    <WorkTableDefault>
      <CardWrapper>
        <div className="description-heading">
          <h6 className="mt-1">Terms of Agreements</h6>
          <p className="mt-3">
            As a seasoned plumber dedicated to excellence in my craft, I bring a
            wealth of experience and a passion for delivering top-notch plumbing
            solutions. With a [insert number of years] years' track record in
            the field, I have honed my skills in diagnosing, repairing, and
            maintaining plumbing systems with precision and efficiency.
          </p>
          <p className="mt-3">
            My commitment to quality craftsmanship is evident in my attention to
            detail, ensuring that every installation and repair meets the
            highest industry standards. I am adept at handling a diverse range
            of plumbing tasks, from routine maintenance to troubleshooting
            complex issues.
          </p>
        </div>
      </CardWrapper>
      <div className="mt-4 mb-4">
        <CardWrapper>
          <div className="description-heading">
            <h6 className="mt-1">Code of Conduct</h6>
            <p className="mt-3">
              As a seasoned plumber dedicated to excellence in my craft, I bring
              a wealth of experience and a passion for delivering top-notch
              plumbing solutions. With a [insert number of years] years' track
              record in the field, I have honed my skills in diagnosing,
              repairing, and maintaining plumbing systems with precision and
              efficiency.
            </p>
          </div>
        </CardWrapper>
      </div>

      <CardWrapper>
        <div className="description-heading">
          <h6 className="mt-1">Agreement</h6>
          <div className="mt-3">
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <div
                  key={index}
                  className="d-flex gap-4 align-items-center mb-3"
                >
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-${index}`}
                    className="mt-1 buyer-checkbox"
                  />
                  <label htmlFor={`checkbox-${index}`} className="agreement">
                    Provider SOP Agreement
                  </label>

                  <div className="py-1 px-3 custom-badge fw-500 font-14 ms-5">
                    PENDING
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CardWrapper>
    </WorkTableDefault>
  );
};
