import React from "react";
import "./clients.css";
import { Form, Navbar } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { AiOutlineSetting } from "react-icons/ai";
import { RiDownloadCloud2Line } from "react-icons/ri";

export const ClientSearchBar = () => {
  return (
    <div className="header d-flex align-items-center justify-content-between">
      <Navbar.Brand className="position-relative">
        <IoSearch className="form-control__search-icon color-grey-500" />
        <Form.Control
          type="text"
          placeholder="Search"
          className="form-control__search applicant-search-input"
        />
      </Navbar.Brand>
      <div className="d-flex align-items-center gap-3">
        <button
          type="button"
          className="filterButton"
          // onClick={() => setShowFilters(true)}
        >
          <AiOutlineSetting className="me-1" />
          <span>Filter</span>
        </button>

        <button
          type="button"
          className={`d-flex align-items-center gap-1 download-document `}
        >
          <RiDownloadCloud2Line className="me-1" size={16} />
          <span>Download CSV</span>
        </button>
      </div>
    </div>
  );
};
