import React, { useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { ProfileUpdateStatus } from "../../../components/dashboard/ProfileUpdateStatus";
import { useLocation, useNavigate } from "react-router-dom";
import { JobUpdates } from "../../../components/dashboard/job-updates/JobUpdates";
import {
  BUYER_PROVIDER_navITEMS,
  BUYER_PROVIDER_tabPanes,
} from "../../../data/constants";
import { LayoutSwitcher } from "../../../components/dashboard/job-updates/LayoutSwitcher";
import { Filters } from "../../../components/buyer/work-orders/offcanvas/Filters";
import { AiOutlineSetting } from "react-icons/ai";
import { Button } from "../../../components/common/button/Button";
import { useSelector } from "react-redux";
import { Stack } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { InviteMultiple } from "../../../components/buyer/providers/offcanvas/InviteMultiple";

export const Providers = () => {

  const navigate = useNavigate();
  const location = useLocation()

  const defaultActiveKey = location.state?.eventKey

  const checkedItems = useSelector((state) => state.buyerReducer.checkedItems)

  const [showFilters, setShowFilters] = useState(false);
  const [showInvite, setShowInvite] = useState(false)

  const [currentEventKey, setCurrentEventKey] = useState(defaultActiveKey || 'all-providers')

  const navigateToProfileSetUp = () => {
    navigate("/profile/company");
  };

  return (
    <MainDefault>
      <ProfileUpdateStatus onUpdateProfile={navigateToProfileSetUp} />
      <Stack direction='horizontal' className='justify-content-between mt-3'>
        <h4 className="mb-0 fw-bold mt-4 topHead">Providers</h4>

        {checkedItems?.length > 0 && currentEventKey === 'all-providers' ? (
          <Button
            type='button'
            onClick={() => setShowInvite(true)}
            className='py-14px'
          >
            <FiPlus size={20} className='me-2' />
            <span>Invite {checkedItems?.length} {checkedItems?.length > 1 ? 'providers' : 'provider'}</span>
          </Button>
        ) : (
          <></>
        )}
      </Stack>

      <JobUpdates
        navItems={BUYER_PROVIDER_navITEMS}
        tabPanes={BUYER_PROVIDER_tabPanes}
        defaultActiveKey={defaultActiveKey}
        handleSelect={(eventKey) => setCurrentEventKey(eventKey)}
      >
        <LayoutSwitcher showCalendar />
        <button
          type="button"
          onClick={() => setShowFilters(true)}
          className="filterButton"
        >
          <AiOutlineSetting className="me-1" />
          <span>Filter</span>
        </button>
      </JobUpdates>
      <Filters show={showFilters} handleClose={() => setShowFilters(false)} />
      <InviteMultiple show={showInvite} handleClose={() => setShowInvite(false)}  />
    </MainDefault>
  );
};
