import React, { useMemo, useState } from 'react'
import { CardWrapper, MainDefault } from '../../../../components/common/Default'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HiArrowLongLeft, HiOutlineSquare3Stack3D } from 'react-icons/hi2'
import { Col, ListGroup, NavDropdown, Row, Stack } from 'react-bootstrap'
import { FilterButton } from '../../../../components/common/filter-button/FilterButton'
import { CommonHeader } from '../../../../components/buyer-provider/common-header/CommonHeader'
import { Table } from '../../../../components/common/table/Table'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import Avatar from 'react-avatar'
import { ApprovalModificationTimesheet } from '../../../../components/buyer/poc/offcanvas/ApprovalModificationTimesheet'
import './poc-details.css'

export const POCDetails = () => {

  const navigate = useNavigate()
  const params = useParams()

  const [showApprovalModification, setShowApprovalModification] = useState(false)
  const [POCRequest, setPOCRequest] = useState('approval')

  const columns = useMemo(() => [
    {
      accessorKey: 'WOTitle',
      header: () => 'Work Order Title',
      cell: info => info.renderValue(),
      // enableSorting: false
    },
    {
      accessorKey: 'WOId',
      header: () => 'Work Order ID',
      cell: info => <Link to={`/poc/${params?.pocId}/details`} className='color-grayscale-900'>
        {info.renderValue()}
      </Link>,
      // enableSorting: false
    },
    {
      accessorKey: 'location',
      header: () => 'Location',
      cell: info => info.renderValue(),
      // enableSorting: false
    },
    {
      accessorKey: 'approvalRequired',
      header: () => 'Approval Required',
      cell: info => info.renderValue(),
      // enableSorting: false
    },
    {
      accessorKey: 'totalHours',
      header: () => 'Total Hours',
      cell: info => info.renderValue(),
      // enableSorting: false
    },
    {
      accessorKey: 'assignedProvider',
      header: () => 'Assigned Provider',
      cell: ({ row }) => (
        <Stack direction='horizontal' className='gap-2'>
          <Avatar round size='30' name={row.original.assignedProvider} />
          <span>{row.original.assignedProvider}</span>
        </Stack>
      ),
      // enableSorting: false
    },
    {
      id: 'actions',
      header: () => 'Action',
      cell: ({ row }) => {

        return <NavDropdown
          title={<MdOutlineMoreHoriz size={24} />}
          id='profile-dropdown'
        >
          <ListGroup variant='flush'>
            <ListGroup.Item
              action
              onClick={() => {
                setPOCRequest('approval')
                setShowApprovalModification(true)
              }}
              className='cursor-pointer color-gray-700 border-bottom-0'
            >
              Resend for approval
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => {
                setPOCRequest('modification')
                setShowApprovalModification(true)
              }}
              className='cursor-pointer color-gray-700'
            >
              Request for modification
            </ListGroup.Item>
          </ListGroup>
        </NavDropdown>
      },
    },
  ], [])

  const rows = [
    {
      WOTitle: 'Plumbing',
      WOId: '#28388323',
      location: 'Birmingham, United Kingdom',
      approvalRequired: 'No',
      totalHours: '05 hrs 03 mins',
      assignedProvider: 'Adeleke Fajuyi Olayiwola',
    },
    {
      WOTitle: 'Plumbing',
      WOId: '#28388323',
      location: 'Birmingham, United Kingdom',
      approvalRequired: 'No',
      totalHours: '05 hrs 03 mins',
      assignedProvider: 'Adeleke Fajuyi Olayiwola',
    },
    {
      WOTitle: 'Plumbing',
      WOId: '#28388323',
      location: 'Birmingham, United Kingdom',
      approvalRequired: 'No',
      totalHours: '05 hrs 03 mins',
      assignedProvider: 'Adeleke Fajuyi Olayiwola',
    },
  ]

  return (
    <MainDefault>
      <div className="mb-3 w-fit">
        <HiArrowLongLeft
          size={28}
          color="black"
          className='cursor-pointer'
          onClick={() => navigate(-1)}
        />
        <span className="fw-medium ms-2">Back To POC</span>
      </div>
      <Stack direction='horizontal' className='justify-content-between mb-3'>
        <div>
          <Stack direction='horizontal' className='mb-2' gap={2}>
            <h4 className='mb-0 fw-bold'>Ademola Lookman</h4>
            <span className='job-status badge-status__active'>ACTIVE</span>
          </Stack>
          <span className="card-tag__fixed rounded font-12 color-grey-900">ASSIGNED WO: #225367771</span>
        </div>
        <FilterButton>
          <HiOutlineSquare3Stack3D className="me-1" size={20} />
          <span>History</span>
        </FilterButton>
      </Stack>
      <Row>
        <Col sm={6}>
          <CardWrapper>
            <Row>
              <Col sm={6}>
                <p className='font-18 fw-medium mb-3'>Contact Details</p>
                <p className='color-fiord'>
                  Ademola Lookman <br />
                  gabrielabimbola@gmail.com <br />
                  01-27731229
                </p>
              </Col>
              <Col sm={6}>
                <p className='font-18 fw-medium mb-3'>Client Details</p>
                <p className='color-fiord'>
                  Heurty Inc. <br />
                  Birmingham, United Kingdom <br />
                  01-27731229 <br />
                </p>
              </Col>
            </Row>
          </CardWrapper>
        </Col>
        <Col sm={6}>
          <CardWrapper>
            <p className='font-18 fw-medium mb-3'>Pending Timesheet awaiting approval</p>
            <p className='color-fiord'>
              3 Pending Timesheet
            </p>
          </CardWrapper>
        </Col>
      </Row>
      <p className='font-18 fw-medium my-3'>Pending Timesheet</p>
      <CommonHeader isFilterButton={false} showUnderLine={false} />
      <div className='mt-3'>
        <Table data={rows} columns={columns} />
      </div>
      <ApprovalModificationTimesheet
        show={showApprovalModification}
        handleClose={() => setShowApprovalModification(false)}
        pocRequest={POCRequest}
      />
    </MainDefault>
  )
}
