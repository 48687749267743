import { Fragment, useState } from 'react'
import { Col, Form, ListGroup, NavDropdown, Row, Stack } from 'react-bootstrap'
import { Button } from '../../../../common/button/Button'
import { HiInformationCircle } from 'react-icons/hi2'
import { LayoutSwitcher } from '../../../../dashboard/job-updates/LayoutSwitcher'
import { AiOutlineSetting } from 'react-icons/ai'
import ReactSelect from "react-select";
import { dashboardFilterDropdownStyles } from '../../../../../data/constants'
import { CardWrapper } from '../../../../common/Default'
import './invite-provider.css'
import Rating from 'react-rating'
import { MdStar } from 'react-icons/md'
import { Input } from '../../../../common/input/Input'
import { Select } from '../../../../common/select/Select'
import { getTrackBackground, Range } from 'react-range'
import { SaveTemplate } from '../../modals/SaveTemplate'
import { QuickProfileDetails } from '../../offcanvas/QuickProfileDetails'
import { ConfirmationActionModal } from '../../modals/ConfirmationActionModal'
import { ReactComponent as Message } from '../../../../../assets/images/message.svg'
import { WOCreated } from '../../modals/WOCreated'
import { AlertModal } from '../../modals/AlertModal'

export const InviteProviderForm = () => {

  const [showAutoInvite, setShowAutoInvite] = useState(false)
  const [showInviteSent, setShowInviteSent] = useState(false)
  const [showSaveTemplate, setShowSaveTemplate] = useState(false)
  const [showWOCreated, setShowWOCreated] = useState(false)
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      // Add card id to checkedItems if it's checked
      setCheckedItems((prevChecked) => [...prevChecked, id]);
    } else {
      // Remove card id from checkedItems if it's unchecked
      setCheckedItems((prevChecked) =>
        prevChecked.filter((itemId) => itemId !== id)
      );
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {}
    const formData = new FormData(e.target)

    formData.forEach((val, key) => data[key] = val)

    console.log('Form Submitted', data)
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Stack direction='horizontal' className='justify-content-between'>
          <div className='basic-info__wrapper'>
            <h6 className='title line-height-160 font-18 mb-0'>Invite Provider</h6>
            <p className='subtitle'>Add known provider to this job</p>
          </div>
          {checkedItems?.length > 0 ? (
            <Button
              type='button'
              onClick={() => setShowInviteSent(true)}
              className='py-14px px-5'
            >
              Invite {checkedItems?.length}
            </Button>
          ) : (
            <></>
          )}

        </Stack>
        <hr className='opacity-100 border-botticelli' />
        <Stack direction='horizontal' className='justify-content-between'>
          <Button
            type='button'
            className='btn__accept-invite rounded-3 color-primary py-2'
            onClick={() => setShowAutoInvite(true)}
          >
            <span className='font-14 me-1'>Auto Invite</span>
            <HiInformationCircle size={20} className='color-grey-500' />
          </Button>
          <div>
            <Stack direction='horizontal' gap={3}>
              <LayoutSwitcher />
              <InviteProviderDropdown />
              <button
                type="button"
                // onClick={() => setShowFilters(true)}
                className='filterButton'
              >
                <AiOutlineSetting className="me-1" />
                <span>Filter</span>
              </button>
            </Stack>
          </div>
        </Stack>
        <Row className='mt-3 row-gap-3'>
          {Array.from({ length: 8 }).map((card, i) => {
            return (
              <Col md={3} key={i}>
                <InviteProviderCard index={i} onCheckboxChange={handleCheckboxChange} />
              </Col>
            )
          })}
        </Row>
        <hr className='opacity-100 border-botticelli' />
        <Row>
          <Col md={3} className='basic-info__wrapper'>
            <h6 className='title mb-0'>Invite Specific Provider <span className='fst-italic'>(optional)</span></h6>
            <p className='subtitle'>You can invite single provider</p>
          </Col>
          <Col md={9}>
            <Input
              className='w-50'
              placeholder='Enter provider id or email to search'
              name='providerSearch'
              id='providerSearchInput'
            />
          </Col>
        </Row>
        <hr className='opacity-100 border-botticelli' />
        <Row>
          <Col md={3} className='basic-info__wrapper'>
            <h6 className='title mb-0'>Browse Provider <span className='fst-italic'>(optional)</span></h6>
            <p className='subtitle'>Select provider from saved list</p>
          </Col>
          <Col md={9}>
            <Select
              name='providerSelect'
              inputId='providerSelectInput'
              htmlFor='providerSelectInput'
              placeholder='Select provider'
              className='w-50'
            />
          </Col>
        </Row>
        <hr className='opacity-100 border-botticelli' />
        <Stack direction='horizontal' className='justify-content-between'>
          <Button
            type='button'
            variant='outline'
            className='fw-semibold px-3 color-ebony padded-border-button'
          // onClick={handleClose}
          >
            Cancel
          </Button>
          <Stack direction='horizontal' className='gap-3'>
            <Button
              type='button'
              variant='outline'
              className='fw-semibold px-3 color-ebony padded-border-button'
              onClick={() => setShowSaveTemplate(true)}
            >
              Save as draft
            </Button>
            <Button
              type='submit'
              className='fw-semibold py-14px'
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </Form>
      <ConfirmationActionModal
        show={showAutoInvite}
        handleClose={() => setShowAutoInvite(false)}
        image={Message}
        title='Auto Invite'
        subtitle={
          <>
            You are able to smart finder to auto find right provider for this work order. The first provider to accept will be auto assigned. <br />
            Do you want to continue?
          </>
        }
        primaryButtonText='Yes, Continue'
        onPrimaryButtonClick={() => setShowWOCreated(true)}
        secondaryButtonText='Cancel'
        isOutlineStyle={false}
      />
      <WOCreated show={showWOCreated} handleClose={() => setShowWOCreated(false)} />
      <AlertModal 
        show={showInviteSent} 
        handleClose={() => setShowInviteSent(false)}
        image={Message} 
      />
      <SaveTemplate show={showSaveTemplate} handleClose={() => setShowSaveTemplate(false)} />
    </Fragment>
  )
}


const InviteProviderCard = ({ index, onCheckboxChange }) => {

  const [showProfileDetails, setShowProfileDetails] = useState(false)

  return (
    <>
      <CardWrapper className='position-relative'>
        <Form.Check
          type='checkbox'
          className='buyer-checkbox invite-provider__checkbox'
          onChange={(e) => onCheckboxChange(e, index)}
          name='remember'
          id='remember-device'
        />
        <div
          className='p-2 cursor-pointer'
          onClick={() => setShowProfileDetails(true)}
        >
          <Stack direction='horizontal' gap={2}>
            <img src='https://contentwriter.co/images/avatars/1708227404.jpg' className='card-image__invite-provider' alt='' />
            <div>
              <p className='font-14 color-ebony fw-semibold'>Ademola Adetunji</p>
              <p className='font-12 color-grey-500'>Amsterdam, UK</p>
              <p className='font-12 color-grey-500'>15 miles away</p>
            </div>
          </Stack>
          <Stack direction='horizontal' className='mt-3 justify-content-between'>
            <span className="card-tag__fixed rounded-2 font-12 color-grey-900">40 jobs completed</span>
            <Rating
              fullSymbol={<MdStar size={20} color='#FB9905' />}
              emptySymbol={<MdStar size={20} color='#D9D9D9' />}
              initialRating={4}
              readonly
            />
          </Stack>
          <hr className='opacity-100 border-grayscale-100' />
          <Stack direction='horizontal' className='flex-wrap gap-1 skills__wrapper'>
            <span className='skill'>Plumbing</span>
            <span className='skill'>Carpentry</span>
            <span className='skill'>Build</span>
            <span className='skill'>Hardwork</span>
            <span className='skill'>Hardwork</span>
            <span className='font-12 color-primary'>3 more</span>
          </Stack>
        </div>
      </CardWrapper>
      <QuickProfileDetails show={showProfileDetails} handleClose={() => setShowProfileDetails(false)} />
    </>
  )
}

const InviteProviderDropdown = () => {
  const [showZipCodeMenu, setShowZipCodeMenu] = useState(false)
  const [showDistanceMenu, setShowDistanceMenu] = useState(false)

  const handleDropdownToggle = (isOpen) => {
    if (!isOpen) {
      setShowZipCodeMenu(false)
      setShowDistanceMenu(false)
    }
  }

  return (
    <>
      {showZipCodeMenu ? (
        <NavDropdown
          className='zip-code__dropdown'
          title={
            <ReactSelect
              isSearchable={false}
              styles={{
                ...dashboardFilterDropdownStyles,
                menu: (provided) => ({
                  ...provided,
                  display: 'none'
                })
              }}
              placeholder="Sort by"
            />
          }
          onToggle={handleDropdownToggle}
        >
          <ListGroup variant="flush">
            <ListGroup.Item className="border-bottom-0">
              <Input label='Zip code' placeholder='Zip code' inputClasses='py-14px' className='mb-2' />
              <Button type='button' className='py-2' onClick={() => setShowZipCodeMenu(false)}>Sort</Button>
            </ListGroup.Item>
          </ListGroup>
        </NavDropdown>
      ) : showDistanceMenu ? (
        <NavDropdown
          className='zip-code__dropdown'
          title={
            <ReactSelect
              isSearchable={false}
              styles={{
                ...dashboardFilterDropdownStyles,
                menu: (provided) => ({
                  ...provided,
                  display: 'none'
                })
              }}
              placeholder="Sort by"
            />
          }
          onToggle={handleDropdownToggle}
        >
          <ListGroup variant="flush">
            <ListGroup.Item className="border-bottom-0">
              <p className='form-label line-height-160'>By Distance</p>
              <TwoThumbs />
              <Button type='button' className='py-2 mt-4' onClick={() => setShowZipCodeMenu(false)}>Sort</Button>
            </ListGroup.Item>
          </ListGroup>
        </NavDropdown>
      ) : (
        <NavDropdown
          className='invite-provider__dropdown'
          title={
            <ReactSelect
              isSearchable={false}
              styles={{
                ...dashboardFilterDropdownStyles,
                menu: (provided) => ({
                  ...provided,
                  display: 'none'
                })
              }}
              placeholder="Sort by"
            />
          }
        >
          <ListGroup variant="flush">
            <ListGroup.Item
              action
              onClick={() => { }}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              By Location
            </ListGroup.Item>

            <ListGroup.Item
              action
              onClick={() => setShowDistanceMenu(true)}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              By Distance
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => setShowZipCodeMenu(true)}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              By Zip Code
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => { }}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              By Jobs Completed
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => { }}
              className="cursor-pointer font-14 color-gray-700"
            >
              By Availability
            </ListGroup.Item>
          </ListGroup>
        </NavDropdown>
      )}
    </>
  )
}

const TwoThumbs = () => {

  const STEP = 1;
  const MIN = 0;
  const MAX = 100;

  const [values, setValues] = useState([25, 75]);
  return (
    <Range
      values={values}
      step={STEP}
      min={MIN}
      max={MAX}
      onChange={(values) => setValues(values)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "36px",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "5px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values,
                colors: ["#EAECF0", "#7F56D9", "#EAECF0"],
                min: MIN,
                max: MAX,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props, isDragged }) => (
        <div
          {...props}
          key={props.key}
          style={{
            ...props.style,
            height: "24px",
            width: "24px",
            borderRadius: "50%",
            backgroundColor: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #7F56D9",
            boxShadow: "0px 2px 6px #AAA",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "28px",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {values[index].toFixed(1)}
          </div>
        </div>
      )}
    />
  )
}