import React from "react";
import WorkTableDefault from "../../provider-work-order-details/WorkTableDefault";
import { Input } from "../../../../../common/input/Input";
import { Button } from "../../../../../common/button/Button";
import { CardWrapper } from "../../../../../common/Default";
import Underline from "../../../../../buyer-provider/Underline";
import { Stack } from "react-bootstrap";
import Avatar from "react-avatar";

export const MessagesMyWork = () => {
  
  const handelSubmitMessage = (e) => {
    try {
      e.preventDefault();
      e.target.reset();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <WorkTableDefault>
      <CardWrapper>
        <h5 className="mt-1 font-18">Send a message with the buyer</h5>

        <Underline />
        <div className="p-2 mt-4">
          <div className="d-flex justify-content-between">
            <Stack direction="horizontal" className="gap-3">
              <Avatar round size="30" name={"ME"} className="message-sender" />
              <span className="font-14 line-height-160 color-grey-500">
                This work order is not really detailed enough for me. Can you
                provider <br></br> information
              </span>
            </Stack>
            <p className="font-10 line-height-160 color-grey-500">3 days ago</p>
          </div>

          <div className="ms-5 mt-4">
            <div className="d-flex justify-content-between">
              <Stack direction="horizontal" className="gap-3">
                <Avatar round size="30" name={"B"} className="message-receiver" />
                <span className="font-14 line-height-160 color-grey-500">
                  Quite informative enough
                </span>
              </Stack>
              <p className="font-10 line-height-160 color-grey-500">
                3 days ago
              </p>
            </div>
          </div>
        </div>
        <Underline />
        <h6 className="mt-4">Your message</h6>

        <form onSubmit={handelSubmitMessage}>
          <Input
            required
            style={{ height: "130px" }}
            multiline={true}
            placeholder="message"
            className={"mt-3"}
          />

          <Button className="py-3 mt-4" children="Submit" />
        </form>
      </CardWrapper>
    </WorkTableDefault>
  );
};
