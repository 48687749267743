import React from "react";

import { Modal } from "react-bootstrap";
import "./confirmation-modal.css";
import { Button } from "../../../../common/button/Button";
import { MESSAGE_IMG } from "../../../../../assets/images.assets";

const ConfirmationModal = ({ confirmationShow, handelClose, children }) => {
  return (
    <Modal
      size="sm"
      dialogClassName="invite-dialog custom_sm_modal"
      show={confirmationShow}
      onHide={handelClose}
      centered
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
