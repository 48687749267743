import React, { useMemo } from "react";
import { Form, Offcanvas, Stack } from "react-bootstrap";
import { Table } from "../../common/table/Table";
import { RxCross1 } from "react-icons/rx";
import "./history.css";
import { RightOffcanvas } from "../../buyer-provider/RightOffcanvas";

export const History = (props) => {
  const { show, handleClose } = props;

  const columns = useMemo(
    () => [
      {
        accessorKey: "action",
        header: () => "Action",
        cell: (info) => (
          <span className="history-data">Work order created</span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "date",
        header: () => "Date",
        cell: (info) => <span className="history-data">03/01/2024</span>,
        // enableSorting: false
      },
      {
        accessorKey: "time",
        header: () => "Time",
        cell: (info) => <span className="history-data">9:30AM</span>,
      },
      {
        accessorKey: "performedBy",
        header: () => "Performed By",
        cell: (info) => <span className="history-data">Olakunle Airs</span>,
        // enableSorting: false
      },
    ],
    []
  );

  const rows = [
    {
      name: "Plumber",
      emailAddress: "Birmingham, United Kingdom",
      rating: "Active",
      rating: " 5",
      jobsCompleted: "30/200",
      price: "$500",
    },
    {
      name: "Plumber",
      emailAddress: "Birmingham, United Kingdom",
      rating: "Active",
      rating: " 5",
      jobsCompleted: "30/200",
      price: "$500",
    },
  ];
  return (
    <>
     <RightOffcanvas show={show} handleClose={handleClose} className="w-50">
        <div className="d-flex align-items-center justify-content-between p-3">
          <Offcanvas.Title>
            <h6 className="history">History</h6>
          </Offcanvas.Title>
          <Offcanvas.Header closeButton></Offcanvas.Header>
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <Table columns={columns} data={rows} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
