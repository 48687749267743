import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Col, Form, Row, Stack } from 'react-bootstrap'
import { fetchIndustries } from '../../../../store/buyer/buyer.action'
import { fetchSkills } from '../../../../store/provider/provider.action'
import { Input } from '../../../common/input/Input'
import { Creatable, Select } from '../../../common/select/Select'
import { Button } from '../../../common/button/Button'
import { SaveTemplate } from '../modals/SaveTemplate'
import { handleChange, handleCreate, handleSelectChange, isFormValid } from '../../../../utils/utils'
import { RichTextEditor } from '../../../common/rich-text-editor/RichTextEditor'

export const BasicInformationForm = () => {

    const dispatch = useDispatch()

    var industryOptions = useSelector(state => state.buyerReducer.industries)
    industryOptions = industryOptions?.map(industry => ({
        label: industry?.name,
        value: industry?._id
    }))

    var SKILLS = useSelector(state => state.providerReducer.skills)
    SKILLS = SKILLS?.map(option => ({
        label: option?.skill,
        value: option?.skill
    }))

    const [formData, setFormData] = useState({
        jobTitle: '',
        jobDescription: '',
        specialInstructions: '',
        industry: null,
        category: null,
        skills: [],
        qualifications: [],
        toolsAndEquipment: [],
        supportContact: null
    })

    const [errors, setErrors] = useState({
        jobTitle: '',
        jobDescription: '',
        specialInstructions: '',
        industry: '',
        category: '',
        skills: '',
        qualifications: '',
        toolsAndEquipment: '',
        supportContact: ''
    })

    const [showSaveTemplate, setShowSaveTemplate] = useState(false)

    const validateField = (name, value) => {
        var error = ''

        if (name === 'jobTitle') {
            if (!value.trim()) {
                error = 'Job title is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Job title cannot contains special characters';
            }
        }
        if (name === 'jobDescription') {
            if (!value.trim()) {
                error = 'Job description is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Job description cannot contains special characters';
            }
        }
        if (name === 'industry' && !value) error = 'Industry is required.'
        if (name === 'category' && !value) error = 'Category is required.'
        if (name === 'supportContact' && !value) error = 'Support Contact is required.'

        return error
    }

    const handleInputChange = (e) => {
        handleChange(e, setFormData, setErrors, validateField)
    }

    const handleSelectInputChange = (selectedOption, name) => {
        handleSelectChange(selectedOption, name, setFormData, setErrors, validateField)
    }

    const handleCreateOption = (inputValue, field) => {
        handleCreate(inputValue, field, setFormData)
    }

    const isBasicFormValid = () => {
        const { specialInstructions, skills, qualifications, ...restFormData } = formData
        return isFormValid(errors, restFormData)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = {
            jobTitle: '',
            jobDescription: '',
            specialInstructions: '',
            industry: '',
            category: '',
            skills: '',
            qualifications: '',
            toolsAndEquipment: '',
            supportContact: ''
        }

        Object.entries(formData).forEach(([key, value]) => {
            const error = validateField(key, value);
            if (error) newErrors[key] = error;
        })

        if(formData?.toolsAndEquipment.length <= 0) newErrors.toolsAndEquipment = 'Tools and equipment is required' 

        setErrors(newErrors)

        if (isBasicFormValid()) {
            var data = {}
            var formDataObj = new FormData(e.target)

            const skillsArray = [];
            const qualificationsArray = [];
            const toolsAndEquipmentArray = [];

            formDataObj.forEach((val, key) => {
                if (key === 'skills' && val && val.trim() !== '') skillsArray.push(val);
                if (key === 'qualifications' && val && val.trim() !== '') qualificationsArray.push(val);
                if (key === 'toolsAndEquipment' && val && val.trim() !== '') toolsAndEquipmentArray.push(val);
                data[key] = val
            });

            data['skills'] = skillsArray.length > 0 ? JSON.stringify(skillsArray) : '[]';
            data['qualifications'] = qualificationsArray.length > 0 ? JSON.stringify(qualificationsArray) : '[]';
            data['toolsAndEquipment'] = toolsAndEquipmentArray.length > 0 ? JSON.stringify(toolsAndEquipmentArray) : '[]';
            
            console.log('data', data)
        }
    }

    useEffect(() => {
        dispatch(fetchIndustries())
        dispatch(fetchSkills())
    }, [])

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className='basic-info__wrapper'>
                    <h6 className='title mb-0'>Basic Information</h6>
                    <p className='subtitle'>This information will be displayed publicly as checked</p>
                </div>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Job Title</h6>
                        <p className='subtitle'>Job titles must be describe one position</p>
                    </Col>
                    <Col md={9}>
                        <Input
                            className='w-50'
                            placeholder='e.g. Plumbing Specialist'
                            name='jobTitle'
                            value={formData.jobTitle}
                            error={errors.jobTitle}
                            onChange={handleInputChange}
                            infoText='At least 80 characters'
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Job Description</h6>
                        <p className='subtitle'>Job titles must be describe one position</p>
                    </Col>
                    <Col md={9}>
                        <RichTextEditor placeholder='Enter job description' />
                        {/* <Input
                            multiline
                            rows={5}
                            placeholder='Enter job description'
                            name='jobDescription'
                            value={formData.jobDescription}
                            onChange={handleInputChange}
                            error={errors.jobDescription}
                        /> */}
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Special Instructions <span className='fst-italic'>(optional)</span></h6>
                        <p className='subtitle'>Give the special instructions to the assigned provider</p>
                    </Col>
                    <Col md={9}>
                        <RichTextEditor placeholder='Enter instructions' />
                        {/* <Input
                            multiline
                            rows={5}
                            className='mb-2'
                            placeholder='Enter instructions'
                            name='specialInstructions'
                            value={formData?.specialInstructions}
                            onChange={handleInputChange}
                        /> */}
                        <Form.Check
                            type='checkbox'
                            id='assigned-provider-checkbox'
                            label='Only show to the assigned provider'
                            className='buyer-checkbox instructions-checkbox mt-2'
                            defaultChecked
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Industry</h6>
                        <p className='subtitle'>Select the industry of the job</p>
                    </Col>
                    <Col md={9}>
                        <Select
                            name='industry'
                            inputId='industry'
                            htmlFor='industry'
                            placeholder='Select Industry'
                            className='w-50'
                            options={industryOptions}
                            value={formData?.industry}
                            error={errors?.industry}
                            onChange={(industry) => handleSelectInputChange(industry, 'industry')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Category</h6>
                        <p className='subtitle'>Select the category of the job</p>
                    </Col>
                    <Col md={9}>
                        <Select
                            name='category'
                            inputId='category'
                            htmlFor='category'
                            placeholder='Select Category'
                            className='w-50'
                            options={industryOptions}
                            value={formData?.category}
                            error={errors?.category}
                            onChange={(category) => handleSelectInputChange(category, 'category')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Required Skills <span className='fst-italic'>(optional)</span></h6>
                        <p className='subtitle'>Add required skills for the job</p>
                    </Col>
                    <Col md={9}>
                        <Creatable
                            name='skills'
                            inputId='skills-select'
                            placeholder='Start typing to search for skills'
                            className='w-50'
                            options={SKILLS}
                            value={formData?.skills}
                            onChange={(selectedOptions) => handleSelectInputChange(selectedOptions, 'skills')}
                            onCreateOption={(inputValue) => handleCreateOption(inputValue, 'skills')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Required Qualifications <span className='fst-italic'>(optional)</span></h6>
                        <p className='subtitle'>Add required qualifications for the job</p>
                    </Col>
                    <Col md={9}>
                        <Creatable
                            name='qualifications'
                            inputId='qualifications-select'
                            placeholder='Start typing to search for qualifications'
                            className='w-50'
                            options={SKILLS}
                            value={formData?.qualifications}
                            error={errors.qualifications}
                            onChange={(selectedOptions) => handleSelectInputChange(selectedOptions, 'qualifications')}
                            onCreateOption={(inputValue) => handleCreateOption(inputValue, 'qualifications')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Required Tools & Equipment</h6>
                        <p className='subtitle'>Add required qualifications for the job</p>
                    </Col>
                    <Col md={9}>
                        <Creatable
                            name='toolsAndEquipment'
                            inputId='tools-select'
                            placeholder='Start typing to add tools'
                            className='w-50'
                            options={SKILLS}
                            value={formData?.toolsAndEquipment}
                            error={errors?.toolsAndEquipment}
                            onChange={(selectedOptions) => handleSelectInputChange(selectedOptions, 'toolsAndEquipment')}
                            onCreateOption={(inputValue) => handleCreateOption(inputValue, 'toolsAndEquipment')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Support Contact</h6>
                        <p className='subtitle'>Add your team member</p>
                    </Col>
                    <Col md={9}>
                        <Select
                            name='supportContact'
                            inputId='supportContact'
                            htmlFor='supportContact'
                            placeholder='Select Member'
                            className='w-50'
                            options={industryOptions}
                            value={formData?.supportContact}
                            error={errors?.supportContact}
                            onChange={(supportContact) => handleSelectInputChange(supportContact, 'supportContact')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Stack direction='horizontal' className='justify-content-between'>
                    <Button
                        type='button'
                        variant='outline'
                        className='fw-semibold px-3 color-ebony padded-border-button'
                    >
                        Cancel
                    </Button>
                    <Stack direction='horizontal' className='gap-3'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={() => setShowSaveTemplate(true)}
                        >
                            Save as draft
                        </Button>
                        <Button
                            type='submit'
                            className='fw-semibold py-14px'
                            disabled={!isBasicFormValid()}
                        >
                            Continue
                        </Button>
                    </Stack>
                </Stack>
            </Form>
            <SaveTemplate show={showSaveTemplate} handleClose={() => setShowSaveTemplate(false)} />
        </>
    )
}