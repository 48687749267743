import React from 'react'
import { Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { ReactComponent as Message } from '../../../../assets/images/message.svg'

export const TemplatesSaved = ({ show, handleClose }) => {
    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Stack gap={3} className='align-items-center mb-3'>
                    <Message />
                    <div className='text-center'>
                        <h5 className='text-black fw-medium'>You saved a template</h5>
                        <p className='text-center'>
                            Your job has been posted. You will get a notice when applications come in.
                        </p>
                    </div>
                    <Button
                        type='submit'
                        className='fw-semibold py-14px w-100'
                    >
                        View all templates
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}
