import React from "react";
import "./wideButton.css";

export const WideButton = ({ btnName, className }) => {
  return (
    <>
      <button className={`${className} wideButton`}>{btnName}</button>
    </>
  );
};
