import React, { useState } from "react";
import { Button } from "../../../../../components/common/button/Button";
import { Input } from "../../../../../components/common/input/Input";
import Underline from "../../../../../components/buyer-provider/Underline";
import Avatar from "react-avatar";

export const SuggestionDetails = () => {
  const [addComment, setAddComment] = useState(false);

  return (
    <>
      <div className="mb-3">
        <h6 className="font-14 line-height-160 fw-500">Message</h6>
        <p className="font-14 line-height-160 color-grey-500">
          You can create support tickets where issues can be fixed
        </p>
      </div>
      <div className="mb-5">
        <h6 className="font-14 line-height-160 fw-500">Posted by</h6>
        <p className="font-14 line-height-160 color-grey-500">Gabriel Ariel</p>
      </div>

      <div className="d-flex justify-content-between">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-3 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          onClick={() => setAddComment(false)}
        >
          Downvote
        </Button>
        <Button
          type="submit"
          className="fw-semibold py-3 px-3"
          onClick={() => setAddComment(true)}
        >
          Upvote
        </Button>
      </div>
      {addComment && (
        <div>
          <Underline className="mt-4" />
          <Input
            label="Message"
            type="text"
            placeholder="Comment here"
            name="message"
            // value={formData.message}
            // onChange={handleChange}
            // error={errors.message}
            className="mt-4"
            multiline="h"
            style={{ height: "140px" }}
          />
          <Button
            type="submit"
            className="fw-semibold py-3 px-3 fit-content mt-3"
            onClick={() => setAddComment(true)}
          >
            Submit
          </Button>
        </div>
      )}

      <div className="mt-4">
        <h6 className="font-20 line-height-160 fw-600 latter_spacing-1">
          Comments
        </h6>
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex align-items-center gap-2">
            <Avatar
              round
              size="40"
              name={"A"}
              className="message-sender color-black fw-600"
            />
            <div>
              <p className="font-14 line-height-160 color-grey-500">
                Work on it
              </p>
              <p className="font-10 line-height-160 color-grey-500 font-italic">
                Super Admin
              </p>
            </div>
          </div>
          <p className="font-10 line-height-160 color-grey-500">3 days ago</p>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex align-items-center gap-2">
            <Avatar
              round
              size="40"
              name={"M"}
              className="message-sender color-black fw-600"
            />
            <div>
              <p className="font-14 line-height-160 color-grey-500">Okay</p>
              <p className="font-10 line-height-160 color-grey-500 font-italic">
                You
              </p>
            </div>
          </div>
          <p className="font-10 line-height-160 color-grey-500">3 days ago</p>
        </div>
      </div>
    </>
  );
};
