import React from "react";
import Underline from "../../../../components/buyer-provider/Underline";

export const ProjectDetails = () => {
  return (
    <>
      <div className="mt-2">
        <p className="font-14 line-height-160 fw-600">Project Title</p>
        <Underline />
        <p className="font-14 line-height-160 color-custom-grey-500 pt-3">
          Total Hours
        </p>
      </div>

      <div className="mt-3">
        <p className="font-14 line-height-160 fw-600">Description</p>
        <Underline className="mt-2" />
        <p className="font-14 line-height-160 color-custom-grey-500 pt-2">
          Water purification and drilling of borehole for a 5 bedroom duplex,
          drilling of borehole for a 5 bedroom duplex, drilling of borehole for
          a 5 bedroom duplex
        </p>
      </div>
      <div className="mt-3">
        <p className="font-14 line-height-160 fw-600">Owner</p>
        <Underline className="mt-2" />
        <p className="font-14 line-height-160 color-custom-grey-500 pt-2">
          Adebola Janet
        </p>
      </div>
      <div className="mt-3">
        <p className="font-14 line-height-160 fw-600">Related Work Order</p>
        <Underline className="mt-2" />
        <p className="font-14 line-height-160 color-custom-grey-500 pt-2">
          Plumber #25537779
        </p>
      </div>
      <div className="mt-3">
        <p className="font-14 line-height-160 fw-600">Status</p>
        <Underline className="mt-2" />
        <div
          className="badge-status__active badge-status__active_text py-2 px-3 font-10 fw-bold mt-3"
          style={{ letterSpacing: "0.2px" }}
        >
          Active
        </div>
      </div>
    </>
  );
};
