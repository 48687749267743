import React from "react";
import { MainDefaultSettingSideBar } from "../../../../components/common/Default";
import { HiOutlineDownload } from "react-icons/hi";
import Underline from "../../../../components/buyer-provider/Underline";
import { Button } from "../../../../components/common/button/Button";

export const RecoveryCodes = ({ setIsTwoFA }) => {
  const dataLeft = [32374299, 12345678, 87654321, 98765432, 11223344];
  const dataRight = [98765432, 22334455, 55667788, 77889900, 33445566];
  return (
    <>
      <div className="top-nav py-3 mt-4 mb-4">
        <h4 className="mb-0">Recovery Codes</h4>
      </div>
      <div className="d-flex align-items-center gap-3 mt-5">
        <HiOutlineDownload size={24} color="#920af2" />
        <p className="primary-text-color">Save Recovery Codes</p>
      </div>
      <p className="policy-instruction mt-3">
        Save these one-time use recovery codes to use when your authentication
        app isn’t available. You will not see them <br></br> once you finish
        configuration. *
      </p>

      <div className="d-flex align-items-center mt-3 NumberIndex">
        <ul>
          {dataLeft.map((item, index) => (
            <li className="pt-3" key={`left-${index}`}>
              {item}
            </li>
          ))}
        </ul>
        <ul>
          {dataRight.map((item, index) => (
            <li className="pt-3" key={`right-${index}`}>
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="d-flex gap-4 mt-1 mb-4">
        <p className="primary-text-color">Download Code</p>
        <p className="primary-text-color">Copy to Clipboard</p>
      </div>

      <Underline />

      <Button
        className="py-3 px-4 mt-4"
        onClick={() => setIsTwoFA("configure")}
      >
        Next
      </Button>
    </>
  );
};
