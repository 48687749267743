import React, { useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { AiOutlineSetting } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { Form, Navbar } from "react-bootstrap";
import "./my-work.css";
import { LayoutSwitcher } from "../../../components/dashboard/job-updates/LayoutSwitcher";
import { JobUpdates } from "../../../components/dashboard/job-updates/JobUpdates";
import { MY_WORKS, MY_WORKS_NAVBAR } from "../../../data/constants";

export const MyWork = () => {

  return (
    <MainDefault>
      <div className="ms-3">
        <h4 className="mb-0 fw-bold">My Work Orders </h4>

        <div className="d-flex align-items-center justify-content-between mt-3">
          <Navbar.Brand className="position-relative">
            <IoSearch className="form-control__search-icon color-grey-500" />
            <Form.Control
              type="text"
              placeholder="Search"
              className="form-control__search my-work-search-input"
            />
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <select
              style={{ width: "100px" }}
              className="form-select padding-select-box font-14 me-3"
              name=""
              id=""
            >
              <option value="">Show 10</option>
              <option value="">Show 20</option>
            </select>

            <button
              type="button"
              className="filterButton"
              // onClick={() => setShowFilters(true)}
            >
              <AiOutlineSetting className="me-1" />
              <span className="font-14">Filter</span>
            </button>
          </div>
        </div>

        <JobUpdates navItems={MY_WORKS_NAVBAR} tabPanes={MY_WORKS}>
          <LayoutSwitcher showCalendar />
        </JobUpdates>
      </div>
    </MainDefault>
  );
};
