import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Select } from "../../../common/select/Select";
import { Input } from "../../../common/input/Input";
import { Button } from "../../../common/button/Button";
import { createTicket } from "../../../../store/provider/provider.action";
import { useDispatch } from "react-redux";

export const AddEditTicket = ({ handleClose,mode }) => {
  // const dispatch = useDispatch();
  // const handleClose = () => {
  //   dispatch(createTicket(false));
  // };

  // console.log("type",mode)


  const [formData, setFormData] = useState({
    title: "",
    type: null,
    message: "",
    additionalInput: "",
  });

  const [errors, setErrors] = useState({
    title: "",
    type: "",
    message: "",
    additionalInput: "",
  });

  const typeOptions = [
    { label: "Work Order related", value: "workOrderRelated" },
    { label: "Technical Issues", value: "technicalIssues" },
    { label: "Payment and Billing", value: "paymentAndBilling" },
    { label: "Feedback & Suggestion", value: "feedbackAndSuggestion" },
    { label: "General Inquiry", value: "generalInquiry" },
  ];

  // Map dropdown value to input label
  const inputLabels = {
    workOrderRelated: "Work ID",
    technicalIssues: "Issue Description",
    paymentAndBilling: "Billing Reference",
    feedbackAndSuggestion: "Feedback Topic",
    generalInquiry: "Inquiry Details",
  };

  const validateField = (name, value) => {
    let error = "";

    if (name === "title") {
      if (!value.trim()) {
        error = "Title is required.";
      }
    } else if (name === "type") {
      if (!value) {
        error = "Type selection is required.";
      }
    } else if (name === "message") {
      if (!value.trim()) {
        error = "Message is required.";
      } else if (value.trim().length < 10) {
        error = "Message must be at least 10 characters.";
      }
    } else if (name === "additionalInput") {
      if (!value.trim()) {
        error = `${inputLabels[formData.type?.value]} is required.`;
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption,
      additionalInput: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      additionalInput: "",
    }));
  };

  const isFormValid = () => {
    const requiredFields = ["title", "type", "message", "additionalInput"];
    return requiredFields.every(
      (field) => errors[field] === "" && formData[field]
    );
  };

  const handleCreateTicket = (e) => {
    e.preventDefault();

    const newErrors = {
      title: validateField("title", formData.title),
      type: validateField("type", formData.type?.value),
      message: validateField("message", formData.message),
      additionalInput: validateField(
        "additionalInput",
        formData.additionalInput
      ),
    };

    setErrors(newErrors);

    if (isFormValid()) {
      console.log("Submitted Data:", {
        title: formData.title,
        type: formData.type.value,
        message: formData.message,
        additionalInput: formData.additionalInput,
      });
      handleClose();
    }
  };

  const handleEditTicket = (e) => {
    e.preventDefault();
  };

  return (
    <Form
      className="form-wrapper"
      onSubmit={mode == "create" ? handleCreateTicket : handleEditTicket}
    >
      <Input
        label="Title"
        type="text"
        placeholder="Enter title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        error={errors.title}
        className="mb-3"
        defaultValue="Hello"
      />

      <Select
        label="Type"
        name="type"
        inputId="type"
        htmlFor="type"
        placeholder="Select one"
        options={typeOptions}
        value={formData.type}
        error={errors.type}
        onChange={(selectedOption) =>
          handleSelectChange(selectedOption, "type")
        }
        required
        className="mb-3"
      />

      {formData.type?.value && (
        <Input
          label={inputLabels[formData.type.value]}
          type="text"
          placeholder={`Enter ${inputLabels[formData.type.value]}`}
          name="additionalInput"
          value={formData.additionalInput}
          onChange={handleChange}
          error={errors.additionalInput}
          className="mb-3"
        />
      )}

      <Input
        label="Message"
        type="text"
        placeholder="Enter message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        error={errors.message}
        className="mb-3"
        multiline="h"
        style={{ height: "140px" }}
      />

      <div className="button-bottom-side d-flex">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-3 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          // onClick={
          //   mode !== "Edit Ticket" ? handleClose :  handleCloseEdit
          // }
          onClick={
            handleClose
          }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="fw-semibold py-3 px-3"
          disabled={!isFormValid()}
        >
          {mode === "create" ? "Create Ticket" : "Save Changes"}
        </Button>
      </div>
    </Form>
  );
};
