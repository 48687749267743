import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./input.css";
import { useState } from "react";

export const Input = ({ 
    star,
    htmlFor, 
    label, 
    type, 
    className, 
    inputClasses, 
    error, 
    infoText,
    multiline, 
    ...rest 
}) => {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    return (
        <div className={className}>
            {label && <div> <label className='form-label line-height-160' htmlFor={htmlFor || ''}>{label}</label>
               {star &&  <span className="star ps-1">*</span>}
            </div>

            }
            {multiline ? (
                <textarea className={`form-input line-height-160 ${error && 'form-error'}`} style={{ resize: 'none' }} {...rest} />
            ) : (
                <>
                    {type === 'password' ? (
                        <div className='position-relative'>
                            <input className={`form-input line-height-160 ${error && 'form-error'}`} type={isPasswordVisible ? 'text' : 'password'} {...rest} />
                            <span className='password-toggle-icon color-secondary' onClick={() => setIsPasswordVisible(prev => !prev)}>
                                {isPasswordVisible ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
                            </span>
                        </div>
                    ) : (
                        <input className={`form-input ${inputClasses} ${error && 'form-error'}`} type={type} {...rest} />
                    )}
                </>
            )}
            {error ? (
                <span className='error-text line-height-160 mt-2'>{error}</span>
            ) : (
                infoText && <span className='info-text line-height-160 mt-2'>{infoText}</span>
            )}
        </div>
    )
}
