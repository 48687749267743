import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Select } from '../../common/select/Select'
import { Input } from '../../common/input/Input'
import { Button } from '../../common/button/Button'
import DatePicker from 'react-datepicker'
import { CustomDateInput } from '../../buyer-provider/CustomDateInput'
import { ImageUploader } from '../ImageUploader'

export const AddInsurance = ({ show, handleClose }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            className='timesheet-offcanvas'
            placement='end'
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='fw-bold'>Add Insurance</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='d-flex flex-column'>
                <Form onSubmit={handleSubmit} className='d-flex flex-column flex-grow-1'>
                    <Select
                        label='Insurance Type'
                        name='industry'
                        inputId='industry'
                        htmlFor='industry'
                        placeholder='Select one'
                        className='mb-2'
                        required
                    // options={industryOptions}
                    // value={formData?.industry}
                    // error={errors?.industry}
                    // onChange={(industry) => handleSelectChange(industry, 'industry')}
                    />
                    <Select
                        label='Provider'
                        name='provider'
                        inputId='provider'
                        htmlFor='provider'
                        placeholder='Select one'
                        className='mb-2'
                        required
                    // options={industryOptions}
                    // value={formData?.industry}
                    // error={errors?.industry}
                    // onChange={(industry) => handleSelectChange(industry, 'industry')}
                    />
                    <Input
                        label='Policy Number'
                        placeholder='Policy Number'
                        htmlFor='policyNumber'
                        id='policyNumber'
                        name='policyNumber'
                        type='number'
                        // value={formData?.address}
                        // error={errors?.address}
                        // onChange={handleChange}
                        className='mb-2'
                    />
                    <Input
                        label='Coverage Amount'
                        placeholder='Coverage Amount'
                        htmlFor='coverageAmount'
                        id='coverageAmount'
                        name='coverageAmount'
                        type='number'
                        // value={formData?.address}
                        // error={errors?.address}
                        // onChange={handleChange}
                        className='mb-2'
                    />
                    <DatePicker
                        // selected={formData?.startDate}
                        // onChange={(date) => handleDateChange(date, 'startDate')}
                        dateFormat='dd/MM/yy'
                        placeholderText='DD/MM/YY'
                        customInput={<CustomDateInput label='Issue Date' name='issueDate' className='mb-2' />}
                    />
                    <DatePicker
                        // selected={formData?.endDate}
                        // onChange={(date) => handleDateChange(date, 'endDate')}
                        dateFormat='dd/MM/yy'
                        placeholderText='DD/MM/YY'
                        customInput={<CustomDateInput label='Expiry Date' name='expiryDate' className='mb-2' />}
                    />
                    <ImageUploader name='insurance' className='mb-3' />
                    <Stack direction='horizontal' className='justify-content-between mt-auto'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='fw-semibold p-3'
                        // disabled={!isFormValid()}
                        >
                            Add Insurance
                        </Button>
                    </Stack>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
