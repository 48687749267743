import React, { useMemo, useState } from "react";
import { PaymentCommonHeader } from "../payment-common-header/PaymentCommonHeader";
import { Table } from "../../../../components/common/table/Table";
import { USER } from "../../../../assets/images.assets";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { Offcanvas } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { WorkOrderDetails } from "./WorkOrderDetails";

export const ApprovalPending = () => {
  const [workOrderDetails, setWorkOrderDetails] = useState(false);

  const Columns = useMemo(
    () => [
      {
        accessorKey: "woID",
        header: () => "WO ID",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "woTitle",
        header: () => "WO Title",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "initiationDate",
        header: () => "Initiation Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "completionDate",
        header: () => "Completion Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "location",
        header: () => "Location",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "provider",
        header: () => "Provider",
        cell: (info) => (
          <div className="d-flex align-items-center gap-2">
            <img src={USER} height={24} width={24} />
            <span className="font-12 line-height-160 color-grayscale-900 fw-600">
              {info.renderValue()}
            </span>
          </div>
        ),
      },
      {
        accessorKey: "amount",
        header: () => "Amount",
        cell: (info) => (
          <span
            className="font-12 line-height-160 color-grayscale-900 cursor-pointer"
            onClick={() => setWorkOrderDetails(true)}
          >
            {info.renderValue()}
          </span>
        ),
      },
    ],
    []
  );

  const Rows = [
    {
      woID: "#38834394",
      woTitle: "Plumber",
      initiationDate: "03/24/2024",
      completionDate: "03/24/2024",
      location: "Brooklyn, London",
      provider: "Johnson Nnamani",
      amount: "$3,000.00",
    },
  ];
  return (
    <>
      <PaymentCommonHeader />

      <div className="mt-3">
        <Table columns={Columns} data={Rows} />
      </div>

      <RightOffcanvas show={workOrderDetails} onHide={setWorkOrderDetails}>
        <div className="p-3 d-flex align-items-center justify-content-between">
          <h5 className="text-black fw-semibold font-20">Work Order Details</h5>
          <RxCross1
            size={20}
            onClick={() => setWorkOrderDetails(false)}
            className="cursor-pointer"
          />
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <WorkOrderDetails setWorkOrderDetails={setWorkOrderDetails} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
