import React, { useState } from "react";
import { MainDefaultSettingSideBar } from "../../../../components/common/Default";
import { RecoveryCodes } from "./RecoveryCodes";
import { Configure } from "./Configure";
import { TwoFAEnabled } from "./TwoFAEnabled";
import { Link } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";
import { GoToSettings } from "./GoToSettings";
import { Stepper } from "../../../../components/common/Stepper";
import { Col, Row } from "react-bootstrap";

export const TwoFA = ({ setIsTwoFAOpen }) => {
  const [isTwoFA, setIsTwoFA] = useState("recoveryCodes");

  const steps = [
    { label: "Recovery Codes", value: "recoveryCodes" },
    { label: "Configure", value: "configure" },
    { label: "Two-Factor Enabled", value: "twoFAEnabled" },
  ];

  // const currentStepIndex = steps.findIndex((step) => step.value === isTwoFA);

  // console.log("currentStepIndex", currentStepIndex);

  return (
    <>
      <div
        className="px-2 py-1 mb-3 w-fit"
        style={{ display: isTwoFA == "goToSettings" ? "none" : "block" }}
      >
        <Link onClick={() => setIsTwoFAOpen(false)}>
          <HiArrowLongLeft
            size={28}
            color="black"
            style={{ marginBottom: 2 }}
          />
        </Link>
        <span className="fw-medium ms-2">Back</span>
      </div>

      <div style={{ display: isTwoFA == "goToSettings" ? "none" : "block" }} className="mt-4">
        <Row className="justify-content-center profile-wrapper mb-5">
          <Col xs={11} sm={8} md={2}>
            <Stepper steps={steps.map((s) => s.value)} currentStep={isTwoFA} />
          </Col>
        </Row>
      </div>
      <div style={{ display: isTwoFA == "recoveryCodes" ? "block" : "none" }}>
        <RecoveryCodes setIsTwoFA={setIsTwoFA} />
      </div>
      <div style={{ display: isTwoFA == "configure" ? "block" : "none" }}>
        <Configure setIsTwoFA={setIsTwoFA} />
      </div>
      <div style={{ display: isTwoFA == "twoFAEnabled" ? "block" : "none" }}>
        <TwoFAEnabled setIsTwoFA={setIsTwoFA} />
      </div>
      <div style={{ display: isTwoFA == "goToSettings" ? "block" : "none" }}>
        <GoToSettings setIsTwoFA={setIsTwoFA} />
      </div>
    </>
  );
};
