import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { Input } from "../../../../components/common/input/Input";
import { Button } from "../../../../components/common/button/Button";
import DatePicker from "react-datepicker";
import { Select } from "../../../../components/common/select/Select";
import { BackGroundPayment } from "./BackGroundPayment";
import { HiArrowLongLeft } from "react-icons/hi2";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import {
  dateOptions,
  monthOptions,
  yearOptions,
} from "../../../../data/custom-options";

export const BackGroundOrder = ({ setBackGroundOrder }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    ssn: "",
    emailAddress: "",
    month: "",
    day: "",
    year: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  const [errors, setErrors] = useState({});
  const [backGroundOrderPayment, setBackGroundOrderPayment] = useState(false);

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value)
          error = `${
            name === "firstName" ? "First" : "Last"
          } name is required.`;
        break;
      case "ssn":
        if (!value) error = "SSN is required.";
        else if (!/^\d{3}-\d{2}-\d{4}$/.test(value))
          error = "SSN must be in the format ***-**-****.";
        break;
      case "emailAddress":
        if (!value) error = "Email address is required.";
        else if (!/^\S+@\S+\.\S+$/.test(value))
          error = "Enter a valid email address.";
        break;
      case "month":
      case "day":
      case "year":
        if (!value) error = "Date of birth is incomplete.";
        break;
      case "address":
        if (!value) error = "Address is required.";
        break;
      case "city":
        if (!value) error = "City is required.";
        break;
      case "state":
        if (!value) error = "State is required.";
        break;
      case "country":
        if (!value) error = "Country is required.";
        break;
      case "postalCode":
        if (!value) error = "Postal code is required.";
        else if (!/^\d+$/.test(value)) error = "Postal code must be numeric.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // const newErrors = Object.keys(formData).reduce((acc, key) => {
    //   acc[key] = validateField(key, formData[key]);
    //   return acc;
    // }, {});
    // setErrors(newErrors);

    // if (Object.values(newErrors).every((error) => error === "")) {
    //   console.log("Form Data Submitted:", formData);
    //   setBackGroundOrderPayment(true);
    // }
  };

  return (
    <>
      <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
        Order for Background Check
      </h6>

      <Form className="mt-3 form-wrapper" onSubmit={handleSubmit}>
        <Input
          label="First Name"
          type="text"
          placeholder="Enter first name"
          className="mb-3"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          error={errors.firstName}
        />
        <Input
          label="Middle Name"
          type="text"
          placeholder="Enter middle name"
          className="mb-3"
          name="middleName"
          value={formData.middleName}
          onChange={handleChange}
        />
        <Input
          label="Last Name"
          type="text"
          placeholder="Enter last name"
          className="mb-3"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          error={errors.lastName}
        />
        <Input
          label="SSN"
          type="text"
          placeholder="***-**-****"
          className="mb-3"
          name="ssn"
          value={formData.ssn}
          onChange={handleChange}
          error={errors.ssn}
        />
        <Input
          label="Email Address"
          type="text"
          placeholder="Enter email address"
          className="mb-3"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleChange}
          error={errors.emailAddress}
        />
        <div>
          <label className="form-label line-height-160">Date of Birth</label>
          <span className="star ps-1">*</span>
        </div>
        <div className="d-flex gap-1 align-items-center mb-3 flex">
          <Select
            name="month"
            placeholder="Month"
            options={monthOptions}
            value={formData.month}
            onChange={(e) =>
              handleChange({ target: { name: "month", value: e.target.value } })
            }
            className="w-100"
          />
          <Select
            name="day"
            placeholder="Day"
            options={dateOptions}
            value={formData.day}
            onChange={(e) =>
              handleChange({ target: { name: "day", value: e.target.value } })
            }
            className="w-100"
          />
          <Select
            name="year"
            placeholder="Year"
            options={yearOptions}
            value={formData.year}
            onChange={(e) =>
              handleChange({ target: { name: "year", value: e.target.value } })
            }
            className="w-100"
          />
        </div>
        <Input
          label="Address"
          type="text"
          placeholder="Address"
          className="mb-3"
          name="address"
          value={formData.address}
          onChange={handleChange}
          error={errors.address}
        />
        <div className="d-flex justify-content-between mb-3 gap-1">
          <Select
            label="City"
            name="city"
            placeholder="Select City"
            className="mb-2 w-100"
            value={formData.city}
            onChange={(e) =>
              handleChange({ target: { name: "city", value: e.target.value } })
            }
            error={errors.city}
            star
          />
          <Select
            label="State"
            name="state"
            placeholder="Select State"
            className="mb-2 w-100"
            value={formData.state}
            onChange={(e) =>
              handleChange({ target: { name: "state", value: e.target.value } })
            }
            error={errors.state}
            star
          />
        </div>
        <div className="d-flex justify-content-between mb-3 gap-1">
          <Select
            label="Country"
            name="country"
            placeholder="Select Country"
            className="mb-2 w-100"
            value={formData.country}
            onChange={(e) =>
              handleChange({
                target: { name: "country", value: e.target.value },
              })
            }
            error={errors.country}
            star
          />
          <Input
            label="Postal Code"
            name="postalCode"
            placeholder="Postal Code"
            className="mb-2 w-100"
            value={formData.postalCode}
            onChange={handleChange}
            error={errors.postalCode}
          />
        </div>
        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setBackGroundOrder(false)}
          >
            Cancel
          </Button>
          <Button
            // disabled={!isFormValid()}
            onClick={() => setBackGroundOrderPayment(true)}
            type="submit"
            className="fw-semibold py-3 px-3"
          >
            Pay for Background Check
          </Button>
        </div>
      </Form>

      <RightOffcanvas
        show={backGroundOrderPayment}
        onHide={setBackGroundOrderPayment}
        className="w-25"
      >
        <div className="p-3 d-flex align-items-center justify-content-between">
          <div className="w-fit">
            <Link onClick={() => setBackGroundOrderPayment(false)}>
              <HiArrowLongLeft
                size={28}
                color="black"
                style={{ marginBottom: 2 }}
              />
            </Link>
            <span className="fw-medium ms-2">Back</span>
          </div>
          <RxCross1
            size={20}
            onClick={() => setBackGroundOrderPayment(false)}
            className="cursor-pointer"
          />
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <BackGroundPayment
            setBackGroundOrderPayment={setBackGroundOrderPayment}
          />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
