import { Form } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'
import { AuthWrapper } from '../../../../components/common/Default'
import { useLocation } from 'react-router-dom'
import { showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'
import { MyOTPInput } from '../../../../components/common/my-otp-input/MyOTPInput'
import { useOTPValidation } from '../../../../hooks/useOTPValidation'

export const ProviderLoginEmailOTPVerification = () => {

  const location = useLocation()

  const { otp, setOtp, isOTPValid, error, handleSubmit } = useOTPValidation()


  const handleFormSubmit = async (e) => {
    const formResult = handleSubmit(e)
    if (!formResult) return

    try {
      const response = await PostRequest('/provider/login/verify-otp/email', { otp: otp, ...location?.state })
      if (response) {
        window.localStorage.setItem('TOKEN', response?.token);
        window.localStorage.setItem('AUTH_PROVIDER', JSON.stringify(response));
      }
      if (response?.isProfileCompleted) {
        window.location.href = '/dashboard'
      } else {
        window.location.href = '/get-started'
      }
    } catch (error) {
      console.log('error', error)
      showToaster(error.message, 'error')
    }
  }

  return (
    <AuthWrapper>
      <h5 className='welcome-heading'>Confirm Identity</h5>
      <p className='text-black mb-4'>Enter the code sent to your email to confirm your identity</p>
      <Form onSubmit={handleFormSubmit}>
        <MyOTPInput
          value={otp}
          onChange={setOtp}
          inputType='number'
          label='Enter OTP'
          error={error}
          wrapperClasses='hide__arrows'
        />
        <Button type='submit' disabled={!isOTPValid} className='w-100 mb-3'>Verify</Button>
      </Form>
      <Form.Check
        type='checkbox'
        label='Always remember this device'
        className='provider-checkbox'
        name='remember'
        id='remember-device'
      />
    </AuthWrapper>
  )
}
