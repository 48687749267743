import { Form, Modal } from 'react-bootstrap'
import { Button } from '../../common/button/Button'
import { Input } from '../../common/input/Input'
import { useState } from 'react'

export const SetLimit = ({ show, handleClose }) => {
    
    const [limit, setLimit] = useState('')
    const [error, setError] = useState('')
    
    const handleChange = (e) => {
        const value = e.target.value
        
        const cleanedValue = value.replace(/[^0-9]/g, '');
        setLimit(cleanedValue)

        if (!cleanedValue) {
            setError('Limit amount is required')
            return
        }

        setError('')
    }

    const handleLimitClose = () => {
        setLimit('')
        handleClose()
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        
        if(limit === '' || !limit) {
            setError('Limit amount is required')
            return
        }
        
        handleLimitClose()
    }
    
    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleLimitClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Set Limit</h5>
                    <p>You can set limit for the user</p>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Input
                        label='Limit Amount'
                        name='limit'
                        value={limit}
                        onChange={handleChange}
                        placeholder='Enter amount'
                        className='mb-3'
                        error={error}
                    />
                    <Button disabled={!limit || limit === ''} className='py-3 w-100'>
                        Limit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
