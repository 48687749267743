import { Col, Row } from 'react-bootstrap'
import { UserInformationCard } from '../../../../components/buyer-provider/user-information-card/UserInformationCard'

export const Blocked = () => {

  const dropdownItems = [
    { name: "Unblock" },
  ]

  return (
    <>
      <Row className='row-gap-4'>
        {Array.from({ length: 8 }).map((_, i) => {
          return (
            <Col md={3} key={i}>
              <UserInformationCard
                userName="Ademola Adetunji"
                address="Amsterdam, UK Profeesional"
                dropdownItems={dropdownItems}
                isFormCheck={false}
                isWideButton={false}
              />
            </Col>
          )
        })}
      </Row>
    </>
  )
}
