import React from "react";
import { Form } from "react-bootstrap";
import { BiSolidFilePdf } from "react-icons/bi";
import { Button } from "../../../../components/common/button/Button";
import { RxCross2 } from "react-icons/rx";

export const UploadOrderBackGround = ({
  acceptedFiles,
  setAcceptedFiles,
  setUploadBackground,
}) => {
  const removeOrderBackground = (fileId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const updatedFiles = acceptedFiles.filter((file) => file.id !== fileId);
      setAcceptedFiles(updatedFiles);
    }
  };

  return (
    <Form className="form-wrapper-upload-order">
      {acceptedFiles.map((file, index) => (
        <div key={index}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <BiSolidFilePdf className="documentation-icon me-2" size={20} />
              <span className="me-1">{file.name}</span>
              <small className="text-muted">
                ({(file.size / 1024).toFixed(2)} KB)
              </small>
            </div>

            <div className="remove-selected-order cursor-pointer">
              <RxCross2
                size={15}
                onClick={() => removeOrderBackground(file.id)}
              />
            </div>
          </div>

          <div className="button-submit-upload-order d-flex">
            <Button
              type="button"
              variant="outline"
              className="fw-semibold px-3 color-ebony"
              style={{
                paddingBlock: 14,
                border: "1px solid #CCCCF5",
              }}
              onClick={() => setUploadBackground(false)}
            >
              Cancel
            </Button>

            <Button
              // disabled={!isFormValid()}
              type="submit"
              className="fw-semibold py-3 px-3"
              //   onClick={() => setPaymentSuccessful(true)}
            >
              Submit
            </Button>
          </div>
        </div>
      ))}
    </Form>
  );
};
