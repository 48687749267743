import React from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Input } from '../../../common/input/Input'

export const Message = ({ show, handleClose }) => {
    
    const handleSubmit = (e) => {
        e.preventDefault()
    }
    
    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Message Adebola</h5>
                    <p>You are about to send a message</p>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Input
                        label='Message'
                        placeholder='Enter your message'
                        htmlFor='message'
                        id='message'
                        name='message'
                        multiline
                        rows={5}
                        // value={formData?.address}
                        // error={errors.address}
                        // onChange={handleChange}
                        className='mb-5'
                    />
                    <Stack direction='horizontal' className='justify-content-between'>
                        <Button
                            type='button'
                            variant='outline'
                            className='color-ebony padded-border-button py-14px'
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='py-14px'
                        >
                            Send message
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
