import React from 'react'
import { Modal } from 'react-bootstrap'
import { PhotoAndLocationForm } from '../PhotoAndLocationForm'

export const PhotoAndLocation = ({ show, handleClose }) => {
    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose}>
            <Modal.Body>
                {/* <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Your photo and details</h5>
                    <p>Getting your account more personalized</p>
                </div> */}
                <PhotoAndLocationForm
                    fromModal
                    handleClose={handleClose}
                />
            </Modal.Body>
        </Modal>
    )
}
