
export const Loader = ({ className }) => {
    return (
        <div className={className} style={styles.loaderContainer}>
            <div style={styles.loader}></div>
        </div>
    )
}

const styles = {
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 100px)'
    },
    loader: {
        border: '8px solid #F3F3FE',
        borderTop: '8px solid #920AF2',
        borderRadius: '50%',
        width: '80px',
        height: '80px',
        animation: 'spin 2s linear infinite'
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    }
}

