import React from "react";
import { FUND_ADDED } from "../../../../assets/images.assets";
import { Button } from "../../../../components/common/button/Button";
import { Form } from "react-bootstrap";

export const FundsAdded = ({ fundingMethod = 'eCheck', handleClose }) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <img src={FUND_ADDED} height={70} width={85} />

          <div className="mt-4">
            <h6 className="font-20 latter_spacing-1 fw-600">Funds Added</h6>

            <p className="font-14 color-gray-700">
              You have successfully added {' '}
              <span className="color-black fw-bold">$1000</span> funds into your
              account
            </p>
            <Button
              type="submit"
              className="fw-semibold py-3 px-3 mt-4"
              onClick={handleClose}
            >
              Return
            </Button>

            {fundingMethod === 'eCheck' && (
              <div className="mt-3 d-flex  justify-content-center gap-4">
                <p className="font-16 color-dark-gray">Save for future use</p>
                <Form.Check type="switch" id="custom-switch" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
