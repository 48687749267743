import React, { useMemo } from "react";
import { PaymentCommonHeader } from "../payment-common-header/PaymentCommonHeader";
import { Table } from "../../../../components/common/table/Table";

export const LedgerTransactions = () => {
  const Columns = useMemo(
    () => [
      {
        accessorKey: "initiationDate",
        header: () => "Initiation Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "type",
        header: () => "Type",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "description",
        header: () => "Description",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "debit",
        header: () => "Debit",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },

      {
        accessorKey: "credit",
        header: () => "Credit",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },

      {
        accessorKey: "balance",
        header: () => "Balance",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900 ">
            {info.renderValue()}
          </span>
        ),
      },
    ],
    []
  );

  const Rows = [
    {
      initiationDate: "03/24/2024",
      type: "Sales Tax",
      description: "Brooklyn, London",
      debit: "$3,000.00",
      credit: "$3,000.00",
      balance: "$3,000.00",
    },
  ];

  return (
    <>
      <PaymentCommonHeader />

      <div className="mt-3">
        <Table columns={Columns} data={Rows} />
      </div>
    </>
  );
};
