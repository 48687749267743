import React from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const MyPhoneInput = ({ htmlFor, label, className, error, country, name, inputProps, ...rest }) => {
    return (
        <div className={className}>
            {label && <label className='form-label line-height-160' htmlFor={htmlFor || 'phoneNumber'}>{label}</label>}
            <PhoneInput
                country={country || 'us'}
                inputProps={{
                    name: name || 'phoneNumber',
                    id: htmlFor || 'phoneNumber',
                    required: true,
                    className: 'form-input',
                    style: { paddingLeft: 56 },
                    ...inputProps
                }}
                enableSearch={true}
                buttonClass='my-2 ms-2'
                {...rest}
            />
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    );
};

export default MyPhoneInput;