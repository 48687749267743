import React from "react";
import { Form } from "react-bootstrap";
import { GoPaperclip } from "react-icons/go";
import "./checkBoxWithIcon.css";

export const CheckBoxWithIcon = ({ labelName, uniqueId }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <Form.Check
            type="checkbox"
            className="buyer-checkbox"
            id={`checkbox-${uniqueId}-${labelName.replace(/\s+/g, '-').toLowerCase()}`}
          />

          <label
            className="agreement"
            htmlFor={`checkbox-${uniqueId}-${labelName.replace(/\s+/g, '-').toLowerCase()}`}
          >
            {labelName}
          </label>
        </div>
        <GoPaperclip size={24} />
      </div>
    </>
  );
};
