import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Input } from '../../../common/input/Input'
import { Button } from '../../../common/button/Button'
import { TemplatesSaved } from './TemplatesSaved'

export const SaveTemplate = ({ show, handleClose }) => {

    const [templateTitle, setTemplateTitle] = useState('')
    const [error, setError] = useState('')

    const [showTemplatesSaved, setShowTemplatesSaved] = useState(false)

    const handleTemplateTitleChange = (e) => {
        const value = e.target.value;
        setTemplateTitle(value);

        if (!value) {
            setError('Template Title is required');
            return;
        }
        setError('');
    }

    const isTemplateTitle = templateTitle && templateTitle.trim() !== ''

    const handleSaveAsTemplate = (e) => {
        e.preventDefault()
        
        if(isTemplateTitle) {
            setShowTemplatesSaved(true)
        }
    }

    return (
        <>
            <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='mb-3'>
                        <h5 className='text-black fw-semibold'>Save as a template</h5>
                        <p>Add a title name to this template</p>
                    </div>
                    <Form onSubmit={handleSaveAsTemplate}>
                        <Input
                            label='Template Title'
                            placeholder='Enter title'
                            name='templateTitle'
                            value={templateTitle}
                            error={error}
                            onChange={handleTemplateTitleChange}
                            className='mb-3'
                        />
                        <Button disabled={!isTemplateTitle} className='py-3 w-100'>
                            Save as a template
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <TemplatesSaved show={showTemplatesSaved} handleClose={() => setShowTemplatesSaved(false)} />
        </>
    )
}
