import { ProgressBar, Stack } from 'react-bootstrap'
import { MainDefault } from '../../../components/common/Default'
import { Button } from '../../../components/common/button/Button'
import ReactSelect from 'react-select'
import './dashboard.css'
import { dashboardFilterDropdownStyles, PROVIDER_navItems, PROVIDER_tabPanes } from '../../../data/constants'
import { AnalyticsSummary } from '../../../components/dashboard/analytics-summary/AnalyticsSummary'
import { JobUpdates } from '../../../components/dashboard/job-updates/JobUpdates'
// import { useSelector } from 'react-redux'
// import { useEffect } from 'react'
import { getAuthProvider } from '../../../utils/utils'
import { WorkOrdersHeader } from '../../../components/dashboard/job-updates/WorkOrdersHeader'
import { LayoutSwitcher } from '../../../components/dashboard/job-updates/LayoutSwitcher'


export const Dashboard = () => {


  // const user = getLoginUser()
  const { parsedAuthData: parsedAuthProvider } = getAuthProvider()
  // const providerDetails = useSelector(state => state.providerReducer.providerDetails)

  // useEffect(() => {
  //   if(providerDetails) {
  //     window.localStorage.setItem('AUTH_PROVIDER', JSON.stringify(providerDetails))
  //   }
  // }, [providerDetails])

  return (
    <MainDefault>
      <Stack direction='horizontal' className='badge__group'>
        <div className='font-14 fw-medium me-2'>Your profile is yet to be updated.</div>
        <span className='font-14 fw-medium'>0%</span>
        <ProgressBar className='ms-2' now={5} />
        <Button type='button' className='py-2 px-3 fw-medium font-14 ms-auto'>Update Profile</Button>
      </Stack>
      <div className='top-nav py-3'>
        <h4 className='mb-0'>Good morning, {parsedAuthProvider?.firstName}</h4>
      </div>
      <div className='greeting__filter my-4'>
        <p>Here is your job listings statistic report from July 19 - July 25.</p>
        <ReactSelect
          menuPlacement='auto'
          isSearchable={false}
          styles={dashboardFilterDropdownStyles}
        />
      </div>
      <AnalyticsSummary />
      <WorkOrdersHeader />
      <JobUpdates
        navItems={PROVIDER_navItems}
        tabPanes={PROVIDER_tabPanes}
      >
        <LayoutSwitcher />
      </JobUpdates>
    </MainDefault>
  )
}
