import React, { Fragment, useState } from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { Select } from '../../../common/select/Select'
import { Button } from '../../../common/button/Button'
import { FiPlus } from 'react-icons/fi'
import { CreatePool } from './CreatePool'

export const AddToPool = ({
    show,
    handleClose,
    fromProviders = false,
    isOutlineStyle = false,
}) => {

    const [showCreatePool, setShowCreatePool] = useState(false)

    const handleAddToPool = (e) => {
        e.preventDefault()
    }

    return (
        <Fragment>
            <Modal size={fromProviders ? 'md' :'sm'} dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='mb-3'>
                        <h5 className='text-black fw-semibold'>Add to a Pool</h5>
                        <p>You can add provider to {fromProviders ? 'a' : 'created'} pool</p>
                    </div>
                    <Form onSubmit={handleAddToPool}>
                        <Select
                            label='Provider’s Pool'
                            name='provider-pool'
                            inputId='provider-pool'
                            htmlFor='provider-pool'
                            placeholder='Select one'
                            className='mb-2'
                        />
                        {fromProviders ? (
                            <Stack direction='horizontal' className='justify-content-between mt-5'>
                                <Button
                                    type='button'
                                    variant={!isOutlineStyle ? 'outline' : 'primary'}
                                    className={`py-14px ${!isOutlineStyle ? 'px-3 color-ebony padded-border-button' : ''}`}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    variant={isOutlineStyle ? 'outline' : 'primary'}
                                    className={`py-14px ${isOutlineStyle ? 'px-3 color-ebony padded-border-button' : ''}`}
                                >
                                    Create Pool
                                </Button>
                            </Stack>
                        ) : (
                            <>
                                <div className='text-center'>
                                    <Button
                                        type='button'
                                        onClick={() => setShowCreatePool(true)}
                                        className='p-2 mb-3 bg-transparent color-primary'
                                    >
                                        <FiPlus size={20} className='me-2' />
                                        <span className='lh-1'>Create Pool</span>
                                    </Button>
                                </div>
                                <Button className='py-3 w-100'>
                                    Add to Pool
                                </Button>
                            </>
                        )}
                    </Form>
                </Modal.Body>
            </Modal>
            <CreatePool show={showCreatePool} handleClose={() => setShowCreatePool(false)} />
        </Fragment>
    )
}
