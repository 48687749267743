import { Badge, Nav, Tab } from "react-bootstrap";
import "./job-updates.css";

export const JobUpdates = ({ defaultActiveKey, navItems, tabPanes, handleSelect, children }) => {
  return (
    <Tab.Container defaultActiveKey={defaultActiveKey || navItems[0]?.eventKey || ""}>
      <Nav variant="tabs" className="my-3 work-order__tabs fw-bold" onSelect={handleSelect}>
        {navItems?.map(({ eventKey, label, badge }) => (
          <Nav.Item key={eventKey}>
            <Nav.Link eventKey={eventKey} as="button">
              <span>{label}</span>
              {badge && (
                <Badge className="ms-2 rounded-circle py-1">{badge}</Badge>
              )}
            </Nav.Link>
          </Nav.Item>
        ))}
        <div className="ms-auto d-flex gap-3 pb-1">{children}</div>
      </Nav>
      <Tab.Content>
        {tabPanes?.map(({ eventKey, Content }) => (
          <Tab.Pane eventKey={eventKey} key={eventKey}>
            <Content />
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};
