import React, { useEffect, useState } from 'react'
import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Input } from '../../../common/input/Input'
import { Button } from '../../../common/button/Button'
import MyPhoneInput from '../../../common/my-phone-input/MyPhoneInput'
import { Select } from '../../../common/select/Select'
import { workOrderOptions } from '../../../../data/custom-options'
import { handleChange, handleSelectChange, isFormValid } from '../../../../utils/utils'
import { emailPattern } from '../../../../data/constants'

export const AddEditPOC = ({ show, handleClose, mode, pocData }) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        client: null,
        workOrder: null
    })
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        client: '',
        workOrder: ''
    })

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName') {
            if (!value.trim()) {
                error = 'First name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'First name cannot contains special characters';
            }
        }
        if (name === 'lastName') {
            if (!value.trim()) {
                error = 'Last name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Last name cannot contains special characters';
            }
        }
        if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        }
        if (name === 'email') {
            if (!value) {
                error = 'Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
        }
        if (name === 'client' && !value) error = 'Client is required'
        if (name === 'workOrder' && !value) error = 'Work Order is required'
        return error
    }

    const handleInputChange = (e) => {
        handleChange(e, setFormData, setErrors, validateField)
    }

    const handleSelectInputChange = (selectedOption, name) => {
        handleSelectChange(selectedOption, name, setFormData, setErrors, validateField)
    }

    const isPOCFormValid = isFormValid(errors, formData)

    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            client: '',
            workOrder: ''
        }

        Object.entries(formData).forEach(([key, value]) => {
            const error = validateField(key, value);
            if (error) newErrors[key] = error;
        })

        setErrors(newErrors)

        if (isPOCFormValid) {
            console.log('formData', formData)
        }
    }

    const handleClosePOC = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            client: null,
            workOrder: null
        })
        setErrors({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            client: '',
            workOrder: ''
        })
        handleClose()
    }

    // If in "Edit" mode, populate the form fields with the pocData
    useEffect(() => {
        if (mode === 'edit' && pocData) {
            setFormData({
                firstName: pocData?.firstName,
                lastName: pocData?.lastName,
                email: pocData?.email,
                phoneNumber: pocData?.phoneNumber,
                client: pocData?.client ? { label: pocData?.client, value: pocData?.client } : null,
                workOrder: pocData?.workOrder ? { label: pocData?.workOrder, value: pocData?.workOrder } : null
            })
        }
    }, [mode, pocData]);

    return (
        <Offcanvas
            show={show}
            onHide={handleClosePOC}
            placement='end'
        >
            <Offcanvas.Body className='d-flex flex-column'>
                <div className='mb-3'>
                    <Offcanvas.Title className='fw-semibold text-black'>
                        {mode === 'edit' ? 'Edit' : 'Add'}{' '}Person of Contact
                    </Offcanvas.Title>
                    <p>You can create person of contact</p>
                </div>
                <Form onSubmit={handleSubmit} className='d-flex flex-column flex-grow-1'>
                    <Input
                        label='First Name'
                        name='firstName'
                        id='firstName'
                        htmlFor='firstName'
                        value={formData?.firstName}
                        onChange={handleInputChange}
                        error={errors?.firstName}
                        placeholder='Enter first name'
                        className='mb-2'
                    />
                    <Input
                        label='Last Name'
                        name='lastName'
                        id='lastName'
                        htmlFor='lastName'
                        value={formData?.lastName}
                        onChange={handleInputChange}
                        error={errors?.lastName}
                        placeholder='Enter last name'
                        className='mb-2'
                    />
                    <Input
                        label='Email Address'
                        name='email'
                        id='email'
                        htmlFor='email'
                        type='email'
                        value={formData?.email}
                        onChange={handleInputChange}
                        error={errors?.email}
                        placeholder='Enter email address'
                        className='mb-2'
                    />
                    <MyPhoneInput
                        label='Phone Number'
                        value={formData?.phoneNumber}
                        onChange={(phone, country) => {
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                phoneNumber: phone
                            }))
                            const error = validateField('phoneNumber', phone)
                            setErrors(prevErr => ({
                                ...prevErr,
                                phoneNumber: error
                            }))
                        }}
                        error={errors?.phoneNumber}
                        className='mb-2'
                    />
                    <Select
                        label='Client'
                        name='client'
                        inputId='client-select'
                        htmlFor='client-select'
                        placeholder='Select Client'
                        options={workOrderOptions}
                        value={formData?.client}
                        onChange={(selectedOption) => handleSelectInputChange(selectedOption, 'client')}
                        error={errors?.client}
                        className='mb-2'
                    />
                    <Select
                        label='Work Order'
                        name='workOrder'
                        inputId='workOrder-select'
                        htmlFor='workOrder-select'
                        placeholder='Select work order'
                        options={workOrderOptions}
                        value={formData?.workOrder}
                        onChange={(selectedOption) => handleSelectInputChange(selectedOption, 'workOrder')}
                        error={errors?.workOrder}
                        className='mb-2'
                    />
                    <Stack direction='horizontal' className='justify-content-between mt-auto'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={handleClosePOC}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='fw-semibold py-14px'
                            disabled={!isPOCFormValid}
                        >
                            Add POC
                        </Button>
                    </Stack>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
