import React, { useMemo } from 'react'
import { Table } from '../../common/table/Table'
import { Form, ListGroup, Stack } from 'react-bootstrap'
import { USER } from '../../../assets/images.assets'
import { MdOutlineMoreHoriz, MdStar } from 'react-icons/md'
import Rating from 'react-rating'
import { NavDropdownWrapper } from '../../common/header/nav-dropdown/Wrapper'

export const MyProviderPoolTable = ({ onMove }) => {

    const columns = useMemo(() => [
        {
            accessorKey: "name",
            header: () => "Name",
            cell: ({ row }) => (
                <Stack direction='horizontal' gap={2}>
                    <Form.Check type="checkbox" className="provider-checkbox" />
                    <img src={USER} alt="applicant-profile" />
                    <p>{row.original.name}</p>
                </Stack>
            )
        },
        {
            accessorKey: "location",
            header: () => "Location",
            cell: (info) => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: "ratings",
            header: () => "Ratings",
            cell: (info) => (
                <Rating
                    fullSymbol={<MdStar size={20} color='#FB9905' />}
                    emptySymbol={<MdStar size={20} color='#D9D9D9' />}
                    initialRating={4}
                    readonly
                />
            ),
            // enableSorting: false
        },
        {
            accessorKey: "jobsCompleted",
            header: () => "Jobs Completed",
            cell: (info) => info.renderValue(),
            // enableSorting: false
        },
        {
            id: "actions",
            header: () => "Action",
            cell: ({ row }) => {
                return (
                    <NavDropdownWrapper title={<MdOutlineMoreHoriz size={24} />}>
                        <ListGroup.Item
                            action
                            onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Move
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Delete
                        </ListGroup.Item>
                    </NavDropdownWrapper>
                );
            },
        },
    ], [])

    const rows = [
        {
            name: 'Adeleke Fajuyi Olayiwola',
            location: 'Brooklyn, London',
            jobsCompleted: 40
        },
        {
            name: 'Adeleke Fajuyi Olayiwola',
            location: 'Brooklyn, London',
            jobsCompleted: 40
        },
        {
            name: 'Adeleke Fajuyi Olayiwola',
            location: 'Brooklyn, London',
            jobsCompleted: 40
        },
        {
            name: 'Adeleke Fajuyi Olayiwola',
            location: 'Brooklyn, London',
            jobsCompleted: 40
        },
        {
            name: 'Adeleke Fajuyi Olayiwola',
            location: 'Brooklyn, London',
            jobsCompleted: 40
        },
    ]

    return (
        <Table data={rows} columns={columns} />
    )
}
