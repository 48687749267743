import { Col, ProgressBar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const WorkOrderSummary = () => {
  
  const userType = window.localStorage.getItem("USER_TYPE");
  
  return (
    <Col md={4} className='applicant-summary'>
          <h5 className='title fw-bold'>Work Order Summary</h5>
          <h1 className='total-applicants fw-bold'>67 <span className='fs-5 fw-normal'>work orders</span></h1>
          <ProgressBar className='m-2 chart'>
            <ProgressBar className={userType === 'provider' ? 'schedule' : 'invites'} now={35} key={1} />
            <ProgressBar className='completed' now={20} key={2} />
            <ProgressBar className='applied' now={10} key={3} />
            <ProgressBar className='approved' now={15} key={4} />
            <ProgressBar className='assigned' now={10} key={5} />
            <ProgressBar className='paid' now={10} key={6} />
          </ProgressBar>
          <Row className='justify-content-between row-gap-2 mx-2 my-4'>
            {userType === 'provider' ? (
              <Col md={5} className='hired px-0 d-flex gap-2'>
                <div className='schedule' />
                <h6 className='mb-0 fw-normal'>Invites : <span className='fw-medium'>35</span></h6>
              </Col>
            ) : (
              <Col md={5} as={Link} to='/work-orders' state={{ eventKey: 'in-progress' }} className='hired px-0 d-flex gap-2'>
                <div className='invites' />
                <h6 className='mb-0 fw-normal'>In-Progress : <span className='fw-medium'>35</span></h6>
              </Col>
            )}
            <Col md={5} as={Link} to='/work-orders' state={{ eventKey: 'completed' }} className='hired px-0 d-flex gap-2'>
              <div className='completed' />
              <h6 className='mb-0 fw-normal'>Completed : <span className='fw-medium'>35</span></h6>
            </Col>
            {userType === 'provider' ? (
              <Col md={5} className='hired px-0 d-flex gap-2'>
                <div className='applied' />
                <h6 className='mb-0 fw-normal'>Applied : <span className='fw-medium'>35</span></h6>
              </Col>
            ) : (
              <Col md={5} as={Link} to='/work-orders' state={{ eventKey: 'published' }} className='hired px-0 d-flex gap-2'>
                <div className='applied' />
                <h6 className='mb-0 fw-normal'>Published : <span className='fw-medium'>35</span></h6>
              </Col>
            )}
            <Col md={5} as={Link} to='/work-orders' state={{ eventKey: 'approved' }} className='hired px-0 d-flex gap-2'>
              <div className='approved' />
              <h6 className='mb-0 fw-normal'>Approved : <span className='fw-medium'>35</span></h6>
            </Col>
            <Col md={5} as={Link} to='/work-orders' state={{ eventKey: 'assigned' }} className='hired px-0 d-flex gap-2'>
              <div className='assigned' />
              <h6 className='mb-0 fw-normal'>Assigned : <span className='fw-medium'>35</span></h6>
            </Col>
            <Col md={5} as={Link} to='/work-orders' state={{ eventKey: 'paid' }} className='hired px-0 d-flex gap-2'>
              <div className='paid' />
              <h6 className='mb-0 fw-normal'>Paid : <span className='fw-medium'>35</span></h6>
            </Col>
          </Row>
        </Col>
  )
}
