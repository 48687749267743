import React, { useState } from "react";
import { CardWrapper, MainDefault } from "../../../components/common/Default";
import { Button } from "../../../components/common/button/Button";
import { FiPlus } from "react-icons/fi";
import { RightOffcanvas } from "../../../components/buyer-provider/RightOffcanvas";
import { RxCross1 } from "react-icons/rx";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { ChooseYourFundingMethod } from "./add-funds/ChooseYourFundingMethod";
import "./payments.css";
import { JobUpdates } from "../../../components/dashboard/job-updates/JobUpdates";
import { PAYMENTS_navITEMS, PAYMENTS_tabPanes } from "../../../data/constants";
import { TotalBalanceCard } from "../../../components/buyer-provider/TotalBalanceCard";

export const Payments = () => {
  const [fundingMethod, setFundingMethod] = useState(false);

  const paymentMethods = [
    {
      image: require("../../../assets/images/approvalP-past-dueP.png"),
      title: "Approval Pending",
      description: "These are pending payables awaiting approval",
      amount: "$4,360.00",
    },
    {
      image: require("../../../assets/images/current-payables.png"),
      title: "Current Payables",
      description: "These are approved payables in processl",
      amount: "$4,360.00",
    },
    {
      image: require("../../../assets/images/cash-balance.png"),
      title: "Cash Balance",
      description:
        "These are payables containing all late invoices in your account",
      amount: "$4,360.00",
    },
    {
      image: require("../../../assets/images/available-funds.png"),
      title: "Available Funds",
      description: "These are available funds for use.",
      amount: "$4,360.00",
    },
  ];
  return (
    <MainDefault>
      <div className="px-3 d-flex align-items-center justify-content-between">
        <h6 className="font-24 line-height-130 fw-bold">Payment</h6>

        <Button
          className="px-3"
          style={{ paddingBlock: 12 }}
          onClick={() => setFundingMethod(true)}
        >
          <FiPlus size={20} className="me-2" />
          <span>Add Funds</span>
        </Button>
      </div>

      <Row className="mt-4">
        {paymentMethods.map((p, index) => (
          <Col sm={3} key={index}>
            <TotalBalanceCard
              image={p.image}
              title={p.title}
              description={p.description}
              amount={p.amount}
            />
          </Col>
        ))}
      </Row>

      <JobUpdates
        navItems={PAYMENTS_navITEMS}
        tabPanes={PAYMENTS_tabPanes}
      ></JobUpdates>

      <RightOffcanvas show={fundingMethod} handleClose={setFundingMethod}>
        <div className="p-3 d-flex align-items-center justify-content-between">
          <h5 className="text-black fw-semibold font-20">Add Funds</h5>
          <RxCross1
            size={20}
            onClick={() => setFundingMethod(false)}
            className="cursor-pointer"
          />
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <ChooseYourFundingMethod setShowFundingMethod={setFundingMethod} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </MainDefault>
  );
};
