import React, { useMemo, useState } from "react";
import { CommonHeader } from "../../common-header/CommonHeader";
import { Table } from "../../../common/table/Table";
import { ListGroup, NavDropdown, Offcanvas } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { RightOffcanvas } from "../../RightOffcanvas";
import { AddEditTicket } from "../add-edit-ticket/AddEditTicket";
import { TicketDetails } from "../../../../pages/provider/support/ticket-details/TicketDetails";
import { createTicket, editTicket } from "../../../../store/provider/provider.action";
import { useDispatch, useSelector } from "react-redux";

export const SupportTableDetails = () => {
  // const [editTicket, setEditTicket] = useState(false);
  const dispatch = useDispatch();

  const _editTicket = useSelector(
    (state) => state.providerReducer.editTicketOffCanvas
  );
  const [ticketDetails, setTicketDetails] = useState(false);

  const handleOpenEditTicket = () => {
    // console.log("hhhh")
    dispatch(editTicket(true));
  };
  const handleClose = () => {
    dispatch(editTicket(false));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "ticketID",
        header: () => "Ticket ID",
        cell: (info) => (
          <span
            className="font-12 line-height-160 color-grayscale-900 cursor-pointer"
            onClick={() => setTicketDetails(true)}
          >
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "title",
        header: () => "Title",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "type",
        header: () => "Type",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "message",
        header: () => "Message",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => (
          // <span className="font-12 line-height-160 color-grayscale-900">{info.renderValue()}</span>
          <div className="pending-status fw-500 font-14">PENDING</div>
        ),
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={handleOpenEditTicket}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Edit
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      ticketID: "Ademola Lookman",
      title: "01-24356789",
      type: "Iristech Inc",
      message: "johnsonnamani@gmail.com",
      // status: "Pending",
    },
  ];

  return (
    <>
      <div className="mt-4">
        <CommonHeader />
      </div>

      <div className="mt-4">
        <Table columns={columns} data={rows} />
      </div>

      <RightOffcanvas
        show={_editTicket}
        onHide={handleClose}
        className="w-25"
      >
        <div className="p-3">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Edit Ticket
          </h6>
          <p className="font-16 color-gray-700">
            You can edit an open ticket here
          </p>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <AddEditTicket handleClose={handleClose} mode="edit" />
        </Offcanvas.Body>
      </RightOffcanvas>

      {/* TICKET DETAILS */}
      <Offcanvas
        show={ticketDetails}
        onHide={() => setTicketDetails(false)}
        placement="end"
        style={{ overflowY: "auto", maxHeight: "100vh" }}
        keyboard={true}
        backdrop={true}
      >
        <div className="p-3">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Ticket Details
          </h6>
          <p className="font-16 color-gray-700">
            See more details about the ticket created
          </p>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <TicketDetails />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
