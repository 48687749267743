import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Button } from '../../common/button/Button'
import { PERMISSIONS } from '../../../data/constants'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addRole, fetchBuyerRoles, updateRole } from '../../../store/buyer/roles/roles.action'

export const SetPermission = ({ 
    show, 
    handleClose, 
    mode,
    permissions, 
    formData, 
    handleCloseRole
}) => {

    const dispatch = useDispatch()

    const [permissionsState, setPermissionsState] = useState(() => {
        const initialState = PERMISSIONS.map(permission => {
            const children = permission.children?.map(child => {
                return { [child.name]: false }
            }) || [];
            return {
                [permission.name]: false,
                children: children
            };
        });
        return initialState;
    });

    const handleCheckboxChange = (permissionName) => {
        setPermissionsState((prevState) => {
            return prevState.map(permission => {
                if (permission[permissionName] !== undefined) {
                    const newParentState = !permission[permissionName]
                    return {
                        ...permission,
                        [permissionName]: !permission[permissionName],
                        children: permission.children.map(child => {
                            return { ...child, [Object.keys(child)[0]]: newParentState }
                        }),
                    };
                } else if (permission.children) {
                    const updatedChildren = permission.children.map(child => {
                        if (child[permissionName] !== undefined) {
                            return { ...child, [permissionName]: !child[permissionName] };
                        }
                        return child;
                    });

                    const allChildrenUnchecked = updatedChildren.some(child => {
                        return Object.values(child)[0] === false
                    });
    
                    return {
                        ...permission,
                        children: updatedChildren,
                        [Object.keys(permission)[0]]: allChildrenUnchecked ? false : true,
                    };
                }
                return permission;
            });
        });
    };    

    const handleClosePermission = () => {
        handleClose()
        setPermissionsState(() => {
            const initialState = PERMISSIONS.map(permission => {
                const children = permission.children?.map(child => {
                    return { [child.name]: false }
                }) || [];
                return {
                    [permission.name]: false,
                    children: children
                };
            });
            return initialState;
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            ...formData,
            permissions: JSON.stringify(permissionsState)
        }
        if(mode === 'edit') {
            dispatch(updateRole(data, { showLoader: 'submit-role' }))
        } else {
            delete data?.role
            dispatch(addRole(data, { showLoader: 'submit-role' }))
        }
        setTimeout(() => {
            dispatch(fetchBuyerRoles());
        }, 1000);
        handleClosePermission()
        handleCloseRole()
    }

    useEffect(() => {
        if (mode === 'edit' && permissions) {
            setPermissionsState(permissions)
        }
    }, [mode, permissions]);

    return (
        <Offcanvas show={show} onHide={handleClosePermission} placement='end'>
            <Offcanvas.Body className='d-flex flex-column h-100 scrollbar-thin'>
                <div className='mb-3'>
                    <Offcanvas.Title className='fw-semibold text-black'>Set Permission</Offcanvas.Title>
                    <p>You can set permission for this role</p>
                </div>
                <Form onSubmit={handleSubmit} className='d-flex flex-column flex-grow-1'>
                    {PERMISSIONS.map((permission, i) => {
                        return (
                            <div className='mb-3' key={i}>
                                <Stack direction='horizontal' className='justify-content-between'>
                                    <label htmlFor={`checkbox-${permission.name}`} className='text-black font-14 fw-medium'>{permission.label}</label>
                                    <Form.Check
                                        type='checkbox'
                                        className='buyer-checkbox'
                                        id={`checkbox-${permission.name}`}
                                        checked={permissionsState?.[i]?.[permission.name]}
                                        onChange={() => handleCheckboxChange(permission.name)}
                                    />
                                </Stack>
                                <hr
                                    className='opacity-100 border-grayscale-100 my-2'
                                />
                                {permission.children?.map((child, index) => {
                                    return (
                                        <Stack
                                            direction='horizontal'
                                            className='justify-content-between'
                                            key={index}
                                        >
                                            <label
                                                htmlFor={`checkbox-${child.name}`}
                                                className='font-14 fw-medium'
                                                style={{ color: '#84818A' }}
                                            >
                                                {child.label}
                                            </label>
                                            <Form.Check
                                                type='checkbox'
                                                id={`checkbox-${child.name}`}
                                                className='buyer-checkbox'
                                                checked={permissionsState?.[i]?.children?.[index]?.[child.name]}
                                                onChange={() => handleCheckboxChange(child.name)}
                                            />
                                        </Stack>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <Stack direction='horizontal' className='justify-content-between mt-auto' gap={4}>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony'
                            style={{
                                paddingBlock: 14,
                                border: '1px solid #CCCCF5'
                            }}
                            onClick={handleClosePermission}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            id='submit-role'
                            className='fw-semibold p-3'
                        >
                            {mode === 'edit' ? 'Save' : 'Create Role'}
                        </Button>
                    </Stack>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
