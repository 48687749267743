import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Input } from '../common/input/Input'
import { Select } from '../common/select/Select';

export const BillingInformationForm = () => {

    const [formData, setFormData] = useState({
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
    });

    const [errors, setErrors] = useState({
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
    });

    const validateField = (name, value) => {
        let error = "";
        switch (name) {
            case "address":
                if (!value) error = "Address is required.";
                break;
            case "city":
                if (!value) error = "City is required.";
                break;
            case "state":
                if (!value) error = "State is required.";
                break;
            case "country":
                if (!value) error = "Country is required.";
                break;
            case "postalCode":
                if (!value) error = "Postal code is required.";
                else if (!/^\d{5}$/.test(value))
                    error = "Postal code must be 5 digits.";
                break;
            default:
                break;
        }
        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const isFormValid = () => {
        return (
            Object.values(errors).every((error) => error === "") &&
            Object.values(formData).every((field) => field !== "")
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = Object.keys(formData).reduce((acc, key) => {
            acc[key] = validateField(key, formData[key]);
            return acc;
        }, {});
        setErrors(newErrors);

        if (isFormValid()) {
            return formData
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                label="Address"
                type="text"
                placeholder="Address"
                className="mb-3"
                name="address"
                value={formData.address}
                onChange={handleChange}
                error={errors.address}
            />
            <div className="d-flex justify-content-between mb-3 gap-1">
                <Select
                    label="City"
                    name="city"
                    placeholder="Select City"
                    className="mb-2 w-100"
                    value={formData.city}
                    onChange={(e) =>
                        handleChange({ target: { name: "city", value: e.target.value } })
                    }
                    error={errors.city}
                    star
                />
                <Select
                    label="State"
                    name="state"
                    placeholder="Select State"
                    className="mb-2 w-100"
                    value={formData.state}
                    onChange={(e) =>
                        handleChange({ target: { name: "state", value: e.target.value } })
                    }
                    error={errors.state}
                    star
                />
            </div>
            <div className="d-flex justify-content-between mb-3 gap-1">
                <Select
                    label="Country"
                    name="country"
                    placeholder="Select Country"
                    className="mb- w-100"
                    value={formData.country}
                    onChange={(e) =>
                        handleChange({
                            target: { name: "country", value: e.target.value },
                        })
                    }
                    error={errors.country}
                    star
                />
                <Input
                    label="Postal Code"
                    name="postalCode"
                    placeholder="Postal Code"
                    className="mb-3 w-100"
                    value={formData.postalCode}
                    onChange={handleChange}
                    error={errors.postalCode}
                />
            </div>
        </Form>
    )
}
