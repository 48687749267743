import { Col, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap'
import { Select } from '../common/select/Select'
import { taxOptions, taxTypeOptions } from '../../data/custom-options'
import { Input } from '../common/input/Input'
import { HiInformationCircle } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../common/button/Button'
import { useEffect, useState } from 'react'
import { getCountries, getStates } from 'country-state-picker'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxDetails } from '../../store/buyer/buyer.action'

const renderTooltip = (props) => (
    <Tooltip {...props}>
        Social Security Number (SSN)/TIN
    </Tooltip>
)

export const BuyerTaxInformationForm = ({
    onSubmit,
    fromProfile = false,
    wrapperClasses,
    // linkTo = '/get-started',
    // linkText = 'Back',
    // buttonText = 'Continue'
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const taxDetails = useSelector(state => state.buyerReducer.taxDetails)

    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        tax: null,
        ssn: '',
        ein: '',
        taxType: null,
        address: '',
        country: null,
        state: null,
        city: '',
        floor: '',
        zipCode: '',
    })

    const [errors, setErrors] = useState({
        tax: '',
        ssn: '',
        ein: '',
        taxType: '',
        address: '',
        country: '',
        state: '',
        city: '',
        floor: '',
        zipCode: ''
    })

    const validateField = (name, value) => {
        var error = '';
        if (name === 'tax' && !value) {
            error = 'Tax is required';
        } else if (name === 'taxType' && !value) {
            error = 'Tax Type is required';
        } else if (name === 'address') {
            if (!value.trim()) {
                error = 'Address is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Address cannot contains special characters';
            }
        } else if (name === 'country' && !value) {
            error = 'Country is required';
        } else if (name === 'state' && !value) {
            error = 'State is required';
        } else if (name === 'city' && !value) {
            error = 'City is required';
        } else if (name === 'floor' && !value) {
            error = 'Suite/Floor is required';
        } else if (name === 'zipCode' && !value) {
            error = 'Zip code is required';
        }

        if (name === 'ssn') {
            if (!value) {
                error = 'Social Security Number (SSN) is required'
            } else if (value.length !== 9 && value.length !== 10) {
                error = 'Social Security Number (SSN) must be 9 digit exactly'
            }
        } else if (name === 'ein') {
            if (!value) {
                error = 'Employer Identification Number (EIN) is required'
            } else if (value.length !== 9 && value.length !== 10) {
                error = 'Employer Identification Number (EIN) must be 9 digit exactly'
            }
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        var updatedValue = value;
        if (name === 'ssn' || name === 'ein') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '').slice(0, 9)
        } else if (name === 'zipCode') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '')
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        if(name === 'country') setFormData(prevFormData => ({
            ...prevFormData,
            state: null
        }))

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.keys(formData).every(key => {
        if (key === 'ssn' && formData?.tax?.value === 'EIN') return true;
        if (key === 'ein' && formData?.tax?.value === 'SSN') return true;
        return formData[key];
    })

    const handleFormSubmit = () => {

        const newErrors = {
            tax: '',
            ssn: '',
            ein: '',
            taxType: '',
            address: '',
            country: '',
            state: '',
            city: '',
            floor: '',
            zipCode: ''
        }

        if (!formData.tax) newErrors.tax = 'Tax is required';
        if (!formData.taxType) newErrors.taxType = 'Tax Type is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.country) newErrors.country = 'Country is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.floor) newErrors.floor = 'Suite/Floor is required';
        if (!formData.zipCode) newErrors.zipCode = 'Zip code is required';

        if (formData.tax?.value === 'SSN') {
            if (!formData.ssn) {
                newErrors.ssn = 'Social Security Number (SSN) is required'
            } else if (formData.ssn.trim().length !== 9) {
                newErrors.ssn = 'Social Security Number (SSN) must be 9 digit exactly'
            }
        } else if (formData.tax?.value === 'EIN') {
            if (!formData.ein) {
                newErrors.ein = 'Employer Identification Number (EIN) is required'
            } else if (formData.ein.trim().length !== 9) {
                newErrors.ein = 'Employer Identification Number (EIN) must be 9 digit exactly'
            }
        }

        setErrors(newErrors);

        if (isFormValid()) {

            const data = {
                ...formData,
                tax: formData?.tax?.value,
                taxNumber: formData?.tax?.value === 'SSN' ? formData?.ssn : formData?.ein,
                taxNumberLastDigits: formData?.tax?.value === 'SSN' ? formData?.ssn?.slice(-4) : formData?.ein?.slice(-4),
                taxType: formData?.taxType?.value,
                country: formData?.country?.code,
                state: formData?.state?.value,
                street: formData?.address
            }

            delete data.ssn
            delete data.ein
            delete data.address

            return data

            // try {
            //     const response = await PostRequest('/buyer/tax-details/update', data, { showLoader: 'tax-details-button' })
            //     return response
            // } catch (error) {
            //     showToaster(error.message, 'error')
            //     return error
            // }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(handleFormSubmit)
    }

    // Fetch countries
    useEffect(() => {
        const countriesData = getCountries();
        const countriesList = countriesData.map(country => ({
            label: country?.name,
            value: country?.name,
            code: country?.code
        }));
        setCountries(countriesList);
    }, []);

    // Fetch states based on selected country
    useEffect(() => {
        const fetchStates = () => {
            if (formData?.country?.code) {
                const statesData = getStates(formData?.country?.code);
                const statesList = statesData?.map(state => ({
                    label: state,
                    value: state
                }));
                setStates(statesList);
            } else {
                setStates([]);
            }
        };
        fetchStates();
    }, [formData?.country?.code])

    useEffect(() => {
        if (taxDetails && countries.length > 0) {
            const selectedCountry = countries.find(option => option.code === taxDetails.country) || null;
            var availableStates = selectedCountry ? getStates(selectedCountry.code) : [];
            availableStates = availableStates.map(state => ({
                label: state,
                value: state
            }))
            const selectedState = availableStates.find(state => state.value === taxDetails.state) || null

            setFormData({
                tax: taxOptions?.find(option => option.value === taxDetails?.tax) || null,
                ssn: taxDetails?.tax === 'SSN' ? `*****${taxDetails?.taxNumberLastDigits}` : '',
                ein: taxDetails?.tax === 'EIN' ? `*****${taxDetails?.taxNumberLastDigits}` : '',
                taxType: taxTypeOptions?.find(option => option.value === taxDetails?.taxType) || null,
                country: selectedCountry,
                state: selectedState,
                city: taxDetails?.city || '',
                address: taxDetails?.street || '',
                floor: taxDetails?.floor || '',
                zipCode: taxDetails?.zipCode || '',
            });
        }
    }, [taxDetails, countries])

    useEffect(() => {
        dispatch(fetchTaxDetails())
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <div className={wrapperClasses}>
                <Select
                    label='Tax'
                    name='tax'
                    inputId='tax'
                    htmlFor='tax'
                    placeholder='Select one'
                    className='mb-2'
                    options={[taxOptions[1]]}
                    value={formData?.tax}
                    error={errors.tax}
                    onChange={(tax) => handleSelectChange(tax, 'tax')}
                    required
                />
                {formData?.tax?.value === 'SSN' ? (
                    <Input
                        label={<>
                            Social Security Number (SSN)/TIN {' '}
                            <OverlayTrigger overlay={renderTooltip}>
                                <span>
                                    <HiInformationCircle
                                        className='color-grey-500'
                                        size={20}
                                    />
                                </span>
                            </OverlayTrigger>
                        </>}
                        placeholder='your ssn'
                        name='ssn'
                        id='ssn'
                        htmlFor='ssn'
                        className='mb-2'
                        value={formData?.ssn}
                        onChange={handleChange}
                        error={errors.ssn}
                    />
                ) : formData?.tax?.value === 'EIN' ? (
                    <Input
                        label='Employer Identification Number (EIN)'
                        placeholder='EIN Number'
                        name='ein'
                        id='ein'
                        htmlFor='ein'
                        className='mb-2'
                        value={formData?.ein}
                        onChange={handleChange}
                        error={errors.ein}
                    />
                ) : null}
                <Select
                    label='Tax Type'
                    name='taxType'
                    inputId='taxType'
                    htmlFor='taxType'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxTypeOptions}
                    value={formData?.taxType}
                    error={errors.taxType}
                    onChange={(taxType) => handleSelectChange(taxType, 'taxType')}
                    required
                />
                <Input
                    label='Address'
                    placeholder='Address'
                    htmlFor='address'
                    id='address'
                    name='address'
                    value={formData?.address}
                    error={errors.address}
                    onChange={handleChange}
                    className='mb-2'
                />
                <Select
                    name='country'
                    inputId='countrySelect'
                    htmlFor='countrySelect'
                    placeholder='Country'
                    className='mb-3'
                    options={countries}
                    value={formData?.country}
                    error={errors.country}
                    isSearchable
                    onChange={(country) => handleSelectChange(country, 'country')}
                    required
                />
                <Row className='row-gap-2 mb-3'>
                    <Col md={6}>
                        <Select
                            name='state'
                            inputId='stateSelect'
                            htmlFor='stateSelect'
                            placeholder='State'
                            className='mb-2'
                            options={states}
                            value={formData?.state}
                            isSearchable
                            onChange={(state) => handleSelectChange(state, 'state')}
                            error={errors?.state}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='Suite/Floor'
                            name='floor'
                            value={formData?.floor}
                            error={errors.floor}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='City'
                            name='city'
                            value={formData?.city}
                            error={errors.city}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            placeholder='Zip code'
                            name='zipCode'
                            value={formData?.zipCode}
                            error={errors.zipCode}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </div>
            {fromProfile ? (
                <>
                    <hr className='border-botticelli opacity-100' />
                    <Stack direction='horizontal' gap={4}>
                        <Link
                            to='/dashboard'
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 color-ebony border-ebony text-center fw-semibold'
                            style={{ paddingBlock: 14 }}
                        >
                            Cancel
                        </Link>
                        <Button
                            type='submit'
                            disabled={!isFormValid()}
                            className='fw-semibold'
                            style={{ paddingBlock: 14 }}
                        >
                            Update Tax Information
                        </Button>
                    </Stack>
                </>
            ) : (
                <Row className='mb-1 gap-2'>
                    <Col>
                        <Button
                            onClick={() => navigate(-1)}
                            type='button'
                            variant='outline'
                            className='w-100 color-ebony border-ebony py-3'
                        >
                            Back
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type='submit'
                            id='tax-details-button'
                            disabled={!isFormValid()}
                            className='w-100 py-3'
                        >
                            Continue
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    )
}
