import React, { useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { Button } from "../../../components/common/button/Button";
import { FiPlus } from "react-icons/fi";
import { AddEditProject } from "./add-edit-project/AddEditProject";
import { ProjectTable } from "./project-table/ProjectTable";
import { RightOffcanvas } from "../../../components/buyer-provider/RightOffcanvas";
import { Offcanvas } from "react-bootstrap";
import { NotFoundCard } from "../../../components/profile/not-found/NotFoundCard";

export const Projects = () => {
  const [isCreateProject, setIsCreateProject] = useState(false);
  return (
    <>
      <MainDefault containerClasses={[''].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
        {[''].length <= 0 ? (
          <>
            <h4 className="mb-0 mt-3 fw-bold topHead">
              Projects
            </h4>
            <NotFoundCard
              title="No project added"
              subTitle="Create a project to assign to work orders"
              buttonText="Create project"
              onButtonClick={() => setIsCreateProject(true)}
            />
          </>
        ) : (
          <>
            <div className="mt-2 d-flex align-items-center justify-content-between">
              <h6 className="font-24 line-height-130 fw-bold">Projects</h6>

              <Button
                className="px-3"
                style={{ paddingBlock: 12 }}
                onClick={() => setIsCreateProject(true)}
              >
                <FiPlus size={20} className="me-2" />
                <span>Add Project</span>
              </Button>
            </div>
            {/* PROJECT TABLE */}
            <ProjectTable />
          </>
        )}

        <RightOffcanvas
          show={isCreateProject}
          handleClose={() => setIsCreateProject(false)}
        >
          <div className="p-3">
            <h5 className="text-black fw-semibold font-20">Add Project</h5>
            <p>You can add project details</p>
          </div>
          <Offcanvas.Body className="d-flex flex-column">
            <AddEditProject setIsCreateProject={setIsCreateProject} type="Add Project" key="Add" />
          </Offcanvas.Body>"
        </RightOffcanvas>

      </MainDefault>
    </>
  );
};
