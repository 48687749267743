import React, { useState } from "react";
import { MainDefaultSettingSideBar } from "../../../components/common/Default";
import { IoLockClosed } from "react-icons/io5";
import "./settings.css";
import Underline from "../../../components/buyer-provider/Underline";
import { Button } from "../../../components/common/button/Button";
import { Form } from "react-bootstrap";
import { TwoFA } from "./two-fa/TwoFA";

export const Security = () => {
  const [isTwoFAOpen, setIsTwoFAOpen] = useState(false);

  return (
    <MainDefaultSettingSideBar>
      {!isTwoFAOpen ? (
        <div>
          <div className="top-nav py-3 mb-3">
            <h4 className="mb-0">Two-Factor Authentication</h4>
          </div>

          <div className="mt-4">
            <div className="d-flex align-items-center gap-2">
              <IoLockClosed size={32} color="#920af2" />
              <p className="enable-security">Enable Security</p>
            </div>
            <p className="policy-instruction mt-3">
              Add a layer of protection to your account with both a password and a
              <br />
              verification code *
            </p>
          </div>

          <div className="mt-3">
            <p className="small-header">How it works</p>
            <Underline className="mt-3" />
            <ul className="mt-3 ">
              <li>Configure a third party authentication with WorkRobin</li>
              <li className="pt-3">
                We will ask for a login token from the authentication app at intervals
              </li>
            </ul>
            <Underline className="mt-4 mb-2" />
          </div>

          <Button className="mt-4 py-3 px-3" onClick={() => setIsTwoFAOpen(true)}>
            Get Started
          </Button>

          <div className="top-nav py-3 mt-4 mb-4">
            <h4 className="mb-0">Login Restrictions</h4>
          </div>

          <div className="d-flex align-items-center gap-2">
            <Form.Check type="checkbox" className="buyer-checkbox mt-1" id='manageRolesCheckbox' />
            <label htmlFor="manageRolesCheckbox" className="login-restriction-policy">Manage all company’s roles</label>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3">
            <Form.Check type="checkbox" className="buyer-checkbox mt-1" id='manageOrgStructuresCheckbox' />
            <label htmlFor="manageOrgStructuresCheckbox" className="login-restriction-policy">
              Manage all company’s org structures
            </label>
          </div>
        </div>
      ) : (
        <TwoFA setIsTwoFAOpen={setIsTwoFAOpen}/>
      )}
    </MainDefaultSettingSideBar>
  );
};
