import { MainDefault } from '../../../../../components/common/Default'
import { BasicInformationForm } from '../../../../../components/buyer/work-orders/creation/BasicInformationForm'
import './basic-information.css'
import { NewWODefault } from '../../../../../components/buyer/work-orders/creation/Default'
import { useLocation } from 'react-router-dom'

export const BasicInformation = () => {

    const location = useLocation()
    const mode = location.state?.mode
    
    return (
        <MainDefault>
            <NewWODefault mode={mode}>
                <BasicInformationForm />
            </NewWODefault>
        </MainDefault>
    )
}