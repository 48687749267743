import React from "react";
import "./jobInformation.css";
import { Col, Row } from "react-bootstrap";
import { CardWrapper } from "../../common/Default";
import Underline from "../../buyer-provider/Underline";

export const JobInformation = () => {
  return (
    <>
      <CardWrapper>
        <div className="description-heading">
          <h6>Job description</h6>
          <p className="mt-4">
            As a seasoned plumber dedicated to excellence in my craft, I bring a
            wealth of experience and a passion for delivering top-notch plumbing
            solutions. With a [insert number of years] years' track record in
            the field, I have honed my skills in diagnosing, repairing, and
            maintaining plumbing systems with precision and efficiency.
          </p>
          <p className="mt-3">
            My commitment to quality craftsmanship is evident in my attention to
            detail, ensuring that every installation and repair meets the
            highest industry standards. I am adept at handling a diverse range
            of plumbing tasks, from routine maintenance to troubleshooting
            complex issues.
          </p>
          <Underline />
        </div>
        <div className="description-heading mt-4">
          <h6>Special Instructions</h6>
          <p className="mt-4">
            As a seasoned plumber dedicated to excellence in my craft, I bring a
            wealth of experience and a passion for delivering top-notch plumbing
            solutions. With a [insert number of years] years' track record in
            the field, I have honed my skills in diagnosing, repairing, and
            maintaining plumbing systems with precision and efficiency.
          </p>
          <Underline />
        </div>

        <Row className="mt-3 skills-info__heading">
          <Col>
            <h5> Skills</h5>
            <div className="d-flex gap-1">
              <div className="skill">Plumbing</div>
              <div className="skill">Attention to details</div>
              <div className="skill">English</div>
            </div>
          </Col>
          <Col>
            <h5>Qualifications</h5>
            <div className="d-flex gap-1">
              <div className="skill">Plumbing</div>
              <div className="skill">Attention to details</div>
              <div className="skill">English</div>
            </div>
          </Col>
          <Col>
            <h5>Tools</h5>
            <div className="d-flex gap-1">
              <div className="skill">Plumbing</div>
              <div className="skill">Attention to details</div>
              <div className="skill">English</div>
            </div>
          </Col>
        </Row>

        <Underline />

        <Row className="mt-3">
          <Col sm={4}>
            <h6 className="contact-info-heading">Person of Contact</h6>
            <div className="contact-info">
              <p>Gabriel Abimbola</p>
              <p>gabrielabimbola@gmail.com</p>
              <p>01-27731229</p>
            </div>
          </Col>
          <Col sm={8}>
            <h6 className="contact-info-heading">Approve provider timesheet</h6>
            <div className="contact-info">
              <p>Yes</p>
            </div>
          </Col>
        </Row>
      </CardWrapper>

      <div className="mt-4">
        <CardWrapper>
          <Row>
            <Col sm={4}>
              <h6 className="client-info-heading">Job Type</h6>

              <p className="on-site">Onsite</p>
            </Col>
            <Col sm={4}>
              <h6 className="client-info-heading">Client</h6>

              <div className="d-flex align-items-center gap-2">
                <div className="heading-wrapper">Heurty Inc.</div>
                <p className="business-id">ID: 5662333</p>
              </div>
              <div className="mt-2">
                <p className="client-address">Birmingham, United Kingdom</p>
                <p className="client-address pt-2">01-2667333</p>
              </div>
            </Col>
            <Col sm={4}>
              <h6 className="client-info-heading">Project</h6>
              <h6 className="heading-wrapper">Construction of Pipeline</h6>
              <div className="project-info">
                <p>
                  As a seasoned plumber dedicated to excellence in my craft, I
                  bring a wealth of experience and a passion for delivering
                  top-notch plumbing solutions. With a [insert number of years]
                  years' track record in the field, I have honed my skills in
                  diagnosing, repairing, and maintaining plumbing systems with
                  precision and efficiency.
                </p>
                <div className="pt-2">
                  <p>Heurty Inc</p>
                  <p className="pt-2">24-07-2024</p>
                </div>
              </div>
            </Col>
          </Row>
        </CardWrapper>
      </div>
      <div className="mt-4">
        <CardWrapper className="mt-4">
          <h6 className="heading-wrapper">Providers & Location</h6>

          <Row className="mt-3">
            <Col sm={4}>
              <h6 className="client-info-heading">No Of Providers</h6>
              <p className="no-of-providers">20</p>
            </Col>
            <Col sm={4}>
              <h6 className="client-info-heading">Location</h6>

              <div className="d-flex align-items-center gap-2">
                <div className="heading-wrapper">Heurty Inc.</div>
                <p className="business-id">ID: 5662333</p>
              </div>
              <div className="mt-2">
                <p className="client-address">Birmingham, United Kingdom</p>
                <p className="client-address pt-2">01-2667333</p>
              </div>
            </Col>
            <Col sm={4}>
              <h6 className="client-info-heading">Schedule</h6>

              <div className="date-and-time">
                <p>Heurty Inc</p>
                <p className="pt-2">24-07-2024</p>
              </div>
            </Col>
          </Row>
          <Underline />
        </CardWrapper>
      </div>
    </>
  );
};
