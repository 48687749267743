import React from "react";
import { Form, Navbar } from "react-bootstrap";
import { BsInfoLg } from "react-icons/bs";
import { LayoutSwitcher } from "../dashboard/job-updates/LayoutSwitcher";
import { Downloader } from "../common/downloader/Downloader";
import { IoSearch } from "react-icons/io5";
import "./applicants/applicants.css";

export const JobInfoHeader = ({ isCSV = false, isSortDropDownLeft = false }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
        <div className="d-flex align-items-center gap-2">
          <Navbar.Brand className="position-relative">
            <IoSearch className="form-control__search-icon color-grey-500" />
            <Form.Control
              type="text"
              placeholder="Search"
              className="form-control__search applicant-search-input"
            />
          </Navbar.Brand>
          {!isSortDropDownLeft && (
            <div className="smart-finder d-flex align-items-center gap-2">
              SmartFinder
              <div className="smart-finder-icon d-flex align-items-center justify-content-center">
                <BsInfoLg />
              </div>
            </div>
          )}
          {isSortDropDownLeft && (
            <select
              style={{ width: "100px" }}
              className="sort-applicant padding-select-box font-14"
              name=""
              id=""
            >
              <option>Short</option>
              <option value="">Show 10</option>
              <option value="">Show 20</option>
            </select>
          )}
        </div>

        <div className="d-flex align-items-center gap-4">
          <LayoutSwitcher />
          {!isSortDropDownLeft && (
            <select
              style={{ width: "100px" }}
              className="sort-applicant padding-select-box font-14"
              name=""
              id=""
            >
              <option>Short</option>
              <option value="">Show 10</option>
              <option value="">Show 20</option>
            </select>
          )}

          <Downloader isCSV={isCSV} />
        </div>
      </div>
    </>
  );
};
