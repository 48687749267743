import { Fragment, useMemo, useState } from 'react'
import { Col, Form, InputGroup, Row, Stack } from 'react-bootstrap'
import { Table } from '../../../../common/table/Table'
import './pricing.css'
import { Button } from '../../../../common/button/Button'
import { HiInformationCircle } from 'react-icons/hi2'
import { Select } from '../../../../common/select/Select'
import ReactSelect from "react-select";
import { dashboardFilterDropdownStyles } from '../../../../../data/constants'
import { JOB_STATUS_OPTIONS, PAYMENT_TERMS_OPTIONS } from '../../../../../data/custom-options'
import { Input } from '../../../../common/input/Input'
import { PDFandCSVUploader } from '../PDFandCSVUploader'
import { SaveTemplate } from '../../modals/SaveTemplate'
import { handleSelectChange, isFormValid } from '../../../../../utils/utils'

const data = [
    {
        paymentTerm: 'Immediately',
        platformFee: '4.00%',
        salesTax: '4.00%',
        providersNet: '$534',
    }
]

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const PricingForm = () => {

    const columns = useMemo(() => [
        {
            header: 'Payment Term',
            accessorKey: 'paymentTerm',
            cell: info => info.getValue(),
            enableSorting: false
        },
        {
            header: 'Platform Fee',
            accessorKey: 'platformFee',
            cell: info => info.getValue(),
            enableSorting: false
        },
        {
            header: 'Sales Tax',
            accessorKey: 'salesTax',
            cell: info => info.getValue(),
            enableSorting: false
        },
        {
            header: 'Provider’s Net',
            accessorKey: 'providersNet',
            cell: info => info.getValue(),
            enableSorting: false
        },
    ], [])

    const [formData, setFormData] = useState({
        selectedPaymentRate: 'hourly',
        payRate: '',
        fixedRate: '',
        fixedHours: '',
        hourlyRate: '',
        additionalHours: '',
        maxHours: '',
        checkedDays: [],
        paymentTerms: PAYMENT_TERMS_OPTIONS[0],
        jobStatus: JOB_STATUS_OPTIONS[0],
        sendRobin: false,
        addExpense: false,
        documents: []
    })

    const [errors, setErrors] = useState({
        selectedPaymentRate: '',
        payRate: '',
        fixedRate: '',
        fixedHours: '',
        hourlyRate: '',
        additionalHours: '',
        maxHours: '',
        checkedDays: '',
        paymentTerms: '',
        jobStatus: '',
        sendRobin: '',
        addExpense: '',
        documents: ''
    })

    const [showSaveTemplate, setShowSaveTemplate] = useState(false)

    const validateField = (name, value) => {
        var error = ''

        if(name === 'payRate' && !value) error = 'Pay Rate is required'
        if(name === 'maxHours' && !value) error = 'Maximum Hours is required'
        if(name === 'paymentTerms' && !value) error = 'Payment Terms is required'
        if(name === 'jobStatus' && !value) error = 'Job Status is required'

        if(name === 'fixedRate' && !value) error = 'Fixed Rate is required'
        if(name === 'fixedHours' && !value) error = 'Fixed Hours is required'
        if(name === 'hourlyRate' && !value) error = 'Hourly Rate is required'
        if(name === 'additionalHours' && !value) error = 'Additional hours is required'

        return error
    }

    const handleDayChange = (day) => {
        setFormData(prevFormData => {
            const updatedCheckedDays = prevFormData.checkedDays.includes(day)
                ? prevFormData.checkedDays.filter((d) => d !== day)
                : [...prevFormData.checkedDays, day];

            return {
                ...prevFormData,
                checkedDays: updatedCheckedDays
            }
        })
    };

    const handlePaymentRateChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            selectedPaymentRate: e.target.value
        }))
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        var updatedValue = value;
        if (name === 'payRate' || name === 'fixedRate' || name === 'fixedHours' || name === 'hourlyRate' || name === 'additionalHours' || name === 'maxHours') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '')
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));


        const error = validateField(name, updatedValue);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    }

    const handleSelectInputChange = (selectedOption, name) => {
        handleSelectChange(selectedOption, name, setFormData, setErrors, validateField)
    }

    const onDrop = (files) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            documents: files
        }))
    }

    const isPricingFormValid = () => {
        var isValid = false;

        const { addExpense, sendRobin, ...restFormData } = formData

        const formCopy = {
            ...restFormData,
            paymentTerms: formData?.paymentTerms?.value,
            jobStatus: formData?.jobStatus?.value,
        }

        const fieldsToDelete = {
            hourly: ['fixedRate', 'fixedHours', 'hourlyRate', 'additionalHours', 'checkedDays'],
            daily: ['fixedRate', 'fixedHours', 'hourlyRate', 'additionalHours', 'maxHours', 'checkedDays'],
            weekly: ['fixedRate', 'fixedHours', 'hourlyRate', 'additionalHours', 'maxHours'],
            fixed: ['fixedRate', 'fixedHours', 'hourlyRate', 'additionalHours', 'maxHours', 'checkedDays'],
            blended: ['maxHours', 'checkedDays', 'payRate'],
        }

        const selectedPaymentRate = formData?.selectedPaymentRate;
        if (fieldsToDelete[selectedPaymentRate]) {
            fieldsToDelete[selectedPaymentRate].forEach(field => delete formCopy[field]);
        }

        isValid = isFormValid(errors, formCopy)
        return { isValid, formCopy }
    }

    const { isValid, formCopy } = isPricingFormValid()
    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = {
            selectedPaymentRate: '',
            payRate: '',
            fixedRate: '',
            fixedHours: '',
            hourlyRate: '',
            additionalHours: '',
            maxHours: '',
            checkedDays: '',
            paymentTerms: '',
            jobStatus: '',
            sendRobin: '',
            addExpense: '',
            documents: ''
        }

        Object.entries(formData).forEach(([key, value]) => {
            const error = validateField(key, value);
            if (error) newErrors[key] = error;
        })

        setErrors(newErrors)

        if (isValid) {
            console.log('data', formCopy)
        }
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit}>
                <div className='basic-info__wrapper'>
                    <h6 className='title mb-0'>Pricing & Documents</h6>
                    <p className='subtitle'>Add the description of the job, pay rate, duration and additional documents</p>
                </div>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Job Type</h6>
                        <p className='subtitle'>Indicate the type of job</p>
                    </Col>
                    <Col md={9}>
                        <Form.Check
                            inline
                            type='radio'
                            name='paymentType'
                            id='hourly'
                            value='hourly'
                            label='Hourly'
                            className='color-grey-500'
                            onChange={handlePaymentRateChange}
                            defaultChecked
                        />
                        <Form.Check
                            inline
                            type='radio'
                            name='paymentType'
                            id='daily'
                            value='daily'
                            label='Daily'
                            className='color-grey-500'
                            onChange={handlePaymentRateChange}
                        />
                        <Form.Check
                            inline
                            type='radio'
                            name='paymentType'
                            id='weekly'
                            value='weekly'
                            label='Weekly'
                            className='color-grey-500'
                            onChange={handlePaymentRateChange}
                        />
                        <Form.Check
                            inline
                            type='radio'
                            name='paymentType'
                            id='fixed'
                            value='fixed'
                            label='Fixed'
                            className='color-grey-500'
                            onChange={handlePaymentRateChange}
                        />
                        <Form.Check
                            inline
                            type='radio'
                            name='paymentType'
                            id='blended'
                            value='blended'
                            label='Blended'
                            className='color-grey-500'
                            onChange={handlePaymentRateChange}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                {formData?.selectedPaymentRate !== 'blended' ? (
                    <Row>
                        <Col md={3} className='basic-info__wrapper'>
                            <h6 className='title mb-0'>Pay rate</h6>
                            <p className='subtitle'>Please specify the estimated pay rate role.</p>
                        </Col>
                        <Col md={9}>
                            <div className='mb-2 d-inline-block'>
                                <InputGroup className=''>
                                    <InputGroup.Text className='dropzone__accepted-formats'>$</InputGroup.Text>
                                    <Input
                                        className='w-fit'
                                        inputClasses='rounded-start-0'
                                        name='payRate'
                                        value={formData?.payRate}
                                        onChange={handleInputChange}
                                    />
                                </InputGroup>
                                {errors?.payRate && <span className='error-text line-height-160 mt-2'>{errors?.payRate}</span>}
                            </div>
                            {formData?.selectedPaymentRate === 'hourly' && <p className='ms-2 d-inline-block dropzone__accepted-formats'>/hr</p>}
                            <Form.Check
                                type='checkbox'
                                className='buyer-checkbox'
                                id='allow-counter-offers-checkbox'
                                label='Allow counter offers'
                            />
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row className='mb-3'>
                            <Col md={3} className='basic-info__wrapper'>
                                <h6 className='title mb-0'>Fixed Rate</h6>
                                <p className='subtitle'>Specify fixed amount and maximum hours</p>
                            </Col>
                            <Col md={9}>
                                <Stack direction='horizontal' className='justify-content-between w-75'>
                                    <div>
                                        <InputGroup>
                                            <InputGroup.Text className='dropzone__accepted-formats'>$</InputGroup.Text>
                                            <Input
                                                inputClasses='rounded-start-0'
                                                name='fixedRate'
                                                value={formData.fixedRate}
                                                onChange={handleInputChange}
                                            />
                                        </InputGroup>
                                        {errors.fixedRate && <span className='error-text line-height-160 mt-2'>{errors.fixedRate}</span>}
                                    </div>
                                    <Stack direction='horizontal' gap={2}>
                                        <p className='dropzone__accepted-formats'>Hours</p>
                                        <Input
                                            name='fixedHours'
                                            value={formData.fixedHours}
                                            error={errors.fixedHours}
                                            onChange={handleInputChange}
                                        />
                                    </Stack>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className='basic-info__wrapper'>
                                <h6 className='title mb-0'>Hourly Rate</h6>
                                <p className='subtitle'>Specify hourly rate and additional hours</p>
                            </Col>
                            <Col md={9}>
                                <Stack direction='horizontal' className='mb-2 justify-content-between w-75'>
                                    <div>
                                        <InputGroup className='w-fit'>
                                            <InputGroup.Text className='dropzone__accepted-formats'>$</InputGroup.Text>
                                            <Input
                                                inputClasses='rounded-start-0'
                                                name='hourlyRate'
                                                value={formData.hourlyRate}
                                                onChange={handleInputChange}
                                            />
                                        </InputGroup>
                                        {errors.hourlyRate && <span className='error-text line-height-160 mt-2'>{errors.hourlyRate}</span>}
                                    </div>
                                    <Stack direction='horizontal' gap={2}>
                                        <p className='dropzone__accepted-formats'>Additional hours</p>
                                        <Input
                                            name='additionalHours'
                                            value={formData.additionalHours}
                                            onChange={handleInputChange}
                                            error={errors.additionalHours}
                                        />
                                    </Stack>
                                </Stack>
                                <Form.Check
                                    type='checkbox'
                                    className='buyer-checkbox'
                                    name='allow-counter-offers'
                                    id='allow-counter-offers-checkbox'
                                    label='Allow counter offers'
                                />
                            </Col>
                        </Row>
                    </>
                )}
                {formData?.selectedPaymentRate === 'hourly' && (
                    <>
                        <hr className='opacity-100 border-botticelli' />
                        <Row>
                            <Col md={3} className='basic-info__wrapper'>
                                <h6 className='title mb-0'>Maximum Hours</h6>
                                <p className='subtitle'>Indicate the maximum hours required</p>
                            </Col>
                            <Col md={9}>
                                <Input className='w-fit' name='maxHours' value={formData.maxHours} onChange={handleInputChange} error={errors.maxHours} />
                            </Col>
                        </Row>
                    </>
                )}
                {formData?.selectedPaymentRate === 'weekly' && (
                    <>
                        <hr className='opacity-100 border-botticelli' />
                        <Row>
                            <Col md={3} className='basic-info__wrapper'>
                                <h6 className='title mb-0'>Week Schedule</h6>
                                <p className='subtitle'>Indicate the start and end day of the week</p>
                            </Col>
                            <Col md={9}>
                                <Stack direction='horizontal' gap={3}>
                                    {DAYS.map((day, index) => {
                                        const isChecked = formData?.checkedDays.includes(day)
                                        return (
                                            <div
                                                key={index}
                                                className={`week-day__wrapper ${isChecked ? 'checked' : 'unchecked'}`}
                                                onClick={() => handleDayChange(day)}
                                            >
                                                {day.charAt(0)}
                                            </div>
                                        )
                                    })}
                                </Stack>
                            </Col>
                        </Row>
                    </>
                )}
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Payment Terms</h6>
                        <p className='subtitle'>Select the terms for payment</p>
                    </Col>
                    <Col md={9}>
                        <Select
                            name='paymentTerms'
                            inputId='paymentTerms'
                            htmlFor='paymentTerms'
                            placeholder='Select Payment Terms'
                            className='w-50'
                            options={PAYMENT_TERMS_OPTIONS}
                            value={formData?.paymentTerms}
                            error={errors?.paymentTerms}
                            onChange={(paymentTerms) => handleSelectInputChange(paymentTerms, 'paymentTerms')}
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <div className='payment-table__wrapper'>
                    <Table data={data} columns={columns} />
                </div>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Job Status</h6>
                        <p className='subtitle'>Indicate the status of the job</p>
                    </Col>
                    <Col md={9}>
                        <ReactSelect
                            menuPlacement="auto"
                            isSearchable={false}
                            className='w-fit'
                            name='jobStatus'
                            value={formData?.jobStatus}
                            error={errors?.jobStatus}
                            onChange={(jobStatus) => handleSelectInputChange(jobStatus, 'jobStatus')}
                            options={JOB_STATUS_OPTIONS}
                            styles={dashboardFilterDropdownStyles}
                            placeholder="Select Job Status"
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>
                            Enable SendRodin {''}
                            <HiInformationCircle size={20} className='color-grey-500' />
                        </h6>
                        <p className='subtitle'>Enable the SendRobin feature</p>
                    </Col>
                    <Col md={9}>
                        <Form.Check
                            type='checkbox'
                            className='buyer-checkbox'
                            checked={formData?.sendRobin}
                            onChange={(e) => {
                                setFormData(prev => ({
                                    ...prev,
                                    sendRobin: e.target.checked
                                }))
                            }}
                            name='sendRobin'
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>
                            Enable Add Expense {''}
                            <HiInformationCircle size={20} className='color-grey-500' />
                        </h6>
                        <p className='subtitle'>Enable the add expense feature</p>
                    </Col>
                    <Col md={9}>
                        <Form.Check
                            type='checkbox'
                            className='buyer-checkbox'
                            checked={formData?.addExpense}
                            onChange={(e) => {
                                setFormData(prev => ({
                                    ...prev,
                                    addExpense: e.target.checked
                                }))
                            }}
                            name='addExpense'
                        />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Additional documents</h6>
                        <p className='subtitle'>Add relevant documents to help the job post</p>
                    </Col>
                    <Col md={9}>
                        <PDFandCSVUploader name='documents' onDrop={onDrop} className='w-50' />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Stack direction='horizontal' className='justify-content-between'>
                    <Button
                        type='button'
                        variant='outline'
                        className='fw-semibold px-3 color-ebony padded-border-button'
                    // onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Stack direction='horizontal' className='gap-3'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={() => setShowSaveTemplate(true)}
                        >
                            Save as draft
                        </Button>
                        <Button
                            type='submit'
                            disabled={!isValid}
                            className='fw-semibold py-14px'
                        >
                            Publish
                        </Button>
                    </Stack>
                </Stack>
            </Form>
            <SaveTemplate show={showSaveTemplate} handleClose={() => setShowSaveTemplate(false)} />
        </Fragment>
    )
}
