import React, { useMemo } from "react";
import { Table } from "../../../../../components/common/table/Table";
import { ListGroup, NavDropdown } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";

export const AlleCheck = () => {
  const Columns = useMemo(
    () => [
      {
        accessorKey: "paymentMethod",
        header: () => "Payment Method",
        cell: (info) => <span>{info.renderValue()}</span>,
        // enableSorting: false
      },
      {
        accessorKey: "cardAccount",
        header: () => "Card/Account",
        cell: (info) => <span className="">{info.renderValue()}</span>,
        // enableSorting: false
      },
      {
        accessorKey: "dateAdded",
        header: () => "Date Added",
        cell: (info) => <span>{info.renderValue()}</span>,
      },
      {
        accessorKey: "lastUsed",
        header: () => "Last Used",
        cell: (info) => <span className="last-used">{info.renderValue()}</span>,
        // enableSorting: false
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Edit
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Delete
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );
  const Rows = [
    {
      paymentMethod: "eCheck",
      cardAccount: "377888199",
      dateAdded: "August 12, 2024",
      lastUsed: "12/24/2024 | 12:23PM",
    },
  ];

  return (
    <div className="px-1 mb-3">
      <label className="table-name">All eCheck</label>
      <div className="mt-2">
        <Table columns={Columns} data={Rows} />
      </div>
    </div>
  );
};
