import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { NotFoundCard } from '../../../../components/profile/not-found/NotFoundCard'
import { InviteMember } from '../../../../components/profile/modals/InviteMember'
import { Table } from '../../../../components/common/table/Table'
import { Button } from '../../../../components/common/button/Button'
import { ListGroup, NavDropdown, Stack } from 'react-bootstrap'
import moment from 'moment'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { MemberDetails } from '../../../../components/profile/offcanvas/MemberDetails'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTeamMember, listTeamMembers } from '../../../../store/buyer/team-member/team-member.action'
import Avatar from 'react-avatar'
import { Loader } from '../../../../components/common/Loader'

export const ManageMembers = () => {

    const dispatch = useDispatch()
    const MEMBERS = useSelector(state => state.teamMemberReducer.teamMembers)
    const isLoading = useSelector(state => state.teamMemberReducer.isLoading)

    const [showInvite, setShowInvite] = useState(false)
    const [showMemberDetails, setShowMemberDetails] = useState(false)
    const [memberToEdit, setMemberToEdit] = useState(null)

    const handleDelete = (index) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this user?');
        if (confirmDelete) {
            dispatch(deleteTeamMember(index));
            setTimeout(() => {
                dispatch(listTeamMembers());
            }, 1000);
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: () => 'Name',
                cell: ({ row }) => (
                    <Stack direction='horizontal' className='gap-2'>
                        <Avatar round size='30' name={row.original.name} />
                        <span>{row.original.name}</span>
                    </Stack>
                ),
                // enableSorting: false
            },
            {
                accessorKey: 'email',
                header: () => 'Email Address',
                cell: info => info.renderValue(),
                // enableSorting: false
            },
            {
                accessorKey: 'role',
                header: () => 'Role',
                cell: info => info.getValue()?.roleName,
                // enableSorting: false
            },
            {
                accessorKey: 'lastActive',
                header: () => 'Last Active',
                cell: info => <span>{moment(info.getValue()).format('DD/MM/YYYY')}</span>,
                // enableSorting: false
            },
            {
                id: 'actions',
                header: () => 'Action',
                cell: ({ row }) => {
                    const rowIndex = row.original?._id

                    return <NavDropdown
                        title={<MdOutlineMoreHoriz size={24} />}
                        style={{ width: 'fit-content' }}
                    >
                        <ListGroup variant='flush'>
                            <ListGroup.Item
                                action
                                // onClick={() => {
                                //     setMemberToEdit(row.original)
                                //     setShowMemberDetails(true)
                                // }}
                                className='cursor-pointer color-gray-700 border-bottom-0'
                            >
                               Resend Invite
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                onClick={() => {
                                    setMemberToEdit(row.original)
                                    setShowMemberDetails(true)
                                }}
                                className='cursor-pointer color-gray-700 border-bottom-0'
                            >
                                Edit Role
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                onClick={() => handleDelete(rowIndex)}
                                className='cursor-pointer color-gray-700'
                            >
                                Delete
                            </ListGroup.Item>
                        </ListGroup>
                    </NavDropdown>
                },
            },
        ],
        []
    )

    useEffect(() => {
        dispatch(listTeamMembers())
    }, [])

    return (
        <MainDefault containerClasses={[""].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
            {isLoading ? (
                <Loader />
            ) : MEMBERS.length <= 0 ? (
                <Fragment>
                    <h4 className='mb-0 fw-bold topHead'>Manage Members</h4>
                    <NotFoundCard
                        title='No member added'
                        subTitle='You can invite members here'
                        buttonText='Invite member'
                        onButtonClick={() => setShowInvite(true)}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <Stack
                        direction='horizontal'
                        className='top-nav justify-content-between py-3 mb-3'
                    >
                        <h4 className='mb-0'>Manage Members</h4>
                        <Button 
                            className='px-3'
                            style={{ paddingBlock: 12 }} onClick={() => setShowInvite(true)}>Invite member</Button>
                    </Stack>
                    <Table data={MEMBERS} columns={columns} />
                </Fragment>
            )}
            <InviteMember
                show={showInvite}
                handleClose={() => setShowInvite(false)}
            />
            <MemberDetails
                show={showMemberDetails}
                handleClose={() => setShowMemberDetails(false)}
                memberData={memberToEdit}
            />
        </MainDefault>
    )
}