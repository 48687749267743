import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button } from '../../common/button/Button'
import { Input } from '../../common/input/Input'
import { Select } from '../../common/select/Select'
import { showToaster } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { inviteTeamMember, listTeamMembers } from '../../../store/buyer/team-member/team-member.action'
import { fetchBuyerRoles } from '../../../store/buyer/roles/roles.action'
import { emailPattern } from '../../../data/constants'

export const InviteMember = ({ show, handleClose }) => {

    const dispatch = useDispatch()
    var roleOptions = useSelector(state => state.rolesReducer.roles)
    roleOptions = roleOptions.map(role => ({ label: role.roleName, value: role._id }))

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        role: null
    })

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        role: ''
    })

    const validateField = (name, value) => {
        var error = '';
        if(name === 'name') {
            if (!value.trim()) {
                error = 'Name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Name cannot contains special characters';
            }
        } else if (name === 'email') {
            if (!value) {
                error = 'Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Email address is invalid';
            }
        } else if (name === 'role' && !value) {
            error = 'Role is required';
        }
        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    }

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.keys(formData).every(key => formData[key] !== '' && formData[key] !== null)

    const handleInviteClose = () => {
        setFormData({
            email: '',
            role: null
        })
        setErrors({
            email: '',
            role: ''
        })
        handleClose()
    }

    const handleInvite = (e) => {
        e.preventDefault()

        const newErrors = {
            email: '',
            role: ''
        }

        if (!formData.name) newErrors.name = 'Name is required'
        if (!formData.email) {
            newErrors.email = 'Email is required'
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Email address is invalid'
        }
        if (!formData.role) newErrors.role = 'Role is required'
        setErrors(newErrors)

        if (isFormValid()) {
            try {
                const data = {
                    ...formData,
                    role: formData?.role?.value
                }
                dispatch(inviteTeamMember(data, { showLoader: 'invite-button' }))
                setTimeout(() => {
                    dispatch(listTeamMembers());
                }, 1000);
                handleInviteClose()
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if(show) dispatch(fetchBuyerRoles())
    }, [show])

    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleInviteClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Invite Member</h5>
                    <p>You can invite member</p>
                </div>
                <Form onSubmit={handleInvite}>
                    <Input
                        label='Name'
                        placeholder='Enter your name'
                        className='mb-2'
                        name='name'
                        id='name'
                        htmlFor='name'
                        value={formData?.name}
                        onChange={handleChange}
                        error={errors?.name}
                    />
                    <Input
                        label='Email Address'
                        placeholder='Enter email address'
                        className='mb-2'
                        type='email'
                        name='email'
                        id='email'
                        htmlFor='email'
                        value={formData?.email}
                        onChange={handleChange}
                        error={errors?.email}
                    />
                    <Select
                        label='Role'
                        placeholder='Select one'
                        className='mb-3'
                        options={roleOptions}
                        name='role'
                        inputId='role'
                        htmlFor='role'
                        value={formData?.role}
                        onChange={(role) => handleSelectChange(role, 'role')}
                        error={errors?.role}
                    />
                    <Button id='invite-button' disabled={!isFormValid()} className='py-3 w-100'>
                        Invite
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
