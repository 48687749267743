export const COMMON_IMG = require("../assets/images/common-img.png");
export const USER = require("../assets/images/user.png");
export const DIAMOND = require("../assets/images/diamond.png");
export const AVATAR = require("../assets/images/Avatar.png");
export const DOCU_SIGN = require("../assets/images/docu-sign.png");
export const DROPBOX_SIGN = require("../assets/images/drop-box-sign.png");
export const ADOBE_SIGN = require("../assets/images/adobe-sign.png");
export const MESSAGE_IMG = require("../assets/images/message.png");
export const FUND_ADDED = require("../assets/images/fund_added.png");
export const APPROVAL_PENDING = require("../assets/images/approvalP-past-dueP.png");
export const CURRENT_PAYABLES = require("../assets/images/current-payables.png");
export const CASH_BALANCE = require("../assets/images/cash-balance.png");
export const AVAILABLE_FUNDS = require("../assets/images/available-funds.png");
export const SUCCESS = require("../assets/images/success.png");
export const CV = require("../assets/images/YourName_CV.png");
export const JOB_ORDER_CONTACT = require("../assets/images/job-order-contact.png");
export const WOMAN_TALKING_WITH_SUPPORT_CHATBOT = require("../assets/images/Woman talking to a support chatbot.png");
export const YOUNG_PEOPLE_WORKING = require("../assets/images/young people working.png");
export const MASTER_CARD = require("../assets/images/master-card.png");
export const CHECKR = require("../assets/images/checkr.png");
