import React, { useMemo, useState } from "react";
import { MainDefault } from "../../../../components/common/Default";
import { Table } from "../../../../components/common/table/Table";
import "../buyerProfile.css";
import { ListGroup, NavDropdown } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { NotFoundCard } from "../../../../components/profile/not-found/NotFoundCard";
import { SetLimit } from "../../../../components/profile/modals/SetLimit";
import { CreateLimit } from "../../../../components/profile/modals/CreateLimit";

export const CompanySpendingLimit = () => {
  const [showLimit, setShowLimit] = useState(false);
  const [showCreateLimit, setShowCreateLimit] = useState(false);


  const columns = useMemo(
    () => [
      {
        accessorKey: "user",
        header: () => "User",
        cell: (info) => <span>W9 NEC Report</span>,
        // enableSorting: false
      },
      {
        accessorKey: "limitAccount",
        header: () => "Limit Account",
        cell: (info) => <span className="">Omolola Wawu</span>,
        // enableSorting: false
      },

      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => setShowLimit(true)}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Changing Limit
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Remove
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      documentType: "Plumber",
      creator: "Birmingham, United Kingdom",
      dateCreator: "Active",
    },
  ];

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4">
        <h4 className="mb-0">Spending Limit</h4>
      </div>
      {/* <Table columns={columns} data={rows} /> */}
      <NotFoundCard
        title="No Limit created"
        subTitle="Create a limit for any desired user"
        buttonText="Create a Limit"
        onButtonClick={() => setShowCreateLimit(true)}
      />

      <SetLimit show={showLimit} handleClose={() => setShowLimit(false)} />

      <CreateLimit
        show={showCreateLimit}
        handleClose={() => setShowCreateLimit(false)}
      />
    </MainDefault>
  );
};
