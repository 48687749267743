import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Button } from "../../common/button/Button";
import { Input } from "../../common/input/Input";
import { Select } from "../../common/select/Select";

export const CreateLimit = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    user: null,
    limitAmount: "",
  });

  const [errors, setErrors] = useState({
    user: "",
    limitAmount: "",
  });

  const validateField = (name, value) => {
    let error = "";

    if (name === "limitAmount") {
      if (!value) {
        error = "Limit amount is required.";
      } else if (isNaN(value) || Number(value) <= 0) {
        error = "Limit amount must be a positive number.";
      }
    } else if (name === "user") {
      if (!value) {
        error = "User selection is required.";
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    const value = selectedOption?.value || null;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption || null,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      formData.user &&
      formData.limitAmount
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      user: validateField("user", formData.user?.value),
      limitAmount: validateField("limitAmount", formData.limitAmount),
    };

    setErrors(newErrors);

    if (isFormValid()) {
      console.log("Submitted Data:", {
        user: formData.user.value,
        limitAmount: formData.limitAmount,
      });
      handleClose();
    }
  };

  const handleFormClose = () => {
    setFormData({ user: null, limitAmount: "" });
    setErrors({ user: "", limitAmount: "" });
    handleClose();
  };

  const userOptions = [
    { label: "User 1", value: "user1" },
    { label: "User 2", value: "user2" },
    { label: "User 3", value: "user3" },
  ];

  return (
    <Modal
      size="sm"
      dialogClassName="invite-dialog"
      show={show}
      onHide={handleFormClose}
      centered
    >
      <Modal.Body>
        <div className="mb-3">
          <h5 className="text-black fw-semibold">Create Limit</h5>
          <p>You can create a limit for a selected user.</p>
        </div>
        <Form onSubmit={handleSubmit}>
          <Select
            label="User"
            placeholder="Select a user"
            className="mb-3"
            options={userOptions}
            name="user"
            inputId="user"
            htmlFor="user"
            value={formData?.user}
            onChange={(user) => handleSelectChange(user, "user")}
            error={errors?.user}
          />
          <Input
            label="Limit Amount"
            placeholder="Enter amount"
            className="mb-2"
            name="limitAmount"
            id="limitAmount"
            htmlFor="limitAmount"
            value={formData?.limitAmount}
            onChange={handleChange}
            error={errors?.limitAmount}
          />
          <Button
            id="submit-button"
            disabled={!isFormValid()}
            className="py-3 w-100"
          >
            Limit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
