import React from 'react'
import { MainDefault } from '../../../../../components/common/Default'
import { NewWODefault } from '../../../../../components/buyer/work-orders/creation/Default'
import { InviteProviderForm } from '../../../../../components/buyer/work-orders/creation/invite-provider/InviteProviderForm'
import { useLocation } from 'react-router-dom'

export const InviteProvider = () => {

    const location = useLocation()
    const mode = location.state?.mode

    return (
        <MainDefault>
            <NewWODefault mode={mode}>
                <InviteProviderForm />
            </NewWODefault>
        </MainDefault>
    )
}
