// import { Form } from 'react-bootstrap'
import ReactSelect from 'react-select'
import ReactCreatable from 'react-select/creatable'
import './select.css'
import { selectStyles } from '../../../data/constants';

export const Select = ({
  star,
  htmlFor,
  className,
  label,
  error,
  options,
  newStyles,
  newControlStyle,
  ...rest
}) => {
  const customStyles = {
    ...selectStyles,
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      borderColor: state.isFocused || state.menuIsOpen ? "#920AF2" : "#E2E8F0",
      padding: "10px",
      boxShadow: "none",
      ":hover": {
        borderColor: state.menuIsOpen ? "#920AF2" : "#E2E8F0",
      },
      ...newControlStyle,
    }),
  };

  return (
    <div className={className}>
      {label && (
        <label className="form-label line-height-160" htmlFor={htmlFor || ""}>
          {label} {star &&  <span className="star ps-1">*</span>}
        </label>
      )}
      <ReactSelect
        options={options}
        menuPlacement="auto"
        isSearchable={false}
        styles={{ ...customStyles, ...newStyles }}
        {...rest}
      />
      {/* <Form.Select className='form-input' {...rest}>
                <option className='d-none'>Select one</option>
                {options?.map(opt => {
                    return <option value={opt.value} key={opt.value}>{opt.label}</option>
                })}
            </Form.Select> */}
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    )
}

export const Creatable = ({ htmlFor, className, label, error, options, newStyles, newControlStyle, ...rest }) => {
    
    const customStyles = {
        ...selectStyles,
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#F8F8FD',
            padding: '4px',
            gap: 4
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#920AF2',
            padding: 0,
            paddingLeft: 0
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#920AF2',
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: '5px 5px 50px 0px rgba(26, 32, 44, 0.06)'
        }),
        menuList: (provided) => ({
            ...provided,
            scrollbarWidth: 'none'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: '#0000',
            color: state.isFocused ? 'black' : '#667185',
            ':active': {
                backgroundColor: '#0000', // '#920AF2'
            }
        })
    }
    
    return (
        <div className={className}>
            {label && <label
                className='form-label line-height-160'
                htmlFor={htmlFor || ''}
            >
                {label}
            </label>
            }
            <ReactCreatable
                options={options}
                isMulti
                menuPlacement='auto'
                isSearchable={true}
                closeMenuOnSelect={false}
                styles={customStyles}
                {...rest}
            />
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    )
}
