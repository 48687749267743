import React, { useMemo, useState } from "react";
import { Table } from "../../../../components/common/table/Table";
import { USER } from "../../../../assets/images.assets";
import { ListGroup, NavDropdown, Offcanvas } from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Underline from "../../../../components/buyer-provider/Underline";
import { CardWrapper } from "../../../../components/common/Default";
import { AddEditClientForm } from "../add-edit-client-form/AddEditClientForm";

// Register Chart.js components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export const ClientDetailsTable = () => {
  const [viewReportShowModel, setViewReportShowModel] = useState(false);
  const [manageClientModelShow, setManageClientModelShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: () => "Name",
        cell: (info) => (
          <div className="d-flex align-items-center gap-2 client-profile">
            <img src={USER} />
            <p>{info.renderValue()}</p>
          </div>
        ),
      },
      {
        accessorKey: "emailAddress",
        header: () => "Email Address",
        cell: (info) => (
          <span className="client-data">{info.renderValue()}</span>
        ),
      },
      {
        accessorKey: "address",
        header: () => "Address",
        cell: (info) => (
          <span className="client-data">{info.renderValue()}</span>
        ),
      },
      {
        accessorKey: "phoneNumber",
        header: () => "Phone Number",
        cell: (info) => (
          <span className="client-data">{info.renderValue()}</span>
        ),
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => setManageClientModelShow(true)}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Manage
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => setViewReportShowModel(true)}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  View Report
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Delete
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      name: "Adeleke Fajuyi Olayiwola",
      emailAddress: "Brooklyn, London",
      address: "Brooklyn, London",
      phoneNumber: "08092139441",
    },
    {
      name: "Johnson Nnamani Paul",
      emailAddress: "johnsonnamani@gmail.com",
      address: "johnsonnamani@gmail.com",
      phoneNumber: "08092139442",
    },
    {
      name: "Miracle Obafemi Paul",
      emailAddress: "obafemi5@gmail.com",
      address: "obafemi5@gmail.com",
      phoneNumber: "08092139443",
    },
    {
      name: "Chinedu Surprise Costa",
      emailAddress: "surprisecosta@gmail.com",
      address: "surprisecosta@gmail.com",
      phoneNumber: "08092139444",
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom", // Place the legend at the bottom
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: "Rounded",
        },
      },
      // title: {
      //   display: true,
      //   text: "Work Order Summary",
      // },
    },
    scales: {
      y: {
        beginAtZero: true,
        // min: 0,
        // max: 10,
        ticks: {
          stepSize: 1.25,
          callback: function (value) {
            const valuesToShow = [10, 8.75, 7.5, 6.25, 5, 3.75, 2.5, 1.25, 0];
            return valuesToShow.includes(value) ? value : "";
          },
        },
      },

      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Published Work Order",
        data: [7.5, 8.75, 6.3, 9.2, 10, 8.5, 5.4, 6.7, 8.1, 9.9, 10, 9.3],
        backgroundColor: "#920AF2",
      },
      {
        label: "In-Progress Work Order",
        data: [6.0, 7.2, 8.3, 5.5, 7.6, 6.8, 4.9, 5.2, 7.7, 8.8, 9.1, 8.4],
        backgroundColor: "#90BE6D",
      },
      {
        label: "Completed Work Order",
        data: [5.5, 6.5, 7.0, 8.0, 9.0, 7.8, 5.6, 6.0, 7.5, 8.6, 9.2, 9.8],
        backgroundColor: "#2D9CDB",
      },
    ],
  };

  const typesOfWorkOrder = [
    {
      type: "Published Work Order",
      count: 12,
    },
    {
      type: "In-Progress Work Order",
      count: 12,
    },
    {
      type: "Completed Work Order",
      count: 12,
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic for editing a client
  };

  return (
    <div>
      <Table columns={columns} data={rows} />
      <RightOffcanvas
        show={viewReportShowModel}
        handleClose={() => setViewReportShowModel(false)}
        className="client-heading"
      >
        <div className="form-heading p-3">
          <h6>Client Report</h6>
          <p>Here is the report of the client</p>
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <h4>Work Order Summary</h4>

          <Underline className="mt-2" />

          <div className="mt-5">
            <Bar options={options} data={data} />
          </div>

          <div className="mt-4">
            {typesOfWorkOrder.map((type) => (
              <div className="mt-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>{type.type}</h4>
                  <h3>{type.count}</h3>
                </div>
                <Underline className="mt-3" />
              </div>
            ))}
          </div>

          <div className="mt-4">
            <h4>Expenses Breakdown</h4>
            <Underline className="mt-3" />
          </div>

          <div className="mt-4">
            <CardWrapper>
              <div className="total-revenue-and-expenses p-3 d-flex align-items-center justify-content-between">
                <p>Total Revenue</p>
                <p>$10,000</p>
              </div>
            </CardWrapper>
            <div className="mt-3">
              <CardWrapper>
                <div className="total-revenue-and-expenses p-3 d-flex align-items-center justify-content-between">
                  <p>Total Expenses</p>
                  <p>$23,000</p>
                </div>
              </CardWrapper>
            </div>
          </div>
        </Offcanvas.Body>
      </RightOffcanvas>

      <RightOffcanvas
        show={manageClientModelShow}
        handleClose={() => setManageClientModelShow(false)}
      >
        <div className="form-heading p-3">
          <h6>Edit Client</h6>
          <p>You can update client details here</p>
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <AddEditClientForm
            handleSubmit={handleSubmit}
            setManageClientModelShow={setManageClientModelShow}
            actionLabel="Save Changes"
          />
        </Offcanvas.Body>
      </RightOffcanvas>
    </div>
  );
};
