import React, { useState } from "react";
import {
  CardWrapper,
  MainDefault,
} from "../../../../components/common/Default";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/common/button/Button";
import { FiPlus } from "react-icons/fi";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { SupportCard } from "../../../../components/buyer-provider/common-support/SupportCard";
import { WOMAN_TALKING_WITH_SUPPORT_CHATBOT } from "../../../../assets/images.assets";
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import Underline from "../../../../components/buyer-provider/Underline";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { SuggestionDetails } from "./suggestion-details/SuggestionDetails";
import { AddSuggestion } from "./add-suggestion/AddSuggestion";
import { useDispatch, useSelector } from "react-redux";
import { createTicket } from "../../../../store/provider/provider.action";
import { AddEditTicket } from "../../../../components/buyer-provider/common-support/add-edit-ticket/AddEditTicket";

export const AllSuggestions = () => {
  const [suggestionDetails, setSuggestionDetails] = useState(false);
  const [addSuggestion, setAddSuggestion] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _createTicket = useSelector(
    (state) => state.providerReducer.createTicketOffCanvas
  );

  const handleOpenCreateTicket = () => {
    console.log("hhhh");
    dispatch(createTicket(true));
  };
  const handleClose = () => {
    dispatch(createTicket(false));
  };

  return (
    <>
      <MainDefault className="bg-grayscale-50">
        <div className="p-3">
          <div className="d-flex align-items-center gap-2 cursor-pointer">
            <MdOutlineKeyboardBackspace
              onClick={() => navigate(-1)}
              size={32}
            />
            <label className="back-slide-label">Back to Support</label>
          </div>

          <div className="d-flex align-items-center justify-content-between mt-4">
            <h6 className="font-24 line-height-130 fw-bold">All Suggestions</h6>

            <div>
              <Button
                type="submit"
                className="fw-semibold py-3 px-3"
                onClick={() => setAddSuggestion(true)}
              >
                <FiPlus size={20} className="me-1" />
                <span className="lh-1">Raise a Suggestion</span>
              </Button>
            </div>
          </div>

          <Row className="mt-5">
            <Col sm={8}>
              {Array.from({ length: 4 }).map(() => {
                return (
                  <div
                    className="mb-3 all-suggestion-wrapper cursor-pointer"
                    onClick={() => setSuggestionDetails(true)}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="font-14 color-grey-800">Ariel John</p>
                        <p className="font-16 color-grey-800 pt-1">
                          Discover web3 talent and recruiters together in one
                          place
                        </p>
                        <p className="font-11 color-grey-800 font-italic pt-3">
                          Posted 3 mins ago
                        </p>
                      </div>

                      <div className="text-center card-like-dislike">
                        <IoMdThumbsUp size={14} color="#920af2" />
                        <p className="fw-bold font-12 like-count">161</p>
                        <Underline className="mt-1" />
                        <IoMdThumbsDown size={14} color="#920af2" />
                        <p className="fw-bold font-12 like-count">24</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col sm={4}>
              <SupportCard
                flexColReverse
                heading="Need an Issue fixed?"
                description="You can create support tickets where issues can be
                    fixed"
                image={WOMAN_TALKING_WITH_SUPPORT_CHATBOT}
                isTicket
                setCreateTicket={handleOpenCreateTicket}
              ></SupportCard>
            </Col>
          </Row>
        </div>
      </MainDefault>

      <Offcanvas
        show={suggestionDetails}
        onHide={setSuggestionDetails}
        placement="end"
        style={{ overflowY: "auto", maxHeight: "100vh" }}
        keyboard={true}
        backdrop={true}
        className="w-25"
      >
        <div className="p-3">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Suggestion Details
          </h6>
          <p className="font-16 color-gray-700">Here is our open community</p>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <SuggestionDetails />
        </Offcanvas.Body>
      </Offcanvas>

      <RightOffcanvas show={addSuggestion} onHide={setAddSuggestion}>
        <div className="p-3">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Raise a Suggestion
          </h6>
          <p className="font-16 color-gray-700">Here is our open community</p>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <AddSuggestion setAddSuggestion={setAddSuggestion} />
        </Offcanvas.Body>
      </RightOffcanvas>

      <RightOffcanvas
        show={_createTicket}
        onHide={handleClose}
        className="w-25"
      >
        <div className="p-3">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Create Ticket
          </h6>
          <p className="font-16 color-gray-700">
            You can create an open ticket here
          </p>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <AddEditTicket handleClose={handleClose} mode="create" />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
