import { Col, Row, Stack } from "react-bootstrap";
import { CardWrapper } from "../../../common/Default";
import { ReactComponent as Business } from "../../../../assets/images/business.svg";
import { HiDotsVertical, HiOutlineLocationMarker } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import PublishedContentTable from "./PublishedContentTable";
import { MyTooltip } from "../../../common/tooltip/MyTooltip";
import { ViewCalendar } from "../../../buyer-provider/view-calendar/ViewCalendar";

export const TemplatesContent = () => {
  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);
  const events = [
    {
      title: "Plumber",
      start: new Date().toISOString().split("T")[0] + "T07:00:00",
      location: "Birmingham, United Kingdom",
      charge: "$500",
    },
  ];
  return (
    <Fragment>
      {_activeTabLayout === "list" ? (
        <PublishedContentTable />
      ) : _activeTabLayout === "cards" ? (
        <Row className="row-gap-4">
          <Col md={3}>
            <TemplatesCard />
          </Col>
          <Col md={3}>
            <TemplatesCard />
          </Col>
          <Col md={3}>
            <TemplatesCard />
          </Col>
          <Col md={3}>
            <TemplatesCard />
          </Col>
        </Row>
      ) : (
        <ViewCalendar events={events} headerToolbar={false} />
      )}
    </Fragment>
  );
};

const TemplatesCard = () => {

  return (
    <CardWrapper>
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-center mb-2"
      >
        <MyTooltip content={<span className="fs-6">This is a series work order</span>}>
          <div className="position-relative">
            <div className="semi-circle"></div>
            <Business className="position-relative" />
          </div>
        </MyTooltip>
        <HiDotsVertical className="color-grey-500 cursor-pointer" />
      </Stack>
      <span className="color-grey-500 font-10 fw-medium">#1233944</span>
      <p className="font-14 fw-semibold line-clamp-2">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente culpa
        temporibus modi tenetur rerum aspernatur!
      </p>
      <Stack direction="horizontal" className="justify-content-between my-2">
        <div className="d-flex align-items-center color-grey-500 gap-1">
          <HiOutlineLocationMarker />
          <span
            className="font-12 lh-1 text-truncate"
            style={{ maxWidth: 120 }}
          >
            Birmingham, United Kingdom
          </span>
        </div>
        <span className="job-status badge-status__active">ACTIVE</span>
      </Stack>
      <p className="font-12 color-grayscale-500 line-clamp-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. In recusandae
        eum maxime sapiente impedit molestiae.
      </p>
      <div>
        <span className="me-1 card-tag__fixed rounded color-grey-900">
          Plumbing
        </span>
        <span className="me-1 card-tag__fixed rounded color-grey-900">
          Communication
        </span>
        <span className="color-primary font-10">1 more</span>
      </div>
      <hr className="opacity-100 border-grayscale-100" />
      <Stack direction="horizontal" className="justify-content-between">
        <h6 className="text-black fw-bold mb-0">$500</h6>
        <span className="color-pale-sky font-10">Posted 3m ago</span>
      </Stack>
    </CardWrapper>
  );
};
