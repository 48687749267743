import { Container, Form, Nav, Navbar } from "react-bootstrap";
import "./header.css";
import { FiHelpCircle } from "react-icons/fi";
import {
  MessageDropdown,
  NotificationDropdown,
  ProfileDropdown,
  WalletDropdown,
} from "./nav-dropdown";
import { IoSearch } from "react-icons/io5";
import DashboardSearchComponent from "../../dashboard/dashboard-search/DashboardSearchComponent";
import { useEffect, useRef, useState } from "react";

export const Header = () => {
  const modalRef = useRef(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowSearchModal(false);
      }
    };

    if (showSearchModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showSearchModal]);

  return (
    <Navbar expand="lg" className="bg-white border-bottom sticky-top">
      <Container fluid>
        <Navbar.Brand className="position-relative">
          <IoSearch className="form-control__search-icon color-grey-500" />
          <Form.Control
            type="text"
            onClick={() => setShowSearchModal(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            className="form-control__search"
          />
        </Navbar.Brand>

        {showSearchModal && (
          <DashboardSearchComponent search={search} modalRef={modalRef} />
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <WalletDropdown />
            <div className="vr align-self-center" style={{ height: 40 }} />
            <Nav.Link href="#link">
              <FiHelpCircle size={20} color="#667185" />
            </Nav.Link>
            <MessageDropdown />
            <NotificationDropdown />
            <ProfileDropdown />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
