import React, { Fragment, useState } from 'react'
import { CloseButton, Offcanvas, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { TbMessage2 } from 'react-icons/tb'
import { HiCheckCircle } from 'react-icons/hi2'
import Rating from 'react-rating'
import { MdStar } from 'react-icons/md'
import { AddToPool } from '../modals/AddToPool'

export const QuickProfileDetails = ({ show, handleClose }) => {
    
    const [showAddToPool, setShowAddToPool] = useState(false)
    
    return (
        <Fragment>
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Body>
                <Stack direction='horizontal' className='align-items-start' gap={2}>
                    <img src='https://contentwriter.co/images/avatars/1708227404.jpg' className='card-image__invite-provider' alt='' />
                    <div>
                        <Stack direction='horizontal' gap={2}>
                            <p className='font-14 color-ebony fw-semibold'>Ademola Adetunji</p>
                            <span className='job-status badge-status__active'>AVAILABLE</span>
                        </Stack>
                        <p className='font-12 color-grey-500'>Amsterdam, UK</p>
                        <span className="card-tag__fixed rounded-2 font-12 color-grey-900">15 miles away</span>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold color-ebony py-2 padded-border-button mt-2'
                        // onClick={() => setShowSaveTemplate(true)}
                        >
                            <TbMessage2 className='me-1' />
                            <span className='font-12'>Message Ademola</span>
                        </Button>
                    </div>
                    <CloseButton onClick={handleClose} className='ms-auto' />
                </Stack>
                <div className='font-14 my-3'>
                    <p className='text-black fw-medium'>Screenings</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <Stack direction='horizontal' className='justify-content-between gap-2'>
                        <p className='color-grey-500 w-50'>Background Screening</p>
                        <HiCheckCircle size={18} className='color-green' />
                        <p className='color-grey-500'>13/04/2024</p>
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between gap-2'>
                        <p className='color-grey-500 w-50'>Drug Screening</p>
                        <HiCheckCircle size={18} className='color-green' />
                        <p className='color-grey-500'>13/04/2024</p>
                    </Stack>
                </div>
                <div className='font-14 mb-2'>
                    <p className='text-black fw-medium'>Summary Ratings</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <Stack direction='horizontal' className='justify-content-between w-75 gap-2'>
                        <p className='color-grey-500'>Jobs Completed</p>
                        <p className='color-grey-700 fw-semibold'>40 jobs</p>
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between w-75 gap-2'>
                        <p className='color-grey-500'>Timeliness</p>
                        <Rating
                            fullSymbol={<MdStar size={18} color='#FB9905' />}
                            emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                            initialRating={4}
                            readonly
                        />
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between w-75 gap-2'>
                        <p className='color-grey-500'>Professionalism</p>
                        <Rating
                            fullSymbol={<MdStar size={18} color='#FB9905' />}
                            emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                            initialRating={4}
                            readonly
                        />
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between w-75 gap-2'>
                        <p className='color-grey-500'>Quality of work</p>
                        <Rating
                            fullSymbol={<MdStar size={18} color='#FB9905' />}
                            emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                            initialRating={4}
                            readonly
                        />
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between w-75 gap-2'>
                        <p className='color-grey-500'>Customer service</p>
                        <Rating
                            fullSymbol={<MdStar size={18} color='#FB9905' />}
                            emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                            initialRating={4}
                            readonly
                        />
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-between w-75 gap-2'>
                        <p className='color-grey-500'>Overall satisfaction</p>
                        <Rating
                            fullSymbol={<MdStar size={18} color='#FB9905' />}
                            emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                            initialRating={4}
                            readonly
                        />
                    </Stack>
                </div>
                <div className='font-14 my-3'>
                    <p className='text-black fw-medium'>Skills/Expertise Tags</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <Stack direction='horizontal' className='flex-wrap gap-1 skills__wrapper'>
                        <span className='skill'>Plumbing</span>
                        <span className='skill'>Carpentry</span>
                        <span className='skill'>Build</span>
                        <span className='skill'>Hardwork</span>
                        <span className='skill'>Hardwork</span>
                    </Stack>
                </div>
                <div className='font-14 my-3'>
                    <p className='text-black fw-medium'>Job Category</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <Stack direction='horizontal' className='flex-wrap gap-1 skills__wrapper'>
                        <span className='skill'>Plumbing</span>
                        <span className='skill'>Carpentry</span>
                        <span className='skill'>Build</span>
                        <span className='skill'>Hardwork</span>
                        <span className='skill'>Hardwork</span>
                    </Stack>
                </div>
                <div className='font-14 mb-3'>
                    <p className='text-black fw-medium'>Education</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <p className='color-grey-500'>B.Sc Mechanical Engineering</p>
                    <p className='color-grey-500'>B.Sc Mechanical Engineering</p>
                </div>
                <div className='font-14 mb-3'>
                    <p className='text-black fw-medium'>Licenses & Certifications</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <p className='color-grey-500'>Modelling in Steel Blending</p>
                    <p className='color-grey-500'>Artifacts in Creativity</p>
                </div>
                <div className='font-14 mb-3'>
                    <p className='text-black fw-medium'>Resume</p>
                    <hr className='opacity-100 my-2 border-grayscale-100' />
                    <p className='color-grey-500'>resume.pdf</p>
                </div>
                <Stack direction='horizontal' className='justify-content-between mt-auto' gap={4}>
                    <Button
                        type='button'
                        variant='outline'
                        className='fw-semibold px-3 color-ebony padded-border-button'
                        onClick={() => setShowAddToPool(true)}
                    >
                        Add to a pool
                    </Button>
                    <Button
                        type='submit'
                        className='fw-semibold p-3'
                    >
                        Invite to work order
                    </Button>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
        <AddToPool show={showAddToPool} handleClose={() => setShowAddToPool(false)} />
        </Fragment>
    )
}
