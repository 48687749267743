import React, { Fragment } from "react";
import "./additionalInformation.css";
import { Col, Row } from "react-bootstrap";
import { BiSolidFilePdf } from "react-icons/bi";
import { VscEye } from "react-icons/vsc";
import "./additionalInformation.css";
import { FilterButton } from "../../common/filter-button/FilterButton";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { PaymentDetails } from "../PaymentDetails";
import { CardWrapper } from "../../common/Default";
import Underline from "../../buyer-provider/Underline";
import Documents from "../../buyer-provider/Documents";
import LocationCommon from "../../buyer-provider/LocationCommon";

const position = { lat: 52.4862, lng: -1.8904 };

export const AdditionalInformation = () => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <CardWrapper className='additional-information'>
            <h6>SendRobin</h6>
            <div className="mt-3 status">ENABLED</div>
          </CardWrapper>
          <div className="mt-4">
            <Documents />
          </div>

          <div className="mt-4">
            <LocationCommon />
          </div>
        </Col>
        <Col sm={6}>
          <PaymentDetails />
        </Col>
      </Row>
    </>
  );
};
