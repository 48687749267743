import React, { useState } from "react";
import { MainDefault } from "../../../../common/Default";
import {
  declineDropDown,
  PROVIDER_WORK_DETAIL_navITEMS,
  PROVIDER_WORK_DETAIL_tabPANELS,
} from "../../../../../data/constants";
import { ScheduleDetailsCard } from "../../../../common/schedule-detalis-card/ScheduleDetailsCard";
import { FilterButton } from "../../../../common/filter-button/FilterButton";
import { FiUser, FiUsers } from "react-icons/fi";
import { MdOutlineDateRange, MdOutlineKeyboardBackspace } from "react-icons/md";
import { IoCloseOutline, IoLocationOutline } from "react-icons/io5";
import { CiCoins1 } from "react-icons/ci";
import { JobUpdates } from "../../JobUpdates";
import { Button } from "../../../../common/button/Button";
import { useNavigate } from "react-router-dom";
import { RightOffcanvas } from "../../../../buyer-provider/RightOffcanvas";
import { Select } from "../../../../common/select/Select";
import CountFilter from "./CountFilter";
import ConfirmationModal from "../../buyer/modal/ConfirmationModal";
import { MESSAGE_IMG } from "../../../../../assets/images.assets";

const ProviderWorkOrderDetails = () => {
  const navigate = useNavigate();
  const [showCount, setShowCount] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [finalMessageApply, setFinalMessageApply] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  const handelApply = () => {
    try {
      setFinalMessageApply(true);

      setTimeout(() => {
        setShowApplyModal(false);
        // setFinalMessageApply(false);
      }, 1000);
    } catch (error) {}
  };

  const handelDeclineOrder = () => {
    try {
      setShowDeclineModal(false);
    } catch (error) {}
  };

  return (
    <MainDefault
      containerClasses={[""].length <= 0 ? "bg-grayscale-50" : "bg-white"}
    >
      <div className="px-3">
        <div className="d-flex align-items-center gap-2 cursor-pointer">
          <MdOutlineKeyboardBackspace onClick={() => navigate(-1)} size={32} />
          <label className="back-slide-label">Back to Work Orders</label>
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between">
          <div>
            <div className="">
              <label className="job-name">
                Plumbing is the real and coolest gig to do
              </label>
            </div>
            <div className="mt-2 d-flex align-items-center gap-2 candidate-count">
              <div className="candidate-count">#1233944</div>
              <span>|</span>
              <FiUsers size={14} />
              <div>Century Factories</div>
            </div>
          </div>

          <div className="d-flex">
            <FilterButton
              onClick={() => setShowDeclineModal(true)}
              className={
                "me-3 fw-600 color-error color-bg-error border-none px-3"
              }
            >
              Decline{" "}
            </FilterButton>
            <FilterButton
              onClick={() => setShowCount(true)}
              className={"me-3 fw-600 px-3"}
            >
              Counter{" "}
            </FilterButton>
            <FilterButton
              onClick={() => setShowApplyModal(true)}
              className={"fw-600 color-bg-primary border-none px-3 text-white"}
            >
              Apply{" "}
            </FilterButton>
          </div>
        </div>

        <div className="mt-3 job-details">
          <div className="d-flex" style={{ gap: "250px" }}>
            <ScheduleDetailsCard
              fieldName="LOCATION"
              fieldIcon={<IoLocationOutline size={13} />}
            >
              <div className="state-name mt-1">Birmingham, United Kingdom</div>
            </ScheduleDetailsCard>
            <ScheduleDetailsCard
              fieldName="SCHEDULE"
              fieldIcon={<MdOutlineDateRange size={13} />}
            >
              <div className="deadline-commitment mt-1">Fixed</div>
              <div className="work-commit-date mt-1">03/04/2023 | 11:20PM</div>
            </ScheduleDetailsCard>
            <ScheduleDetailsCard
              fieldName="PROVIDER ASSIGNED"
              fieldIcon={<FiUser size={13} />}
            >
              <div className="deadline-commitment mt-1">NOT ASSIGNED</div>
            </ScheduleDetailsCard>
            <ScheduleDetailsCard
              fieldName="PAY RATE"
              fieldIcon={<CiCoins1 size={13} />}
            >
              <div className="mt-1">
                <div className="rate">
                  $500
                  <span className="ps-2">
                    Send
                    <span>Robin</span>
                  </span>
                </div>
                <div className="platform-and-sales-fees">
                  Platform Fees: $15
                </div>
                <div className="platform-and-sales-fees">Sales Tax: $11</div>
              </div>
            </ScheduleDetailsCard>
          </div>
        </div>
        <CountFilter showCount={showCount} setShowCount={setShowCount} />

        <JobUpdates
          navItems={PROVIDER_WORK_DETAIL_navITEMS}
          tabPanes={PROVIDER_WORK_DETAIL_tabPANELS}
        ></JobUpdates>

        {/* apply modal */}

        <ConfirmationModal
          confirmationShow={showApplyModal}
          handelClose={() => setShowApplyModal(false)}
        >
          <div className="mb-3">
            <div className="text-center">
              <img src={MESSAGE_IMG} alt="" />
            </div>
            <h5 className="text-black text-center fw-semibold font-20 mb-2 mt-3">
              {finalMessageApply
                ? "You have applied for this work order"
                : " You are about to apply for this work order"}
            </h5>
            <p className="font-16 text-center fw-regular color-gray-700 mb-3">
              {finalMessageApply ? (
                <span>
                  Your work application has submitted. You <br /> will get a
                  notice once accepted
                </span>
              ) : (
                <span>
                  You are proceeding with this application. Do you <br /> want
                  to proceed to accept.
                </span>
              )}
            </p>

            {!finalMessageApply && (
              <div className="d-flex justify-content-between mt-4">
                <Button
                  variant={"outline"}
                  onClick={() => setShowApplyModal(false)}
                  className="px-3 py-3 padded-border-button color-ebony"
                  children={"Cancel"}
                />
                <Button
                  onClick={handelApply}
                  className={" px-3 py-3"}
                  children={"Proceed"}
                />
              </div>
            )}
          </div>
        </ConfirmationModal>
        {/* decline modal */}
        <ConfirmationModal
          confirmationShow={showDeclineModal}
          handelClose={() => setShowDeclineModal(false)}
        >
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <span className="font-bold fw-bold font-24">
                Decline Work Order
              </span>
              <IoCloseOutline
                onClick={() => setShowDeclineModal(false)}
                size={30}
              />
            </div>
            <Select label={"Reason for decline"} options={declineDropDown} />

            <div className="mt-3 d-flex align-items-center gap-2">
              <input type="checkbox" />
              <span className="font-16 color-gray-600 ">
                Do not show future work from this buyer
              </span>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <Button
                variant={"outline"}
                onClick={() => setShowDeclineModal(false)}
                className="px-3 py-3 padded-border-button color-ebony"
                children={"Cancel"}
              />
              <Button
                onClick={handelDeclineOrder}
                className={" px-3 py-3"}
                children={"Decline work offer"}
              />
            </div>
          </div>
        </ConfirmationModal>
      </div>
    </MainDefault>
  );
};

export default ProviderWorkOrderDetails;
