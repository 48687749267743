import React, { useState } from "react";
import { MainDefault } from "../../../../components/common/Default";
import { Button } from "../../../../components/common/button/Button";
import { ServiceCompanyProgram } from "./become-a-service-company/ServiceCompanyProgram";
import { RequirementAndFAQs } from "./become-a-service-company/RequirementAndFAQs";
import { TermsAndCondition } from "./become-a-service-company/TermsAndCondition";
import { NewCompanyInformation } from "./become-a-service-company/NewCompanyInformation";
import { Col, Row } from "react-bootstrap";
import { Stepper } from "../../../../components/common/Stepper";
import { StepperRound } from "../../../../components/common/StepperRound";

export const MyBecomeAServiceCompany = () => {
  const [tabBecomeServiceCompany, setTabBecomeServiceCompany] = useState(
    "serviceCompanyProgram"
  );

  const steps = [
    { label: "Service Company Program", value: "serviceCompanyProgram" },
    { label: "Requirement & FAQs", value: "requirement&FAQs" },
    { label: "Terms & Condition", value: "termsAndCondition" },
  ];

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4 d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Become a Service Company</h4>

        <div style={{display: tabBecomeServiceCompany === "newCompanyInformation" ? "none" : "block"}}>
         <StepperRound steps={steps.map((s) => s.value)} currentStep={tabBecomeServiceCompany} />
        </div>
      </div>
     

      <div style={{display: tabBecomeServiceCompany === "serviceCompanyProgram" ? "block" : "none"}}>
        <ServiceCompanyProgram setTabBecomeServiceCompany={setTabBecomeServiceCompany}/>
      </div>
      <div style={{display: tabBecomeServiceCompany === "requirement&FAQs" ? "block" : "none"}}>
        <RequirementAndFAQs setTabBecomeServiceCompany={setTabBecomeServiceCompany}/>
      </div>
      <div style={{display: tabBecomeServiceCompany === "termsAndCondition" ? "block" : "none"}}>
        <TermsAndCondition setTabBecomeServiceCompany={setTabBecomeServiceCompany}/>
      </div>
      <div style={{display: tabBecomeServiceCompany === "newCompanyInformation" ? "block" : "none"}}>
        <NewCompanyInformation setTabBecomeServiceCompany={setTabBecomeServiceCompany}/>
      </div>
    </MainDefault>
  );
};
