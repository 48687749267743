import { Col, ListGroup, NavDropdown, Row, Stack } from "react-bootstrap";
import { CardWrapper } from "../../../common/Default";
import { ReactComponent as Business } from "../../../../assets/images/business.svg";
import { HiDotsVertical, HiOutlineLocationMarker } from "react-icons/hi";

import { Fragment, useState } from "react";
import AssignedContentTable from "./AssignedContentTable";
import { useSelector } from "react-redux";
import { ViewCalendar } from "../../../buyer-provider/view-calendar/ViewCalendar";
import { ConfirmationActionModal } from "../../../buyer/work-orders/modals/ConfirmationActionModal";
import { ReactComponent as UnpublishWO } from '../../../../assets/images/unpublish-work-order.svg'
import { ReactComponent as WOCancel } from '../../../../assets/images/cancel-work-order.svg'
import { ReactComponent as CancelWO } from '../../../../assets/images/cancel-wo.svg'
import { MarkAsInProgress } from "../../../buyer/work-orders/offcanvas/MarkAsInProgress";
import { CheckInOutSchedule } from "../../../buyer/work-orders/modals/CheckInOutSchedule";

export const AssignedContent = () => {
  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);

  const [showUnAssign, setShowUnAssign] = useState(false)
  const [showModifyWO, setShowModifyWO] = useState(false)
  const [showCancelWO, setShowCancelWO] = useState(false)
  const [showChangeSchedule, setShowChangeSchedule] = useState(false)
  const [showMarkAsInProgress, setShowMarkAsInProgress] = useState(false)

  const events = [
    {
      title: "Plumber",
      start: new Date().toISOString().split("T")[0] + "T07:00:00",
      location: "Birmingham, United Kingdom",
      charge: "$500",
    },
  ];
  return (
    <Fragment>
      {_activeTabLayout === "list" ? (
        <AssignedContentTable
          onShowUnAssignWO={() => setShowUnAssign(true)}
          onShowModifyWO={() => setShowModifyWO(true)}
          onShowCancelWO={() => setShowCancelWO(true)}
          onShowMarkAsInProgress={() => setShowMarkAsInProgress(true)}
          onShowChangeSchedule={() => setShowChangeSchedule(true)}
        />
      ) : _activeTabLayout === "cards" ? (
        <Fragment>
          <Row className="row-gap-4">
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <Col md={3} key={i}>
                  <AssignedCard
                    onShowUnAssignWO={() => setShowUnAssign(true)}
                    onShowModifyWO={() => setShowModifyWO(true)}
                    onShowCancelWO={() => setShowCancelWO(true)}
                    onShowMarkAsInProgress={() => setShowMarkAsInProgress(true)}
                    onShowChangeSchedule={() => setShowChangeSchedule(true)}
                  />
                </Col>
              )
            })}
          </Row>
        </Fragment>
      ) : (
        <ViewCalendar events={events} headerToolbar={false} />
      )}
      <ConfirmationActionModal
        show={showUnAssign}
        handleClose={() => setShowUnAssign(false)}
        image={UnpublishWO}
        title='You are about to unassign this work order'
        subtitle={
          <>
            This action will affect your ratings
            <br /> Are you sure you want to proceed?
          </>
        }
      />
      <ConfirmationActionModal
        show={showModifyWO}
        handleClose={() => setShowModifyWO(false)}
        image={WOCancel}
        title='You are able to cancel this work done'
      />
      <ConfirmationActionModal
        show={showCancelWO}
        handleClose={() => setShowCancelWO(false)}
        image={CancelWO}
        title='You are about to cancel this work order'
        subtitle={
          <>
            This action will affect your ratings
            <br /> Are you sure you want to proceed?
          </>
        }
      />
      <MarkAsInProgress show={showMarkAsInProgress} handleClose={() => setShowMarkAsInProgress(false)} />
      <CheckInOutSchedule
        show={showChangeSchedule}
        handleClose={() => setShowChangeSchedule(false)}
      />
    </Fragment>
  );
};

const AssignedCard = ({
  onShowUnAssignWO,
  onShowModifyWO,
  onShowCancelWO,
  onShowMarkAsInProgress,
  onShowChangeSchedule
}) => {
  return (
    <CardWrapper>
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-center mb-2"
      >
        <Business />
        <NavDropdown
          title={<HiDotsVertical className="color-grey-500 cursor-pointer" />}
        >
          <ListGroup variant="flush">
            <ListGroup.Item
              action
              onClick={onShowMarkAsInProgress}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              Mark in progress
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={onShowUnAssignWO}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              Unassign
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={onShowModifyWO}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              Modify
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={onShowChangeSchedule}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              Change Schedule
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={onShowCancelWO}
              className="cursor-pointer font-14 color-gray-700"
            >
              Cancel
            </ListGroup.Item>
          </ListGroup>
        </NavDropdown>
      </Stack>
      <span className="color-grey-500 font-10 fw-medium">#1233944</span>
      <p className="font-14 fw-semibold line-clamp-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum nobis
        accusamus aliquam incidunt, et explicabo.
      </p>
      <Stack direction="horizontal" className="justify-content-between my-2">
        <div className="d-flex align-items-center color-grey-500 gap-1">
          <HiOutlineLocationMarker />
          <span
            className="font-12 lh-1 text-truncate"
            style={{ maxWidth: 120 }}
          >
            Birmingham, United Kingdom
          </span>
        </div>
        <span className="job-status badge-status__assigned">ASSIGNED</span>
      </Stack>
      <hr className="opacity-100 border-grayscale-100" />
      <div>
        <p className="color-pale-sky font-10 mb-1">Start date & time</p>
        <p className="color-ebony font-12">02/13/2024 | 11:30AM</p>
      </div>
      <hr className="opacity-100 border-grayscale-100" />
      <div>
        <p className="color-pale-sky font-10 mb-1">Assigned to</p>
        <Stack direction="horizontal" className="gap-2">
          <img
            src="https://contentwriter.co/images/avatars/1708227404.jpg"
            className="card-image"
            alt=""
          />
          <div>
            <p className="color-ebony fw-semibold font-12">Ademola Adetunji</p>
            <p className="font-10">15 miles away</p>
          </div>
        </Stack>
      </div>
      <hr className="opacity-100 border-grayscale-100" />
      <Stack direction="horizontal" className="justify-content-between">
        <h6 className="text-black fw-bold mb-0">$500</h6>
        <span className="color-pale-sky font-10">Posted 3m ago</span>
      </Stack>
    </CardWrapper>
  );
};
