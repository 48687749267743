import React, { useMemo, useState } from 'react'
import { NotFoundCard } from '../../../../../components/profile/not-found/NotFoundCard'
import { Table } from '../../../../../components/common/table/Table'
import moment from 'moment'
import { NavDropdownWrapper } from '../../../../../components/common/header/nav-dropdown/Wrapper'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { ListGroup } from 'react-bootstrap'
import { AddLicense } from '../../../../../components/profile/offcanvas/AddLicense'

export const LicensesContent = () => {
    
    const [showAddLicense, setShowAddLicense] = useState(false)
    
    const columns = useMemo(() => [
        {
            accessorKey: 'license',
            header: () => 'License',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'licenseNumber',
            header: () => 'License Number',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            accessorKey: 'state',
            header: () => 'State',
            cell: info => info.renderValue(),
            // enableSorting: false
        },
        {
            header: 'Issue Date',
            accessorKey: 'issueDate',
            cell: (info) => moment(info.renderValue()).format('DD/MM/YYYY')
        },
        {
            header: 'Expiry Date',
            accessorKey: 'expiryDate',
            cell: (info) => moment(info.renderValue()).format('DD/MM/YYYY')
        },
        {
            id: "actions",
            header: () => "Action",
            cell: ({ row }) => {
                return (
                    <NavDropdownWrapper title={<MdOutlineMoreHoriz size={24} />}>
                        <ListGroup.Item
                            action
                            // onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            View document
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            // onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Update
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Delete
                        </ListGroup.Item>
                    </NavDropdownWrapper>
                );
            },
        },
    ], [])

    const rows = [
        {
            license: 'License 1',
            licenseNumber: '123456',
            state: 'State 1',
            issueDate: '2022-07-01',
            expiryDate: '2022-07-01'
        },
        {
            license: 'License 2',
            licenseNumber: '123456',
            state: 'State 2',
            issueDate: '2022-07-01',
            expiryDate: '2022-07-01'
        }
    ]
    
    return (
        <>
            {[''].length <= 0 ? (
                <>
                    <NotFoundCard
                        title="No license added"
                        subTitle="You have not add any license here"
                        buttonText="Add license"
                        onButtonClick={() => setShowAddLicense(true)}
                    />
                    <AddLicense show={showAddLicense} handleClose={() => setShowAddLicense(false)} />
                </>
            ) : (
                <Table data={rows} columns={columns} />
            )}
        </>
    )
}
