import { useState } from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { JobUpdates } from '../../../../components/dashboard/job-updates/JobUpdates'
import { INSURANCE_LICENSES_navItems, INSURANCE_LICENSES_tabPanes } from '../../../../data/constants'
import { AddInsurance } from '../../../../components/profile/offcanvas/AddInsurance'
import { Stack } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'
import { AddLicense } from '../../../../components/profile/offcanvas/AddLicense'

export const MyInsuranceAndLicenses = () => {

    const [showAddInsurance, setShowAddInsurance] = useState(false)
    const [showAddLicense, setShowAddLicense] = useState(false)

    const [currentEventKey, setCurrentEventKey] = useState('insurance')

    return (
        <MainDefault>
            <Stack
                direction="horizontal"
                className="justify-content-between py-3 mb-3"
            >
                <h4 className="mb-0 fw-bold topHead">
                    Insurance & Licenses
                </h4>
                {[''].length > 0 && (
                    <Button
                        className="px-3"
                        style={{ paddingBlock: 12 }}
                        onClick={() => {
                            currentEventKey === 'insurance' ? (
                                setShowAddInsurance(true)
                            ) : (
                                setShowAddLicense(true)
                            )
                        }}
                    >
                        Add new
                    </Button>
                )}
            </Stack>

            <JobUpdates
                navItems={INSURANCE_LICENSES_navItems}
                tabPanes={INSURANCE_LICENSES_tabPanes}
                handleSelect={(eventKey) => setCurrentEventKey(eventKey)}
            />

            <AddInsurance show={showAddInsurance} handleClose={() => setShowAddInsurance(false)} />
            <AddLicense show={showAddLicense} handleClose={() => setShowAddLicense(false)} />
        </MainDefault>
    )
}
