import React from "react";
import { MainDefault } from "../../../../../components/common/Default";
import { Col, Form, Row } from "react-bootstrap";
import { MyOTPInput } from "../../../../../components/common/my-otp-input/MyOTPInput";
import { Button } from "../../../../../components/common/button/Button";
import { ReactComponent as Lock } from "../../../../../assets/images/lock.svg";
import { useNavigate } from "react-router-dom";

export const MyInformationProtection = () => {

    const navigate = useNavigate() 
  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate("/profile/tax-information")
  };

  return (
    <MainDefault>
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Col xs={11} sm={8} md={4}>
          <div className="text-center">
            <Lock className="mb-4" />
            <div className="mb-3">
              <h5 className="welcome-heading fs-3 line-height-125">
                Information Protected
              </h5>
              <p
                className="get-started fw-normal line-height-150 mx-auto"
                style={{ maxWidth: "350px" }}
              >
                Enter the code sent to your email to have access
              </p>
            </div>
          </div>
          <Form onSubmit={handleFormSubmit}>
            <MyOTPInput
              label="Enter OTP"
              //   value={otp}
              //   onChange={setOtp}
              inputType="number"
              //   error={error}
              wrapperClasses="hide__arrows"
            />
            <Button
              type="submit"
              id="update-tax-details"
              //   disabled={!isOTPValid}
              className="w-100 py-3 mb-4"
            
            >
              Update Details
            </Button>
          </Form>
        </Col>
      </Row>
    </MainDefault>
  );
};
