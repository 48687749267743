import { Col, Form, Row } from 'react-bootstrap'
import { ProfileWrapper } from '../../../../components/common/Default'
import { Input } from '../../../../components/common/input/Input'
import { Button } from '../../../../components/common/button/Button'
import { FiPlus } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { showToaster } from '../../../../utils/utils'
import PostRequest from '../../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTeamMembers } from '../../../../store/buyer/buyer.action'
import { FaTrash } from 'react-icons/fa6'
import { emailPattern } from '../../../../data/constants'

const INFO = {
    title: 'Invite Team Members',
    description: 'Just a few info about you...'
}

const validateEmail = (email) => {
    if (!email.trim()) {
        return 'Email is required';
    }
    if (!emailPattern.test(email)) {
        return 'Invalid email address';
    }
    return ''
};

const checkForDuplicateEmails = (emails) => {
    const emailSet = new Set();
    const duplicates = []
    for (var i = 0; i < emails.length; i++) {
        if (!emails[i].trim()) continue;
        if (emailSet.has(emails[i])) {
            duplicates.push(emails[i])
        } else {
            emailSet.add(emails[i]);
        }
    }
    return duplicates;
}

export const InviteTeamMembers = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const teamMembers = useSelector(state => state.buyerReducer.teamMembers)

    const [emails, setEmails] = useState([''])
    const [errors, setErrors] = useState([''])

    const handleEmailChange = (index, event) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = event.target.value;
        setEmails(updatedEmails);

        const updatedErrors = [...errors];
        updatedErrors[index] = validateEmail(updatedEmails[index]);
        setErrors(updatedErrors);
    };

    const handleEmailBlur = (index) => {
        const updatedErrors = [...errors];
        const duplicateEmails = checkForDuplicateEmails(emails);
        if (duplicateEmails.includes(emails[index].trim()) && emails[index].trim()) {
            updatedErrors[index] = `Email "${emails[index]}" is already added`;
        } else {
            updatedErrors[index] = validateEmail(emails[index]);
        }
        setErrors(updatedErrors);
    }

    const addEmailField = () => {
        setEmails(prev => [...prev, '']);
        setErrors(prev => [...prev, ''])
    };

    const deleteEmailField = (i) => {
        const filteredEmails = emails.filter((_, index) => index !== i)
        setEmails(filteredEmails)

        const filteredErrors = errors.filter((_, index) => index !== i);
        setErrors(filteredErrors)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const updatedErrors = emails.map((email) => (validateEmail(email)));
        setErrors(updatedErrors);

        // // Check for duplicate emails
        const duplicateEmails = checkForDuplicateEmails(emails);

        // Loop through duplicate emails and set error messages for those specific indices
        duplicateEmails.forEach((duplicateEmail) => {
            emails.forEach((email, index) => {
                // Check if this email is in the duplicates list
                if (email.trim() === duplicateEmail.trim()) {
                    updatedErrors[index] = `Email "${email}" is already added`; // Set the error for this email
                }
            });
        });

        if (updatedErrors.some((error) => error !== '') || emails.some(email => !email) || duplicateEmails.length > 0) return;

        const data = {
            members: JSON.stringify(emails) || '[]'
        }
        try {
            const response = await PostRequest('/buyer/team-members/update', data, { showLoader: 'team-members-button' })
            console.log('buyer wallet-details response', response)
            navigate('/person-of-contact')
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        if (teamMembers) setEmails(teamMembers?.members.length > 0 ? teamMembers?.members : [''])
    }, [teamMembers])

    useEffect(() => {
        dispatch(fetchTeamMembers())
    }, [])

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.values(emails).every(value => value)

    return (
        <ProfileWrapper info={INFO}>
            <Form onSubmit={handleSubmit}>
                {emails?.map((email, i) => {
                    return <div className={`d-flex ${errors[i] ? 'align-items-baseline' : 'align-items-center'} gap-2 mb-3`} key={i}>
                        <Input
                            name={`email-${i}`}
                            placeholder='Enter email address'
                            type='email'
                            value={email}
                            onChange={(e) => handleEmailChange(i, e)}
                            onBlur={() => handleEmailBlur(i)}
                            className='w-100'
                            error={errors[i]}
                        />
                        {i !== 0 && (
                            <button type='button' onClick={() => deleteEmailField(i)} className='btn-delete'>
                                <FaTrash className='text-white' />
                            </button>
                        )}
                    </div>
                })}
                <div className='text-end mb-3'>
                    <Button type='button' onClick={addEmailField} className='py-2 bg-transparent color-primary'>
                        <FiPlus size={20} className='me-2' />
                        <span className='lh-1'>Add more</span>
                    </Button>
                </div>
                <Row className='mb-3 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Button
                            onClick={() => navigate(-1)}
                            type='button'
                            variant='outline'
                            className='w-100 color-ebony border-ebony py-3'
                        >
                            Back
                        </Button>
                    </Col>
                    <Col>
                        <Button 
                            type='submit' 
                            disabled={!isFormValid()} 
                            id='team-members-button' 
                            className='w-100 py-3'
                        >
                            Send Invites
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className='text-center fw-bold'>
                <Link
                    to='/person-of-contact'
                    className='text-decoration-none'
                    style={{ color: '#111827' }}
                >
                    Skip for now
                </Link>
            </div>
        </ProfileWrapper>
    )
}
