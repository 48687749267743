import React, { Fragment, useState } from "react";
import { MdOutlineDateRange, MdOutlineKeyboardBackspace } from "react-icons/md";
import "./jobinfo.css";
import { FiUser, FiUsers } from "react-icons/fi";
import { CiCoins1 } from "react-icons/ci";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { HiDotsVertical } from "react-icons/hi";
import { ListGroup } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { ScheduleDetailsCard } from "../../../../components/common/schedule-detalis-card/ScheduleDetailsCard";
import { MainDefault } from "../../../../components/common/Default";
import { JobUpdates } from "../../../../components/dashboard/job-updates/JobUpdates";
import {
  JOB_INFO_navITEMS,
  JOB_INFO_tabPanes,
} from "../../../../data/constants";
import { FilterButton } from "../../../../components/common/filter-button/FilterButton";
import { History } from "../../../../components/job-info-tab/history/History";
import { MyTooltip } from "../../../../components/common/tooltip/MyTooltip";
import { Link, useNavigate } from "react-router-dom";
import { NavDropdownWrapper } from "../../../../components/common/header/nav-dropdown/Wrapper";
import { ConfirmationActionModal } from "../../../../components/buyer/work-orders/modals/ConfirmationActionModal";
import { ReactComponent as UnpublishWO } from '../../../../assets/images/unpublish-work-order.svg'

export const JobInfo = () => {
  const [historyModalShow, setHistoryModalShow] = useState(false);

  const navigate = useNavigate()

  return (
    <>
      <MainDefault containerClasses={[""].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
        <div className="px-3">
          <div className="d-flex align-items-center gap-2">
            <MdOutlineKeyboardBackspace onClick={() => navigate(-1)} className="cursor-pointer" size={32} />
            <label className="back-slide-label">Back to Job Listings</label>
          </div>

          <div className="mt-4 d-flex align-items-center justify-content-between">
            <div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <label className="job-name">Plumber</label>
                <div className="status-indicator">PUBLISHED</div>
                <JobInfoDropdown />
              </div>
              <div className="mt-2 d-flex align-items-center gap-2 candidate-count">
                <div className="candidate-count">#1233944</div>
                <span>|</span>
                <FiUsers size={14} />
                <div>10 Candidates Applied</div>
              </div>
            </div>

            <FilterButton onClick={() => setHistoryModalShow(true)}>
              <HiOutlineSquare3Stack3D className="me-1" size={20} />
              <span>History</span>
            </FilterButton>
          </div>

          <div className="mt-3 job-details">
            <div className="d-flex justify-content-between">
              <ScheduleDetailsCard
                fieldName="LOCATION"
                fieldIcon={<IoLocationOutline size={13} />}
              >
                <div className="state-name mt-1">
                  Birmingham, United Kingdom
                </div>
              </ScheduleDetailsCard>
              <ScheduleDetailsCard
                fieldName="SCHEDULE"
                fieldIcon={<MdOutlineDateRange size={13} />}
              >
                <div className="deadline-commitment mt-1">Fixed</div>
                <div className="work-commit-date mt-1">
                  03/04/2023 | 11:20PM
                </div>
              </ScheduleDetailsCard>
              <ScheduleDetailsCard
                fieldName="PROVIDER ASSIGNED"
                fieldIcon={<FiUser size={13} />}
              >
                <div className="deadline-commitment mt-1">NOT ASSIGNED</div>
              </ScheduleDetailsCard>
              <ScheduleDetailsCard
                fieldName="PAY RATE"
                fieldIcon={<CiCoins1 size={13} />}
              >
                <div className="mt-1">
                  <div className="rate">
                    $500
                    <MyTooltip
                      content={<span className="color-grey-500 fw-500">SendRobin Enabled</span>}
                    >
                      <span className="ps-2">
                        Send
                        <span>Robin</span>
                      </span>
                    </MyTooltip>
                  </div>
                  <div className="platform-and-sales-fees">
                    Platform Fees: $15
                  </div>
                  <div className="platform-and-sales-fees">Sales Tax: $11</div>
                </div>
              </ScheduleDetailsCard>
            </div>
          </div>

          <JobUpdates navItems={JOB_INFO_navITEMS} tabPanes={JOB_INFO_tabPanes}>
            {/* <LayoutSwitcher showCalendar />tabPanes={BUYER_tabPanes}
                              <button type="button" className="filterButton">
                                <AiOutlineSetting className="me-1" />
                                <span>Filter</span>
                              </button> */}
          </JobUpdates>
        </div>

        <History
          show={historyModalShow}
          handleClose={() => setHistoryModalShow(false)}
        />
      </MainDefault>
    </>
  );
};

const JobInfoDropdown = () => {

  const [showUnassignWO, setShowUnassignWO] = useState(false)

  return (
    <Fragment>
      <NavDropdownWrapper
        id='job-info__dropdown'
        title={
          <div className="status-indicator-features d-flex align-items-center justify-content-center">
            <HiDotsVertical
              className="color-grey-500 cursor-pointer"
              size={15}
            />
          </div>
        }
      >
        <ListGroup.Item
          as={Link}
          to='/work-orders/basic-information'
          state={{ mode: 'edit' }}
          className="cursor-pointer font-14 color-grey-500 border-bottom-0"
        >
          Edit work order
        </ListGroup.Item>
        <ListGroup.Item
          action
          onClick={() => { }}
          className="cursor-pointer font-14 color-grey-500 border-bottom-0"
        >
          Archive work order
        </ListGroup.Item>
        <ListGroup.Item
          action
          onClick={() => setShowUnassignWO(true)}
          className="cursor-pointer font-14 color-grey-500"
        >
          Unpublish work order
        </ListGroup.Item>
      </NavDropdownWrapper>
      <ConfirmationActionModal
        show={showUnassignWO}
        handleClose={() => setShowUnassignWO(false)}
        image={UnpublishWO}
        title='You are about to unpublish this work order'
      />
    </Fragment>
  )
}