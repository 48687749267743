import { Form } from 'react-bootstrap'
import { AuthWrapper } from '../components/common/Default'
import { Button } from '../components/common/button/Button'

export const UserType = () => {

    const userType = window.localStorage.getItem('USER_TYPE') || ''

    const handleSubmit = (e) => {
        e.preventDefault()

        var data = {}
        const formData = new FormData(e.target);
        formData.forEach((val, key) => {
            data[key] = val;
        })

        if (userType) window.localStorage.removeItem('USER_TYPE')

        window.localStorage.setItem('USER_TYPE', data.userType)

        if (data.userType === 'provider') {
            window.location.href = '/provider/welcome'
        } else if (data.userType === 'buyer') {
            window.location.href = '/buyer/welcome'
        }
    }

    return (
        <AuthWrapper>
            <h5 className='welcome-heading'>Account Type</h5>
            <p className='text-black mb-4'>Choose your account type</p>
            <hr />
            <Form onSubmit={handleSubmit}>
                <Form.Check
                    type={'radio'}
                    name='userType'
                    id='buyer'
                    value='buyer'
                    label={`I am a Buyer`}
                    className='color-grey-500'
                    defaultChecked
                />
                <hr />
                <Form.Check
                    type={'radio'}
                    name='userType'
                    id='provider'
                    value='provider'
                    label={`I am a Provider`}
                    className='color-grey-500 mb-4 '
                />
                <Button type='submit' className='w-100 mb-3'>Continue</Button>
            </Form>
        </AuthWrapper>
    )
}
