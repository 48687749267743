import React, { useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/timegrid";
import timeGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Form, Modal, Navbar } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { Button } from "../../../components/common/button/Button";
import { SlLocationPin } from "react-icons/sl";
import { RxCross1 } from "react-icons/rx";
import "./calendar.css";
import { ViewCalendar } from "../../../components/buyer-provider/view-calendar/ViewCalendar";

export const ProviderCalendar = () => {
  const [showAvailability, setShowAvailability] = useState(false);

  const events = [
    {
      title: "Plumber",
      pinNumber: "#1233944",
      start: new Date().toISOString().split("T")[0] + "T07:00:00",
      // end: new Date().toISOString().split("T")[0] + "T12:00:00",
      location: "Birmingham, United Kingdom",
      // icon: <SlLocationPin />,
      charge: "$500",
    },
  ];

  return (
    <>
      <MainDefault>
        <div className="px-3">
          <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
            <h4 className="font-24 line-height-130 fw-bold">My Calendar </h4>
            <Navbar.Brand className="position-relative">
              <IoSearch className="form-control__search-icon color-grey-500" />
              <Form.Control
                type="text"
                placeholder="Search"
                className="form-control__search applicant-search-input"
              />
            </Navbar.Brand>
          </div>

          <ViewCalendar
            events={events}
            isBtn
            btnName="Set Availability"
            click={setShowAvailability}
          />
        </div>
      </MainDefault>

      <Modal
        dialogClassName="invite-dialog"
        show={showAvailability}
        onHide={setShowAvailability}
        centered
        // size="md"
      >
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
              Set Availability
            </h6>
            <RxCross1
              size={20}
              onClick={() => setShowAvailability(false)}
              className="cursor-pointer"
            />
          </div>

          <p className="font-16 color-gray-700">
            Let buyers know when you can work. This will help you attract
            customers and manage your time.
          </p>

          <div className="mt-3">
            <p className="font-16 color-gray-700">Pick one of these options:</p>

            <ul>
              <li className="mt-3">
                <span className="font-16 color-gray-700 fw-600">
                  Always available:{" "}
                  <span className="ms-1 fw-400">
                    Work anytime, with no limits.
                  </span>
                </span>
              </li>
              <li className="mt-3">
                <span className="font-16 color-gray-700 fw-600">
                  Custom availability:
                  <span className="ms-1 fw-400">
                    Set specific days and hours for your work. You can do this
                    for each product or service if you have more than one.
                  </span>
                </span>
              </li>
              <li className="mt-3">
                <span className="font-16 color-gray-700 fw-600">
                  Away:
                  <span className="ms-1 fw-400">
                    Take a break from work for a while, for example, for
                    vacation, illness, or maintenance. You can also set a date
                    when you will return.
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <p className="mt-3 mb-4 font-16 color-gray-700">
            To set your availability, go to your profile and tap the
            Availability tab or from your calendar.
          </p>

          <Button type="submit" className="fw-semibold py-3 px-3 ">
            Go to settings
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
