import React, { useState } from 'react'
import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Input } from '../../../../components/common/input/Input';
import { handleChange, isFormValid } from '../../../../utils/utils';
import { AddFundsForm } from '../../../../components/authentication/profiling/AddFundsForm';
import { Button } from '../../../../components/common/button/Button';
import { RightOffcanvas } from '../../../../components/buyer-provider/RightOffcanvas';
import { RxCross1 } from 'react-icons/rx';
import { FundsAdded } from './FundsAdded';
// import { CardWrapper } from '../../../../components/common/Default';
// import { MASTER_CARD } from '../../../../assets/images.assets';
// import { HiOutlineTrash } from 'react-icons/hi2';

export const CreditCardInputYourAmount = ({ setInputYourAmount, setShowFundingMethod }) => {

  const [showAddFundsForm, setShowAddFundsForm] = useState(false)
  const [fundsAdded, setFundsAdded] = useState(false);
  const [isCardSelected, setIsCardSelected] = useState(false)

  const [formData, setFormData] = useState({
    amount: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
    description: "",
  });

  const validateField = (name, value) => {
    var error = '';

    if (name === 'amount') {
      if (!value)
        error = "Amount is required.";
      else if (isNaN(value) || Number(value) <= 0)
        error = "Amount must be a positive number.";
    }

    if (name === 'description' && !value) error = "Description is required."

    return error;
  }

  const handleInputChange = (e) => {
    handleChange(e, setFormData, setErrors, validateField)
  }
  
  const isCreditCardFormValid = isFormValid(errors, formData)

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
    <Form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit}>
      <Input
        label="Amount"
        type="number"
        placeholder="Enter amount"
        className="mb-3"
        name="amount"
        value={formData.amount}
        onChange={handleInputChange}
        error={errors.amount}
        star
      />
      <Input
        label="Description"
        placeholder="Enter your project description"
        className="mb-3"
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        error={errors.description}
        rows={3}
        multiline
      />
      <div className='font-14 my-3'>
        <p className='text-black fw-medium'>Card Details</p>
        <hr className='opacity-100 mt-2 mb-3 border-grayscale-100' />
        {!showAddFundsForm ? (
          <div className='text-center'>
            {/* <p className='color-gray-700 mb-2'>You don’t have any added card here</p> */}
            {/* <CardWrapper 
              className={`mb-3 cursor-pointer ${isCardSelected ? 'border-primary' : ''}`}
              onClick={() => setIsCardSelected(true)}
            >
              <Stack direction='horizontal' className='justify-content-between'>
                <div className='card-wrapper px-2 py-1 rounded-3'>
                  <img src={MASTER_CARD} alt="MasterCard" />
                </div>
                <p className='color-gray-800 font-14 fw-500 ms-2 me-auto'>**** **** **** 9877</p>
                <button type='button' className='pdf-close__wrapper border-0'>
                    <HiOutlineTrash size={20} />
                </button>
              </Stack>
            </CardWrapper> */}
            <span
              className='color-primary fw-500 cursor-pointer'
              onClick={() => setShowAddFundsForm(true)}
            >
              + Add new card
            </span>
          </div>
        ) : (
          <AddFundsForm fromPayments />
        )}
      </div>
      <Stack direction='horizontal' className='justify-content-between mt-auto'>
        <Button
          type='button'
          variant='outline'
          className='fw-semibold px-3 color-ebony padded-border-button'
          onClick={() => {
            if(isCardSelected) {
              setIsCardSelected(false)
            } else {
              setInputYourAmount(false)
            }
          }}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          className='fw-semibold p-3'
          disabled={!isCreditCardFormValid}
          onClick={() => setFundsAdded(true)}
        >
          Add funds
        </Button>
      </Stack>
    </Form>
    <RightOffcanvas className='timesheet-offcanvas' show={fundsAdded} handleClose={setFundsAdded}>
        <div className="p-3 d-flex justify-content-end">
          <RxCross1
            size={20}
            onClick={() => setFundsAdded(false)}
            className="cursor-pointer"
          />
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <FundsAdded fundingMethod='creditCard' handleClose={() => {
            setFundsAdded(false)
            setInputYourAmount(false)
            setShowFundingMethod(false)
          }} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  )
}
