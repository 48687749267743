import React, { Children } from "react";
import { CardWrapper } from "../common/Default";
import { Button } from "../common/button/Button";

export const TotalBalanceCard = ({
  image,
  title,
  description,
  amount,
  className,
  btn,
  children
}) => {
  return (
    <CardWrapper className={`px-2 payment-card-wrapper`}>
      <img src={image} />
      <h6 className="mt-2 font-18 line-height-160 fw-600">{title}</h6>
      <p className="mt-1 font-14 line-height-160 color-grey-500 w-75 card-description">
        {description}
      </p>
      <div className="d-flex justify-content-between">
        <h3
          className={`${className} mt-5 font-28 color-black line-height-100 fw-bold`}
        >
          {amount}
        </h3>

        {btn && (
         children
        )}
      </div>
    </CardWrapper>
  );
};
