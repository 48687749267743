import React from "react";
import { Col, Row } from "react-bootstrap";
import Underline from "../../../../components/buyer-provider/Underline";
import { MdOutlineStar } from "react-icons/md";

export const Profile = () => {
  const providerIntro = [
    {
      label: "Full Name",
      values: "Adeleke Fajuyi Olayiwola",
    },
    {
      label: "Gender",
      values: "Male",
    },
    {
      label: "Date of Birth",
      values: "March 23, 1995",
      year: "(26 y.o)",
    },
    {
      label: "Language",
      values: "English, Yoruba, Pidgin",
    },
    {
      label: "Address",
      values: "144, Olohunleke street, Ahmadiyya, Lagos",
    },
  ];

  const providerJobDetails = [
    {
      label: "Current Job",
      values: "Plumber",
    },
    {
      label: "Experience in Years",
      values: "4 Years",
    },
    {
      label: "Highest Qualification Held",
      values: "Bachelors in Engineering",
    },
    {
      label: "Skill set",
      skill: {
        skillOne: "Plumbing",
        skillTwo: "Attention to details",
        skillThree: "English",
      },
    },
  ];

  const jobsCompleted = [
    {
      jobName: "Timeliness",
    },
    {
      jobName: "Professionalism",
    },
    {
      jobName: "Quality of work",
    },
    {
      jobName: "Customer service",
    },
    {
      jobName: "Overall satisfaction",
    },
  ];

  const ratingsOfCompletedJobs = [
    {
      rating: (
        <div>
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="D9D9D9" />
        </div>
      ),
    },
    {
      rating: (
        <div>
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="D9D9D9" />
          <MdOutlineStar size={16} color="D9D9D9" />
        </div>
      ),
    },
    {
      rating: (
        <div>
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="D9D9D9" />
        </div>
      ),
    },
    {
      rating: (
        <div>
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="D9D9D9" />
        </div>
      ),
    },
    {
      rating: (
        <div>
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="FB9905" />
          <MdOutlineStar size={16} color="D9D9D9" />
        </div>
      ),
    },
  ];
  
  return (
    <>
      <h6 className="font-18 line-height-160 fw-600">Personal Info</h6>

      <Row>
        {providerIntro.map((pInfo, index) => (
          <Col sm={6} key={index} className="mt-3">
            <p className="font-16 line-height-160 color-grey-500">
              {pInfo.label}
            </p>
            <p className="font-16 fw-500 line-height-125 w-50">
              {pInfo.values}
              <span className="color-grey-500"> {pInfo?.year} </span>
            </p>
          </Col>
        ))}
      </Row>
      <Underline className="mb-4 mt-4" />

      <div>
        <h5 className="font-18 line-height-160 fw-600">Professional Info</h5>
        <h6 className="font-16 line-height-160 color-grey-500 fw-normal">
          About Me
        </h6>
        <p className="font-16 line-height-160">
          As a seasoned plumber dedicated to excellence in my craft, I bring a
          wealth of experience and a passion for delivering top-notch plumbing
          solutions. With a [insert number of years] years' track record in the
          field, I have honed my skills in diagnosing, repairing, and
          maintaining plumbing systems with precision and efficiency.
        </p>
        <p className="font-16 line-height-160 mt-2">
          My commitment to quality craftsmanship is evident in my attention to
          detail, ensuring that every installation and repair meets the highest
          industry standards. I am adept at handling a diverse range of plumbing
          tasks, from routine maintenance to troubleshooting complex issues.
        </p>
      </div>

      <Row>
        {providerJobDetails.map((pInfo, index) => (
          <Col sm={6} key={index} className="mt-3">
            <p className="font-16 line-height-160 color-grey-500">
              {pInfo.label}
            </p>
            <p className="font-16 fw-500 line-height-160 w-50">
              {pInfo.values}
            </p>

            <div className="d-flex align-items-center">
              <div className="provider-skill">{pInfo.skill?.skillOne}</div>
              <div className="provider-skill">{pInfo.skill?.skillTwo}</div>
              <div className="provider-skill">{pInfo.skill?.skillThree}</div>
            </div>
          </Col>
        ))}
      </Row>

      <div className="mt-4">
        <h6 className="font-14 line-height-160 fw-600">Summary Ratings</h6>

        <Underline className="mt-2 mb-3" />

        <Row className="d-flex align-items-center">
          <Col xs={11} sm={8} md={2}>
            <p className="font-14 line-height-160 color-grey-500 ">
              Jobs Completed
            </p>

            {jobsCompleted.map((jCom, index) => (
              <p
                key={index}
                className="font-14 line-height-160 color-grey-500 pt-2"
              >
                {jCom.jobName}
              </p>
            ))}
          </Col>
          <Col xs={11} sm={8} md={2}>
            <p className="font-14 line-height-160 color-grey-700 fw-bold">
              40 Jobs
            </p>
            {ratingsOfCompletedJobs.map((r, index) => (
              <div className="d-flex gap-2 pt-2" key={index}>
                {r.rating}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};
