import React, { Fragment, useState } from 'react'
import { Offcanvas, Stack } from 'react-bootstrap'
import { CardWrapper } from '../../../common/Default'
import { MASTER_CARD } from '../../../../assets/images.assets'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { Button } from '../../../common/button/Button'
import { PaidSuccessful } from './PaidSuccessful'

export const Summary = ({ show, handleClose, handleCloseOrder, screeningId }) => {

    const [showPaidSuccessful, setShowPaidSuccessful] = useState(false)

    return (
        <Fragment>
            <Offcanvas
                show={show}
                onHide={handleClose}
                className='timesheet-offcanvas'
                placement='end'
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='fw-bold'>Summary</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='d-flex flex-column'>
                    <div>
                        <div className='mb-4'>
                            <p className='fw-700 mb-2'>Order Type</p>
                            <p className='color-neutrals-80 fw-500'>
                                {screeningId === 'background-check' ? 'Background Check' : 'Drug Test'}
                            </p>
                        </div>
                        <div className='mb-4'>
                            <p className='fw-700 mb-2'>Information</p>
                            <p className='color-neutrals-80 fw-500 mb-2'>Name:  Tayo Heurets</p>
                            <p className='color-neutrals-80 fw-500 mb-2'>Email Address:  tayoheurets@gmail.com</p>
                            <p className='color-neutrals-80 fw-500 mb-2'>Zip Code:  178829209</p>
                            <p className='color-neutrals-80 fw-500'>Phone Number:  +!37783999</p>
                        </div>
                        <div className='mb-4'>
                            <p className='fw-700 mb-1'>Card Information</p>
                            <CardWrapper>
                                <Stack direction='horizontal' className='justify-content-between'>
                                    <div className='card-wrapper px-2 py-1 rounded-3'>
                                        <img src={MASTER_CARD} alt="MasterCard" />
                                    </div>
                                    <p className='color-gray-800 font-14 fw-500 ms-2 me-auto'>**** **** **** 9877</p>
                                    <button type='button' className='pdf-close__wrapper btn-edit__saved-location border-0'>
                                        <HiOutlinePencilAlt size={20} />
                                    </button>
                                </Stack>
                            </CardWrapper>
                        </div>
                        <div>
                            <p className='fw-700 mb-2'>Billing Information</p>
                            <p className='color-neutrals-80 fw-500 mb-2'>Plot 2, Amsterdam Avenue.</p>
                            <p className='color-neutrals-80 fw-500 mb-2'>London</p>
                            <p className='color-neutrals-80 fw-500'>United Kingdom.</p>
                        </div>
                    </div>
                    <Stack direction='horizontal' className='justify-content-between mt-auto'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold color-ebony padded-border-button'
                            onClick={() => {
                                // if (isCardSelected) {
                                //     setIsCardSelected(false)
                                // } else {
                                //     setInputYourAmount(false)
                                // }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='fw-semibold py-14px'
                            // disabled={!isCreditCardFormValid}
                            onClick={() => setShowPaidSuccessful(true)}
                        >
                            Proceed to Pay
                        </Button>
                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
            <PaidSuccessful
                show={showPaidSuccessful}
                handleClose={() => {
                    setShowPaidSuccessful(false)
                    handleClose()
                    handleCloseOrder()
                }}
                screeningId={screeningId}
            />
        </Fragment>
    )
}
