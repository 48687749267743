import { useNavigate } from 'react-router-dom'
import { HiArrowRight } from 'react-icons/hi'

export const WorkOrdersHeader = () => {

    const navigate = useNavigate()

    const userType = window.localStorage.getItem('USER_TYPE')

    const handleNavigate = () => {
        if (userType === 'buyer') {
            navigate('/work-orders')
        } else if (userType === 'provider') {
            navigate('/find-work')
        }
    }

    return (
        <div className='top-nav d-flex justify-content-between py-3 mt-3'>
            <h4 className='mb-0 fs-5'>Work Orders</h4>
            <button type='button' onClick={handleNavigate} className='color-primary border-0 bg-transparent'>
                <span className='fw-bold'>View All</span>
                <HiArrowRight size={24} className='ms-2' />
            </button>
        </div>
    )
}