import React, { Fragment, useState } from "react";
import { CardWrapper } from "../../../../components/common/Default";
import { Col, ListGroup, NavDropdown, Row, Stack } from "react-bootstrap";
import { HiDotsVertical, HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineCalendarToday } from "react-icons/md";
import { COMMON_IMG, MESSAGE_IMG } from "../../../../assets/images.assets";
import { useSelector } from "react-redux";
import { useActiveLayout } from "../../../../hooks/useActiveLayout";
import MyWWorkInvitesTable from "./MyWWorkInvitesTable";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/dashboard/job-updates/buyer/modal/ConfirmationModal";

const MyWorkInvitesContent = () => {
  const navigate = useNavigate();
  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);
  const [acceptInviteModal, setAcceptInviteModal] = useState(false);
  const handelAcceptInvite = () => {
    try {
      setAcceptInviteModal(true);
    } catch (error) {}
  };
  return (
    <Fragment>
      {_activeTabLayout == "list" ? (
        <MyWWorkInvitesTable />
      ) : (
        <Row>
          {Array.from({ length: 3 }).map((item) => {
            return (
              <Col md={4}>
                <CardWrapper>
                  <Stack
                    direction="horizontal"
                    className="justify-content-between align-items-center mb-2"
                  >
                    <img src={COMMON_IMG} alt="" />

                    <NavDropdown
                      title={
                        <HiDotsVertical className="color-grey-500 cursor-pointer" />
                      }
                    >
                      <ListGroup variant="flush">
                        <ListGroup.Item
                          action
                          onClick={handelAcceptInvite}
                          className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                          Accept Invite
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          onClick={() => {}}
                          className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                          Counter offer
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          onClick={() => {}}
                          className="cursor-pointer font-14 color-gray-700"
                        >
                          Reject Invite
                        </ListGroup.Item>
                      </ListGroup>
                    </NavDropdown>
                  </Stack>
                  <span className="color-grey-500 font-10 fw-medium">
                    #1233944
                  </span>
                  <p
                    onClick={() => navigate("/provider/my-work/details")}
                    className="font-14 fw-semibold cursor-pointer"
                  >
                    Work that create money that move the world and control ...
                  </p>
                  <Stack
                    direction="horizontal"
                    className="justify-content-between my-2"
                  >
                    <div className="d-flex align-items-center color-grey-500 gap-1">
                      <HiOutlineLocationMarker />
                      <span className="font-12 lh-1">
                        Birmingham, United Kingdom
                      </span>
                    </div>
                    <span className="job-status badge-status__active">ACTIVE</span>
                  </Stack>
                  <p className="font-12 color-grayscale-500">
                    Water purification and drilling of borehole for a 5 bedroom
                    duplex...
                  </p>
                  <Stack direction="horizontal" className="gap-1 w-fit">
                    <h4 className="color-primary fw-bold my-3">
                      $3,500<span className="color-pale-sky fw-normal">/m</span>
                    </h4>
                    <span className="rounded-pill card-tag__fixed">FIXED</span>
                  </Stack>
                  <div className="d-flex align-items-center color-grey-500 gap-1 mb-1">
                    <HiOutlineLocationMarker size={14} />
                    <span className="font-10 lh-1">
                      24, Amandos Avenue, Birmingham | 12 miles
                    </span>
                  </div>
                  <div className="d-flex align-items-center color-grey-500 gap-1 mb-3">
                    <MdOutlineCalendarToday size={14} />
                    <span className="font-10 lh-1">
                      Sun 24, Mar 2024 | 10:20AM
                    </span>
                    <span className="font-10 lh-1">-</span>
                    <span className="font-10 lh-1">
                      Sun 24, Mar 2024 | 10:20AM
                    </span>
                  </div>
                </CardWrapper>
              </Col>
            );
          })}
        </Row>
      )}

      {/* accept-invite-modal */}

      <ConfirmationModal
        confirmationShow={acceptInviteModal}
        handelClose={() => setAcceptInviteModal(false)}
      >
        <div className="mb-3">
          <div className="text-center">
            <img src={MESSAGE_IMG} alt="" />
          </div>
          <h5 className="text-black text-center fw-semibold font-20 mb-2 mt-3">
            You have accepted this work offer
          </h5>
          <p className="font-16 text-center fw-regular color-gray-700 mb-3">
            You have successfully accepted this offer
          </p>
        </div>
      </ConfirmationModal>
    </Fragment>
  );
};

export default MyWorkInvitesContent;
