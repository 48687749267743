import {
    FETCH_ROLES,
    ADD_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
    FETCH_ROLES_LOADING,
} from './roles.action';

const initialState = {
    roles: [],
    isLoading: false,
};

export const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROLES_LOADING:
            return {
                ...state,
                isLoading: action.value
            };
        case FETCH_ROLES:
            return { ...state, roles: action.value, isLoading: false };
        case ADD_ROLE:
            return { ...state, roles: [...state.roles, action.value] };
        case UPDATE_ROLE:
            return {
                ...state,
                roles: state.roles.map(role =>
                    role.id === action.value?._id ? action.value : role
                ),
            };
        case DELETE_ROLE:
            return {
                ...state,
                roles: state.roles.filter(role => role.id !== action.value),
            };
        default:
            return state;
    }
};