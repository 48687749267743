import { Form } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'
import { AuthWrapper } from '../../../../components/common/Default';
import { useNavigate } from 'react-router-dom';
import { MyOTPInput } from '../../../../components/common/my-otp-input/MyOTPInput';
import { useOTPValidation } from '../../../../hooks/useOTPValidation';

export const AuthenticatorCodeVerification = () => {

  const navigate = useNavigate()

  const { otp, setOtp, isOTPValid, error, handleSubmit } = useOTPValidation()


  const handleFormSubmit = (e) => {
    const formResult = handleSubmit(e)
    if (!formResult) return

    navigate('/dashboard')
  }

  return (
    <AuthWrapper>
      <h5 className='welcome-heading'>Confirm Identity</h5>
      <p className='text-black mb-4'>Open your authenticator on your phone and generate your login code</p>
      <Form onSubmit={handleFormSubmit}>
        <MyOTPInput
          value={otp}
          onChange={setOtp}
          error={error}
          label='Enter code generated'
        />
        <Button type='submit' disabled={!isOTPValid} className='w-100 mb-3'>Verify</Button>
      </Form>
      <Form.Check
        type='checkbox'
        label='Always remember this device'
        className='provider-checkbox'
        name='remember'
        id='remember-device'
      />
    </AuthWrapper>
  )
}
