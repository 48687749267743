import React from "react";
import Underline from "../../../../components/buyer-provider/Underline";
import Avatar from "react-avatar";
import { Input } from "../../../../components/common/input/Input";
import { Button } from "../../../../components/common/button/Button";

export const TicketDetails = () => {
  return (
    <>
      <div className="mt-2">
        <h6 className="font-16 line-height-160 fw-500">Ticket ID</h6>
        <Underline className="mt-2 mb-2" />
        <p className="font-14 line-height-160 color-grey-500">#25537779</p>
      </div>

      <div className="mt-3">
        <h6 className="font-16 line-height-160 fw-500">Title</h6>
        <Underline className="mt-2 mb-2" />
        <p className="font-14 line-height-160 color-grey-500">Total Hours</p>
      </div>

      <div className="mt-3">
        <h6 className="font-16 line-height-160 fw-500">Description</h6>
        <Underline className="mt-2 mb-2" />
        <p className="font-14 line-height-160 color-grey-500">
          Water purification and drilling of borehole for a 5 bedroom duplex,
          drilling of borehole for a 5 bedroom duplex, drilling of borehole for
          a 5 bedroom duplex
        </p>
      </div>

      <div className="mt-3">
        <h6 className="font-16 line-height-160 fw-500">Ticket Type</h6>
        <Underline className="mt-2 mb-2" />
        <p className="font-14 line-height-160 color-grey-500">Adebola Janet</p>
      </div>

      <div className="mt-3 mb-3">
        <h6 className="font-16 line-height-160 fw-500">Replies</h6>
        <Underline className="mt-2 mb-3" />

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <Avatar
              round
              size="40"
              name={"A"}
              className="message-sender color-black fw-600"
            />
            <div>
              <p className="font-14 line-height-160 color-grey-500">
                Work on it
              </p>
              <p className="font-10 line-height-160 color-grey-500 font-italic">
                Super Admin
              </p>
            </div>
          </div>
          <p className="font-10 line-height-160 color-grey-500">3 days ago</p>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex align-items-center gap-2">
            <Avatar
              round
              size="40"
              name={"M"}
              className="message-sender color-black fw-600"
            />
            <div>
              <p className="font-14 line-height-160 color-grey-500">Okay</p>
              <p className="font-10 line-height-160 color-grey-500 font-italic">
                You
              </p>
            </div>
          </div>
          <p className="font-10 line-height-160 color-grey-500">3 days ago</p>
        </div>
      </div>

      <Input
        label="Message"
        type="text"
        placeholder="Enter phone number"
        name="message"
        // value={formData.message}
        // onChange={handleChange}
        // error={errors.message}
        className="mb-3"
        multiline="h"
        style={{ height: "140px" }}
      />

      <Button
        type="submit"
        className="fw-semibold py-3 px-3 fit-content"
        // disabled={!isFormValid()}
       
      >
        Submit
      </Button>
    </>
  );
};
