import { ProgressBar, Stack } from 'react-bootstrap'
import { Button } from '../common/button/Button'
import { useProfileCompletion } from '../../hooks/useProfileCompletion'

export const ProfileUpdateStatus = ({ onUpdateProfile }) => {

    const { completionPercentage } = useProfileCompletion()

    return (
        <Stack direction='horizontal' className='badge__group'>
            <div className='font-14 fw-medium me-2'>Your profile is yet to be updated.</div>
            <span className='font-14 fw-medium'>{completionPercentage}%</span>
            <ProgressBar className='ms-2' now={completionPercentage} />
            <Button type='button' onClick={onUpdateProfile} className='py-2 px-3 fw-medium font-14 ms-auto'>Update Profile</Button>
        </Stack>
    )
}
