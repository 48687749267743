import React from "react";
import { Button } from "../../../../components/common/button/Button";
import { HiOutlineDownload } from "react-icons/hi";
import { BsQrCode } from "react-icons/bs";
import { Input } from "../../../../components/common/input/Input";
import Underline from "../../../../components/buyer-provider/Underline";

export const Configure = ({ setIsTwoFA }) => {
  return (
    <>
      <div className="top-nav py-3 mt-4 mb-4">
        <h4 className="mb-0">Configure</h4>
      </div>
      <div className="d-flex gap-3 mt-5">
        <HiOutlineDownload size={24} color="#920af2" />
        <div>
          <p className="policy-instruction">
            Download an authentication app from Google Play Store or App Store
          </p>
          <p className="primary-text-color">WHY DO I NEED TO DO THIS?</p>
        </div>
      </div>
      <div className="d-flex gap-3 mt-4">
        <HiOutlineDownload size={24} color="#920af2" />

        <p className="policy-instruction">
          Then open the authentication and use your phone camera to scan this
          barcode.
        </p>
      </div>
      <div className="mt-3 px-5">
        <BsQrCode size={210} />

        <p className="policy-instruction pt-3">
          Can’t scan this barcode? Manually enter this code into the
          authentication app
        </p>

        <h6 className="QR-number pt-3">2YTTWBBE8999</h6>

        <p className="primary-text-color pt-2">Copy to Clipboard</p>
      </div>
      <div className="d-flex gap-3 mt-4">
        <HiOutlineDownload size={24} color="#920af2" />

        <div className="w-75">
          <p className="policy-instruction">
            Once you’ve configured the authentication app, enter the given
            verification code
          </p>
          <Input placeholder="Verification code" className="mt-2"/>
        </div>
      </div>

      <Underline className="mt-5"/>

      <Button
        className="py-3 px-4 mt-4"
        onClick={() => setIsTwoFA("twoFAEnabled")}
      >
        Finish
      </Button>
    </>
  );
};
