import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Input } from '../../../common/input/Input'
import MyPhoneInput from '../../../common/my-phone-input/MyPhoneInput'

export const AddClient = ({ show, handleClose }) => {

    const handleInvite = (e) => {
        e.preventDefault()
    }

    return (
        <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='mb-3'>
                    <h5 className='text-black fw-semibold'>Add Client</h5>
                    <p>You can add client details here</p>
                </div>
                <Form onSubmit={handleInvite}>
                    <Input
                        label='Client Name'
                        placeholder='Enter name'
                        className='mb-2'
                    />
                    <Input
                        label='Email address'
                        placeholder='Enter email address'
                        className='mb-2'
                    />
                    <Input
                        label='Address'
                        placeholder='Enter your address'
                        className='mb-2'
                        multiline
                    />
                    <MyPhoneInput
                        label='Phone Number'
                        className='mb-2'
                    />
                    <Input
                        label='Notes'
                        placeholder='Enter notes'
                        className='mb-3'
                        multiline
                    />
                    <Button className='py-3 w-100'>
                        Add Client
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
