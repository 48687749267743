import React, { useState } from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { Select } from '../../../common/select/Select'
import { CardWrapper } from '../../../common/Default'
import { USER } from '../../../../assets/images.assets'
import { AlertModal } from '../../work-orders/modals/AlertModal'
import { ReactComponent as Message } from '../../../../assets/images/message.svg'

export const MoveProvider = ({ show, handleClose }) => {

    const [showAlertModal, setShowAlertModal] = useState(false)
    
    const handleSubmit = (e) => {
        e.preventDefault()
        setShowAlertModal(true)
    }

    return (
        <>
            <Modal dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='mb-3'>
                        <h5 className='text-black fw-semibold'>Move Provider</h5>
                        <p>You are about to move this provider</p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className='mb-3'>
                            <p className='text-black font-14 fw-500 mb-1'>You are moving</p>
                            <CardWrapper className='list-group__item-active'>
                                <Stack direction='horizontal' className='gap-2'>
                                    <img src={USER} alt="Avatar" />
                                    <p className='color-grayscale-900 fw-500'>Adeleke Fajuyi Olayiwola</p>
                                </Stack>
                            </CardWrapper>
                        </div>
                        <Select
                            label='Move to'
                            name='moveTo'
                            inputId='moveTo'
                            htmlFor='moveTo'
                            placeholder='Select Pool'
                            options={[
                                { label: 'Technicians', value: 'technicians' },
                                { label: 'Mechanics', value: 'mechanics' },
                            ]}
                            className='mb-3'
                        />
                        <Stack direction='horizontal' className='justify-content-between'>
                            <Button
                                type='button'
                                variant='outline'
                                className='color-ebony padded-border-button py-14px'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                className='py-14px'
                            >
                                Move Provider
                            </Button>
                        </Stack>
                    </Form>
                </Modal.Body>
            </Modal>
            <AlertModal 
                show={showAlertModal} 
                handleClose={() => setShowAlertModal(false)} 
                image={Message} 
                title='Move Successful'
                subtitle='You have move the provider successfully'
            />
        </>
    )
}
