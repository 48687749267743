import React, { Fragment, useState } from 'react'
import { Offcanvas, Stack } from 'react-bootstrap'
import { MdStar } from 'react-icons/md'
import Rating from 'react-rating'
import { Button } from '../../../common/button/Button'
import { ApprovalModificationPrompt } from '../modal/ApprovalModificationPrompt'

export const ApprovalModificationTimesheet = ({ show, handleClose, pocRequest }) => {

    const [showApprovalModificationPrompt, setShowApprovalModificationPrompt] = useState(false)

    return (
        <Fragment>
            <Offcanvas
                show={show}
                onHide={handleClose}
                className='timesheet-offcanvas'
                placement='end'
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='fw-bold'>Timesheet</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='d-flex flex-column'>
                    <div className='d-flex flex-column flex-grow-1'>
                        <div className='font-14 mb-3'>
                            <p className='text-black fw-medium'>Work Order Details</p>
                            <hr className='opacity-100 my-2 border-grayscale-100' />
                            {/* <p className='color-grey-500'>Ademola Fajuyi</p> */}
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Work Order ID</p>
                                <p className='color-grey-500 col-md-8'>#2283812</p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Work Order Title</p>
                                <p className='color-grey-500 col-md-8'>Plumber</p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Location</p>
                                <p className='color-grey-500 col-md-8'>Birmingham, United Kingdom</p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2 align-items-start'>
                                <p className='color-grey-500 col-md-4'>Description</p>
                                <p className='color-grey-500 col-md-8'>
                                    Water purification and drilling of borehole for a 5 bedroom duplex...
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className='align-items-start'>
                                <p className='color-grey-500 col-md-4'>Close-Out Notes</p>
                                <p className='color-grey-500 col-md-8'>
                                    Water purification and drilling of borehole for a 5 bedroom duplex...
                                </p>
                            </Stack>
                        </div>
                        <div className='font-14 mb-3'>
                            <p className='text-black fw-medium'>Timesheet</p>
                            <hr className='opacity-100 my-2 border-grayscale-100' />
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-5'>Check-In Date & Time</p>
                                <p className='color-grey-500 col-md-7'>
                                    13/04/2024 | 11:30AM
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-5'>Check-Out Date & Time</p>
                                <p className='color-grey-500 col-md-7'>
                                    13/04/2024 | 11:30AM
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className=''>
                                <p className='color-grey-500 col-md-5'>Total Hours</p>
                                <p className='color-grey-500 col-md-7'>
                                    12 hours
                                </p>
                            </Stack>
                        </div>
                        <div className='font-14 mb-3'>
                            <p className='text-black fw-medium'>Client & Project Details</p>
                            <hr className='opacity-100 my-2 border-grayscale-100' />
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Client</p>
                                <p className='color-grey-500 col-md-8'>
                                    Iristech
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className=''>
                                <p className='color-grey-500 col-md-4'>Project</p>
                                <p className='color-grey-500 col-md-8'>
                                    Plumbing Renew
                                </p>
                            </Stack>
                        </div>
                        <div className='font-14 mb-3'>
                            <p className='text-black fw-medium'>Ratings</p>
                            <hr className='opacity-100 my-2 border-grayscale-100' />
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Timeliness</p>
                                <p className='color-grey-500 col-md-8'>
                                    <Rating
                                        fullSymbol={<MdStar size={18} color='#FB9905' />}
                                        emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                                        readonly
                                    />
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Professionalism</p>
                                <p className='color-grey-500 col-md-8'>
                                    <Rating
                                        fullSymbol={<MdStar size={18} color='#FB9905' />}
                                        emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                                        readonly
                                    />
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Quality of work</p>
                                <p className='color-grey-500 col-md-8'>
                                    <Rating
                                        fullSymbol={<MdStar size={18} color='#FB9905' />}
                                        emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                                        readonly
                                    />
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Customer service</p>
                                <p className='color-grey-500 col-md-8'>
                                    <Rating
                                        fullSymbol={<MdStar size={18} color='#FB9905' />}
                                        emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                                        readonly
                                    />
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className='mb-2'>
                                <p className='color-grey-500 col-md-4'>Instructions</p>
                                <p className='color-grey-500 col-md-8'>
                                    <Rating
                                        fullSymbol={<MdStar size={18} color='#FB9905' />}
                                        emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                                        readonly
                                    />
                                </p>
                            </Stack>
                            <Stack direction='horizontal' className=''>
                                <p className='color-grey-500 col-md-4'>Overall satisfaction</p>
                                <p className='color-grey-500 col-md-8'>
                                    <Rating
                                        fullSymbol={<MdStar size={18} color='#FB9905' />}
                                        emptySymbol={<MdStar size={18} color='#D9D9D9' />}
                                        readonly
                                    />
                                </p>
                            </Stack>
                        </div>

                        <Stack direction='horizontal' className='justify-content-between mt-auto'>
                            <Button
                                type='button'
                                variant='outline'
                                className='fw-semibold font-14 px-3 color-ebony padded-border-button'
                            // onClick={handleClose}
                            >
                                Update POC details
                            </Button>
                            <Button
                                type='submit'
                                className='fw-semibold font-14 px-3 py-14px'
                                onClick={() => setShowApprovalModificationPrompt(true)}
                            // disabled={!isFormValid()}
                            >
                                {pocRequest === 'approval' ? 'Resend Approval Reminder' : 'Request Modification'}
                            </Button>
                        </Stack>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <ApprovalModificationPrompt
                show={showApprovalModificationPrompt}
                handleClose={() => setShowApprovalModificationPrompt(false)}
                pocRequest={pocRequest}
            />
        </Fragment>
    )
}
