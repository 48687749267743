import { AuthWrapper } from '../../../../components/common/Default'
import { ReactComponent as EmailBox } from '../../../../assets/images/email-icon.svg'
import { Link, useLocation } from 'react-router-dom';

export const EmailOTPSentMessage = () => {

    const providerEmail = window.localStorage.getItem('PROVIDER_EMAIL') || ''

    const location = useLocation();

    const email = location?.state || providerEmail

    return (
        <AuthWrapper>
            <div className='text-center'>
                <EmailBox className='mb-3' />
                <h5 className='welcome-heading fs-3 fw-bold line-height-125'>Email sent</h5>
                <p className='mb-3' style={{ marginInline: 40 }}>
                    We've sent an OTP to {email}. If it's not in your inbox, check your spam/junk folder.
                </p>
                <Link
                    to='/verify-email-otp'
                    state={email}
                    className='custom_button zero_button py-3 d-block text-decoration-none line-height-150 w-100'
                >
                    Enter OTP
                </Link>
            </div>
        </AuthWrapper>
    )
}
