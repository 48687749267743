import { Card, Col, Container, Row } from "react-bootstrap";
import { Testimonial } from "../authentication/Testimonial";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { IoCloseOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import {
  MainSidebar,
  ProfileSidebar,
  ProviderProfileSidebar,
  ProviderSettingsSidebar,
  SettingsSidebar,
} from "./sidebar/Sidebar";
import { Header } from "./header/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchProviderDetails } from "../../store/provider/provider.action";
import { Stepper } from "./Stepper";
import { Link, useLocation } from "react-router-dom";
import { getAuthProvider } from "../../utils/utils";
import { fetchBuyerDetails } from "../../store/buyer/buyer.action";

export const AuthWrapper = ({ children }) => {
  return (
    <div className="my-3 px-4">
      <Row className="gap-5">
        <Col md={5}>
          <Testimonial />
        </Col>
        <Col as={Row} className="px-0 scrollbar-none wrapper__right" md={6}>
          <div style={{ width: "65%" }}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export const ResetPasswordWrapper = ({ children }) => {
  return (
    <Container className="mt-3">
      <Row className="justify-content-between align-items-center">
        <Col xs={5} sm={4} md={3} className="p-2">
          <Logo />
        </Col>
        <Col xs={2} className="text-end">
          <Link to="/login">
            <IoCloseOutline size={24} color="black" />
          </Link>
        </Col>
      </Row>
      <hr className="border-botticelli opacity-100" />
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "calc(100vh - 118px)" }}
      >
        <Col xs={11} sm={8} md={4}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export const ProfileWrapper = ({ children, email, info }) => {
  const location = useLocation();
  const userType = window.localStorage.getItem("USER_TYPE");

  const { parsedAuthData: providerDetails } = getAuthProvider();

  const steps =
    userType === "provider"
      ? [
          "/tax-information",
          "/photo-location",
          "/resume-uploader",
          "/skills",
          "/bio",
        ]
      : [
          "/get-started",
          "/tax-information",
          "/add-funds",
          "/invite-team-members",
          "/person-of-contact",
        ];

  return (
    <Container className="mt-3">
      <div className="p-2 my-2">
        <Logo />
      </div>
      <Row className="justify-content-center profile-wrapper mb-3">
        <Col xs={11} sm={8} md={4}>
          {steps.includes(location.pathname) && (
            <Stepper steps={steps} currentStep={location.pathname} />
          )}
        </Col>
      </Row>
      <Row
        className="justify-content-center align-items-center overflow-y-auto profile-wrapper"
        style={{ height: "calc(100vh - 118px)", scrollbarWidth: "none" }}
      >
        <Col xs={11} sm={8} md={4}>
          {email && (
            <div className="user-profile-card">
              <span>{providerDetails?.email}</span>
              <FaRegUser size={12} className="text-black ms-1" />
            </div>
          )}
          {info && (
            <div className="text-center">
              <h5 className="welcome-heading line-height-125">{info?.title}</h5>
              <p className="font-14 fw-medium line-height-160 color-grey-500 mb-3">
                {info?.description}
              </p>
            </div>
          )}
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export const MainDefault = ({ children, containerClasses }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const userType = window.localStorage.getItem("USER_TYPE");

  // const providerDetails = useSelector(state => state.providerReducer.providerDetails)
  // const buyerDetails = useSelector(state => state.buyerReducer.buyerDetails)

  // if(!providerDetails?.isProfileCompleted ) {
  // }
  // if(!buyerDetails?.isProfileCompleted ) {
  // }

  useEffect(() => {
    if (userType === "provider") {
      dispatch(fetchProviderDetails());
    } else if (userType === "buyer") {
      dispatch(fetchBuyerDetails());
    }
  }, []);

  return (
    <div className="d-flex overflow-x-hidden" id="wrapper">
      {location.pathname.includes("/profile") || location.pathname.includes("/settings") ? (
        <ProfileSidebar />
      ) : (
        <MainSidebar />
      )}
      <div id="page-content-wrapper">
        <Header />
        <Container fluid className={`py-3 px-4 scrollbar-thin ${containerClasses}`}>
          {children}
        </Container>
      </div>
    </div>
  );
};

export const MainDefaultSettingSideBar = ({ children, containerClasses }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const userType = window.localStorage.getItem("USER_TYPE");

  useEffect(() => {
    if (userType === "provider") {
      dispatch(fetchProviderDetails());
    } else if (userType === "buyer") {
      dispatch(fetchBuyerDetails());
    }
  }, []);

  return (
    <div className="d-flex overflow-x-hidden" id="wrapper">
      {location.pathname.includes("/settings") ? (
        <SettingsSidebar />
      ) : (
        <MainSidebar />
      )}
      <div id="page-content-wrapper">
        <Header />
        <Container fluid className={`py-3 scrollbar-thin ${containerClasses}`}>
          {children}
        </Container>
      </div>
    </div>
  );
};

export const CardWrapper = ({ children, className, ...rest }) => {
  return (
    <Card body className={`card-wrapper ${className}`} {...rest}>
      {children}
    </Card>
  );
};
