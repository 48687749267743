import {
    LIST_TEAM_MEMBERS,
    INVITE_TEAM_MEMBER,
    UPDATE_TEAM_MEMBER,
    DELETE_TEAM_MEMBER,
    FETCH_TEAM_MEMBERS_LOADING,
} from './team-member.action';

const initialState = {
    teamMembers: [],
    isLoading: false,
};

export const teamMemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEAM_MEMBERS_LOADING:
            return {
                ...state,
                isLoading: action.value
            };
        case LIST_TEAM_MEMBERS:
            return { ...state, teamMembers: action.value, isLoading: false };
        case INVITE_TEAM_MEMBER:
            return { ...state, teamMembers: [...state.teamMembers, action.value] };
        case UPDATE_TEAM_MEMBER:
            return {
                ...state,
                teamMembers: state.teamMembers.map(member =>
                    member.id === action.value?._id ? action.value : member
                ),
            };
        case DELETE_TEAM_MEMBER:
            return {
                ...state,
                teamMembers: state.teamMembers.filter(member => member.id !== action.value),
            };
        default:
            return state;
    }
};