import React from "react";
import { JobInfoHeader } from "../JobInfoHeader";
import { Col, Row } from "react-bootstrap";
import { UserInformationCard } from "../../buyer-provider/user-information-card/UserInformationCard";

export const Providers = () => {
  const dropdownItems = [
    { name: "Assign work order" },
    { name: "Message applicant" },
    { name: " Block applicant" },
  ];
  return (
    <>
      <JobInfoHeader />

      <Row className="row-gap-4">
        <Col md={3}>
          <UserInformationCard
            userName="Ademola Adetunji"
            address="Amsterdam, UK Profeesional"
            dropdownItems={dropdownItems}
          />
        </Col>
        <Col md={3}>
          <UserInformationCard
            userName="Ademola Adetunji"
            address="Amsterdam, UK Profeesional"
            dropdownItems={dropdownItems}
          />
        </Col>
        <Col md={3}>
          <UserInformationCard
            userName="Ademola Adetunji"
            address="Amsterdam, UK Profeesional"
            dropdownItems={dropdownItems}
          />
        </Col>
        <Col md={3}>
          <UserInformationCard
            userName="Ademola Adetunji"
            address="Amsterdam, UK Profeesional"
            dropdownItems={dropdownItems}
          />
        </Col>
      </Row>
    </>
  );
};
