import { Fragment, useEffect, useMemo, useState } from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { NotFoundCard } from '../../../../components/profile/not-found/NotFoundCard'
import { CreateRole } from '../../../../components/profile/offcanvas/CreateRole'
import { Badge, ListGroup, NavDropdown, Stack } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'
import { Table } from '../../../../components/common/table/Table'
import moment from 'moment'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRole, fetchBuyerRoles, updateRole } from '../../../../store/buyer/roles/roles.action'
import { Loader } from '../../../../components/common/Loader'

export const RolesAndPermissions = () => {

    const dispatch = useDispatch()
    const ROLES_PERMISSIONS = useSelector(state => state.rolesReducer.roles)
    const isLoading = useSelector(state => state.rolesReducer.isLoading)

    const [showRoles, setShowRoles] = useState(false)
    const [modalMode, setModalMode] = useState('create'); // 'create' or 'edit'
    const [roleToEdit, setRoleToEdit] = useState(null);

    const handleDelete = (index) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this role?');
        if (confirmDelete) {
            dispatch(deleteRole(index));
            setTimeout(() => {
                dispatch(fetchBuyerRoles());
            }, 1000);
        }
    }

    const handleUpdateStatus = (info) => {
        const data = {
            role: info?._id,
            status: info?.status == 'Active' ? 'Inactive' : 'Active'
        }
        dispatch(updateRole(data))
        setTimeout(() => {
            dispatch(fetchBuyerRoles());
        }, 1000);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'roleName',
                header: () => 'Role',
                cell: info => info.renderValue(),
                // enableSorting: false
            },
            {
                accessorKey: 'createdAt',
                header: () => 'Created/Modified',
                cell: ({ row }) => {
                    const { createdAt, updatedAt } = row.original
                    const status = createdAt === updatedAt ? 'Created' : 'Modified'
                    const date = status === 'Created' ? createdAt : updatedAt
                    const formattedDate = moment(date).format('MMM DD, YYYY | hh:mmA')
                    return `${status} by ${formattedDate}`;
                },
                // enableSorting: false
            },
            {
                accessorKey: 'status',
                header: () => 'Status',
                cell: (info) => {
                    const status = info.renderValue()
                    return <Badge className={`rounded-3 text-uppercase ${status === 'Active' ? 'badge-status__active' : 'badge-status__inactive'}`}>
                        {info.renderValue()}
                    </Badge>
                },
                // enableSorting: false
            },
            {
                accessorKey: 'createdBy',
                header: () => 'By',
                cell: info => info.renderValue(),
                // enableSorting: false
            },
            {
                id: 'actions',
                header: () => 'Action',
                cell: ({ row }) => {
                    const rowIndex = row.original?._id

                    return <NavDropdown
                        title={<MdOutlineMoreHoriz size={24} />}
                        style={{ width: 'fit-content' }}
                    >
                        <ListGroup variant='flush'>
                            <ListGroup.Item
                                action
                                onClick={() => handleUpdateStatus(row.original)}
                                className='cursor-pointer color-gray-700 border-bottom-0'
                            >
                                Set {row.original.status == 'Active' ? 'Inactive' : 'Active'}
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                onClick={() => {
                                    setRoleToEdit(row.original);
                                    setModalMode('edit');
                                    setShowRoles(true);
                                }}
                                className='cursor-pointer color-gray-700 border-bottom-0'
                            >
                                Edit
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                onClick={() => handleDelete(rowIndex)}
                                className='cursor-pointer color-gray-700'
                            >
                                Delete
                            </ListGroup.Item>
                        </ListGroup>
                    </NavDropdown>
                },
            },
        ],
        []
    )

    useEffect(() => {
        dispatch(fetchBuyerRoles())
    }, [])

    return (
        <MainDefault containerClasses={[""].length <= 0 ? 'bg-grayscale-50' : 'bg-white'}>
            {isLoading ? (
                <Loader />
            ) : ROLES_PERMISSIONS.length <= 0 ? (
                <Fragment>
                    <h4 className='mb-0 fw-bold topHead'>Roles and Permissions</h4>
                    <NotFoundCard
                        title='No role added'
                        subTitle='You can created your desired role'
                        buttonText='Create role'
                        onButtonClick={() => {
                            setModalMode('create');
                            setShowRoles(true);
                        }}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <Stack
                        direction='horizontal'
                        className='top-nav justify-content-between py-3 mb-3'
                    >
                        <h4 className='mb-0'>Roles and Permissions</h4>
                        <Button
                            className='px-3'
                            style={{ paddingBlock: 12 }}
                            onClick={() => {
                                setModalMode('create');
                                setShowRoles(true);
                            }}>
                            Create role
                        </Button>
                    </Stack>
                    <Table data={ROLES_PERMISSIONS} columns={columns} />
                </Fragment>
            )}
            <CreateRole
                show={showRoles}
                handleClose={() => {
                    setShowRoles(false)
                    setRoleToEdit(null)
                }}
                mode={modalMode}
                roleData={roleToEdit}
            />
        </MainDefault>
    )
}