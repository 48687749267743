import { LuShare2 } from 'react-icons/lu'
import { CommonHeader } from '../../buyer-provider/common-header/CommonHeader'
import { CardWrapper } from '../../common/Default'
import { Col, Row, Stack } from 'react-bootstrap'
import { Table } from '../../common/table/Table'
import Avatar from 'react-avatar'
import { useMemo } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { MyTooltip } from '../../common/tooltip/MyTooltip'

export const TransactionsContent = () => {

  const columns = useMemo(() => [
    {
      accessorKey: "initiationDate",
      header: () => "Initiation Date",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "completionDate",
      header: () => "Completion Date",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "projectTitle",
      header: () => "Project Title",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "description",
      header: () => "Description",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "provider",
      header: () => "Provider",
      cell: ({ row }) => (
        <Stack direction='horizontal' className='gap-2'>
          <Avatar round size='30' name={row.original.provider} />
          <span>{row.original.provider}</span>
        </Stack>
      ),
    },
    {
      accessorKey: "providerNet",
      header: () => "Provider’s Net",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "totalCost",
      header: () => (
        <span>
          Total Cost
          <MyTooltip
            content={
              <>
                <p className='fw-600 font-14'>Note!</p>
                <p className='font-14'>4% platform fee is charged on the total cost</p>
              </>
            }
          >
            <HiInformationCircle size={16} className='mb-2px ms-1' />
          </MyTooltip>
        </span>
      ),
      cell: (info) => info.renderValue(),
    },
  ], [])

  const rows = [
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      providerNet: '$3,000.00',
      totalCost: '$600.00'
    },
  ]

  return (
    <>
      <Row className='row-gap-3 mb-4'>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Total Expenses</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are number of providers you have assigned work orders
            </p>
            <h3 className='text-black fw-700 mb-0'>$60,000</h3>
          </CardWrapper>
        </Col>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Total Deposited</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are number of providers rejected on different work
            </p>
            <h3 className='text-black fw-700 mb-0'>$40,000</h3>
          </CardWrapper>
        </Col>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Total Current Balance</h6>
            <p className='color-grey-500 mb-4 font-14 desc'>
              These are providers you have saved in your pool.
            </p>
            <h3 className='text-black fw-700 mb-0'>20</h3>
          </CardWrapper>
        </Col>
        <Col md={3}>
          <CardWrapper>
            <h6 className='text-black font-18'>Total Charges</h6>
            <p className='color-grey-500 mb-4 font-14 desc'>
              These are providers you have saved in your pool.
            </p>
            <h3 className='text-black fw-700 mb-0'>$6000.00</h3>
          </CardWrapper>
        </Col>
      </Row>
      <h6 className='text-black font-18 mb-3'>Work Order Expenses Breakdown</h6>
      <CommonHeader filterIcon={LuShare2} filterButtonText='Export' showUnderLine={false} />
      <div className='mt-3'>
        <Table data={rows} columns={columns} />
      </div>
    </>
  )
}
