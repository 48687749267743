import PostRequest from "../../services/PostRequest";

export const FETCH_BUYER_DETAILS = "FETCH_BUYER_DETAILS";
export const FETCH_BUSINESS_DETAILS = "FETCH_BUSINESS_DETAILS";
export const FETCH_INDUSTRIES = "FETCH_INDUSTRIES";
export const FETCH_TAX_DETAILS = "FETCH_TAX_DETAILS";
export const FETCH_WALLET_DETAILS = "FETCH_WALLET_DETAILS";
export const FETCH_TEAM_MEMBERS = "FETCH_TEAM_MEMBERS";
export const ACTIVE_LAYOUT = "ACTIVE_LAYOUT";
export const CHECKED_ITEMS = "CHECKED_ITEMS";

export const fetchBuyerDetails = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/buyer/profile");
      dispatch({
        type: FETCH_BUYER_DETAILS,
        value: response,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const fetchBusinessDetails = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/buyer/business-details/get");
      dispatch({
        type: FETCH_BUSINESS_DETAILS,
        value: response,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const fetchTaxDetails = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/buyer/tax-details/get");
      dispatch({
        type: FETCH_TAX_DETAILS,
        value: response,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const fetchWalletDetails = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/buyer/wallet-details/get");
      dispatch({
        type: FETCH_WALLET_DETAILS,
        value: response,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const fetchTeamMembers = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/buyer/team-members/get");
      dispatch({
        type: FETCH_TEAM_MEMBERS,
        value: response,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const fetchIndustries = () => {
  return async (dispatch) => {
    try {
      const response = await PostRequest("/buyer/industries");
      dispatch({
        type: FETCH_INDUSTRIES,
        value: response,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const activeTabLayout = (data = "cards") => {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_LAYOUT,
      value: data,
    });
  };
};

export const setCheckedItems = (data) => {
  return (dispatch) => {
    dispatch({
      type: CHECKED_ITEMS,
      value: data,
    });
  };
}