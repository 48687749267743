// import { ReactComponent as Dashboard } from '../assets/images/sidebar/elements.svg'
import { ReactComponent as Users } from "../assets/images/sidebar/users.svg";
import { ReactComponent as MyWork } from "../assets/images/sidebar/briefcase.svg";
import { ReactComponent as Payments } from "../assets/images/sidebar/card.svg";
import { ReactComponent as MyAgreements } from "../assets/images/sidebar/suitcase.svg";
import {
  FiFileText,
  FiHelpCircle,
  FiSettings,
  FiHelpCircle as Support,
} from "react-icons/fi";
import { ReactComponent as Pin } from "../assets/images/pin.svg";

import { ReactComponent as Projects } from "../assets/images/sidebar/folder-user.svg";
import { ReactComponent as Reports } from "../assets/images/sidebar/chart.svg";
import { ReactComponent as POC } from "../assets/images/sidebar/user-tick.svg";
import { ReactComponent as Clients } from "../assets/images/building.svg";
import { ReactComponent as MessageSmile } from "../assets/images/sidebar/message-smile-circle.svg";
import { HiOutlineDocumentCheck as Agreements } from "react-icons/hi2";
import { InvitesContent } from "../components/dashboard/job-updates/provider/InvitesContent";
import { DraftsContent } from "../components/dashboard/job-updates/buyer/DraftsContent";
import { TemplatesContent } from "../components/dashboard/job-updates/buyer/TemplatesContent";
import { PublishedContent } from "../components/dashboard/job-updates/buyer/PublishedContent";
import { AssignedContent } from "../components/dashboard/job-updates/buyer/AssignedContent";
import { InProgressContent } from "../components/dashboard/job-updates/buyer/InProgressContent";
import { CompletedContent } from "../components/dashboard/job-updates/buyer/CompletedContent";
import { ApprovedContent } from "../components/dashboard/job-updates/buyer/ApprovedContent";
import { useActiveLayout } from "../hooks/useActiveLayout";
import { JobInformation } from "../components/job-info-tab/job-information/JobInformation";
import { AdditionalInformation } from "../components/job-info-tab/additional-inforamtion/AdditionalInformation";
import { InviteMember } from "../components/profile/modals/InviteMember";
import MyWorkInvitesContent from "../pages/provider/my-work/my-work-content/MyWorkInvitesContent";
import { Deliverables } from "../components/job-info-tab/deliverables/Deliverables";
import { Applicants } from "../components/job-info-tab/applicants/Applicants";
import WorkDetailsContent from "../components/dashboard/job-updates/provider/provider-work-order-details/tab-content/WorkDetailsContent";

import TermsConditionContent from "../components/dashboard/job-updates/provider/provider-work-order-details/tab-content/TermsConditionContent";
import MessagesContent from "../components/dashboard/job-updates/provider/provider-work-order-details/tab-content/MessagesContent";

import { Providers } from "../components/job-info-tab/providers/Providers";
import { CounterList } from "../components/job-info-tab/counter-list/CounterList";
import { UploadAgreement } from "../pages/buyer/agreements/upload-agreement/UploadAgreement";
import { DocuSignAgreements } from "../pages/buyer/agreements/docu-sign-agreements/DocuSignAgreements";
import { ApprovalPending } from "../pages/buyer/payments/payment-tab/ApprovalPending";
import { CurrentPayablec } from "../pages/buyer/payments/payment-tab/CurrentPayablec";
import { LedgerTransactions } from "../pages/buyer/payments/payment-tab/LedgerTransactions";
import { AllProviders } from "../pages/buyer/providers/providers-tab/AllProviders";
import { Blocked } from "../pages/buyer/providers/providers-tab/Blocked";
import { MyProviderPool } from "../pages/buyer/providers/providers-tab/MyProviderPool";
import { Profile } from "../pages/buyer/providers/provider-overview-tab/Profile";
import { Reviews } from "../pages/buyer/providers/provider-overview-tab/Reviews";
import { CompletedJobs } from "../pages/buyer/providers/provider-overview-tab/work-orders-tab/CompletedJobs";
import { InProgress } from "../pages/buyer/providers/provider-overview-tab/work-orders-tab/InProgress";
import { WorkOrders } from "../pages/buyer/providers/provider-overview-tab/WorkOrders";
import { Resume } from "../pages/buyer/providers/provider-overview-tab/Resume";
import { WorkDetailsMyWork } from "../components/dashboard/job-updates/provider/provider-my-work-order-details/my-work-tab-content/WorkDetailsMyWork";
import { DeliverablesMyWok } from "../components/dashboard/job-updates/provider/provider-my-work-order-details/my-work-tab-content/DeliverablesMyWok";
import { TermsAndConditionMyWork } from "../components/dashboard/job-updates/provider/provider-my-work-order-details/my-work-tab-content/TermsAndConditionMyWork";
import { MessagesMyWork } from "../components/dashboard/job-updates/provider/provider-my-work-order-details/my-work-tab-content/MessagesMyWork";
import { ProvidersContent } from "../components/buyer/reports/providers-tab/ProvidersContent";
import { WorkOrdersContent } from "../components/buyer/reports/WorkOrdersContent";
import { TransactionsContent } from "../components/buyer/reports/TransactionsContent";
import { ClientsContent } from "../components/buyer/reports/ClientsContent";
import { ProjectContent } from "../components/buyer/reports/ProjectContent";
import { InsuranceContent } from "../pages/provider/profile/additional-background/InsuranceAndLicenses/InsuranceContent";
import { LicensesContent } from "../pages/provider/profile/additional-background/InsuranceAndLicenses/LicensesContent";
// import { ReactComponent as Support } from '../assets/images/sidebar/question-circle.svg'

export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const PROVIDER_SIDEBAR_ITEMS = [
  // {
  //     to: '/dashboard',
  //     icon: Dashboard,
  //     label: 'Dashboard',
  //     paths: []
  // },
  {
    to: "/find-work",
    icon: Users,
    label: "Find Work",
    paths: [],
  },
  {
    to: "/my-work",
    icon: MyWork,
    label: "My Work",
    paths: [],
  },
  {
    to: "/payments",
    icon: Payments,
    label: "Payments",
    paths: [],
  },
  {
    to: "/calendar",
    icon: Users,
    label: "Calendar",
    paths: [],
  },
  {
    to: "/my-agreements",
    icon: MyAgreements,
    label: "My Agreements",
    paths: [],
  },
  {
    to: "/support",
    icon: Support,
    label: "Support",
    paths: [],
  },
];

export const BUYER_SIDEBAR_ITEMS = [
  {
    to: "/work-orders",
    icon: MyWork,
    label: "Work Orders",
    paths: [
      "/work-orders/basic-information",
      "/work-orders/location-schedule",
      "/work-orders/invite-provider",
      "/work-orders/agreements",
      "/work-orders/pricing",
      "/work-orders/details",
    ],
  },
  {
    to: "/providers",
    icon: Users,
    label: "Providers",
    paths: ["/providers/details"],
  },
  {
    to: "/clients",
    icon: Clients,
    label: "Clients",
    paths: [],
  },
  {
    to: "/projects",
    icon: Projects,
    label: "Projects",
    paths: [],
  },
  {
    to: "/agreements",
    icon: Agreements,
    label: "Agreements",
    paths: [],
  },
  {
    to: "/payments",
    icon: Payments,
    label: "Payments",
    paths: [],
  },
  {
    to: "/reports",
    icon: Reports,
    label: "Reports",
    paths: [],
  },
  {
    to: "/poc",
    icon: POC,
    label: "POC",
    paths: ["/poc/details"],
  },
  {
    to: "/support",
    icon: MessageSmile,
    label: "Support",
    paths: [],
  },
];

export const PROFILE_ITEMS = [
  // {
  //     to: '/profile',
  //     icon: FiHome,
  //     label: 'My Profile',
  //     paths: []
  // },
  // {
  //   to: "/settings",
  //   icon: FiSettings,
  //   label: "Settings",
  //   paths: [],
  // },
  {
    to: "/help",
    icon: FiHelpCircle,
    label: "Help",
    paths: [],
  },
  {
    to: "/legal",
    icon: FiFileText,
    label: "Legal",
    paths: [],
  },
  {
    to: "/privacy-policy",
    icon: Pin,
    label: "Privacy Policy",
    paths: [],
  },
];

export const BUYER_PROFILE_SIDEBAR_ITEMS = [
  {
    title: "General",
    items: [
      { label: "Company Profile", to: "/profile/company" },
      {
        label: "Tax Information",
        to: "/profile/tax-information",
        paths: [
          "/profile/tax-information/identity-verification",
          "/profile/tax-information/information-protection",
        ],
      },
      { label: "Tax Documentation", to: "/profile/tax-documentation" },
    ],
  },
  {
    title: "Payment Management",
    items: [
      { label: "Payment Method", to: "/profile/payment-method" },
      { label: "Spending Limit", to: "/profile/spending-limit" },
    ],
  },
  {
    title: "User Management",
    items: [{ label: "Manage Members", to: "/profile/manage-members" }],
  },
  {
    title: "Company Settings",
    items: [
      { label: "Roles and Permissions", to: "/profile/roles-permissions" },
      { label: "Activity Log", to: "/profile/activity-log" },
      { label: "Integrations", to: "/profile/integrations" },
    ],
  },
];

export const PROVIDER_PROFILE_SIDEBAR_ITEMS = [
  {
    title: "Profile Settings",
    items: [
      { label: "Contact Information", to: "/profile/my" },
      {
        label: "Tax Information",
        to: "/profile/tax-information/identity-verification",
        paths: [
          // "/profile/tax-information/",
          "/profile/tax-information/information-protection",
        ],
      },
      { label: "Tax Documents", to: "/profile/tax-documentation" },
      { label: "Resources", to: "/profile/resources" },
      { label: "Payment Details", to: "/profile/payment-details" },
      { label: "Become a Service Company", to: "/profile/become-service-company" },
    ],
  },
  {
    title: "Additional Background",
    items: [
      { label: "Bio & Resume", to: "/profile/bio-and-resume" },
      { label: "Rates & Location", to: "/profile/rates-and-location" },
      { label: "Working hours", to: "/profile/working-hours" },
      { label: "Certifications", to: "/profile/certifications" },
      { label: "Insurance & Licenses", to: "/profile/insurance-and-licenses" },
      { label: "Languages", to: "/profile/languages" },
      { label: "Screenings", to: "/profile/screenings" },
    ],
  },
  
];

export const BUYER_SETTINGS_SIDEBAR_ITEMS = [
  {
    title: "Account Settings",
    items: [
      { label: "Notifications", to: "/settings/notifications" },
      { label: "Password", to: "/settings/password" },
      { label: "Security", to: "/settings/security" },
    ],
  },
];
export const PROVIDER_SETTINGS_SIDEBAR_ITEMS = [
  {
    title: "Account Settings",
    items: [
      { label: "Notification", to: "/settings/notifications" },
      { label: "Password", to: "/settings/password" },
      { label: "Sync Calendar", to: "/settings/sync-calendar" },
      { label: "Two-Factor Authentication", to: "/settings/two-factor-auth" },
    ],
  },
  
];

export const PERMISSIONS = [
  {
    label: "Work Orders",
    name: "workOrders",
    children: [
      { label: "Can create work order", name: "canCreateWorkOrder" },
      { label: "Can delete work order", name: "canDeleteWorkOrder" },
      { label: "Can edit work order", name: "canEditWorkOrder" },
      { label: "Can assign work order", name: "canAssignWorkOrder" },
      { label: "Can smart invite", name: "canSmartInvite" },
    ],
  },
  {
    label: "Providers",
    name: "providers",
    children: [
      { label: "Can invite providers", name: "canInviteProvider" },
      { label: "Can bulk invite providers", name: "canBulkInviteProvider" },
      { label: "Can add people to pool", name: "canAddPeopleToPool" },
      { label: "Can create new pool", name: "canCreateNewPool" },
      {
        label: "Can order background checks",
        name: "canOrderBackgroundChecks",
      },
      { label: "Can order drug test", name: "canOrderDrugTest" },
    ],
  },
  {
    label: "Client",
    name: "client",
    children: [
      { label: "Can create clients", name: "canCreateClients" },
      { label: "Can edit client", name: "canEditClient" },
      { label: "Can view reports", name: "canViewReports" },
    ],
  },
  {
    label: "Projects",
    name: "projects",
    children: [
      { label: "Can create project", name: "canCreateProject" },
      { label: "Can edit project", name: "canEditProject" },
      { label: "Can delete project", name: "canDeleteProject" },
    ],
  },
  {
    label: "Payment",
    name: "payment",
    children: [
      { label: "Can add funds to wallet", name: "canAddFunds" },
      { label: "Can approve payment", name: "canApprovePayment" },
      {
        label: "Can download or export payment report",
        name: "canDownloadReport",
      },
    ],
  },
  {
    label: "POC",
    name: "poc",
    children: [
      { label: "Can create person of contact", name: "canCreatePOC" },
      { label: "Can edit person of contact", name: "canEditPOC" },
      {
        label: "Can resend timesheet for approval",
        name: "canResendTimesheet",
      },
      {
        label: "Can request timesheet modification ",
        name: "canRequestTimesheetModification",
      },
    ],
  },
  {
    label: "Support",
    name: "support",
    children: [
      { label: "Can create tickets", name: "canCreateTicket" },
      { label: "Can edit tickets", name: "canEditTicket" },
      { label: "Can post suggestion", name: "canPostSuggestion" },
      { label: "Can comments on a ticket detail ", name: "canCommentTicket" },
      { label: "Can vote a suggestion ", name: "canVoteSuggestion" },
    ],
  },
];

export const selectStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.selectProps.value ? "black" : "#667085",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  container: (provided) => ({
    ...provided,
    display: "block",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 500,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "10px",
    borderColor: state.isFocused || state.menuIsOpen ? "#920AF2" : "#E2E8F0",
    padding: "10px",
    boxShadow: "none",
    ":hover": {
      borderColor: state.menuIsOpen ? "#920AF2" : "#E2E8F0",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#94A3B8",
    fontWeight: 400,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#920AF2" : "#0000",
    color: state.isFocused ? "white" : "black",
    ":active": {
      backgroundColor: "#920AF2",
    },
  }),
};

export const dashboardFilterDropdownStyles = {
  ...selectStyles,
  control: (provided, state) => ({
    ...provided,
    borderRadius: "10px",
    borderColor: state.isFocused || state.menuIsOpen ? "#920AF2" : "#E2E8F0",
    padding: "0 4px",
    // minWidth: 150,
    boxShadow: "none",
    ":hover": {
      borderColor: state.menuIsOpen ? "#920AF2" : "#E2E8F0",
    },
  }),
  container: (provided) => ({
    ...provided,
    color: "#25324B",
    fontSize: 14,
    fontWeight: 400,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#667085",
  }),
};

export const JOB_INFO_navITEMS = [
  { eventKey: "job-information", label: "Job Information" },
  { eventKey: "additional-information", label: "Additional Information" },
  { eventKey: "deliverables", label: "Deliverables" },
  { eventKey: "applicants", label: "Applicants" },
  { eventKey: "providers", label: "Providers" },
  { eventKey: "counter-list", label: "Counter List" },
];
export const JOB_INFO_tabPanes = [
  { eventKey: "job-information", Content: JobInformation },
  { eventKey: "additional-information", Content: AdditionalInformation },
  { eventKey: "deliverables", Content: Deliverables },
  { eventKey: "applicants", Content: Applicants },
  { eventKey: "providers", Content: Providers },
  { eventKey: "counter-list", Content: CounterList },
  { eventKey: "paid", Content: () => <div>Tab content for Paid</div> },
];

export const AGREEMENT_navITEMS = [
  { eventKey: "agreement", label: "Agreement" },
  { eventKey: "docu-sign-agreement", label: "DocuSign Agreement" },
];
export const AGREEMENT_tabPanes = [
  { eventKey: "agreement", Content: UploadAgreement },
  { eventKey: "docu-sign-agreement", Content: DocuSignAgreements },
];

export const PAYMENTS_navITEMS = [
  { eventKey: "approval-pending", label: "Approval Pending" },
  { eventKey: "current-payable", label: "Current Payable" },
  { eventKey: "ledger-transaction", label: "Ledger Transaction" },
];
export const PAYMENTS_tabPanes = [
  { eventKey: "approval-pending", Content: ApprovalPending },
  { eventKey: "current-payable", Content: CurrentPayablec },
  { eventKey: "ledger-transaction", Content: LedgerTransactions },
];

export const BUYER_PROVIDER_navITEMS = [
  { eventKey: "all-providers", label: "All Providers" },
  { eventKey: "blocked", label: "Blocked" },
  { eventKey: "my-provider-pool", label: "My Provider Pool" },
];
export const BUYER_PROVIDER_tabPanes = [
  { eventKey: "all-providers", Content: AllProviders },
  { eventKey: "blocked", Content: Blocked },
  { eventKey: "my-provider-pool", Content: MyProviderPool },
];

export const BUYER_PROVIDER_DETAILS_navITEMS = [
  { eventKey: "profile", label: "Profile" },
  { eventKey: "resume", label: "Resume" },
  { eventKey: "reviews", label: "Reviews" },
  { eventKey: "work-orders", label: "Work Orders" },
];
export const BUYER_PROVIDER_DETAILS_tabPanes = [
  { eventKey: "profile", Content: Profile },
  { eventKey: "resume", Content: Resume },
  { eventKey: "reviews", Content: Reviews },
  { eventKey: "work-orders", Content: WorkOrders },
];

export const BUYER_PROVIDER_WORK_ORDERS_navITEMS = [
  { eventKey: "completed-jobs", label: "Completed Jobs" },
  { eventKey: "in-progress", label: "In Progress" },
];
export const BUYER_PROVIDER_WORK_ORDERS_tabPanes = [
  { eventKey: "completed-jobs", Content: CompletedJobs },
  { eventKey: "in-progress", Content: InProgress },
];

export const PROVIDER_WORK_DETAIL_navITEMS = [
  { eventKey: "Work Details", label: "Work Details" },
  { eventKey: "Deliverables", label: "Deliverables" },
  { eventKey: "Terms & Conditions", label: "Terms & Conditions" },
  { eventKey: "Messages", label: "Messages" },
];

export const PROVIDER_WORK_DETAIL_tabPANELS = [
  { eventKey: "Work Details", Content: WorkDetailsContent },
  { eventKey: "Deliverables", Content: Deliverables },
  { eventKey: "Terms & Conditions", Content: TermsConditionContent },
  { eventKey: "Messages", Content: MessagesContent },
];

export const BUYER_navITEMS = [
  { eventKey: "drafts", label: "Drafts" },
  { eventKey: "templates", label: "Templates" },
  { eventKey: "published", label: "Published" },
  { eventKey: "assigned", label: "Assigned" },
  { eventKey: "in-progress", label: "In-Progress" },
  { eventKey: "completed", label: "Completed" },
  { eventKey: "approved", label: "Approved" },
  { eventKey: "paid", label: "Paid" },
];

export const BUYER_tabPanes = [
  { eventKey: "drafts", Content: DraftsContent },
  { eventKey: "templates", Content: TemplatesContent },
  { eventKey: "published", Content: PublishedContent },
  { eventKey: "assigned", Content: AssignedContent },
  { eventKey: "in-progress", Content: InProgressContent },
  { eventKey: "completed", Content: CompletedContent },
  { eventKey: "approved", Content: ApprovedContent },
  { eventKey: "paid", Content: () => <div>Tab content for Paid</div> },
];

export const MY_WORKS_NAVBAR = [
  { eventKey: "Invites", label: "Invites" },
  { eventKey: "Applied", label: "Applied" },
  { eventKey: "Counter", label: "Counter" },
  { eventKey: "Assigned", label: "Assigned" },
  { eventKey: "in-progress", label: "In-Progress" },
  { eventKey: "completed", label: "Completed" },
  { eventKey: "approved", label: "Approved" },
  { eventKey: "paid", label: "Paid" },
];

export const MY_WORKS = [
  { eventKey: "Invites", Content: MyWorkInvitesContent },
  { eventKey: "Applied", Content: TemplatesContent },
  { eventKey: "Counter", Content: PublishedContent },
  { eventKey: "Assigned", Content: AssignedContent },
  { eventKey: "in-progress", Content: InProgressContent },
  { eventKey: "completed", Content: CompletedContent },
  { eventKey: "approved", Content: ApprovedContent },
  { eventKey: "paid", Content: () => <div>Tab content for Paid</div> },
];

export const PROVIDER_MY_WORK_DETAILS_navITEMS = [
  { eventKey: "work-details", label: "Work Details" },
  { eventKey: "deliverables", label: "Deliverables" },
  { eventKey: "terms-condition", label: "Terms & Condition" },
  { eventKey: "messages", label: "Messages" },
];
export const PROVIDER_MY_WORK_DETAILS_tabPANELS = [
  { eventKey: "work-details", Content: WorkDetailsMyWork },
  { eventKey: "deliverables", Content: DeliverablesMyWok },
  { eventKey: "terms-condition", Content: TermsAndConditionMyWork },
  { eventKey: "messages", Content: MessagesMyWork },
];

export const DASHBOARD_SEARCH_NAVBAR = [
  { eventKey: "all", label: "All" },
  { eventKey: "work orders", label: "Work Orders" },
  { eventKey: "providers", label: "Providers" },
  { eventKey: "clients", label: "Clients" },
  { eventKey: "projects", label: "Projects" },
];

export const DASHBOARD_SEARCH_ITEMS = [
  { eventKey: "all", Content: MyWorkInvitesContent },
  { eventKey: "work orders", Content: TemplatesContent },
  { eventKey: "providers", Content: PublishedContent },
  { eventKey: "clients", Content: AssignedContent },
  { eventKey: "projects", Content: InProgressContent },
];

export const PROVIDER_navItems = [
  { eventKey: "invites", label: "Invites", badge: 3 },
  { eventKey: "applied", label: "Applied" },
  { eventKey: "counter", label: "Counter" },
  { eventKey: "assigned", label: "Assigned" },
  { eventKey: "in-progress", label: "In-Progress" },
  { eventKey: "completed", label: "Completed" },
  { eventKey: "approved", label: "Approved" },
  { eventKey: "paid", label: "Paid" },
];

export const PROVIDER_tabPanes = [
  { eventKey: "invites", Content: InvitesContent },
  { eventKey: "applied", Content: () => <div>Tab content for Applied</div> },
  { eventKey: "counter", Content: () => <div>Tab content for Counter</div> },
  { eventKey: "assigned", Content: () => <div>Tab content for Assigned</div> },
  {
    eventKey: "in-progress",
    Content: () => <div>Tab content for In-Progress</div>,
  },
  {
    eventKey: "completed",
    Content: () => <div>Tab content for Completed</div>,
  },
  { eventKey: "approved", Content: () => <div>Tab content for Approved</div> },
  { eventKey: "paid", Content: () => <div>Tab content for Paid</div> },
];

export const BUYER_REPORTS_navItems = [
  { eventKey: "providers", label: "Providers" },
  { eventKey: "workOrders", label: "Work Orders" },
  { eventKey: "transactions", label: "Transactions" },
  { eventKey: "clients", label: "Clients" },
  { eventKey: "project", label: "Project" }
];
export const BUYER_REPORTS_tabPanes = [
  { eventKey: "providers", Content: ProvidersContent },
  { eventKey: "workOrders", Content: WorkOrdersContent },
  { eventKey: "transactions", Content: TransactionsContent },
  { eventKey: "clients", Content: ClientsContent },
  { eventKey: "project", Content: ProjectContent }
];

export const INSURANCE_LICENSES_navItems = [
  { eventKey: "insurance", label: "Insurance" },
  { eventKey: "licenses", label: "Licenses" }
]
export const INSURANCE_LICENSES_tabPanes = [
  { eventKey: "insurance", Content: InsuranceContent },
  { eventKey: "licenses", Content: LicensesContent }
]

export const declineDropDown = [
  {
    label: "Low Pay",
    value: "Low Pay",
  },
  {
    label: "Schedule Conflict",
    value: "Schedule Conflict",
  },
  {
    label: "Unclear scope of work",
    value: "Unclear scope of work",
  },
  {
    label: "Unreasonable expectations",
    value: "Unreasonable expectations",
  },
  {
    label: "Long proximity",
    value: "Long proximity",
  },
];
