import { NotFoundCard } from "../../../../components/profile/not-found/NotFoundCard";
import { AgreementsTable } from "./AgreementsTable";
import { UploadAgreement as UploadAgreementsForm } from "../../../../components/buyer/agreements/offcanvas/UploadAgreement";
import { useState } from "react";

export const UploadAgreement = () => {
  
  const [uploadAgreement, setUploadAgreement] = useState(false);
  
  return (
    <>
      {[''].length <= 0 ? (
        <>
          <NotFoundCard
            title="No Agreement"
            subTitle="No available agreement has been created yet"
            buttonText="Upload an agreement"
            onButtonClick={() => setUploadAgreement(true)}
          />
          <UploadAgreementsForm show={uploadAgreement} handleClose={() => setUploadAgreement(false)} />
        </>
      ) : (
        <AgreementsTable />
      )}
    </>
  );
};
