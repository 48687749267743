import React from "react";
import WorkTableDefault from "../WorkTableDefault";
import { CardWrapper } from "../../../../../common/Default";
import { Input } from "../../../../../common/input/Input";
import { Button } from "../../../../../common/button/Button";
import { showToaster } from "../../../../../../utils/utils";

const MessagesContent = () => {
  const handelSubmitMessage = (e) => {
    try {
      e.preventDefault();
      e.target.reset();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <WorkTableDefault>
      <CardWrapper>
        <h5 className="mt-1 font-18">Send a message with the buyer</h5>
        <h6 className="mt-4">Your message</h6>

        <form onSubmit={handelSubmitMessage}>
          <Input
            required
            style={{ height: "130px" }}
            multiline={true}
            placeholder="message"
            className={"mt-3"}
          />

          <Button className={"py-3 mt-4"} children={"Submit"} />
        </form>
      </CardWrapper>
    </WorkTableDefault>
  );
};

export default MessagesContent;
