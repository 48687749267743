export const taxOptions = [
    { label: 'Social Security Number (SSN/TIN)', value: 'SSN' },
    { label: 'Employer Identification Number (EIN)', value: 'EIN' },
]

export const taxTypeOptions = [
    { label: 'Sole proprietorship', value: 'Sole proprietorship' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Limited liability company (LLC)', value: 'Limited liability company (LLC)' },
    { label: 'Corporation', value: 'Corporation' },
]

export const titleOptions = [
    { label: 'Shareholder', value: 'shareholder' },
    { label: 'CEO', value: 'ceo' },
    { label: 'President', value: 'president' },
    { label: 'Treasurer', value: 'treasurer' },
    { label: 'Secretary', value: 'secretary' },
    { label: 'Member', value: 'member' },
    { label: 'Partner', value: 'partner' },
    { label: 'Owner', value: 'owner' },
]

const SKILLS = [
    'JavaScript',
    'React',
    'NodeJs',
    'CSS',
    'HTML',
    'Python',
    'Java',
    'Ruby',
    'C++',
    'TypeScript',
    'Git',
    'Docker',
    'AWS'
];

export const skillsWithLabelValue = SKILLS.map(skill => ({
    label: skill,
    value: skill.toLowerCase().replace(/\s+/g, '_') // or any other transformation you need
}))

export const employeeOptions = [
    { label: '1-5', value: '1-5' },
    { label: '5-20', value: '5-20' },
    { label: '20+', value: '20+' }
]

export const cardTypeOptions = [
    { value: 'Credit', label: 'Credit' },
    { value: 'Debit', label: 'Debit' }
]

export const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: i + 1
}))

const currentYear = new Date().getFullYear();
export const yearOptions = Array.from({ length: 10 }, (_, i) => ({
    value: currentYear + i,
    label: currentYear + i
}))

export const dateOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

export const roleOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'Buyer', value: 'buyer' },
    { label: 'Provider', value: 'provider' },
]

const pages = [10, 20, 30, 40, 50]
export const pageOptions = pages.map(page => ({
    value: page,
    label: page
}))

export const DATE_FILTER_OPTIONS = [
    { value: 'today', label: 'Today' },
    { value: 'last7Days', label: 'Last 7 days' },
    { value: 'last30Days', label: 'Last 30 days' },
    { value: 'thisQuarter', label: 'This quarter' },
    { value: 'lastQuarter', label: 'Last quarter' },
    { value: 'thisYear', label: 'This year' },
    { value: 'lastYear', label: 'Last year' },
    { value: 'custom', label: 'Custom' },
]

export const PAYMENT_TERMS_OPTIONS = [
    { value: 'immediately', label: 'Immediately' },
    { value: 'd+7', label: 'D+7' },
    { value: 'd+15', label: 'D+15' },
    { value: 'd+24', label: 'D+24' },
]

export const JOB_STATUS_OPTIONS = [
    { value: 'open', label: 'Open' },
    { value: 'internal', label: 'Internal' }
]

export const INVITE_PROVIDER_SORT_OPTIONS = [
    { label: 'By Location', value: 'location' },
    { label: 'By Distance', value: 'distance' },
    { label: 'By Zip Code', value: 'zipCode' },
    { label: 'By Jobs Completed', value: 'jobsCompleted' },
    { label: 'By Availability', value: 'availability' },
]

export const workOrderOptions = [
    { label: 'WO-#22224449', value: 'WO-#22224449' },
    { label: 'WO-#22224449', value: 'WO-#22224449' },
    { label: 'WO-#22224449', value: 'WO-#22224449' },
]