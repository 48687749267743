import React from "react";
import { RiDownloadCloud2Line } from "react-icons/ri";
import "./downloader.css";

export const Downloader = ({ className, isCSV = false }) => {
  return (
    <>
      <button
        type="button"
        className={`d-flex align-items-center gap-1 download-document ${className}`}
      >
        <RiDownloadCloud2Line className="me-1" size={16} />
        {isCSV ? <span>Download CSV</span> : <span>Download</span>}
      </button>
    </>
  );
};
