import React from 'react'
import { Modal, Stack } from 'react-bootstrap'
import { ReactComponent as Message } from '../../../../assets/images/message.svg'

export const ApprovalModificationSuccess = ({ show, handleClose, pocRequest }) => {
    console.log('pocRequest', pocRequest)
    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Stack gap={3} className='align-items-center mb-3'>
                    <Message />
                    <h5 className='text-black fw-medium text-center'>Request for {pocRequest === 'approval' ? 'approval' : 'modification'} sent</h5>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}
