import { Form, Stack } from 'react-bootstrap'
import { Button } from '../../../components/common/button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import Creatable from 'react-select/creatable'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PostRequest from '../../../services/PostRequest'
import { showToaster } from '../../../utils/utils'
import { fetchProviderDetails, fetchSkills } from '../../../store/provider/provider.action'
import { selectStyles } from '../../../data/constants'

const customStyles = {
    ...selectStyles,
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#F8F8FD',
        padding: '4px',
        gap: 4
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#920AF2',
        padding: 0,
        paddingLeft: 0
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#920AF2',
    }),
    menu: (provided) => ({
        ...provided,
        boxShadow: '5px 5px 50px 0px rgba(26, 32, 44, 0.06)'
    }),
    menuList: (provided) => ({
        ...provided,
        scrollbarWidth: 'none'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#0000',
        color: state.isFocused ? 'black' : '#667185',
        ':active': {
            backgroundColor: '#0000', // '#920AF2'
        }
    })
}

export const SkillsForm = ({
    fromModal = false,
    handleClose = () => { }
}) => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    var SKILLS = useSelector(state => state.providerReducer.skills)
    SKILLS = SKILLS?.map(option => ({
        label: option?.skill,
        value: option?.skill
    }))

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        skills: [],
        equipments: [],
        tools: []
    })
    const [errors, setErrors] = useState({
        skills: '',
        equipments: '',
        tools: ''
    })

    const handleSelectChange = (selectedOption, name) => {
        // const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        // var error = ''
        // if(name === 'skills' && !updatedValue) {
        //     error = 'Skills is required'
        // }
        // setErrors(prevErrors => ({
        //     ...prevErrors,
        //     [name]: error
        // }))
    }

    const handleCreate = async (inputValue, field) => {
        try {
            const newOption = { label: inputValue, value: inputValue.toLowerCase().replace(/\s+/g, '') }
            await PostRequest('/provider/check-spell', { text: inputValue })
            setFormData(prevFormData => ({
                ...prevFormData,
                [field]: [...prevFormData[field], newOption]
            }))
        } catch (error) {
            var errorMessage = error.message
            if(errorMessage === 'Invalid input') {
                errorMessage = `Invalid ${field}`
            } else if (errorMessage === 'text is required.') {
                errorMessage = `${field} is required.`;
            }
            showToaster(errorMessage, 'error')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            skills: '',
            equipments: '',
            tools: ''
        }

        if (formData.skills.length === 0) newErrors.skills = 'Skills is required'

        setErrors(newErrors)

        var data = {}
        var formDataObj = new FormData(e.target)

        const skillsArray = [];
        const equipmentsArray = [];
        const toolsArray = [];

        formDataObj.forEach((val, key) => {
            if (key === 'skills' && val && val.trim() !== '') skillsArray.push(val);
            if (key === 'equipments' && val && val.trim() !== '') equipmentsArray.push(val);
            if (key === 'tools' && val && val.trim() !== '') toolsArray.push(val);
        });

        data['skills'] = skillsArray.length > 0 ? JSON.stringify(skillsArray) : '[]';
        data['equipments'] = equipmentsArray.length > 0 ? JSON.stringify(equipmentsArray) : '[]';
        data['tools'] = toolsArray.length > 0 ? JSON.stringify(toolsArray) : '[]';

        try {
            await PostRequest('/provider/profile/update', data, { showLoader: 'skills-button' })
            if (fromModal) {
                handleClose()
                dispatch(fetchProviderDetails())
            } else {
                navigate('/bio', { state: location?.state })
            }
        } catch (error) {
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        const fields = ['skills', 'equipments', 'tools'];
    
        const updatedFormData = fields.reduce((acc, field) => {
            if (providerDetails?.[field]?.length) {
                acc[field] = providerDetails[field].map(item => ({
                    label: item,
                    value: item
                }));
            }
            return acc;
        }, {});
    
        setFormData(prevFormData => ({
            ...prevFormData,
            ...updatedFormData
        }));
    }, [providerDetails]);

    useEffect(() => {
        dispatch(fetchProviderDetails())
        dispatch(fetchSkills())
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <div className='mb-2'>
                <label className='form-label line-height-160' htmlFor='skills-select'>Your skills</label>
                <Creatable
                    name='skills'
                    inputId='skills-select'
                    placeholder='Start typing to search for skills'
                    options={SKILLS}
                    value={formData?.skills}
                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'skills')}
                    onCreateOption={(inputValue) => handleCreate(inputValue, 'skills')}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customStyles}
                    isSearchable={true}
                    menuPlacement='auto'
                />
                {errors?.skills && <span className='error-text line-height-160 mt-2'>{errors?.skills}</span>}
            </div>
            <div className='mb-2'>
                <label className='form-label line-height-160' htmlFor='skills-select'>Your Equipments</label>
                <Creatable
                    name='equipments'
                    inputId='equipments-select'
                    placeholder='Start typing to search for equipments'
                    options={SKILLS}
                    value={formData?.equipments}
                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'equipments')}
                    onCreateOption={(inputValue) => handleCreate(inputValue, 'equipments')}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customStyles}
                    isSearchable={true}
                    menuPlacement='auto'
                />
            </div>
            <div className='mb-3'>
                <label className='form-label line-height-160' htmlFor='skills-select'>Your Tools</label>
                <Creatable
                    name='tools'
                    inputId='tools-select'
                    placeholder='Start typing to search for tools'
                    options={SKILLS}
                    value={formData?.tools}
                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'tools')}
                    onCreateOption={(inputValue) => handleCreate(inputValue, 'tools')}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customStyles}
                    isSearchable={true}
                    menuPlacement='auto'
                />
            </div>
            {formData.skills?.length === 0 ? (
                <Button type='submit' disabled className='w-100 py-3'>Now, pitch yourself</Button>
            ) : (
                <Stack direction='horizontal' gap={4}>
                    <Button
                        onClick={() => {
                            fromModal ? handleClose() : navigate(-1)
                        }}
                        type='button'
                        variant='outline'
                        className='w-100 color-ebony border-ebony py-3'
                    >
                        {fromModal ? 'Close' : 'Back'}
                    </Button>
                    <Button type='submit' id='skills-button' className='w-100 py-3'>
                        {fromModal ? 'Update' : 'Continue'}
                    </Button>
                </Stack>
            )}
        </Form>
    )
}
