import React from "react";
import { Button } from "../../../../../components/common/button/Button";

export const RequirementAndFAQs = ({ setTabBecomeServiceCompany }) => {
  const requirementAndFAQs = [
    {
      reqAndFAQ:
        " Follow Instructions: Ensure successful completion of the screening process by adhering to emailed instructions.",
    },
    {
      reqAndFAQ:
        "Receive Notifications: Stay informed about the status of your screening results through notifications.",
    },
    {
      reqAndFAQ:
        "Automatic Profile Update: Your profile will be automatically updated upon successful completion of screenings.",
    },
  ];

  return (
    <>
      <h6 className="font-16 fw-bold header-color">Requirements & FAQs</h6>

      <ul className="mt-3 ">
        {requirementAndFAQs.map((s) => (
          <li className="font-14 line-height-20 color-fiord">
            {s.reqAndFAQ}
          </li>
        ))}
      </ul>
      <p className="font-14 line-height-20 color-fiord">
        Expect detailed instructions from our trusted screening partners via
        email.
      </p>
      <hr className="border-botticelli opacity-100 mb-4 mt-4" />
      <div className="d-flex gap-3">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-4 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          onClick={() => setTabBecomeServiceCompany("serviceCompanyProgram")}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="fw-semibold py-1 px-4"
            onClick={() => setTabBecomeServiceCompany("termsAndCondition")}
        >
          Next
        </Button>
      </div>
    </>
  );
};
