import {
  CREATE_TICKET_OFFCANVAS,
  EDIT_TICKET_OFFCANVAS,
  FETCH_PROVIDER_DETAILS,
  FETCH_SKILLS,
  FETCH_TAX_DETAILS,
  FETCH_TITLES,
} from "./provider.action";

const initialState = {
  providerDetails: null,
  taxDetails: null,
  titles: [],
  skills: [],
  createTicketOffCanvas: false,
  editTicketOffCanvas: false,
};

export const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROVIDER_DETAILS:
      return { ...state, providerDetails: action.value };
    case FETCH_TAX_DETAILS:
      return { ...state, taxDetails: action.value };
    case FETCH_TITLES:
      return { ...state, titles: action.value };
    case FETCH_SKILLS:
      return { ...state, skills: action.value };
    case CREATE_TICKET_OFFCANVAS:
      return { ...state, createTicketOffCanvas: action.value };
    case EDIT_TICKET_OFFCANVAS:
      return { ...state, editTicketOffCanvas: action.value };
    default:
      return state;
  }
};
