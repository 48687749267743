import React, { Children } from "react";
import { Offcanvas } from "react-bootstrap";

export const RightOffcanvas = ({ children, show, handleClose, className }) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={className}
        style={{ overflowY: "auto", maxHeight: "100vh" }}
        keyboard={true}
        backdrop={true}
      >
        {children}
      </Offcanvas>
    </>
  );
};
