import { ListGroup, Stack } from 'react-bootstrap'
import { HiPlus } from 'react-icons/hi2'
import { Link, useLocation } from 'react-router-dom'
import './sidebar.css'
import { Button } from '../../../common/button/Button'
import { InviteProviders } from '../modal/InviteProviders'
import { useState } from 'react'

export const MyProviderPoolSidebar = () => {
    
    const [showInviteProviders, setShowInviteProviders] = useState(false)
    
    return (
        <>
        <div 
            className='border-end h-auto scrollbar-thin list-group__item-active rounded-0 px-0 d-flex flex-column' 
            id='sidebar-wrapper'
        >
            <Stack direction='horizontal' className='sidebar-heading justify-content-between px-3'>
                <span className='color-grey-900 fw-600 font-14'>All Created Pools</span>
                <div className='created-pools__plus-wrapper cursor-pointer'>
                    <HiPlus color='black' />
                </div>
            </Stack>
            <ListGroup variant='flush' className='flex-grow-1'>
                <NavItem to='/providers' label='Technicians' />
                {/* <NavItem to='/' label='Mechanics' />
                <NavItem to='/' label='Uncategorized' /> */}
            </ListGroup>
            <Button 
                type='button' 
                className='py-2 rounded-1 fw-400 font-14 mx-3 mb-3'
                onClick={() => setShowInviteProviders(true)}
            >
                Invite Provider
            </Button>
        </div>
        <InviteProviders show={showInviteProviders} handleClose={() => setShowInviteProviders(false)} />
        </>
    )
}

const NavItem = ({ to, label, paths, ...rest }) => {
    const location = useLocation()
    const isActive = location.pathname === to || paths?.includes(location.pathname);

    return (
        <Link
            to={to}
            className={`border-0 list-group-item py-3 ${isActive ? 'list-group__item-active my-provider__active-tab bg-white' : 'bg-transparent'}`}
            {...rest}
        >
            <span className={`font-14 ${isActive ? 'color-primary fw-600' : 'color-fiord'}`}>{label}</span>
        </Link>
    )
}