import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "../../../../../components/common/button/Button";
import { Input } from "../../../../../components/common/input/Input";

export const AddSuggestion = ({ setAddSuggestion }) => {
  const [formData, setFormData] = useState({
    title: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    title: "",
    message: "",
  });

  const validateField = (name, value) => {
    let error = "";

    if (name === "title") {
      if (!value.trim()) {
        error = "Title is required.";
      }
    } else if (name === "message") {
      if (!value.trim()) {
        error = "Message is required.";
      } else if (value.trim().length < 10) {
        error = "Message must be at least 10 characters.";
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    const requiredFields = ["title", "message"];
    return requiredFields.every(
      (field) => errors[field] === "" && formData[field]
    );
  };

  const handleAddSuggestion = (e) => {
    e.preventDefault();

    const newErrors = {
      title: validateField("title", formData.title),
      message: validateField("message", formData.message),
    };

    setErrors(newErrors);

    if (isFormValid()) {
      console.log("Submitted Data:", {
        title: formData.title,
        message: formData.message,
      });
      setAddSuggestion(false);
    }
  };
  return (
    <Form className="form-wrapper" onSubmit={handleAddSuggestion}>
      <Input
        label="Title"
        type="text"
        placeholder="Enter first name"
        name="title"
        value={formData.title}
        onChange={handleChange}
        error={errors.title}
        className="mb-3"
      />

      <Input
        label="Message"
        type="text"
        placeholder="Enter message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        error={errors.message}
        className="mb-3"
        multiline="h"
        style={{ height: "140px" }}
      />

      <div className="button-bottom-side d-flex">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-3 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          onClick={() => setAddSuggestion(false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="fw-semibold py-3 px-3"
          disabled={!isFormValid()}
        >
          Post Suggestion
        </Button>
      </div>
    </Form>
  );
};
