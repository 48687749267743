import React from "react";
import { MainDefault } from "../../../components/common/Default";
import { Form } from "react-bootstrap";
import Underline from "../../../components/buyer-provider/Underline";
import { Button } from "../../../components/common/button/Button";

export const ProviderNotification = () => {
  const workOrders = [
    { label: "Work order published", checked1: true, checked2: true },
    {
      label: "No provider assigned to work order",
      checked1: true,
      checked2: true,
    },
    { label: "Work order completed", checked1: true, checked2: true },
    { label: "Work order paid", checked1: true, checked2: true },
    { label: "Work order rejected", checked1: true, checked2: true },
    { label: "Approval of Timesheet", checked1: true, checked2: true },
    {
      label: "Rating of provider on work done",
      checked1: true,
      checked2: true,
    },
    {
      label: "Rating of provider on work done",
      checked1: true,
      checked2: true,
    },
  ];

  const reminders = [
    { label: "Reminder to confirm", checked1: true, checked2: true },
    {
      label: "Reminder to publish work order",
      checked1: true,
      checked2: true,
    },
  ];

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-3 px-3">
        <h4 className="mb-0">Notifications</h4>
      </div>

      <table className="w-75 notifications-table">
        <thead className="">
          <tr>
            <th className="table-heading color-black">Work Orders Settings</th>
            <th>Email</th>
            <th>Notifications</th>
          </tr>
        </thead>

        {workOrders.map((row, index) => (
          <tr key={index}>
            <td className="w-50">{row.label}</td>
            <td>
              <Form.Check
                type="checkbox"
                className="buyer-checkbox check-work-order"
                defaultChecked={row.checked1}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                className="buyer-checkbox check-work-order"
                defaultChecked={row.checked2}
              />
            </td>
          </tr>
        ))}
      </table>

      <table className="w-75 notifications-table">
        <thead className="">
          <tr>
            <th className="table-heading color-black">Reminder</th>
            <th>Email</th>
            <th>Notifications</th>
          </tr>
        </thead>
        {reminders.map((row, index) => (
          <tr key={index}>
            <td className="w-50">{row.label}</td>
            <td>
              <Form.Check
                type="checkbox"
                className="buyer-checkbox check-work-order"
                defaultChecked={row.checked1}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                className="buyer-checkbox check-work-order"
                defaultChecked={row.checked2}
              />
            </td>
          </tr>
        ))}
      </table>
      <table className="w-75 notifications-table">
        <thead className="w-50">
          <tr>
            <th className="table-heading color-black">Payments</th>
            <th>Email</th>
            <th>Notifications</th>
          </tr>
        </thead>
        <tr>
          <td className="w-50">When funds are withdrawn</td>
          <td>
            <Form.Check
              type="checkbox"
              className="buyer-checkbox check-work-order"
              defaultChecked
            />
          </td>
          <td>
            <Form.Check
              type="checkbox"
              className="buyer-checkbox check-work-order"
              defaultChecked
            />
          </td>
        </tr>
      </table>

      <div className="mt-4">
        <div className="table-heading">General Settings</div>
        <Underline className="mt-3" />
        <div className="d-flex align-items-center gap-2 mt-4">
          <Form.Check
            type="checkbox"
            id="newsletter-checkbox"
            className="buyer-checkbox"
          />
          <label htmlFor="newsletter-checkbox" className="general-policy">
            Sign me up for all WorkRobin newsletter and
          </label>
        </div>
        <div className="d-flex align-items-center gap-2 mt-3">
          <Form.Check
            type="checkbox"
            id="marketing-checkbox"
            className="buyer-checkbox"
          />
          <label htmlFor="marketing-checkbox" className="general-policy">
            Send me marketing messages about new features and tips
          </label>
        </div>
        <Underline />
      </div>

      <div className="d-flex gap-3 mt-4">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-3 py-2 color-ebony"
          style={{
            border: "1px solid #CCCCF5",
          }}
        >
          Cancel
        </Button>
        <Button type="submit" className="fw-semibold px-3 py-2">
          Save Changes
        </Button>
      </div>
    </MainDefault>
  );
};
