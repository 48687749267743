import { useEffect, useState } from 'react'
import { fetchBusinessDetails, fetchIndustries } from '../store/buyer/buyer.action'
import { useDispatch, useSelector } from 'react-redux'
import { employeeOptions } from '../data/custom-options'

export const useBusinessDetailsValidation = () => {

  const dispatch = useDispatch()

  const businessDetails = useSelector(state => state.buyerReducer.businessDetails)
  var industryOptions = useSelector(state => state.buyerReducer.industries)
  industryOptions = industryOptions?.map(industry => ({
    label: industry?.name,
    value: industry?._id
  }))

  const [formData, setFormData] = useState({
    address: '',
    industry: null,
    website: '',
    phoneNumber: '',
    totalEmployees: null
  })

  const [errors, setErrors] = useState({
    address: '',
    industry: '',
    website: '',
    phoneNumber: '',
    totalEmployees: ''
  })

  const validateField = (name, value) => {
    var error = '';
    if (name === 'address' && !value) {
      if (!value.trim()) {
        error = 'Address is required';
      } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
        error = 'Address cannot contains special characters';
      }
    } else if (name === 'industry' && !value) {
      error = 'Industry is required';
    } else if (name === 'website' && !value) {
      if (!value.trim()) {
        error = 'Website is required';
      } else if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value.trim())) {
        error = 'Please enter a valid URL (e.g., http://example.com)';
      }
    } else if (name === 'phoneNumber' && !value) {
      error = 'Phone number is required';
    } else if (name === 'totalEmployees' && !value) {
      error = 'Number of Employees is required';
    }
    return error;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedValue = name === 'phoneNumber' ? value.replace(/[^0-9]/g, '') : value

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    const updatedValue = selectedOption?.value

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedOption || null
    }))

    const error = validateField(name, updatedValue);
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const newErrors = {
      address: '',
      industry: '',
      website: '',
      phoneNumber: '',
      totalEmployees: ''
    }

    if (!formData.address) {
      newErrors.address = 'Address is required';
    } else if (!/^[A-Za-z0-9\s]+$/.test(formData.address.trim())) {
      newErrors.address = 'Address cannot contains special characters';
    }
    if (!formData.industry) newErrors.industry = 'Industry is required';
    if (!formData.website) {
      newErrors.website = 'Website is required';
    }
    else if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(formData.website.trim())) {
      newErrors.website = 'Please enter a valid URL (e.g., http://example.com)';
    }
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
    if (!formData.totalEmployees) newErrors.totalEmployees = 'Number of Employees is required';

    setErrors(newErrors);

    if (Object.values(newErrors).every(err => err === '')) {
      const data = {
        ...formData,
        industry: formData?.industry?.value,
        totalEmployees: formData?.totalEmployees?.value
      }
      return data
    }
  }

  useEffect(() => {
    if (businessDetails) {
      setFormData({
        address: businessDetails?.address || '',
        industry: industryOptions?.find(option => option.value === businessDetails?.industry) || null,
        website: businessDetails?.website || '',
        phoneNumber: businessDetails?.phoneNumber || '',
        totalEmployees: employeeOptions.find(option => option.value === businessDetails?.totalEmployees) || null
      });
    }
  }, [businessDetails])

  useEffect(() => {
    dispatch(fetchIndustries())
    dispatch(fetchBusinessDetails())
  }, [])

  return { formData, errors, setFormData, handleChange, handleSelectChange, handleSubmit }
}
