import { Col, Row } from "react-bootstrap";

export const StepperRound = ({ steps, currentStep, className }) => {
  const currentIndex = steps.indexOf(currentStep);

  return (
    <div className="d-flex align-items-center justify-content-center gap-2">
      {steps?.map((step, i) => {
        return (
          <div
            key={i}
            className={` ${className}`}
            style={{
              borderRadius: "100%",
              height: "12px",
              width: "12px",
              backgroundColor: i <= currentIndex ? "#920AF2" : "#D6DDEB",
            }}
          ></div>
        );
      })}
    </div>
  );
};
