import React, { useMemo } from 'react'
import { Table } from '../common/table/Table'
import moment from 'moment'
import { NavDropdownWrapper } from '../common/header/nav-dropdown/Wrapper'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { ListGroup } from 'react-bootstrap'

export const CertificationsTable = () => {

    const columns = useMemo(() => [
        {
            header: 'Certification Title',
            accessorKey: 'certificationTitle',
            cell: (info) => info.renderValue()
        },
        {
            header: 'Industry',
            accessorKey: 'industry',
            cell: (info) => info.renderValue()
        },
        {
            header: 'Certification Link',
            accessorKey: 'certificationLink',
            cell: (info) => info.renderValue()
        },
        {
            header: 'Company',
            accessorKey: 'company',
            cell: (info) => info.renderValue()
        },
        {
            header: 'Issue Date',
            accessorKey: 'issueDate',
            cell: (info) => moment(info.renderValue()).format('DD/MM/YYYY')
        },
        {
            header: 'Expiry Date',
            accessorKey: 'expiryDate',
            cell: (info) => moment(info.renderValue()).format('DD/MM/YYYY')
        },
        {
            id: "actions",
            header: () => "Action",
            cell: ({ row }) => {
                return (
                    <NavDropdownWrapper title={<MdOutlineMoreHoriz size={24} />}>
                        <ListGroup.Item
                            action
                            // onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            View file
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            // onClick={onMove}
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Update 
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                        >
                            Delete
                        </ListGroup.Item>
                    </NavDropdownWrapper>
                );
            },
        },
    ], [])

    const rows = [
        {
            certificationTitle: 'Certified Ethical Hacker',
            industry: 'Information Technology',
            certificationLink: 'https://www.linkedIn.com/open',
            company: 'EC-Council',
            issueDate: Date.now(),
            expiryDate: Date.now()
        },
        {
            certificationTitle: 'Security Foundation',
            industry: 'Information Technology',
            certificationLink: 'https://www.isc2.org/Certifications/CISSP',
            company: 'ISC2',
            issueDate: Date.now(),
            expiryDate: Date.now()
        }
    ]

  return (
    <Table data={rows} columns={columns} />
  )
}
