import { TbLayoutCards, TbLayoutList } from "react-icons/tb";
import { HiOutlineCalendar } from "react-icons/hi2";
import { useActiveLayout } from "../../../hooks/useActiveLayout";
import { Fragment } from "react";

export const LayoutSwitcher = ({ showCalendar = false }) => {
  const { activeLayout, toggleLayout } = useActiveLayout();
  return (
    <Fragment>
      <div
        className={`layout_wrapper ${
          activeLayout === "cards" ? "layout_active" : "layout_inactive"
        }`}
        onClick={() => toggleLayout("cards")}
      >
        <TbLayoutCards />
      </div>
      <div
        className={`layout_wrapper ${
          activeLayout === "list" ? "layout_active" : "layout_inactive"
        }`}
        onClick={() => toggleLayout("list")}
      >
        <TbLayoutList />
      </div>
      {showCalendar && (
        <div
          className={`layout_wrapper ${
            activeLayout === "calendar" ? "layout_active" : "layout_inactive"
          }`}
          onClick={() => toggleLayout("calendar")}
        >
          <HiOutlineCalendar strokeWidth={2} />
        </div>
      )}
    </Fragment>
  );
};
