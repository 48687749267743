import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Input } from "../../../../components/common/input/Input";
import { Select } from "../../../../components/common/select/Select";
import { Button } from "../../../../components/common/button/Button";

export const AddEditProject = ({
  type,
  setIsCreateProject,
}) => {

    console.log(type)
  const [formData, setFormData] = useState({
    projectTitle: "",
    description: "",
    owner: null,
  });

  const [errors, setErrors] = useState({
    projectTitle: "",
    description: "",
    owner: "",
  });

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "projectTitle":
        if (!value) error = "Project title is required.";
        break;
      case "description":
        if (!value) error = "Description is required.";
        break;
      case "owner":
        if (!value) error = "Project owner is required.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    const value = selectedOption?.value || null;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption || null,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      formData.projectTitle &&
      formData.description &&
      formData.owner
    );
  };

  const createProject = (e) => {
    e.preventDefault();

    const newErrors = {
      projectTitle: validateField("projectTitle", formData.projectTitle),
      description: validateField("description", formData.description),
      owner: validateField("owner", formData.owner?.value),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      console.log("Submitted Data:", {
        projectTitle: formData.projectTitle,
        description: formData.description,
        owner: formData.owner.value,
      });
      setIsCreateProject(false);
    }
  };

  const editProject = (e) => {
    e.preventDefault();
    const newErrors = {
      projectTitle: validateField("projectTitle", formData.projectTitle),
      description: validateField("description", formData.description),
      owner: validateField("owner", formData.owner?.value),
    };

    setErrors(newErrors);
  };

  const ownerOptions = [
    { label: "Owner 1", value: "owner1" },
    { label: "Owner 2", value: "owner2" },
    { label: "Owner 3", value: "owner3" },
  ];

  return (
    <>
      <Form
        onSubmit={(type == "Add Project" ? createProject : editProject)}
        className="form-wrapper"
      >
        <Input
          label="Project Title"
          placeholder="Enter title"
          className="mb-3"
          name="projectTitle"
          value={formData.projectTitle}
          onChange={handleChange}
          error={errors.projectTitle}
          defaultValue={type == "Edit Project" ? "Brooklyn" : ""}
        />
        <Input
          label="Description"
          placeholder="Enter your project description"
          className="mb-3"
          name="description"
          value={formData.description}
          onChange={handleChange}
          error={errors.description}
          multiline
          defaultValue={type == "Edit Project" ? "Brooklyn" : ""}
        />
        <Select
          label="Project Owner"
          placeholder="Select a project owner"
          className="mb-3"
          options={ownerOptions}
          name="owner"
          value={formData.owner}
          onChange={(option) => handleSelectChange(option, "owner")}
          error={errors.owner}
          defaultValue={type == "Edit Project" ? "Brooklyn" : ""}
        />

        <div className="button-bottom-side d-flex">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setIsCreateProject(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            type="submit"
            className="fw-semibold p-3"
          >
            {type}
          </Button>
        </div>
      </Form>
    </>
  );
};
