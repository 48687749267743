import React from "react";
import "./deliverables.css";
import { CheckBoxWithIcon } from "../../common/checkbox-with-iconn/CheckBoxWithIcon";
import Underline from "../../buyer-provider/Underline";
import { CardWrapper } from "../../common/Default";

export const TasksDeliverables = () => {
  return (
    <div className="mt-4">
      <CardWrapper className="deliverables-card">
        <div>
          <h6>Tasks</h6>
          <Underline />

          <div className="mt-4">
            <h6>Before Work Order</h6>

            <div className="mt-4">
              <CheckBoxWithIcon
                uniqueId={1}
                labelName="Provider SOP Agreement"
              />
              <CheckBoxWithIcon
                uniqueId={2}
                labelName="Provider Onsite behavior Agreement"
              />
              <CheckBoxWithIcon
                uniqueId={3}
                labelName="Sexual Harassment Agreement"
              />

              <Underline />
            </div>
          </div>
          <div className="mt-4">
            <h6>Execution</h6>

            <div className="mt-4">
              <CheckBoxWithIcon
                uniqueId={4}
                labelName="Provider SOP Agreement"
              />
              <CheckBoxWithIcon
                uniqueId={5}
                labelName="Provider Onsite behavior Agreement"
              />
              <CheckBoxWithIcon
                uniqueId={6}
                labelName="Sexual Harassment Agreement"
              />

              <Underline />
            </div>
          </div>
          <div className="mt-4">
            <h6>After Work Order</h6>

            <div className="mt-4">
              <CheckBoxWithIcon
                uniqueId={7}
                labelName="Provider SOP Agreement"
              />
              <CheckBoxWithIcon
                uniqueId={8}
                labelName="Provider Onsite behavior Agreement"
              />
              <CheckBoxWithIcon
                uniqueId={9}
                labelName="Sexual Harassment Agreement"
              />
            </div>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};
