// import { useState } from "react";
// export const useActiveLayout = (defaultLayout = "cards") => {
//   const [activeLayout, setActiveLayout] = useState(defaultLayout);

//   const toggleLayout = (layout) => {
//     setActiveLayout(layout);
//   };

//   return {
//     activeLayout,
//     toggleLayout,
//   };
// };

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { activeTabLayout } from "../store/buyer/buyer.action";

export const useActiveLayout = (defaultLayout = "cards") => {
  const dispatch = useDispatch();
  const [activeLayout, setActiveLayout] = useState(defaultLayout);

  const toggleLayout = (layout) => {
    setActiveLayout(layout);
  };
  //   useActiveLayout second page not working too add reducer

  const handelChangeTab = () => {
    dispatch(activeTabLayout(activeLayout));
  };
  useEffect(() => {
    handelChangeTab();
  }, [activeLayout]);

  return {
    activeLayout,
    toggleLayout,
  };
};
