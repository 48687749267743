import { Col, ListGroup, NavDropdown, Row, Stack } from "react-bootstrap";
import { CardWrapper } from "../../../common/Default";
import { ReactComponent as Business } from "../../../../assets/images/business.svg";
import { ReactComponent as Users } from "../../../../assets/images/sidebar/users.svg";
import { HiDotsVertical, HiOutlineLocationMarker } from "react-icons/hi";
import { Fragment, useState } from "react";
import PublishedContentTable from "./PublishedContentTable";
import { useSelector } from "react-redux";
import { ViewCalendar } from "../../../buyer-provider/view-calendar/ViewCalendar";
import { Link } from "react-router-dom";
import { ReactComponent as WOCancel } from '../../../../assets/images/cancel-work-order.svg'
import { ConfirmationActionModal } from "../../../buyer/work-orders/modals/ConfirmationActionModal";

export const PublishedContent = () => {
  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);

  const [showCancelWO, setShowCancelWO] = useState(false)

  const events = [
    {
      title: "Plumber",
      start: new Date().toISOString().split("T")[0] + "T07:00:00",
      location: "Birmingham, United Kingdom",
      charge: "$500",
    },
    {
      title: "Plumber",
      start: new Date().toISOString().split("T")[0] + "T08:00:00",
      location: "Birmingham, United Kingdom",
      charge: "$500",
    },
  ];

  return (
    <Fragment>
      {_activeTabLayout === "list" ? (
        <PublishedContentTable onShowCancelWO={() => setShowCancelWO(true)} />
      ) : _activeTabLayout === "cards" ? (
        <Row className="row-gap-4">
          {Array.from({ length: 4 }).map((_, i) => {
            return (
              <Col md={3} key={i}>
                <PublishedCard onShowCancelWO={() => setShowCancelWO(true)} />
              </Col>
            )
          })}
        </Row>
      ) : (
        <ViewCalendar events={events} headerToolbar={false} />
      )}
      <ConfirmationActionModal
        show={showCancelWO}
        handleClose={() => setShowCancelWO(false)}
        image={WOCancel}
        title='You are able to cancel this work done'
      />
    </Fragment>
  );
};

const PublishedCard = ({ onShowCancelWO }) => {
  return (
    <CardWrapper>
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-center mb-2"
      >
        <Business />
        <NavDropdown
          title={<HiDotsVertical className="color-grey-500 cursor-pointer" />}
        >
          <ListGroup variant="flush">
            <ListGroup.Item
              as={Link}
              to='/work-orders/basic-information'
              state={{ mode: 'edit' }}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              Manage order
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => { }}
              className="cursor-pointer font-14 color-gray-700 border-bottom-0"
            >
              Modify
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={onShowCancelWO}
              className="cursor-pointer font-14 color-gray-700"
            >
              Cancel order
            </ListGroup.Item>
          </ListGroup>
        </NavDropdown>
      </Stack>
      <span className="color-grey-500 font-10 fw-medium">#1233944</span>
      <p className="font-14 fw-semibold line-clamp-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. A vero
        perferendis corporis explicabo nesciunt. Quod!
      </p>
      <Stack direction="horizontal" className="justify-content-between my-2">
        <div className="d-flex align-items-center color-grey-500 gap-1">
          <HiOutlineLocationMarker />
          <span
            className="font-12 lh-1 text-truncate"
            style={{ maxWidth: 120 }}
          >
            Birmingham, United Kingdom
          </span>
        </div>
        <span className="job-status badge-status__active">ACTIVE</span>
      </Stack>
      <p className="font-12 color-grayscale-500 line-clamp-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit fugiat
        debitis possimus reiciendis aperiam ad!
      </p>
      <div>
        <span className="me-1 card-tag__fixed rounded color-grey-900">
          Plumbing
        </span>
        <span className="me-1 card-tag__fixed rounded color-grey-900">
          Communication
        </span>
        <span className="color-primary font-10">1 more</span>
      </div>
      <hr className="opacity-100 border-grayscale-100" />
      <Stack direction="horizontal" className="justify-content-between">
        <h6 className="text-black fw-bold mb-0">$500</h6>
        <span className="color-pale-sky font-10">Posted 3m ago</span>
      </Stack>
      <hr className="opacity-100 border-grayscale-100" />
      <Stack direction="horizontal" className="gap-1">
        <Users style={{ fill: "#687588" }} />
        <span className="color-pale-sky font-12">10 Candidates Applied</span>
      </Stack>
    </CardWrapper>
  );
};
