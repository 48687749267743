import React, { useState } from "react";
import { CardWrapper } from "../../../../components/common/Default";
import { AVATAR, DIAMOND, SUCCESS } from "../../../../assets/images.assets";
import "../providers.css";
import Underline from "../../../../components/buyer-provider/Underline";
import { Button, Offcanvas, ProgressBar, Stack } from "react-bootstrap";
import { useProfileCompletion } from "../../../../hooks/useProfileCompletion";
import { AiOutlineMail } from "react-icons/ai";
import { LuSmartphone } from "react-icons/lu";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { RxCross1 } from "react-icons/rx";
import { HiArrowLongLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { BackGroundOrder } from "../order-now/BackGroundOrder";
import Dropzone from "react-dropzone";
import { ReactComponent as Gallery } from "../../../../assets/images/gallery.svg";
import { BiSolidFilePdf } from "react-icons/bi";
import { UploadOrderBackGround } from "../upload-order-background/UploadOrderBackGround";

export const AboutProvider = () => {
  const [backGroundOrder, setBackGroundOrder] = useState(false);
  const [uploadBackground, setUploadBackground] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const { completionPercentage } = useProfileCompletion();

  const onDrop = (files) => {
    console.log("Accepted Files:", files);
    setAcceptedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  return (
    <>
      <CardWrapper className="about-provider-main-wrapper">
        <div className="d-flex gap-4 align-items-center">
          <img src={AVATAR} height={100} width={100} />
          <FaCircle className="active-provider" color={"#0caf60"} size={12} />
          <div>
            <h5 className="font-24 line-height-100 fw-bold">
              Adeleke Fajuyi Olayiwola
            </h5>
            <p className="font-16 color-gray-700">Plumbing Specialist</p>
            <div className="d-flex align-items-center gap-2 mt-2">
              <div className="d-flex align-align-items-center gap-2">
                <img src={SUCCESS} height={22} width={22} />
                <p className="font-14 line-height-160 fw-500 color-neutrals-100">
                  100% Job Success
                </p>
              </div>
              <div className="d-flex align-align-items-center gap-2">
                <img src={DIAMOND} height={22} width={22} />
                <p className="font-14 line-height-160 fw-500 color-neutrals-100">
                  Top -Rated
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="common-card-provider-details mt-3 p-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className="font-14 line-height-160 color-neutrals-100">
              Assigned Work Order (1)
            </p>
            <p className="font-14 line-height-160 color-neutrals-60">
              5 days ago
            </p>
          </div>
          <Underline className="mt-2" />

          <h4 className="mt-2 font-20 line-height-160 fw-bold color-neutrals-100">
            Plumbing Specialist
          </h4>

          <p className="font-14 line-height-160 color-neutrals-60">Contact</p>
        </div>

        <div className="common-card-provider-details mt-3 p-3">
          <Stack direction="vertical" className="badge__group_provider">
            <p className="font-14 line-height-160 color-neutrals-100">
              {completionPercentage}% Profile Completion
            </p>
            <ProgressBar now={completionPercentage} className="mt-2" />
          </Stack>
        </div>

        <Underline className="mt-3" />

        <div className="mt-4">
          <h5 className="font-20 line-height-125 color-neutrals-100 fw-bold">
            Contact
          </h5>

          <div className="d-flex gap-3 mt-3">
            <AiOutlineMail size={24} color="#7C8493" />

            <div>
              <p className="color-neutrals-60 font-16 line-height-160">Email</p>
              <p className="color-neutrals-100 font-16 line-height-160">
                adelekefajuyi@email.com
              </p>
            </div>
          </div>
          <div className="d-flex gap-3 mt-3">
            <LuSmartphone size={24} color="#7C8493" />

            <div>
              <p className="color-neutrals-60 font-16 line-height-160">Phone</p>
              <p className="color-neutrals-100 font-16 line-height-160">
                +234 801 234 567
              </p>
            </div>
          </div>

          <div className="mt-4">
            <h5 className="font-20 line-height-125 color-neutrals-100 fw-bold">
              Screening
            </h5>

            <div className="d-flex gap-2 mt-3">
              <p className="color-neutrals-100 font-16 line-height-160">
                Background Check:
              </p>
              <div className="d-flex align-items-center gap-1">
                <IoIosCheckmarkCircle size={16} color="#0caf60" />

                <p className="color-neutrals-100 font-16 line-height-160">
                  Monday 13, 2024
                </p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <p className="color-neutrals-100 font-16 line-height-160">
                Drug Screening
              </p>
              <p className="font-14 line-height-160 color-grey-500">
                Not Available
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <div className="d-flex  align-items-center">
            <Button
              variant="link"
              className="font-12 line-height-160 fw-600 color-primary"
              onClick={() => setBackGroundOrder(true)}
            >
              Order now
            </Button>
            <div className="color-grey-500">|</div>
            <Button
              variant="link"
              className="font-12 line-height-160 fw-600 color-primary"
              onClick={() => setUploadBackground(true)}
            >
              Upload
            </Button>
          </div>
        </div>
      </CardWrapper>

      {/* BACKGROUND ORDER */}
      <RightOffcanvas
        show={backGroundOrder}
        onHide={setBackGroundOrder}
        className="w-25"
      >
        <div className="p-3 d-flex align-items-center justify-content-between">
          {/* <h5 className="text-black fw-semibold font-20">Add Funds</h5> */}
          <div className="w-fit">
            <Link onClick={() => setBackGroundOrder(false)}>
              <HiArrowLongLeft
                size={28}
                color="black"
                style={{ marginBottom: 2 }}
              />
            </Link>
            <span className="fw-medium ms-2">Back</span>
          </div>
          <RxCross1
            size={20}
            onClick={() => setBackGroundOrder(false)}
            className="cursor-pointer"
          />
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <BackGroundOrder setBackGroundOrder={setBackGroundOrder} />
        </Offcanvas.Body>
      </RightOffcanvas>

      {/* UPLOAD BACKGROUND */}
      <div>
        {/* <button onClick={() => setUploadBackground(true)}>Upload Files</button> */}

        <RightOffcanvas
          show={uploadBackground}
          onHide={() => setUploadBackground(false)}
          className="w-25"
        >
          <div className="p-3 d-flex align-items-center justify-content-between">
            <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
              Order for Background Check
            </h6>
            <RxCross1
              size={20}
              onClick={() => setUploadBackground(false)}
              className="cursor-pointer"
            />
          </div>
          <Offcanvas.Body>
            {acceptedFiles.length == 0 && (
              <Dropzone
                maxFiles={1}
                onDrop={onDrop}
                accept={{
                  "application/pdf": [".pdf"],
                  "application/msword": [".doc"],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".docx"],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Stack
                    gap={2}
                    {...getRootProps({
                      className:
                        "dropzone__wrapper upload-order-dropzone cursor-pointer align-items-center mb-3",
                    })}
                  >
                    <input name="resume" {...getInputProps()} />
                    <span className="text-center text-secondary fw-medium">
                      Attach document(s)
                    </span>
                  </Stack>
                )}
              </Dropzone>
            )}

            <div className="mt-3 ">
              {acceptedFiles.length > 0 && (
                <UploadOrderBackGround
                  acceptedFiles={acceptedFiles}
                  setUploadBackground={setUploadBackground}
                  setAcceptedFiles={setAcceptedFiles}
                />
              )}
            </div>
          </Offcanvas.Body>
        </RightOffcanvas>
      </div>
    </>
  );
};
