import React from 'react'
import { Modal, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'

export const ConfirmationActionModal = ({
    show,
    handleClose,
    image: Image,
    title,
    subtitle = 'Are you sure you want to proceed?',
    primaryButtonText = 'Yes, Proceed',
    onPrimaryButtonClick,
    secondaryButtonText = 'No, Cancel',
    onSecondaryButtonClick = handleClose,
    isOutlineStyle = true
}) => {
    return (
        <Modal size='sm' dialogClassName='invite-dialog' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Stack gap={2} className='align-items-center mb-3'>
                    <Image className='mb-3' />
                    <div className="text-center">
                        <h5 className='mb-0 text-black fw-medium'>{title}</h5>
                        <p>{subtitle}</p>
                    </div>
                </Stack>

                <Stack direction='horizontal' className='justify-content-between'>
                    <Button
                        type='button'
                        variant={!isOutlineStyle ? 'outline' : 'primary'}
                        className={`py-14px ${!isOutlineStyle ? 'px-3 color-ebony padded-border-button' : ''}`}
                        onClick={onSecondaryButtonClick}
                    >
                        {secondaryButtonText}
                    </Button>
                    <Button
                        type='submit'
                        variant={isOutlineStyle ? 'outline' : 'primary'}
                        className={`py-14px ${isOutlineStyle ? 'px-3 color-ebony padded-border-button' : ''}`}
                        onClick={onPrimaryButtonClick}
                    >
                        {primaryButtonText}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}
