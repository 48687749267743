import React from "react";
import { MainDefault } from "../../../../components/common/Default";
import { MyIdentityConfirmation } from "./my-tax-information/MyIdentityConfirmation";

export const MyTextInformation = () => {
  return (
    <MainDefault>
        <MyIdentityConfirmation />
    </MainDefault>
  );
};
