import React, { useState } from "react";
import { Button } from "../../../../../components/common/button/Button";
import { Form } from "react-bootstrap";
import { Input } from "../../../../../components/common/input/Input";

export const NewCompanyInformation = ({ setTabBecomeServiceCompany }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    adminName: "",
    adminMail: "",
    currentPassword: "",
  });

  const [errors, setErrors] = useState({
    companyName: "",
    adminName: "",
    adminMail: "",
    currentPassword: "",
  });

  const validateField = (name, value) => {
    let error = "";

    if (name === "companyName" && !value) {
      error = "Company name is required";
    } else if (name === "adminName" && !value) {
      error = "Admin name is required";
    } else if (name === "adminMail") {
      if (!value) {
        error = "Email is required";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Email is invalid";
      }
    } else if (name === "currentPassword") {
      if (!value) {
        error = "Password is required";
      } else if (value.length < 8) {
        error = "Password must be at least 8 characters";
      }
    }

    return error;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () =>
    Object.values(errors).every((err) => err === "") &&
    Object.values(formData).every((value) => value);

  const handleSubmit = (event) => {
    event.preventDefault();

    let formErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) formErrors[field] = error;
    });

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      console.log("Form submitted successfully", formData);
    }
  };

  return (
    <>
      <h6 className="font-16 fw-bold header-color mb-3">
        New Company Information
      </h6>

      <Form className="w-50" onSubmit={handleSubmit}>
        <Input
          label="New Company Name"
          type="text"
          placeholder="Enter company name"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          error={errors.companyName}
          className="mb-3"
        />
        <Input
          label="New Admin Username"
          type="text"
          placeholder="Enter user name"
          name="adminName"
          value={formData.adminName}
          onChange={handleChange}
          error={errors.adminName}
          className="mb-3"
        />
        <Input
          label="Admin Email"
          type="text"
          placeholder="Enter email"
          name="adminMail"
          value={formData.adminMail}
          onChange={handleChange}
          error={errors.adminMail}
          className="mb-3"
        />
        <Input
          label="Re-enter Current Password"
          type="password"
          name="currentPassword"
          id="current_password"
          value={formData.currentPassword}
          onChange={handleChange}
          error={errors.currentPassword}
          placeholder="***************"
        />

        <hr className="border-botticelli opacity-100 mb-4 mt-4" />

        <div className="d-flex gap-4">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-4 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setTabBecomeServiceCompany("termsAndCondition")}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="fw-semibold py-1 px-4"
            disabled={!isFormValid()}
          >
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};
