import React, { useMemo } from "react";
import { PaymentCommonHeader } from "../payment-common-header/PaymentCommonHeader";
import { Table } from "../../../../components/common/table/Table";
import { USER } from "../../../../assets/images.assets";

export const CurrentPayablec = () => {
  const Columns = useMemo(
    () => [
      {
        accessorKey: "initiationDate",
        header: () => "Initiation Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "completionDate",
        header: () => "Completion Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "projectTitle",
        header: () => "Project Title",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "description",
        header: () => "Description",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "provider",
        header: () => "Provider",
        cell: (info) => (
          <div className="d-flex align-items-center gap-2">
            <img src={USER} height={24} width={24} />
            <span className="font-12 line-height-160 color-grayscale-900 fw-600">
              {info.renderValue()}
            </span>
          </div>
        ),
      },

      {
        accessorKey: "amount",
        header: () => "Amount",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900 ">
            {info.renderValue()}
          </span>
        ),
      },
    ],
    []
  );

  const Rows = [
    {
      initiationDate: "03/24/2024",
      completionDate: "03/24/2024",
      projectTitle: "Plumber",
      description: "Brooklyn, London",
      provider: "Johnson Nnamani ",
      amount: "$3,000.00",
    },
  ];
  return (
    <>
      <PaymentCommonHeader />

      <div className="mt-3">
        <Table columns={Columns} data={Rows} />
      </div>
    </>
  );
};
