import React, { Fragment, useState } from "react";
import "./dashboard-search.css";

import CustomTab from "../../common/custom-tab/CustomTab";
import DashboardSearchItems from "./DashboardSearchItems";
import { Col, Row } from "react-bootstrap";
import { COMMON_IMG } from "../../../assets/images.assets";
import { FaRegClock } from "react-icons/fa";
const DashboardSearchComponent = ({ modalRef, search }) => {
  const [activeTabCustom, setActiveTabCustom] = useState("All");
  const headers = ["All", "Work Orders", "Providers", "Clients", "Projects"];

  return (
    <div ref={modalRef} className="dashboard-search-main">
      {search ? (
        <Fragment>
          <CustomTab
            headers={headers}
            setActiveTabCustom={setActiveTabCustom}
            activeTabCustom={activeTabCustom}
          />

          <div className="content-tab relative">
            {activeTabCustom == "All" && (
              <Fragment>
                {Array.from({ length: 10 }).map((item, index) => {
                  return <DashboardSearchItems />;
                })}
              </Fragment>
            )}
            {activeTabCustom == "Work Orders" && (
              <Fragment>
                {Array.from({ length: 10 }).map((item, index) => {
                  return <DashboardSearchItems />;
                })}
              </Fragment>
            )}
            {activeTabCustom == "Providers" && (
              <Fragment>
                {Array.from({ length: 10 }).map((item, index) => {
                  return <DashboardSearchItems />;
                })}
              </Fragment>
            )}

            {activeTabCustom == "Clients" && (
              <Fragment>
                {Array.from({ length: 10 }).map((item, index) => {
                  return <DashboardSearchItems />;
                })}
              </Fragment>
            )}

            {activeTabCustom == "Projects" && (
              <Fragment>
                {Array.from({ length: 10 }).map((item, index) => {
                  return <DashboardSearchItems />;
                })}
              </Fragment>
            )}

            <div className="footer-all-details">View all results</div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="border-common d-flex justify-content-between p-2 text-black fw-600">
            <span>Recent</span>
            <span>Clear</span>
          </div>

          <div className="px-4 mt-4 content-recent-tab">
            <Row>
              {Array.from({ length: 4 }).map((item, index) => {
                return (
                  <Col key={index} md={2}>
                    <img height={45} src={COMMON_IMG} alt="" />
                    <p className="fw-300 color-fiord">Plumb...</p>
                  </Col>
                );
              })}
            </Row>
            <div className="mt-4">
              {" "}
              {Array.from({ length: 10 }).map((item, index) => {
                return (
                  <div key={index} className="border-common py-3">
                    <FaRegClock /> <span className="ms-2">Lorret</span>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DashboardSearchComponent;
