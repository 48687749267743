import { ProfileWrapper } from '../../../../components/common/Default'
import { ResumeForm } from '../../../../components/authentication/profiling/ResumeForm'

const INFO = {
  title: <>How would you like tell us <br /> about yourself</>,
  description: 'To show you where your skills are most in demand, we need to get a sense of your experience, skills and desired tools'
}

export const ResumeUploader = () => {

  return (
    <ProfileWrapper isProvider email info={INFO}>
      <ResumeForm />
    </ProfileWrapper>
  )
}
