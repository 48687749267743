import { Col, Form, Row } from 'react-bootstrap'
import { Input } from '../../common/input/Input'
import { Button } from '../../common/button/Button'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as UserCircle } from '../../../assets/images/user.svg'
import { useNavigate } from 'react-router-dom'
import { getAuthProvider, showToaster } from '../../../utils/utils'
import PostRequest from '../../../services/PostRequest'
import { useDispatch, useSelector } from 'react-redux'
import { ASSET_URL } from '../../../config/API_URL'
import { fetchProviderDetails } from '../../../store/provider/provider.action'
import MyPhoneInput from '../../common/my-phone-input/MyPhoneInput'

export const PhotoAndLocationForm = ({
    fromModal = false,
    handleClose = () => {},
    outlineText = 'Back',
    submitText = 'Continue'
}) => {

    const providerDetails = useSelector(state => state.providerReducer.providerDetails)
    const { parsedAuthData: parsedAuthProvider } = getAuthProvider()

    const [formData, setFormData] = useState({
        imageSrc: null,
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: ''
    })

    const [errors, setErrors] = useState({
        imageSrc: '',
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: ''
    });

    const fileInputRef = useRef(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prev => ({
                    ...prev,
                    imageSrc: reader.result
                }))
            }
            reader.readAsDataURL(file)
        }
    };

    const validateField = (name, value) => {
        var error = '';
        if (name === 'firstName') {
            if (!value.trim()) {
                error = 'First name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'First name cannot contains special characters';
            }
        } else if (name === 'middleName') {
            if (!value.trim()) {
                error = 'Middle name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Middle name cannot contains special characters';
            }
        } else if (name === 'lastName') {
            if (!value.trim()) {
                error = 'Last name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Last name cannot contains special characters';
            }
        } else if (name === 'phoneNumber' && !value) {
            error = 'Phone number is required';
        }

        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        var updatedValue = value;
        if (name === 'phoneNumber') {
            updatedValue = updatedValue.replace(/[^0-9]/g, '')
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: updatedValue
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const isFormValid = () => {
        const { imageSrc: formDataImageSrc, ...restFormData } = formData;
        return Object.values(errors).every(err => err === '') && Object.values(restFormData).every(value => value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newErrors = {
            // imageSrc: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNumber: '',
        }

        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.middleName) newErrors.businessName = 'Middle name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone number is required';
        // if (!formData.businessEmail) newErrors.businessEmail = 'Business Email is required';

        setErrors(newErrors);

        if (isFormValid()) {
            var data = {}
            var formDataObj = new FormData(e.target)
            formDataObj.forEach((val, key) => data[key] = val)

            try {
                await PostRequest('/provider/profile/update', data, { isMultiMedia: true, showLoader: 'photo-details' })
                if(fromModal) {
                    handleClose()
                    dispatch(fetchProviderDetails())
                } else {
                    navigate('/resume-uploader')
                }
            } catch (error) {
                showToaster(error.message, 'error')
            }
        }
    }

    useEffect(() => {
        if (providerDetails) {
            setFormData({
                imageSrc: providerDetails?.profileImage ? `${ASSET_URL}/${providerDetails?.profileImage}` : null,
                firstName: providerDetails?.firstName || '',
                middleName: providerDetails?.middleName || '',
                lastName: providerDetails?.lastName || '',
                phoneNumber: providerDetails?.phoneNumber || '',
            })
        }
    }, [providerDetails])

    useEffect(() => {
        dispatch(fetchProviderDetails())
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <div className='text-center mb-2'>
                {formData?.imageSrc ? (
                    <img
                        src={formData?.imageSrc}
                        alt='Uploaded'
                        className='mb-2 rounded-circle profileImage-size object-fit-cover'
                    />
                ) : (
                    <UserCircle className='mb-2' />
                )}

                <Button
                    variant='outline'
                    onClick={(e) => {
                        e.preventDefault()
                        fileInputRef.current.click()
                    }}
                    className='d-block mx-auto py-2 rounded fw-medium'
                >
                    {formData?.imageSrc ? 'Edit picture' : 'Upload new picture'}
                </Button>
                <input
                    type='file'
                    ref={fileInputRef}
                    name='profileImage'
                    className='d-none'
                    accept='image/png, image/jpeg'
                    onChange={handleFileChange}
                />
            </div>

            <Input
                label='First name'
                placeholder='First name'
                htmlFor='firstName'
                id='firstName'
                name='firstName'
                value={formData?.firstName}
                onChange={handleChange}
                error={errors.firstName}
                className='mb-2'
            />
            <Input
                label='Middle name'
                placeholder='Middle name'
                name='middleName'
                htmlFor='middleName'
                id='middleName'
                value={formData?.middleName}
                onChange={handleChange}
                error={errors.middleName}
                className='mb-2'
            />
            <Input
                label='Last name'
                placeholder='Last name'
                name='lastName'
                htmlFor='lastName'
                id='lastName'
                value={formData?.lastName}
                error={errors.lastName}
                onChange={handleChange}
                className='mb-2'
            />
            <MyPhoneInput
                label='Phone Number'
                value={formData.phoneNumber}
                onChange={(phone) => {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        phoneNumber: phone
                    }))
                }}
                error={errors.phoneNumber}
                className='mb-3'
            />

            <Row className='mb-1 gap-2'>
                <Col xs={6} md={12} lg={6}>
                    <Button
                        onClick={() => {
                            fromModal ? handleClose() : navigate(-1)
                        }}
                        type='button'
                        variant='outline'
                        className='w-100 color-ebony border-ebony py-3'
                    >
                        {fromModal ? 'Close' : 'Back'}
                    </Button>
                </Col>
                <Col>
                    <Button
                        type='submit'
                        id='photo-details'
                        disabled={!isFormValid()}
                        className='w-100 py-3'
                    >
                        {fromModal ? 'Update' : 'Continue'}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
