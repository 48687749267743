import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Input } from '../../common/input/Input'
import { Select } from '../../common/select/Select'
import { Button } from '../../common/button/Button'
import { Fragment, useEffect, useState } from 'react'
import { SetPermission } from './SetPermission'

export const CreateRole = ({ show, handleClose, mode, roleData }) => {

    const [showSetPermission, setShowSetPermission] = useState(false)

    const [formData, setFormData] = useState({
        roleName: '',
        description: '',
        status: null
    })
    const [errors, setErrors] = useState({
        roleName: '',
        description: '',
        status: ''
    })

    const validateField = (name, value) => {
        var error = '';
        if (name === 'roleName' && !value) {
            error = 'Role name is required';
        } else if (name === 'description' && !value) {
            error = 'Description is required';
        } else if (name === 'status' && !value) {
            error = 'Status is required';
        }
        return error;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))

        const error = validateField(name, value);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    }

    const handleSelectChange = (selectedOption, name) => {
        const updatedValue = selectedOption?.value

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: selectedOption || null
        }))

        const error = validateField(name, updatedValue);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
    }

    const isFormValid = () => Object.values(errors).every(err => err === '') && Object.keys(formData).every(key => formData[key] !== '' && formData[key] !== null)

    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = {
            roleName: '',
            description: '',
            status: ''
        }

        if (!formData.roleName) newErrors.roleName = 'Role name is required'
        if (!formData.description) newErrors.description = 'Description is required'
        if (!formData.status) newErrors.status = 'Status is required'

        setErrors(newErrors)

        if (isFormValid()) {
            setShowSetPermission(true)
        }
    }

    const handleCloseRole = () => {
        setFormData({
            roleName: '',
            description: '',
            status: null
        })
        setErrors({
            roleName: '',
            description: '',
            status: ''
        })
        handleClose()
    }

    // If in "Edit" mode, populate the form fields with the roleData
    useEffect(() => {
        if (mode === 'edit' && roleData) {
            setFormData({
                roleName: roleData?.roleName,
                description: roleData?.description,
                status: { label: roleData?.status, value: roleData?.status }
            })
        }
    }, [mode, roleData]);

    return (
        <Fragment>
            <Offcanvas
                show={show}
                onHide={handleCloseRole}
                placement='end'
            >
                <Offcanvas.Body className='d-flex flex-column'>
                    <div className='mb-3'>
                        <Offcanvas.Title className='fw-semibold text-black'>{mode === 'edit' ? 'Edit Role' : 'Create Role'}</Offcanvas.Title>
                        <p>You can invite member</p>
                    </div>
                    <Form onSubmit={handleSubmit} className='d-flex flex-column flex-grow-1'>
                        <Input
                            label='Role Name'
                            name='roleName'
                            id='roleName'
                            htmlFor='roleName'
                            value={formData?.roleName}
                            onChange={handleChange}
                            error={errors?.roleName}
                            placeholder='Enter role name'
                            className='mb-2'
                        />
                        <Input
                            label='Description'
                            name='description'
                            id='description'
                            htmlFor='description'
                            value={formData?.description}
                            onChange={handleChange}
                            error={errors?.description}
                            placeholder='description'
                            className='mb-2'
                            rows={4}
                            multiline
                        />
                        <Select
                            label='Status'
                            placeholder='Select one'
                            name='status'
                            inputId='status'
                            htmlFor='status'
                            value={formData?.status}
                            onChange={(status) => handleSelectChange(status, 'status')}
                            error={errors?.status}
                            options={[
                                { label: 'Active', value: 'active' },
                                { label: 'Inactive', value: 'inactive' },
                            ]}
                        />
                        <Stack direction='horizontal' className='justify-content-between mt-auto'>
                            <Button
                                type='button'
                                variant='outline'
                                className='fw-semibold px-3 color-ebony padded-border-button'
                                onClick={handleCloseRole}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                className='fw-semibold p-3'
                                disabled={!isFormValid()}
                            >
                                Proceed to set permission
                            </Button>
                        </Stack>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <SetPermission
                show={showSetPermission}
                handleClose={() => setShowSetPermission(false)}
                handleCloseRole={handleCloseRole}
                mode={mode}
                permissions={roleData?.permissions}
                formData={{
                    ...formData,
                    status: formData.status?.label,
                    role: roleData?._id
                }}
            />
        </Fragment>
    )
}
