import React, { useMemo } from 'react'
import { MainDefault } from '../../../../../components/common/Default'
import { useNavigate } from 'react-router-dom'
import { HiArrowLongLeft } from 'react-icons/hi2'
import { Table } from '../../../../../components/common/table/Table'
import moment from 'moment'

export const BackgroundCheck = () => {

    const navigate = useNavigate()

    const columns = useMemo(() => [
        {
            header: 'Author',
            accessorKey: 'author',
            cell: info => info.renderValue()
        },
        {
            header: 'Date Ordered',
            accessorKey: 'dateOrdered',
            cell: info => (
                <span>{moment(info.getValue()).format('MMMM D, YYYY | hh:mm A')}</span>
            )
        },
        {
            header: 'Amount',
            accessorKey: 'amount',
            cell: info => info.renderValue()
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: info => <span className='job-status badge-status__assigned'>{info.renderValue()}</span>
        },
    ], [])

    const rows = [
        {
            author: 'Johnson Nnamani',
            dateOrdered: Date.now(),
            amount: '$50',
            status: 'Pending'
        },
        {
            author: 'Johnson Nnamani',
            dateOrdered: Date.now(),
            amount: '$50',
            status: 'Pending'
        },
        {
            author: 'Johnson Nnamani',
            dateOrdered: Date.now(),
            amount: '$50',
            status: 'Pending'
        },
        {
            author: 'Johnson Nnamani',
            dateOrdered: Date.now(),
            amount: '$50',
            status: 'Pending'
        },
        {
            author: 'Johnson Nnamani',
            dateOrdered: Date.now(),
            amount: '$50',
            status: 'Pending'
        },
        {
            author: 'Johnson Nnamani',
            dateOrdered: Date.now(),
            amount: '$50',
            status: 'Pending'
        },
    ]

    return (
        <MainDefault>
            <div className='px-2 py-1 mb-4'>
                <HiArrowLongLeft
                    size={22}
                    color='black'
                    onClick={() => navigate(-1)}
                    className='cursor-pointer'
                    style={{ marginBottom: 2 }}
                />
                <span className='fw-medium ms-2'>Back</span>
            </div>
            <div className="top-nav py-3 mb-4 ">
                <h4 className="mb-0">Background Check</h4>
            </div>
            <Table data={rows} columns={columns} />
        </MainDefault>
    )
}
