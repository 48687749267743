import React from "react";
import { Form, Stack } from "react-bootstrap";
import { Input } from "../../../../components/common/input/Input";
import "./addEditClientForm.css";
import { Button } from "../../../../components/common/button/Button";

export const AddEditClientForm = ({
  handleSubmit,
  setAddClientShowModel,
  setManageClientModelShow,
  actionLabel,
}) => {
  return (
    <div className="form-wrapper">
      <Form
        className="d-flex flex-column flex-grow-1 p-1"
        onSubmit={handleSubmit}
      >
        <div className="mt-4">
          <Input
            label="Client Name"
            placeHolder="Enter Name"
            defaultValue={
              actionLabel == "Save Changes" ? "Adeleke Fajuyi Olayiwola" : ""
            }
          />
        </div>
        <div className="mt-4">
          <Input
            label="Email Address"
            placeHolder="Enter email address"
            defaultValue={
              actionLabel == "Save Changes" ? "Brooklyn, London" : ""
            }
          />
        </div>
        <div className="mt-4">
          <Input
            label="Address"
            multiline
            placeHolder="Enter your address"
            defaultValue={
              actionLabel == "Save Changes" ? "Brooklyn, London" : ""
            }
          />
        </div>
        <div className="mt-4">
          <Input
            label="Phone Number"
            placeHolder="01-99399999"
            defaultValue={actionLabel == "Save Changes" ? "08092139441" : ""}
          />
        </div>
        <div className="button-bottom-side d-flex">
          {/* <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony cancel-button"
            onClick={() => setAddClientShowModel(false)}
          >
            Cancel
          </Button> */}
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => {
              actionLabel == "Add Client"   ? setAddClientShowModel(false) :
              setManageClientModelShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="fw-semibold py-1 px-2 add-client-button"
          >
            {actionLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};
