import { Col, Row, Stack } from 'react-bootstrap'
import { CardWrapper } from '../../../common/Default'
import './providers-content.css'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { CommonHeader } from '../../../buyer-provider/common-header/CommonHeader';
import { LuShare2 } from "react-icons/lu";
import { Table } from '../../../common/table/Table';
import { useMemo } from 'react';
import Avatar from 'react-avatar';

ChartJS.register(ArcElement, Tooltip, Legend)

const labels = ['Completed WO by Providers', 'Rejected WO done by Providers', 'Accepted WO by Providers']
const data = {
  labels,
  datasets: [
    {
      // label: '# of Votes',
      data: [54, 26, 20],
      backgroundColor: [
        '#338AFF',
        '#F36B39',
        '#FFB222',
      ],
      borderWidth: 0,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        generateLabels: (chart) => {
          const data = chart.data;
          return data.labels.map((label, index) => {
            const dataset = data.datasets[0];
            const value = dataset.data[index];
            return {
              text: `${label}: ${value}`,
              fillStyle: dataset.backgroundColor[index],
              strokeStyle: dataset.backgroundColor[index],
            };
          });
        },
      },
    },
  }
}

export const ProvidersContent = () => {

  const columns = useMemo(() => [
    {
      accessorKey: "initiationDate",
      header: () => "Initiation Date",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "completionDate",
      header: () => "Completion Date",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "projectTitle",
      header: () => "Project Title",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "description",
      header: () => "Description",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "provider",
      header: () => "Provider",
      cell: ({ row }) => (
        <Stack direction='horizontal' className='gap-2'>
          <Avatar round size='30' name={row.original.provider} />
          <span>{row.original.provider}</span>
        </Stack>
      ),
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => info.renderValue(),
    },
    {
      accessorKey: "status",
      header: () => "Status",
      cell: (info) => <span className='job-status badge-status__active'>{info.renderValue()}</span>,
    },
  ], [])

  const rows = [
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      amount: '$3,000.00',
      status: 'PAID'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      amount: '$3,000.00',
      status: 'PAID'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      amount: '$3,000.00',
      status: 'PAID'
    },
    {
      initiationDate: '03/24/2024',
      completionDate: '03/24/2024',
      projectTitle: 'Plumber',
      description: 'Brooklyn, London',
      provider: 'Johnson Nnamani',
      amount: '$3,000.00',
      status: 'PAID'
    },
  ]

  return (
    <>
      <Row className='row-gap-3 mb-4'>
        <Col md={4}>
          <CardWrapper>
            <h6 className='text-black font-18'>Assigned Providers</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are number of providers you have assigned work orders
            </p>
            <h3 className='text-black fw-700 mb-0'>60</h3>
          </CardWrapper>
        </Col>
        <Col md={4}>
          <CardWrapper>
            <h6 className='text-black font-18'>Rejected Providers</h6>
            <p className='color-grey-500 mb-4 font-14'>
              These are number of providers rejected on different work orders
            </p>
            <h3 className='text-black fw-700 mb-0'>200</h3>
          </CardWrapper>
        </Col>
        <Col md={4}>
          <CardWrapper>
            <h6 className='text-black font-18'>Saved Providers</h6>
            <p className='color-grey-500 mb-4 font-14 desc'>
              These are providers you have saved in your pool.
            </p>
            <h3 className='text-black fw-700 mb-0'>20</h3>
          </CardWrapper>
        </Col>

        <Col md={6}>
          <CardWrapper className='h-100'>
            <h6 className='text-black font-18 mb-3'>Providers by location</h6>
            <Stack className='justify-content-center map-image__wrapper'>
              <img src={require('../../../../assets/images/MAP.png')} className='w-100' alt="" />
            </Stack>
          </CardWrapper>
        </Col>
        <Col md={6}>
          <CardWrapper>
            <h6 className='text-black font-18'>Providers by chart</h6>
            <Pie data={data} options={options} />
          </CardWrapper>
        </Col>
      </Row>
      <h6 className='text-black font-18 mb-3'>Providers’ Expenses</h6>
      <CommonHeader filterIcon={LuShare2} filterButtonText='Export' showUnderLine={false} />
      <div className='mt-3'>
        <Table data={rows} columns={columns} />
      </div>
    </>
  )
}
