import React, { useMemo, useState } from "react";
// import Table from "react-bootstrap/Table";
import { MdOutlineMoreHoriz } from "react-icons/md";
import moment from "moment";
import { Badge, ListGroup, NavDropdown } from "react-bootstrap";
import { Table } from "../../../common/table/Table";
import { Link } from "react-router-dom";
const PublishedContentTable = ({ onShowCancelWO }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: () => "Work Order Title",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },
      {
        accessorKey: "location",
        header: () => "Location",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => {
          const status = info.renderValue();
          return (
            <Badge
              className={`rounded-3 text-uppercase ${status === "Active"
                  ? "badge-status__active"
                  : "badge-status__inactive"
                }`}
            >
              {info.renderValue()}
            </Badge>
          );
        },
        // enableSorting: false
      },
      {
        accessorKey: "datePosted",
        header: () => "Date Posted",
        cell: (info) => (
          <span>{moment(info.getValue()).format("MMMM D, YYYY")}</span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "applicants",
        header: () => "Applicants",
        cell: (info) => info.renderValue(),
        // enableSorting: false
      },
      {
        accessorKey: "price",
        header: () => "Price",
        cell: (info) => (
          <span className="color-grey-900 fw-semibold">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  as={Link}
                  to='/work-orders/basic-information'
                  state={{ mode: 'edit' }}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Manage order
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => { }}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Modify
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={onShowCancelWO}
                  className="cursor-pointer font-14 color-gray-700"
                >
                  Cancel order
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },
    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },

    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },

    {
      title: "Plumber",
      location: "Birmingham, United Kingdom",
      status: "Active",
      datePosted: moment().format("LL"),
      applicants: "30/200",
      price: "$500",
    },
  ];

  return (
    <>
      <Table columns={columns} data={rows} />
    </>
  );
};

export default PublishedContentTable;
