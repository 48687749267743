import React from "react";
import WorkTableDefault from "../../provider-work-order-details/WorkTableDefault";
import { AboutDeliverables } from "../../../../../job-info-tab/deliverables/AboutDeliverables";
import { TasksDeliverables } from "../../../../../job-info-tab/deliverables/TasksDeliverables";

export const DeliverablesMyWok = () => {
  return (
    <WorkTableDefault>
      <AboutDeliverables />

      <TasksDeliverables />
    </WorkTableDefault>
  );
};
