import React, { useState } from 'react'
import { RightOffcanvas } from '../../../buyer-provider/RightOffcanvas'
import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Input } from '../../../common/input/Input'
import { Select } from '../../../common/select/Select'
import Dropzone from 'react-dropzone'
import { Button } from '../../../common/button/Button'
import { ReactComponent as Gallery } from "../../../../assets/images/gallery.svg";

export const UploadAgreement = ({ show, handleClose }) => {

    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [formData, setFormData] = useState({
        user: null,
        agreementTitle: "",
    });

    const [errors, setErrors] = useState({
        user: "",
        agreementTitle: "",
    });

    const validateField = (name, value) => {
        let error = "";

        if (name === "agreementTitle") {
            if (!value) {
                error = "Agreement title is required.";
            }
        } else if (name === "user") {
            if (!value) {
                error = "Agreement type selection is required.";
            }
        }

        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSelectChange = (selectedOption, name) => {
        const value = selectedOption?.value || null;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: selectedOption || null,
        }));

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const isFormValid = () => {
        return (
            Object.values(errors).every((error) => error === "") &&
            formData.user &&
            formData.agreementTitle
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {
            user: validateField("user", formData.user?.value),
            agreementTitle: validateField("agreementTitle", formData.agreementTitle),
        };

        if (acceptedFiles?.length === 0) {
            alert("Please upload a resume PDF file.");
            return;
        }

        setErrors(newErrors);

        if (isFormValid()) {
            console.log("Submitted Data:", {
                user: formData.user.value,
                agreementTitle: formData.agreementTitle,
            });
            handleClose()
        }
    };

    const onDrop = (files) => {
        console.log("Accepted Files:", files);
        setAcceptedFiles(files);
    };

    const userOptions = [
        { label: "Type 1", value: "type1" },
        { label: "Type 2", value: "type2" },
        { label: "Type 3", value: "type3" },
    ];

    return (
        <RightOffcanvas
            show={show}
            handleClose={handleClose}
        >
            <div className="p-3">
                <h5 className="text-black fw-semibold font-20">Upload Agreement</h5>
                <p>You can upload your agreement here</p>
            </div>
            <Offcanvas.Body className="d-flex flex-column">
                <Form onSubmit={handleSubmit} className="form-wrapper mt-3">
                    <Input
                        label="Agreement Title"
                        placeholder="Enter agreement title"
                        className="mb-3"
                        name="agreementTitle"
                        id="agreementTitle"
                        htmlFor="agreementTitle"
                        value={formData?.agreementTitle}
                        onChange={handleChange}
                        error={errors?.agreementTitle}
                    />
                    <Select
                        label="Agreement Type"
                        placeholder="Select one"
                        className="mb-3"
                        options={userOptions}
                        name="user"
                        inputId="user"
                        htmlFor="user"
                        value={formData?.user}
                        onChange={(user) => handleSelectChange(user, "user")}
                        error={errors?.user}
                    />

                    <h6 className="font-14 line-height-160">Agreement Type</h6>
                    <Dropzone
                        maxFiles={1}
                        onDrop={onDrop}
                        accept={{
                            "application/pdf": [".pdf"],
                            "application/msword": [".doc"],
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                [".docx"],
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Stack
                                gap={2}
                                {...getRootProps({
                                    className:
                                        "dropzone__wrapper cursor-pointer align-items-center mb-3",
                                })}
                            >
                                <input name="resume" {...getInputProps()} />
                                <Gallery />
                                <span className="text-center text-secondary fw-medium">
                                    Drag and drop your files Accepted format: csv or pdf
                                </span>
                            </Stack>
                        )}
                    </Dropzone>

                    <div className="button-bottom-side d-flex">
                        <Button
                            type="button"
                            variant="outline"
                            className="fw-semibold px-3 color-ebony cancel-button"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="submit-button"
                            disabled={!isFormValid()}
                            type="submit"
                            className="fw-semibold py-1 px-2 add-client-button"
                        >
                            Upload Agreement
                        </Button>
                    </div>
                </Form>
            </Offcanvas.Body>
        </RightOffcanvas>
    )
}
