import { useState } from 'react'
import { MainDefault } from '../../../../components/common/Default'
import { NotFoundCard } from '../../../../components/profile/not-found/NotFoundCard'
import { AddCertification } from '../../../../components/profile/offcanvas/AddCertification'
import { CertificationsTable } from '../../../../components/profile/CertificationsTable'
import { Stack } from 'react-bootstrap'
import { Button } from '../../../../components/common/button/Button'

export const MyCertifications = () => {

    const [showAddCertification, setShowAddCertification] = useState(false)

    return (
        <MainDefault>
            <Stack
                direction="horizontal"
                className="justify-content-between top-nav py-3 mb-3"
            >
                <h4 className="mb-0">
                    Certifications
                </h4>
                {[''].length > 0 && (
                    <Button
                        className="px-3"
                        style={{ paddingBlock: 12 }}
                        onClick={() => setShowAddCertification(true)}
                    >
                        Add Certification
                    </Button>
                )}
            </Stack>
            {[''].length <= 0 ? (
                <NotFoundCard
                    title="No certification added"
                    subTitle="You have not add any certification here"
                    buttonText="Add certification"
                    onButtonClick={() => setShowAddCertification(true)}
                />
            ) : (
                <CertificationsTable />
            )}
            <AddCertification show={showAddCertification} handleClose={() => setShowAddCertification(false)} />
        </MainDefault>
    )
}
