import React from "react";
import { Form } from "react-bootstrap";
import { IoLockClosed } from "react-icons/io5";
import Underline from "../../../../components/buyer-provider/Underline";

export const GoToSettings = () => {
  return (
    <>
      <div className="top-nav py-3 mt-4 mb-4">
        <h4 className="mb-0">Two-Factor Authentication</h4>
      </div>

      <div className="d-flex gap-2 mt-5">
        <IoLockClosed size={38} color="#920af2" />
        <p className="primary-text-color">Enabled</p>
      </div>

      <div className="d-flex align-items-center gap-2 mt-3">
        <Form.Check type="checkbox" className="buyer-checkbox mt-1 defaultChecked" id='twoFactorAuthCheckbox' defaultChecked/>
        <label htmlFor="twoFactorAuthCheckbox" className="login-restriction-policy">
          Two-Factor Authentication has been enabled
        </label>
      </div>

      <Underline />

      <div className="top-nav py-3 mt-5 mb-4">
        <h4 className="mb-0">Login Restrictions</h4>
      </div>

      <div className="d-flex align-items-center gap-2">
        <Form.Check type="checkbox" className="buyer-checkbox mt-1" id='manageRolesCheckbox' />
        <label htmlFor="manageRolesCheckbox" className="login-restriction-policy">Manage all company’s roles</label>
      </div>
      <div className="d-flex align-items-center gap-2 mt-4">
        <Form.Check type="checkbox" className="buyer-checkbox mt-1" id='manageOrgStructuresCheckbox' />
        <label htmlFor='manageOrgStructuresCheckbox' className="login-restriction-policy">
          Manage all company’s org structures
        </label>
      </div>
    </>
  );
};
