import { Fragment, useState } from 'react'
import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Button } from '../../../common/button/Button'
import { FiPlus } from 'react-icons/fi'
import { CheckInOutSchedule } from '../modals/CheckInOutSchedule'

export const MarkAsInProgress = ({ show, handleClose }) => {
    
    const [showCheckIn, setShowCheckIn] = useState(false)
    const [showCheckOut, setShowCheckOut] = useState(false)
    
    return (
        <Fragment>
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='text-black fw-700'>Mark as In-Progress</Offcanvas.Title>
            </Offcanvas.Header>
            <Form className='d-flex flex-column flex-grow-1'>
                <Offcanvas.Body>
                    <div className='mb-3'>
                        <p className='color-neutrals-80 fw-600'>Check-In Timesheet</p>
                        <div className='text-center'>
                            <Button 
                                type='button' 
                                className='py-3 bg-transparent color-primary'
                                onClick={() => setShowCheckIn(true)}
                            >
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add CheckIn Date & Time</span>
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p className='color-neutrals-80 fw-600'>Check-Out Timesheet</p>
                        <div className='text-center'>
                            <Button 
                                type='button' 
                                className='py-3 bg-transparent color-primary'
                                onClick={() => setShowCheckOut(true)}
                            >
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add CheckOut Date & Time</span>
                            </Button>
                        </div>
                    </div>
                </Offcanvas.Body>
                <Stack direction='horizontal' className='justify-content-between p-3'>
                    <Button
                        type='button'
                        variant='outline'
                        className='fw-semibold px-3 color-ebony padded-border-button'
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        className='fw-semibold p-3'
                    // disabled={!isFormValid()}
                    >
                        Mark as In-Progress
                    </Button>
                </Stack>
            </Form>
        </Offcanvas>
        <CheckInOutSchedule 
            show={showCheckIn} 
            handleClose={() => setShowCheckIn(false)} 
            type='check-in'
            modalTitle='Check In'
            primaryButtonText='Add'
        />
        <CheckInOutSchedule 
            show={showCheckOut} 
            handleClose={() => setShowCheckOut(false)} 
            type='check-out'
            modalTitle='Check Out'
            primaryButtonText='Add'
        />
        </Fragment>
    )
}
