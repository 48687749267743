import { applyMiddleware, combineReducers, createStore } from 'redux';
import { providerReducer } from './provider/provider.reducer';
import { thunk } from 'redux-thunk';
import { buyerReducer } from './buyer/buyer.reducer';
import { rolesReducer } from './buyer/roles/roles.reducer';
import { teamMemberReducer } from './buyer/team-member/team-member.reducer';

const rootReducer = combineReducers({
    providerReducer: providerReducer,
    buyerReducer: buyerReducer,
    rolesReducer: rolesReducer,
    teamMemberReducer: teamMemberReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store