import React from "react";
import { Button } from "../../../../../components/common/button/Button";
import { Form } from "react-bootstrap";

export const TermsAndCondition = ({ setTabBecomeServiceCompany }) => {
  const termsAndConditions = [
    {
      tAndC:
        " Follow Instructions: Ensure successful completion of the screening process by adhering to emailed instructions.",
    },
    {
      tAndC:
        "Receive Notifications: Stay informed about the status of your screening results through notifications.",
    },
    {
      tAndC:
        "Automatic Profile Update: Your profile will be automatically updated upon successful completion of screenings.",
    },
  ];
  return (
    <>
      <h6 className="font-16 fw-bold header-color">Terms & Condition</h6>

      <ul className="mt-3 ">
        {termsAndConditions.map((s) => (
          <li className="font-14 line-height-20 color-fiord">{s.tAndC}</li>
        ))}
      </ul>
      <p className="font-14 line-height-20 color-fiord">
        Expect detailed instructions from our trusted screening partners via
        email.
      </p>

      <div className="d-flex gap-3 mt-3">
        <Form.Check type="checkbox" className="buyer-checkbox mb-2" />
        <p className="font-14 line-height-160 color-gray-800">
          I agree to the Porvider’s Terms & Conditions
        </p>
      </div>
      <hr className="border-botticelli opacity-100 mb-4 mt-4" />
      <div className="d-flex gap-3">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-4 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          onClick={() => setTabBecomeServiceCompany("requirement&FAQs")}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="fw-semibold py-1 px-4"
            onClick={() => setTabBecomeServiceCompany("newCompanyInformation")}
        >
          Next
        </Button>
      </div>
    </>
  );
};
