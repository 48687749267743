import { Form, Offcanvas, Stack } from 'react-bootstrap'
import { Input } from '../../../common/input/Input'
import MyPhoneInput from '../../../common/my-phone-input/MyPhoneInput'
import { AddFundsForm } from '../../../authentication/profiling/AddFundsForm'
import { BillingInformationForm } from '../../../buyer-provider/BillingInformationForm'
import { Button } from '../../../common/button/Button'
import { Fragment, useState } from 'react'
import { Summary } from './Summary'

export const Order = ({ show, handleClose, screeningId }) => {

    const [showSummary, setShowSummary] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowSummary(true)
    }

    return (
        <Fragment>
            <Offcanvas
                show={show}
                onHide={handleClose}
                className='timesheet-offcanvas'
                placement='end'
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='fw-bold'>
                        Order for {screeningId === 'background-check' ? 'Background Check' : 'Drug Test'}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {screeningId === 'drug-test' && <div>
                        <p className='color-neutrals-80'>Price</p>
                        <h5 className='color-grayscale-900 fw-700'>$25.00</h5>
                        <hr className='border-botticelli opacity-100' />
                    </div>}
                    <h6 className='color-neutrals-80'>
                        Please confirm the following details from your profile are accurate. If you need to update any of the pre-populated information below, please update your <span className='color-primary'>user profile</span> information.
                    </h6>
                    <Form onSubmit={handleSubmit}>
                        <Input
                            label='First Name'
                            name='firstName'
                            id='firstName'
                            className='mb-2'
                        />
                        <Input
                            label='Last Name'
                            name='lastName'
                            id='lastName'
                            className='mb-2'
                        />
                        <Stack direction='horizontal' gap={2} className='justify-content-between mb-2'>
                            <Input
                                label='Zip Code'
                                name='zipCode'
                                id='zipCode'
                                htmlFor='zipCode'
                            // value={formData?.zipCode}
                            // onChange={handleChange}
                            />
                            <MyPhoneInput
                                label={<>Phone Number <span className="star">*</span></>}
                                name='ssn'
                                id='ssn'
                            />
                        </Stack>
                        <Form.Check
                            type='checkbox'
                            label='This is my legal first, last name and my current zip code'
                            id='legalNameZipCode'
                            name='legalNameZipCode'
                            className='buyer-checkbox mb-2'
                        />
                        <p className='font-14 color-dark-gray fw-500 mb-2'>
                            If this is not your legal first and last name, please <span className='color-primary'>contact support</span> for assistance. Zip code can be edited in <span className='color-primary'>your profile</span>
                        </p>
                        <Input
                            label='Email address'
                            placeholder='Enter email address'
                            className='mb-2'
                        />
                        <div className='font-14 mb-2'>
                            <p className='text-black fw-medium'>Card Details</p>
                            <hr className='opacity-100 mt-2 mb-3 border-grayscale-100' />
                            <AddFundsForm fromPayments />
                        </div>
                        <div className='font-14 mb-2'>
                            <p className='text-black fw-medium'>Billing Information</p>
                            <hr className='opacity-100 mt-2 mb-3 border-grayscale-100' />
                            <BillingInformationForm />
                        </div>
                        <Stack direction='horizontal' className='justify-content-between'>
                            <Button
                                type='button'
                                variant='outline'
                                className='fw-semibold font-14 color-ebony padded-border-button'
                                onClick={() => {
                                    // if (isCardSelected) {
                                    //     setIsCardSelected(false)
                                    // } else {
                                    //     setInputYourAmount(false)
                                    // }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                className='fw-semibold font-14 px-14px py-14px'
                            // disabled={!isCreditCardFormValid}
                            // onClick={() => setFundsAdded(true)}
                            >
                                Confirm and Order {screeningId === 'background-check' ? 'Background Check' : 'Drug Test'}
                            </Button>
                        </Stack>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <Summary
                show={showSummary}
                handleCloseOrder={handleClose}
                handleClose={() => setShowSummary(false)}
                screeningId={screeningId}
            />
        </Fragment>
    )
}
