import React, { Fragment } from "react";
import { CardWrapper } from "../common/Default";
import { FilterButton } from "../common/filter-button/FilterButton";
import { VscEye } from "react-icons/vsc";
import { BiSolidFilePdf } from "react-icons/bi";

const Documents = () => {
  return (
    <CardWrapper>
      <h6>Documents</h6>

      {Array.from({ length: 3 }, (_, index) => index).map((index) => {
        return (
          <Fragment key={index}>
            <div className="mt-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3">
                  <BiSolidFilePdf className="documentation-icon" />
                  <div className="documentation-info pt-2">
                    <h5>SafetyGuidelines.pdf</h5>
                    <p>867 Kb</p>
                  </div>
                </div>
                <div>
                  <FilterButton>
                    <VscEye className="me-1" size={20} />
                    <span className="view-document">View Document</span>
                  </FilterButton>
                </div>
              </div>
            </div>
            <div
              className={`${index !== 2 ? "mt-4 border-wrapper" : ""}`}
            ></div>
          </Fragment>
        );
      })}
    </CardWrapper>
  );
};

export default Documents;
