import React, { useState } from "react";
import {
  CardWrapper,
  MainDefault,
} from "../../../../components/common/Default";
import { Col, Form, Row } from "react-bootstrap";
import {
  ADOBE_SIGN,
  DOCU_SIGN,
  DROPBOX_SIGN,
} from "../../../../assets/images.assets";
import { Button } from "../../../../components/common/button/Button";

export const Integrations = () => {
  const [selectedIntegration, setSelectedIntegration] = useState("DropboxSign");

  const handleCheckboxChange = (integration) => {
    setSelectedIntegration(
      integration === selectedIntegration ? null : integration
    );
  };

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4">
        <h4 className="mb-0">Integrations</h4>
      </div>
      <Row>
        <Col sm={3}>
          <CardWrapper>
            <div className="d-flex justify-content-end">
              <Form.Check
                type="checkbox"
                className="buyer-checkbox"
                checked={selectedIntegration === "DropboxSign"}
                onChange={() => handleCheckboxChange("DropboxSign")}
              />
            </div>
            <div className="p-3 integration-card">
              <img src={DROPBOX_SIGN} />
              <div className="mt-3">
                <h6>DropboxSign</h6>
                <p>
                  This integration will allow seamless<br></br> addition of
                  signature
                </p>
              </div>
              <Button className="py-2 px-4 mt-3">Connect</Button>
            </div>
          </CardWrapper>
        </Col>
        <Col sm={3}>
          <CardWrapper>
            <div className="d-flex justify-content-end">
              <Form.Check
                type="checkbox"
                className="buyer-checkbox"
                checked={selectedIntegration === "DocuSign"}
                onChange={() => handleCheckboxChange("DocuSign")}
              />
            </div>
            <div className="p-3 integration-card">
              <img src={DOCU_SIGN} />

              <div className="mt-3">
                <h6>DocuSign</h6>
                <p>
                  This integration will allow seamless<br></br> addition of
                  signature
                </p>
              </div>
              <Button className="py-2 px-4 mt-3">Connect</Button>
            </div>
          </CardWrapper>
        </Col>

        <Col sm={3}>
          <CardWrapper>
            <div className="d-flex justify-content-end">
              <Form.Check
                type="checkbox"
                className="buyer-checkbox"
                checked={selectedIntegration === "AdobeSign"}
                onChange={() => handleCheckboxChange("AdobeSign")}
              />
            </div>
            <div className="p-3 integration-card">
              <img src={ADOBE_SIGN} />
              <div className="mt-3">
                <h6>AdobeSign</h6>
                <p>
                  This integration will allow seamless<br></br> addition of
                  signature
                </p>
              </div>
              <Button className="py-2 px-4 mt-3">Connect</Button>
            </div>
          </CardWrapper>
        </Col>
      </Row>
    </MainDefault>
  );
};
