import { useNavigate } from 'react-router-dom'
import { ProfileWrapper } from '../../../../components/common/Default'
import { BuyerTaxInformationForm } from '../../../../components/profile/TaxInformationForm'
import PostRequest from '../../../../services/PostRequest'
import { showToaster } from '../../../../utils/utils'

const INFO = {
    title: 'Tax Information',
    description: 'Just a few info about you...'
}

export const TaxInformation = () => {
    
    const navigate = useNavigate()
    
    const handleSubmit = async (onFormSubmit) => {
        const formSubmitResult = onFormSubmit()
        if(!formSubmitResult) return

        try {
            await PostRequest('/buyer/tax-details/update', formSubmitResult, { showLoader: 'tax-details-button' })
            navigate('/add-funds')
        } catch (error) {
            showToaster(error.message, 'error')
            return error
        }
    }
    
    return (
        <ProfileWrapper info={INFO}>
            <BuyerTaxInformationForm onSubmit={handleSubmit} />
        </ProfileWrapper>
    )
}
