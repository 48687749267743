import React, { useState } from "react";
import { Select } from "../../../../components/common/select/Select";
import { Button } from "../../../../components/common/button/Button";
import { Input } from "../../../../components/common/input/Input";
import { monthOptions, yearOptions } from "../../../../data/custom-options";
import { Form, Offcanvas } from "react-bootstrap";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { HiArrowLongLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { PaymentSuccessful } from "./PaymentSuccessful";
import { RxCross1 } from "react-icons/rx";

export const BackGroundPayment = ({ setBackGroundOrderPayment }) => {
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [formData, setFormData] = useState({
    cardType: "",
    cardName: "",
    cardNumber: "",
    month: "",
    year: "",
    cvv: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "cardType":
        if (!value) error = "Card type is required.";
        break;
      case "cardName":
        if (!value) error = "Card name is required.";
        break;
      case "cardNumber":
        if (!value) error = "Card number is required.";
        else if (!/^\d{16}$/.test(value))
          error = "Card number must be 16 digits.";
        break;
      case "month":
      case "year":
        if (!value) error = "Expiration date is incomplete.";
        break;
      case "cvv":
        if (!value) error = "CVV is required.";
        else if (!/^\d{3}$/.test(value)) error = "CVV must be 3 digits.";
        break;
      case "address":
        if (!value) error = "Address is required.";
        break;
      case "city":
        if (!value) error = "City is required.";
        break;
      case "state":
        if (!value) error = "State is required.";
        break;
      case "country":
        if (!value) error = "Country is required.";
        break;
      case "postalCode":
        if (!value) error = "Postal code is required.";
        else if (!/^\d{5}$/.test(value))
          error = "Postal code must be 5 digits.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = Object.keys(formData).reduce((acc, key) => {
      acc[key] = validateField(key, formData[key]);
      return acc;
    }, {});
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      console.log("Form Data Submitted:", formData);
      setPaymentSuccessful(false);
    }
  };

  const cardTypes = [
    { value: "Visa", label: "Visa" },
    { value: "MasterCard", label: "Master Card" },
    { value: "AmericanExpress", label: "American Express" },
  ];

  return (
    <>
      <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
        Add Payment
      </h6>

      <h6 className="font-16 line-height-160 fw-500 mt-3">Card Details</h6>
      <Form className="mt-3 form-wrapper" onSubmit={handleSubmit}>
        <Select
          label="Card Type"
          name="cardType"
          placeholder="Select one"
          className="mb-3"
          options={cardTypes}
          value={formData.cardType}
          onChange={(e) => handleChange(e)}
          error={errors.cardType}
          star
        />

        <Input
          label="Card Name"
          type="text"
          placeholder="Enter name on the card"
          className="mb-3"
          name="cardName"
          value={formData.cardName}
          onChange={handleChange}
          error={errors.cardName}
        />

        <Input
          label="Card Number"
          type="text"
          placeholder="Enter card number"
          className="mb-3"
          name="cardNumber"
          value={formData.cardNumber}
          onChange={handleChange}
          error={errors.cardNumber}
        />

        <div className="d-flex gap-1 align-items-center mb-3">
          <div>
            <div>
              <label className="form-label line-height-160">
                Expiration Date
              </label>
              <span className="star ps-1">*</span>
            </div>
            <div className="d-flex gap-1 align-items-center">
              <Select
                name="month"
                placeholder="Month"
                options={monthOptions}
                value={formData.month}
                onChange={(e) =>
                  handleChange({
                    target: { name: "month", value: e.target.value },
                  })
                }
                // className="w-100"
              />

              <Select
                name="year"
                placeholder="Year"
                options={yearOptions}
                value={formData.year}
                onChange={(e) =>
                  handleChange({
                    target: { name: "year", value: e.target.value },
                  })
                }
                // className="w-100"
              />
            </div>
          </div>

          <div>
            <div className="mt-3">
              <label className="form-label line-height-160">CVV</label>
              <span className="star ps-1">*</span>
            </div>

            <Input
              type="text"
              placeholder="CVV"
              className="mb-3"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange}
              error={errors.cvv}
            />
          </div>
        </div>

        <Form.Check
          type="checkbox"
          className="buyer-checkbox filter-wo__checkbox"
          label="Save this location for future use"
        />

        <h6 className="font-16 fw-500 line-height-160 mt-3 mb-3">
          Billing Information
        </h6>

        <Input
          label="Address"
          type="text"
          placeholder="Address"
          className="mb-3"
          name="address"
          value={formData.address}
          onChange={handleChange}
          error={errors.address}
        />
        <div className="d-flex justify-content-between mb-3 gap-1">
          <Select
            label="City"
            name="city"
            placeholder="Select City"
            className="mb-2 w-100"
            value={formData.city}
            onChange={(e) =>
              handleChange({ target: { name: "city", value: e.target.value } })
            }
            error={errors.city}
            star
          />
          <Select
            label="State"
            name="state"
            placeholder="Select State"
            className="mb-2 w-100"
            value={formData.state}
            onChange={(e) =>
              handleChange({ target: { name: "state", value: e.target.value } })
            }
            error={errors.state}
            star
          />
        </div>
        <div className="d-flex justify-content-between mb-3 gap-1">
          <Select
            label="Country"
            name="country"
            placeholder="Select Country"
            className="mb- w-100"
            value={formData.country}
            onChange={(e) =>
              handleChange({
                target: { name: "country", value: e.target.value },
              })
            }
            error={errors.country}
            star
          />
          <Input
            label="Postal Code"
            name="postalCode"
            placeholder="Postal Code"
            className="mb-3 w-100"
            value={formData.postalCode}
            onChange={handleChange}
            error={errors.postalCode}
          />
        </div>

        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setBackGroundOrderPayment(false)}
          >
            Cancel
          </Button>

          <Button
            // disabled={!isFormValid()}
            type="submit"
            className="fw-semibold py-3 px-3"
            onClick={() => setPaymentSuccessful(true)}
          >
            Pay for Background Check
          </Button>
        </div>
      </Form>

      <RightOffcanvas
        show={paymentSuccessful}
        onHide={setPaymentSuccessful}
        className="w-25"
      >
        <div className="p-3 d-flex align-items-center justify-content-between">
          <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
            Order for Background Check
          </h6>
          <RxCross1
            size={20}
            onClick={() => setPaymentSuccessful(false)}
            className="cursor-pointer"
          />
        </div>
        <Offcanvas.Body className="d-flex flex-column">
          <PaymentSuccessful setPaymentSuccessful={setPaymentSuccessful} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
