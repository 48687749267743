import { toast } from 'react-toastify';
import PostRequest from '../services/PostRequest';

export function formatFileSize(bytes = 0) {
    if (bytes === 0) return '0 B';

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const power = Math.floor(Math.log(bytes) / Math.log(1024));

    const formattedSize = (bytes / Math.pow(1024, power)).toFixed(2);

    return `${formattedSize} ${units[power]}`;
}

export const showToaster = (message, type = 'success') => {
    toast(message, {
        type: type,
    });
};

const getAuthData = (key) => {
    try {
        const authData = window.localStorage.getItem(key) || '';
        const parsedAuthData = JSON.parse(authData) || {};
        return { authData, parsedAuthData };
    } catch (error) {
        return false;
    }
};

export const getLoginUser = () => {
    try {
        const authData = window.localStorage.getItem('AUTH') || '';
        const parsedAuthData = JSON.parse(authData) || {};
        return parsedAuthData;
    } catch (error) {
        return false;
    }
};

export const getAuthBuyer = () => getAuthData('AUTH_BUYER');
export const getAuthProvider = () => getAuthData('AUTH_PROVIDER');

export const handleChange = (e, setFormData, setErrors, validateField) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : value

    setFormData(prevFormData => ({
        ...prevFormData,
        [name]: newValue
    }));

    const error = validateField(name, newValue);
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error
    }));
};

export const handleSelectChange = (selectedOption, name, setFormData, setErrors, validateField) => {
    const updatedValue = selectedOption?.value

    setFormData(prevFormData => ({
        ...prevFormData,
        [name]: selectedOption || null
    }))

    const error = validateField(name, updatedValue);
    setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error
    }))
}

export const handleCreate = async (inputValue, field, setFormData) => {
    try {
        const newOption = { label: inputValue, value: inputValue.toLowerCase().replace(/\s+/g, '') }
        await PostRequest('/provider/check-spell', { text: inputValue })
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: [...prevFormData[field], newOption]
        }))
    } catch (error) {
        var errorMessage = error.message
        if (errorMessage === 'Invalid input') {
            errorMessage = `Invalid ${field}`
        } else if (errorMessage === 'text is required.') {
            errorMessage = `${field} is required.`;
        }
        showToaster(errorMessage, 'error')
    }
}

function validateEmptyFields(errors) {
    return Object.values(errors).every(value => {
        if (typeof value === 'object' && value !== null) {
            return validateEmptyFields(value);
        }
        return value === '';
    });
}

function validateEmptyFormData(formData) {
    return Object.entries(formData).every(([key, value]) => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }

        if (typeof value === 'object' && value !== null) {
            return validateEmptyFormData(value);
        }

        return value !== undefined && value !== '' && value !== null;
    });
}

export const isFormValid = (errors, formData) => {
    const areErrorsValid = validateEmptyFields(errors)
    const isFormDataValid = validateEmptyFormData(formData)

    return areErrorsValid && isFormDataValid
};