import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { Input } from "../../../../components/common/input/Input";
import { Button } from "../../../../components/common/button/Button";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { RxCross1 } from "react-icons/rx";
import { ReviewDetails } from "./ReviewDetails";

export const InputYourAmount = ({ setInputYourAmount, setShowFundingMethod }) => {
  const [reviewDetails, setReviewDetails] = useState(false);

  const [formData, setFormData] = useState({
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    amount: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    amount: "",
    description: "",
  });

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "accountName":
        if (!value) error = "Account name is required.";
        break;
      case "accountNumber":
        if (!value) error = "Account number is required.";
        else if (!/^\d+$/.test(value))
          error = "Account number must be numeric.";
        break;
      case "routingNumber":
        if (!value) error = "Routing number is required.";
        else if (value.length < 9)
          error = "Routing number must be at least 9 digits.";
        break;
      case "amount":
        if (!value) error = "Amount is required.";
        else if (isNaN(value) || Number(value) <= 0)
          error = "Amount must be a positive number.";
        break;
      case "description":
        if (!value) error = "Description is required.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((field) => field !== "")
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      accountName: validateField("accountName", formData.accountName),
      accountNumber: validateField("accountNumber", formData.accountNumber),
      routingNumber: validateField("routingNumber", formData.routingNumber),
      amount: validateField("amount", formData.amount),
      description: validateField("description", formData.description),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      console.log("Submitted Data:", formData);
    }
  };

  return (
    <>

      <Form className="form-wrapper" onSubmit={handleSubmit}>
        <Input
          label="Account Name"
          type="text"
          placeholder="Enter amount"
          className="mb-3"
          name="accountName"
          value={formData.accountName}
          onChange={handleChange}
          error={errors.accountName}
          star
        />
        <Input
          label="Account Number"
          type="number"
          placeholder="Enter amount"
          className="mb-3"
          name="accountNumber"
          value={formData.accountNumber}
          onChange={handleChange}
          error={errors.accountNumber}
          star
        />
        <Input
          label="Routing Number"
          type="password"
          placeholder="Enter amount"
          className="mb-3"
          name="routingNumber"
          value={formData.routingNumber}
          onChange={handleChange}
          error={errors.routingNumber}
          star
        />
        <Input
          label="Amount"
          type="number"
          placeholder="Enter amount"
          className="mb-3"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          error={errors.amount}
          star
        />
        <Input
          label="Description"
          type="text"
          placeholder="Enter your project description"
          className="mb-3"
          name="description"
          value={formData.description}
          onChange={handleChange}
          error={errors.description}
          multiline
        />

        <div className="button-bottom-side d-flex">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setInputYourAmount(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            type="submit"
            className="fw-semibold py-3 px-3"
            onClick={() => setReviewDetails(true)}
          >
            Continue
          </Button>
        </div>
      </Form>

      <RightOffcanvas show={reviewDetails} onHide={setReviewDetails}>
        <div className="p-3 d-flex align-items-center justify-content-between">
          <h5 className="text-black fw-semibold font-20">Review Details</h5>
          <RxCross1
            size={20}
            onClick={() => setReviewDetails(false)}
            className="cursor-pointer"
          />
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <ReviewDetails 
            setReviewDetails={setReviewDetails} 
            setInputYourAmount={setInputYourAmount} 
            setShowFundingMethod={setShowFundingMethod} 
          />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
