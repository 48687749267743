import { ProfileWrapper } from '../../../../components/common/Default'
import { PersonOfContactForm } from '../../../../components/authentication/profiling/PersonOfContactForm'

const INFO = {
    title: 'Your Person of Contact',
    description: 'Just a few info about your person of contact...'
}

export const PersonOfContact = () => {

    return (
        <ProfileWrapper info={INFO}>
            <PersonOfContactForm />
        </ProfileWrapper>
    )
}
