import { Offcanvas } from 'react-bootstrap'
import { Select } from '../../common/select/Select'
import { Button } from '../../common/button/Button'
import { FiPlus } from 'react-icons/fi'
import { Fragment, useEffect, useState } from 'react'
import { SetLimit } from '../modals/SetLimit'
import { ChangePassword } from '../modals/ChangePassword'
import React from 'react'
import { fetchBuyerRoles } from '../../../store/buyer/roles/roles.action'
import { useDispatch, useSelector } from 'react-redux'

export const MemberDetails = ({ show, handleClose, memberData }) => {

    const dispatch = useDispatch()
    var roleOptions = useSelector(state => state.rolesReducer.roles)
    roleOptions = roleOptions.map(role => ({ label: role.roleName, value: role._id }))
    
    const [showLimit, setShowLimit] = useState(false)
    const [showSecurity, setShowSecurity] = useState(false)

    useEffect(() => {
        if(show) dispatch(fetchBuyerRoles())
    }, [show])
    
    return (
        <Fragment>
            <Offcanvas show={show} onHide={handleClose} placement='end'>
                <Offcanvas.Body className=''>
                    <div className='mb-3'>
                        <Offcanvas.Title className='fw-semibold text-black'>Member Details</Offcanvas.Title>
                        <p>See more information about this member</p>
                    </div>
                    <div className='font-14 mb-3'>
                        <p className='text-black fw-medium'>Name</p>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <p className='color-grey-500'>Ademola Fajuyi</p>
                    </div>
                    <div className='font-14 mb-3'>
                        <p className='text-black fw-medium'>Email Address</p>
                        <hr className='opacity-100 my-2 border-grayscale-100' />
                        <p className='color-grey-500'>{memberData?.email}</p>
                    </div>
                    <Select
                        label='Role'
                        name='role'
                        inputId='role'
                        htmlFor='role'
                        value={{ label: memberData?.role?.roleName, value: memberData?.role?._id }}
                        placeholder='Select one'
                        className='mb-3'
                        options={roleOptions}
                    />
                    <div className='font-14 mb-3'>
                        <p className='text-black fw-medium mb-1'>Spending Limit</p>
                        <Button
                            variant='outline'
                            className='px-3 py-2 rounded-0'
                            onClick={() => setShowLimit(true)}
                            style={{ borderColor: '#CCCCF5' }}
                        >
                            <FiPlus size={20} className='me-2' />
                            <span>Set Spending Limit</span>
                        </Button>
                    </div>
                    <div className='font-14'>
                        <p className='text-black fw-medium mb-1'>Security</p>
                        <Button
                            variant='outline'
                            className='px-3 py-2 rounded-0'
                            onClick={() => setShowSecurity(true)}
                            style={{ borderColor: '#CCCCF5' }}
                        >
                            <FiPlus size={20} className='me-2' />
                            <span>Change Password</span>
                        </Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <SetLimit
                show={showLimit}
                handleClose={() => setShowLimit(false)}
            />
            <ChangePassword
                show={showSecurity}
                handleClose={() => setShowSecurity(false)}
            />
        </Fragment>
    )
}
