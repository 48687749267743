import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import { WelcomeToZero } from "./pages/provider/authentication/sign-up/WelcomeToZero";
import { EmailOTPSentMessage as ProviderEmailOTPSentMessage } from "./pages/provider/authentication/sign-up/EmailOTPSentMessage";
import { EmailOTPVerification as ProviderEmailOTPVerification } from "./pages/provider/authentication/sign-up/EmailOTPVerification";
import { AccountSetup } from "./pages/provider/authentication/sign-up/AccountSetup";
import { Login } from "./pages/provider/authentication/login/Login";
import { IdentityConfirmation } from "./pages/provider/authentication/identity-confirmation/IdentityConfirmation";
import { ProviderLoginEmailOTPVerification } from "./pages/provider/authentication/identity-confirmation/EmailOTPVerification";
import { AuthenticatorCodeVerification } from "./pages/provider/authentication/identity-confirmation/AuthenticatorCodeVerification";
import { ForgotPassword } from "./pages/provider/authentication/forgot-password/ForgotPassword";
import { ResetPasswordOTP } from "./pages/provider/authentication/forgot-password/ResetPasswordOTP";
import { ConfirmationMail } from "./pages/provider/authentication/forgot-password/ConfirmationMail";
import { ResetPassword } from "./pages/provider/authentication/forgot-password/ResetPassword";
import { ResetPasswordSuccess } from "./pages/provider/authentication/forgot-password/ResetPasswordSuccess";
import { GetStarted } from "./pages/provider/authentication/profiling/GetStarted";
import { TaxInformation } from "./pages/provider/authentication/profiling/TaxInformation";
import { PhotoAndLocation } from "./pages/provider/authentication/profiling/PhotoAndLocation";
import { ResumeUploader } from "./pages/provider/authentication/profiling/ResumeUploader";
import { SkillsInputSection } from "./pages/provider/authentication/profiling/SkillsInputSection";
import { BioInputSection } from "./pages/provider/authentication/profiling/BioInputSection";
import { SubmitProfile } from "./pages/provider/authentication/profiling/SubmitProfile";
import { Dashboard } from "./pages/provider/dashboard/Dashboard";
import { FindWork } from "./pages/provider/find-work/FindWork";
import { MyWork } from "./pages/provider/my-work/MyWork";
import { Payments } from "./pages/provider/payments/Payments";
import { ProviderCalendar } from "./pages/provider/calendar/Calendar";
import { MyAgreements } from "./pages/provider/my-agreements/MyAgreements";
import { Support } from "./pages/provider/support/Support";
import { UserType } from "./pages/UserType";

import { WelcomeToWorkRobin } from "./pages/buyer/authentication/sign-up/WelcomeToWorkRobin";
import { EmailOTPSentMessage as BuyerEmailOTPSentMessage } from "./pages/buyer/authentication/sign-up/EmailOTPSentMessage";
import { EmailOTPVerification as BuyerEmailOTPVerification } from "./pages/buyer/authentication/sign-up/EmailOTPVerification";
import { AccountSetup as BuyerAccountSetup } from "./pages/buyer/authentication/sign-up/AccountSetup";
import { GetStarted as BuyerGetStarted } from "./pages/buyer/authentication/profiling/GetStarted";
import { TaxInformation as BuyerTaxInformation } from "./pages/buyer/authentication/profiling/TaxInformation";
import { AddFunds } from "./pages/buyer/authentication/profiling/AddFunds";
import { InviteTeamMembers } from "./pages/buyer/authentication/profiling/InviteTeamMembers";
import { PersonOfContact } from "./pages/buyer/authentication/profiling/PersonOfContact";
import { Login as BuyerLogin } from "./pages/buyer/authentication/login/Login";
import { ForgotPassword as BuyerForgotPassword } from "./pages/buyer/authentication/forgot-password/ForgotPassword";
import { ConfirmationMail as BuyerConfirmationMail } from "./pages/buyer/authentication/forgot-password/ConfirmationMail";
import { ResetPassword as BuyerResetPassword } from "./pages/buyer/authentication/forgot-password/ResetPassword";
import { ResetPasswordOTP as BuyerResetPasswordOTP } from "./pages/buyer/authentication/forgot-password/ResetPasswordOTP";
import { ResetPasswordSuccess as BuyerResetPasswordSuccess } from "./pages/buyer/authentication/forgot-password/ResetPasswordSuccess";
import { Dashboard as BuyerDashboard } from "./pages/buyer/dashboard/Dashboard";
import { Payments as BuyerPayments } from "./pages/buyer/payments/Payments";
import { Agreements } from "./pages/buyer/agreements/Agreements";
import { Support as BuyerSupport } from "./pages/buyer/support/Support";
import ComingSoon from "./pages/other/CommingSoon";
import { CompanyProfile } from "./pages/buyer/profile/general/CompanyProfile";
import { BuyerProfileInformationProtection } from "./pages/buyer/profile/general/tax-information/InformationProtection";
import { BuyerProfileIdentityConfirmation } from "./pages/buyer/profile/general/tax-information/IdentityConfirmation";
import { CompanyTaxInformation } from "./pages/buyer/profile/general/CompanyTaxInformation";
import { ManageMembers } from "./pages/buyer/profile/user-management/ManageMembers";
import { RolesAndPermissions } from "./pages/buyer/profile/company-settings/RolesAndPermissions";
import { useEffect } from "react";
import { fetchProviderDetails } from "./store/provider/provider.action";
import { fetchBuyerDetails } from "./store/buyer/buyer.action";
import { useDispatch, useSelector } from "react-redux";
import { getAuthBuyer, getAuthProvider } from "./utils/utils";
import { WorkOrders } from "./pages/buyer/work-orders/WorkOrders";
import { BasicInformation } from "./pages/buyer/work-orders/creation/basic-information/BasicInformation";
import { LocationSchedule } from "./pages/buyer/work-orders/creation/location-schedule/LocationSchedule";
import { InviteProvider } from "./pages/buyer/work-orders/creation/invite-provider/InviteProvider";
import ProviderWorkOrderDetails from "./components/dashboard/job-updates/provider/provider-work-order-details/ProviderWorkOrderDetails";
import { JobInfo } from "./pages/buyer/work-orders/job-info/JobInfo";
import { Clients } from "./pages/buyer/clients/Clients";
import { WOAgreements } from "./pages/buyer/work-orders/creation/agreements/Agreements";
import { Pricing } from "./pages/buyer/work-orders/creation/pricing/Pricing";
import { CompanyTaxDocumentation } from "./pages/buyer/profile/general/CompanyTaxDocumentation";
import { CompanyPaymentMethod } from "./pages/buyer/profile/general/CompanyPaymentMethod";
import { CompanySpendingLimit } from "./pages/buyer/profile/general/CompanySpendingLimit";
import { ActivityLog } from "./pages/buyer/profile/general/ActivityLog";
import { Integrations } from "./pages/buyer/profile/general/Integrations";
import {
  AccountSettings,
  Notifications,
} from "./pages/buyer/settings/Notifications";
import { Password } from "./pages/buyer/settings/Password";
import { Security } from "./pages/buyer/settings/Security";
import { Projects } from "./pages/buyer/projects/Projects";
import { Providers } from "./pages/buyer/providers/Providers";
import { ProviderDetails } from "./pages/buyer/providers/provider-details/ProviderDetails";
import { ProviderMyWorkOrderDetails } from "./components/dashboard/job-updates/provider/provider-my-work-order-details/ProviderMyWorkOrderDetails";
import { AllSuggestions } from "./pages/provider/support/all-suggestions/AllSuggestions";
import { POC } from "./pages/buyer/poc/POC";
import { POCDetails } from "./pages/buyer/poc/poc-details/POCDetails";
import { ProviderNotification } from "./pages/provider/settings/ProviderNotification";
import { ProviderPassword } from "./pages/provider/settings/ProviderPassword";
import { ProviderSyncCalendar } from "./pages/provider/settings/ProviderSyncCalendar";
import { TwoFactorAuthentication } from "./pages/provider/settings/TwoFactorAuthentication";
import { MyContactInformation } from "./pages/provider/profile/general/MyContactInformation";
import { Reports } from "./pages/buyer/reports/Reports";
import { MyTextInformation } from "./pages/provider/profile/general/MyTextInformation";
import { MyInformationProtection } from "./pages/provider/profile/general/my-tax-information/MyInformationProtection";
import { MyTaxInformationForm } from "./pages/provider/profile/general/my-tax-information/MyTaxInformationForm";
import { MyTaxDocumentation } from "./pages/provider/profile/general/MyTaxDocumentation";
import { MyResources } from "./pages/provider/profile/general/MyResources";
import { MyPaymentDetails } from "./pages/provider/profile/general/MyPaymentDetails";
import { MyBecomeAServiceCompany } from "./pages/provider/profile/general/MyBecomeAServiceCompany";
import { MyBioAndResume } from "./pages/provider/profile/additional-background/MyBioAndResume";
import { MyRatesAndLocation } from "./pages/provider/profile/additional-background/MyRatesAndLocation";
import { MyWorkingHours } from "./pages/provider/profile/additional-background/MyWorkingHours";
import { MyCertifications } from "./pages/provider/profile/additional-background/MyCertifications";
import { MyInsuranceAndLicenses } from "./pages/provider/profile/additional-background/MyInsuranceAndLicenses";
import { MyLanguages } from "./pages/provider/profile/additional-background/MyLanguages";
import { MyScreenings } from "./pages/provider/profile/additional-background/MyScreenings";
import { BackgroundCheck } from "./pages/provider/profile/additional-background/screenings/BackgroundCheck";
import { DrugTest } from "./pages/provider/profile/additional-background/screenings/DrugTest";

const PublicRoute = ({ token, element }) => {
  if (!token) {
    return element;
  }
  return <Navigate to="/dashboard" />;
};

const PrivateRoute = ({ token, userDetails, element }) => {
  if (!token) {
    // If no token, redirect to login
    return <Navigate to="/login" />;
  }

  if (userDetails && userDetails.isProfileCompleted) {
    // If profile is not completed, render the element
    return element;
  }

  return element;

  // If profile is completed, redirect to dashboard
  // return <Navigate to="/get-started" />;
};

const ProfileCheckRoute = ({ token, userDetails, element }) => {
  if (!token) {
    // If no token, redirect to login
    return <Navigate to="/login" />;
  }

  if (userDetails && !userDetails.isProfileCompleted) {
    // If profile is not completed, render the element
    return element;
  }
  return element;

  // If profile is completed, redirect to dashboard
  // return <Navigate to="/dashboard" />;
};

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userType = window.localStorage.getItem("USER_TYPE");
  const token = window.localStorage.getItem("TOKEN");

  const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer();
  const { parsedAuthData: parsedAuthProvider } = getAuthProvider();

  const buyerDetails = useSelector((state) => state.buyerReducer.buyerDetails);
  const providerDetails = useSelector(
    (state) => state.providerReducer.providerDetails
  );

  const providerRoutes = (
    <>
      <Route
        path="/work-orders/details"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<JobInfo />}
          />
        }
      />
      <Route
        path="/provider/welcome"
        element={<PublicRoute token={token} element={<WelcomeToZero />} />}
      />
      <Route
        path="/sent-email-otp"
        element={
          <PublicRoute
            token={token}
            element={<ProviderEmailOTPSentMessage />}
          />
        }
      />
      <Route
        path="/verify-email-otp"
        element={
          <PublicRoute
            token={token}
            element={<ProviderEmailOTPVerification />}
          />
        }
      />
      <Route
        path="/account-setup"
        element={<PublicRoute token={token} element={<AccountSetup />} />}
      />
      <Route
        path="/login"
        element={<PublicRoute token={token} element={<Login />} />}
      />
      <Route
        path="/login/confirm-identity"
        // element={token ? <IdentityConfirmation /> : <Navigate to='/login' />}
        element={
          <PublicRoute token={token} element={<IdentityConfirmation />} />
        }
      />
      <Route
        path="/login/verify-email-otp"
        // element={token ? <ProviderLoginEmailOTPVerification /> : <Navigate to='/login' />}
        element={
          <PublicRoute
            token={token}
            element={<ProviderLoginEmailOTPVerification />}
          />
        }
      />
      <Route
        path="/login/verify-authenticator-code"
        element={
          token ? <AuthenticatorCodeVerification /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/forgot-password"
        element={<PublicRoute token={token} element={<ForgotPassword />} />}
      />
      <Route
        path="/reset-password-otp"
        element={<PublicRoute token={token} element={<ResetPasswordOTP />} />}
      />
      <Route
        path="/confirmation-mail"
        element={<PublicRoute token={token} element={<ConfirmationMail />} />}
      />
      <Route
        path="/provider/password/reset/:token"
        element={<ResetPassword />}
      />
      <Route
        path="/reset-password-success"
        element={<ResetPasswordSuccess />}
      />

      <Route
        path="/get-started"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<GetStarted />}
          />
        }
      />
      <Route
        path="/tax-information"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<TaxInformation />}
          />
        }
      />
      <Route
        path="/photo-location"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<PhotoAndLocation />}
          />
        }
      />
      <Route
        path="/resume-uploader"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ResumeUploader />}
          />
        }
      />
      <Route
        path="/skills"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<SkillsInputSection />}
          />
        }
      />
      <Route
        path="/bio"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<BioInputSection />}
          />
        }
      />
      <Route
        path="/submit"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<SubmitProfile />}
          />
        }
      />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<Dashboard />}
          />
        }
      />
      <Route
        path="/find-work"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<FindWork />}
          />
        }
      />
      <Route
        path="/my-work"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyWork />}
          />
        }
      />
      <Route
        path="/provider/my-work/details"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ProviderMyWorkOrderDetails />}
          />
        }
      />
      <Route
        path="/payments"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<Payments />}
          />
        }
      />
      <Route
        path="/calendar"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ProviderCalendar />}
          />
        }
      />
      <Route
        path="/my-agreements"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyAgreements />}
          />
        }
      />
      <Route
        path="/support"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<Support />}
          />
        }
      />
      <Route
        path="/support/suggestions"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<AllSuggestions />}
          />
        }
      />

      <Route
        path="/provider/work-orders/details"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ProviderWorkOrderDetails />}
          />
        }
      />
      <Route
        path="/settings/notifications"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ProviderNotification />}
          />
        }
      />
      <Route
        path="/settings/password"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ProviderPassword />}
          />
        }
      />
      <Route
        path="/settings/sync-calendar"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<ProviderSyncCalendar />}
          />
        }
      />
      <Route
        path="/settings/two-factor-auth"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<TwoFactorAuthentication />}
          />
        }
      />
      <Route
        path="/profile/my"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyContactInformation />}
          />
        }
      />
      <Route
        path="/profile/tax-information"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyTaxInformationForm />}
          />
        }
      />
      <Route
        path="/profile/tax-information/identity-verification"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyTextInformation />}
          />
        }
      />
      <Route
        path="/profile/tax-information/information-protection"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyInformationProtection />}
          />
        }
      />
      <Route
        path="/profile/tax-documentation"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyTaxDocumentation />}
          />
        }
      />
      <Route
        path="/profile/resources"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyResources />}
          />
        }
      />
      <Route
        path="/profile/payment-details"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyPaymentDetails />}
          />
        }
      />
      <Route
        path="/profile/become-service-company"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyBecomeAServiceCompany />}
          />
        }
      />
      <Route
        path="/profile/bio-and-resume"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyBioAndResume />}
          />
        }
      />
      <Route
        path="/profile/rates-and-location"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyRatesAndLocation />}
          />
        }
      />
      <Route
        path="/profile/working-hours"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyWorkingHours />}
          />
        }
      />
      <Route
        path="/profile/certifications"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyCertifications />}
          />
        }
      />
      <Route
        path="/profile/insurance-and-licenses"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyInsuranceAndLicenses />}
          />
        }
      />
      <Route
        path="/profile/languages"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<MyLanguages />}
          />
        }
      />
      <Route
        path="/profile/screenings"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthProvider}
            element={<Outlet />}
          />
        }
      >
        <Route index element={<MyScreenings />} />
        <Route path="background-check" element={<BackgroundCheck />} />
        <Route path="drug-test" element={<DrugTest />} />
      </Route>

      <Route path="/*" element={<ComingSoon />} />
    </>
  );

  const buyerRoutes = (
    <>
      <Route
        path="/buyer/welcome"
        element={<PublicRoute token={token} element={<WelcomeToWorkRobin />} />}
      />

      <Route
        path="/sent-email-otp"
        element={
          <PublicRoute token={token} element={<BuyerEmailOTPSentMessage />} />
        }
      />

      <Route
        path="/verify-email-otp"
        element={
          <PublicRoute token={token} element={<BuyerEmailOTPVerification />} />
        }
      />

      <Route
        path="/account-setup"
        element={<PublicRoute token={token} element={<BuyerAccountSetup />} />}
      />

      <Route
        path="/login"
        element={<PublicRoute token={token} element={<BuyerLogin />} />}
      />

      <Route
        path="/forgot-password"
        element={
          <PublicRoute token={token} element={<BuyerForgotPassword />} />
        }
      />

      <Route
        path="/reset-password-otp"
        element={
          <PublicRoute token={token} element={<BuyerResetPasswordOTP />} />
        }
      />

      <Route
        path="/confirmation-mail"
        element={
          <PublicRoute token={token} element={<BuyerConfirmationMail />} />
        }
      />

      <Route
        path="/buyer/password/reset/:token"
        element={<BuyerResetPassword />}
      />

      <Route
        path="/reset-password-success"
        element={<BuyerResetPasswordSuccess />}
      />

      <Route
        path="/get-started"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerGetStarted />}
          />
        }
      />

      <Route
        path="/tax-information"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerTaxInformation />}
          />
        }
      />

      <Route
        path="/add-funds"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<AddFunds />}
          />
        }
      />

      <Route
        path="/invite-team-members"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<InviteTeamMembers />}
          />
        }
      />

      <Route
        path="/person-of-contact"
        element={
          <ProfileCheckRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<PersonOfContact />}
          />
        }
      />

      <Route
        path="/profile/company"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<CompanyProfile />}
          />
        }
      />
      <Route
        path="/profile/tax-information"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<CompanyTaxInformation />}
          />
        }
      />
      <Route
        path="/profile/tax-information/identity-verification"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerProfileIdentityConfirmation />}
          />
        }
      />
      <Route
        path="/profile/tax-information/information-protection"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerProfileInformationProtection />}
          />
        }
      />
      <Route
        path="/profile/tax-documentation"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<CompanyTaxDocumentation />}
          />
        }
      />
      <Route
        path="/profile/payment-method"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<CompanyPaymentMethod />}
          />
        }
      />
      <Route
        path="/profile/spending-limit"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<CompanySpendingLimit />}
          />
        }
      />
      <Route
        path="/profile/activity-log"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<ActivityLog />}
          />
        }
      />
      <Route
        path="/profile/integrations"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Integrations />}
          />
        }
      />
      <Route
        path="/profile/manage-members"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<ManageMembers />}
          />
        }
      />
      <Route
        path="/profile/roles-permissions"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<RolesAndPermissions />}
          />
        }
      />
      <Route
        path="/settings/notifications"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Notifications />}
          />
        }
      />
      <Route
        path="/settings/password"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Password />}
          />
        }
      />
      <Route
        path="/settings/security"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Security />}
          />
        }
      />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerDashboard />}
          />
        }
      />
      <Route
        path="/work-orders"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<WorkOrders />}
          />
        }
      />
      <Route
        path="/work-orders/basic-information"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BasicInformation />}
          />
        }
      />
      <Route
        path="/work-orders/location-schedule"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<LocationSchedule />}
          />
        }
      />
      <Route
        path="/work-orders/invite-provider"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<InviteProvider />}
          />
        }
      />
      <Route
        path="/work-orders/agreements"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<WOAgreements />}
          />
        }
      />
      <Route
        path="/work-orders/pricing"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Pricing />}
          />
        }
      />
      {/* <Route path='/my-work' element={<MyWork />} /> */}
      {/* <Route path='/calendar' element={<Calendar />} /> */}
      {/* <Route path='/calendar' element={<Calendar />} /> */}
      <Route
        path="/agreements"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Agreements />}
          />
        }
      />
      <Route
        path="/projects"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Projects />}
          />
        }
      />
      <Route
        path="/payments"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerPayments />}
          />
        }
      />
      <Route
        path="/providers"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Providers />}
          />
        }
      />
      <Route
        path="/providers/details"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<ProviderDetails />}
          />
        }
      />
      {/* <Route path='/my-work' element={<MyWork />} /> */}
      {/* <Route path='/calendar' element={<Calendar />} /> */}
      <Route
        path="/support"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerSupport />}
          />
        }
      />
      <Route
        path="/buyer/support/suggestions"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<AllSuggestions />}
          />
        }
      />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerDashboard />}
          />
        }
      />
      <Route
        path="/work-orders"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<WorkOrders />}
          />
        }
      />
      <Route
        path="/work-orders/details"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<JobInfo />}
          />
        }
      />
      <Route
        path="/work-orders/basic-information"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BasicInformation />}
          />
        }
      />
      <Route
        path="/clients"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Clients />}
          />
        }
      />

      {/* <Route path='/my-work' element={<MyWork />} /> */}
      {/* <Route path='/calendar' element={<Calendar />} /> */}
      {/* <Route path='/calendar' element={<Calendar />} /> */}
      <Route
        path="/agreements"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Agreements />}
          />
        }
      />
      <Route
        path="/payments"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerPayments />}
          />
        }
      />
      <Route 
        path='/reports' 
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Reports />}
          />
        } 
      />
      <Route
        path="/poc"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<Outlet />}
          />
        }
      >
        <Route index element={<POC />} />
        <Route path=":pocId" element={<POCDetails />} />
        <Route path=":pocId/details" element={<JobInfo />} />
      </Route>
      <Route
        path="/support"
        element={
          <PrivateRoute
            token={token}
            userDetails={parsedAuthBuyer}
            element={<BuyerSupport />}
          />
        }
      />

      <Route path="/*" element={<ComingSoon />} />
    </>
  );

  useEffect(() => {
    if (token) {
      if (userType === "provider") {
        if (!providerDetails) {
          dispatch(fetchProviderDetails());
        } else {
          window.localStorage.setItem(
            "AUTH_PROVIDER",
            JSON.stringify(providerDetails)
          );
        }
      } else if (userType === "buyer") {
        if (!buyerDetails) {
          dispatch(fetchBuyerDetails());
        } else {
          window.localStorage.setItem(
            "AUTH_BUYER",
            JSON.stringify(buyerDetails)
          );
        }
      }
    }
  }, [token, userType, dispatch, providerDetails, buyerDetails]);

  useEffect(() => {
    if (!userType) {
      navigate("/");
    }
  }, [userType, navigate]);

  return (
    <Routes>
      <Route path="/" element={<UserType />} />
      {userType === "provider" ? (
        providerRoutes
      ) : userType === "buyer" ? (
        buyerRoutes
      ) : (
        <Route path="/" element={<UserType />} />
      )}
    </Routes>
  );
}
