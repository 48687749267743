import React, { Fragment, useState } from 'react'
import { Col, Form, ListGroup, NavDropdown, Row, Stack } from 'react-bootstrap'
import { Input } from '../../../../common/input/Input';
import MyPhoneInput from '../../../../common/my-phone-input/MyPhoneInput';
import { Select } from '../../../../common/select/Select';
import { Button } from '../../../../common/button/Button';
import { FiPlus } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import './location-schedule.css'
import { HiOutlineXMark } from 'react-icons/hi2';
import { AddProject } from '../../modals/AddProject';
import { AddClient } from '../../modals/AddClient';
import { AddLocation } from '../../modals/AddLocation';
import { SaveTemplate } from '../../modals/SaveTemplate';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { CardWrapper } from '../../../../common/Default';
import { handleSelectChange, isFormValid } from '../../../../../utils/utils';
import { emailPattern } from '../../../../../data/constants';
import { roleOptions } from '../../../../../data/custom-options';
import { CustomDateInput } from '../../../../buyer-provider/CustomDateInput';
import { RichTextEditor } from '../../../../common/rich-text-editor/RichTextEditor';

export const LocationScheduleForm = () => {

    const [formData, setFormData] = useState({
        selectedJobType: 'onsite',
        selectedPOC: 'new',
        contactDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        },
        deliverables: '',
        // tasks: {
        //     beforeWO: [],
        //     execution: [],
        //     afterWO: []
        // },
        client: null,
        project: null,
        selectedLocation: 'exact-address',
        startDate: null,
        endDate: null,
        checkInTime: null,
        checkOutTime: null
    })
    const [errors, setErrors] = useState({
        selectedJobType: '',
        selectedPOC: '',
        contactDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        },
        deliverables: '',
        // tasks: {
        //     beforeWO: '',
        //     execution: '',
        //     afterWO: ''
        // },
        client: '',
        project: '',
        selectedLocation: '',
        startDate: '',
        endDate: '',
        checkInTime: '',
        checkOutTime: ''
    })

    const [showAddProject, setShowAddProject] = useState(false)
    const [showAddClient, setShowAddClient] = useState(false)
    const [showAddLocation, setShowAddLocation] = useState(false)
    const [showSaveTemplate, setShowSaveTemplate] = useState(false)

    const handleJobTypeChange = (e) => {
        setFormData(prev => ({
            ...prev,
            selectedJobType: e.target.value
        }))
    }
    const handlePOCChange = (e) => {
        setFormData(prev => ({
            ...prev,
            selectedPOC: e.target.value
        }))
    }
    const handleLocationChange = (e) => {
        setFormData(prev => ({
            ...prev,
            selectedLocation: e.target.value
        }))
    }

    const validateField = (name, value) => {
        var error = '';
        if (name === 'contactDetails.firstName') {
            if (!value.trim()) {
                error = 'First name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'First name cannot contains special characters';
            }
        }
        if (name === 'contactDetails.lastName') {
            if (!value.trim()) {
                error = 'Last name is required';
            } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
                error = 'Last name cannot contains special characters';
            }
        }
        if (name === 'contactDetails.phoneNumber' && !value) error = 'Phone number is required';
        if (name === 'contactDetails.email') {
            if (!value.trim()) {
                error = 'Email is required';
            } else if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
        }

        if (name === 'project' && !value) error = 'Project is required'
        if (name === 'client' && !value) error = 'Client is required'

        if (name === 'startDate' && !value) error = 'Start date is required'
        if (name === 'endDate' && !value) error = 'End date is required'
        if (name === 'checkInTime' && !value) error = 'Check-In Time is required'
        if (name === 'checkOutTime' && !value) error = 'Check-Out Time is required'

        return error;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const error = validateField(name, value);

        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            setFormData(prevFormData => ({
                ...prevFormData,
                [parent]: {
                    ...prevFormData[parent],
                    [child]: value
                }
            }))
            setErrors((prevErrors) => ({
                ...prevErrors,
                [parent]: {
                    ...prevErrors[parent],
                    [child]: error
                }
            }))
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error
            }));
        }
    }

    const handleDateChange = (selectedDate, name) => {
        setFormData(prev => ({
            ...prev,
            [name]: selectedDate
        }))

        const error = validateField(name, selectedDate);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    }

    const handleSelectInputChange = (selectedOption, name) => {
        handleSelectChange(selectedOption, name, setFormData, setErrors, validateField)
    }

    const isLocationScheduleFormValid = () => {
        var isValid = false;
        const { deliverables, ...restFormData } = formData

        const formCopy = {
            ...restFormData,
            client: formData?.client?.value,
            project: formData?.project?.value,
        }

        if (formData?.selectedJobType === 'remote') {
            delete formCopy?.contactDetails
        }

        isValid = isFormValid(errors, formCopy)
        return { isValid, formCopy }
    }

    const { isValid, formCopy } = isLocationScheduleFormValid()
    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = {
            deliverables: '',
            // tasks: {
            //     beforeWO: '',
            //     execution: '',
            //     afterWO: ''
            // },
            client: '',
            project: '',
            startDate: '',
            endDate: '',
            checkInTime: '',
            checkOutTime: ''
        }

        Object.entries(formCopy).forEach(([key, value]) => {
            if (key === 'contactDetails' && typeof value === 'object' && formCopy?.selectedJobType === 'onsite') {
                Object.entries(value).forEach(([contactKey, contactValue]) => {
                    const error = validateField(`contactDetails.${contactKey}`, contactValue);
                    if (error) {
                        newErrors.contactDetails = newErrors.contactDetails || {};
                        newErrors.contactDetails[contactKey] = error;
                    }
                });
            } else {
                const error = validateField(key, value);
                if (error) newErrors[key] = error;
            }
        })

        setErrors(newErrors)

        if (isValid) {
            console.log('formCopy', formCopy)
        }
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit}>
                <div className='basic-info__wrapper'>
                    <h6 className='title mb-0'>Location & Schedule</h6>
                    <p className='subtitle'>This information is to set the location, date & time of the job</p>
                </div>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Job Type</h6>
                        <p className='subtitle'>Indicate the type of job</p>
                    </Col>
                    <Col md={9}>
                        <Form.Check
                            inline
                            type={'radio'}
                            name='jobType'
                            id='onsite'
                            value='onsite'
                            label='Onsite'
                            className='color-grey-500'
                            onChange={handleJobTypeChange}
                            defaultChecked
                        />
                        <Form.Check
                            inline
                            type={'radio'}
                            name='jobType'
                            id='remote'
                            value='remote'
                            label='Remote'
                            className='color-grey-500'
                            onChange={handleJobTypeChange}
                        />
                    </Col>
                </Row>
                {formData?.selectedJobType === 'onsite' && (
                    <Fragment>
                        <hr className='opacity-100 border-botticelli' />
                        <Row>
                            <Col md={3} className='basic-info__wrapper'>
                                <h6 className='title mb-0'>Person of Contact</h6>
                                <p className='subtitle'>Site contact details</p>
                            </Col>
                            <Col md={9}>
                                <Form.Check
                                    inline
                                    type='radio'
                                    name='POC'
                                    id='new'
                                    value='new'
                                    label='New POC'
                                    className='color-grey-500'
                                    checked={formData?.selectedPOC === 'new'}
                                    onChange={handlePOCChange}
                                // defaultChecked
                                />
                                <Form.Check
                                    inline
                                    type='radio'
                                    name='POC'
                                    id='saved'
                                    value='saved'
                                    label='Saved POC'
                                    className='color-grey-500'
                                    checked={formData?.selectedPOC === 'saved'}
                                    onChange={handlePOCChange}
                                />

                                <div className='mt-3'>
                                    {formData?.selectedPOC === 'new' ? (
                                        <div className='w-75'>
                                            <Row>
                                                <Col md={6}>
                                                    <Input
                                                        name='contactDetails.firstName'
                                                        id='firstName'
                                                        htmlFor='firstName'
                                                        placeholder='First name'
                                                        className='mb-2'
                                                        value={formData?.contactDetails?.firstName}
                                                        onChange={handleInputChange}
                                                        error={errors?.contactDetails?.firstName}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Input
                                                        name='contactDetails.lastName'
                                                        id='lastName'
                                                        htmlFor='lastName'
                                                        placeholder='Last name'
                                                        className='mb-2'
                                                        value={formData?.contactDetails?.lastName}
                                                        onChange={handleInputChange}
                                                        error={errors?.contactDetails?.lastName}
                                                    />
                                                </Col>
                                            </Row>
                                            <Input
                                                name='contactDetails.email'
                                                id='email'
                                                htmlFor='email'
                                                placeholder='Email Address'
                                                type='email'
                                                className='mb-2'
                                                value={formData?.contactDetails?.email}
                                                onChange={handleInputChange}
                                                error={errors?.contactDetails?.email}
                                            />
                                            <MyPhoneInput
                                                className='mb-2'
                                                name='contactDetails.phoneNumber'
                                                value={formData?.contactDetails?.phoneNumber}
                                                onChange={(phone, country) => {
                                                    setFormData(prevFormData => ({
                                                        ...prevFormData,
                                                        contactDetails: {
                                                            ...prevFormData?.contactDetails,
                                                            phoneNumber: phone
                                                        }
                                                    }))
                                                    const error = validateField('phoneNumber', phone)
                                                    setErrors(prevErr => ({
                                                        ...prevErr,
                                                        contactDetails: {
                                                            ...prevErr?.contactDetails,
                                                            phoneNumber: error
                                                        }
                                                    }))
                                                }}
                                                error={errors?.contactDetails?.phoneNumber}
                                            />
                                            <Form.Check
                                                type='checkbox'
                                                label='Approve provider timesheet'
                                                id='approve-timesheet-checkbox'
                                                className='buyer-checkbox instructions-checkbox mb-2'
                                            />
                                            <Form.Check
                                                type='checkbox'
                                                label='Save for future use'
                                                id='save-future-use-checkbox'
                                                className='buyer-checkbox instructions-checkbox'
                                            />
                                        </div>
                                    ) : (
                                        <div className='w-50'>
                                            <Select
                                                name='poc'
                                                inputId='poc-select'
                                                htmlFor='poc-select'
                                                placeholder='Select POC'
                                                // onChange={(selectedOption) => handleSelectInputChange(selectedOption, 'poc')}
                                                className='mb-2'
                                            />
                                            <Form.Check
                                                type='checkbox'
                                                label='Approve provider timesheet'
                                                className='buyer-checkbox instructions-checkbox mb-2'
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                )}
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Deliverables <span className='fst-italic'>(optional)</span></h6>
                        <p className='subtitle'>Job titles must be describe one position</p>
                    </Col>
                    <Col md={9}>
                        <RichTextEditor placeholder='Enter job deliverables' />
                        {/* <Input
                            multiline
                            rows={5}
                            placeholder='Enter job deliverables'
                            name='deliverables'
                            value={formData?.deliverables}
                            onChange={handleInputChange}
                            error={errors?.deliverables}
                        /> */}
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Tasks <span className='fst-italic'>(optional)</span></h6>
                        <p className='subtitle'>Specific tasks</p>
                    </Col>
                    <Col md={9} className='w-50'>
                        <div className='d-flex justify-content-between align-items-end'>
                            <Input
                                label='Before Work Order'
                                placeholder='Enter your task here'
                                className='w-75'
                            />
                            <Button type='button' className='py-3 bg-transparent color-primary'>
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add Task</span>
                            </Button>
                        </div>
                        <hr className='opacity-100 border-botticelli' />
                        <div className='d-flex justify-content-between align-items-end'>
                            <Input
                                label='Execution'
                                placeholder='Enter your task here'
                                className='w-75'
                            />
                            <Button type='button' className='py-3 bg-transparent color-primary'>
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add Task</span>
                            </Button>
                        </div>
                        <hr className='opacity-100 border-botticelli' />
                        <div className='d-flex justify-content-between align-items-end'>
                            <Input
                                label='After Work Order'
                                placeholder='Enter your task here'
                                className='w-75'
                            />
                            <Button type='button' className='py-3 bg-transparent color-primary'>
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add Task</span>
                            </Button>
                        </div>
                        <hr className='opacity-100 border-botticelli' />
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Client</h6>
                        <p className='subtitle'>Add client to the job</p>
                    </Col>
                    <Col md={9}>
                        <Stack direction='horizontal'>
                            <Select
                                name='client'
                                inputId='client-select'
                                htmlFor='client-select'
                                placeholder='Select Client'
                                options={roleOptions}
                                value={formData?.client}
                                onChange={(selectedOption) => handleSelectInputChange(selectedOption, 'client')}
                                error={errors?.client}
                                className='w-50'
                            />
                            <Button
                                type='button'
                                className='py-3 bg-transparent color-primary'
                                onClick={() => setShowAddClient(true)}
                            >
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add Client</span>
                            </Button>
                        </Stack>
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Project</h6>
                        <p className='subtitle'>Add project to the job</p>
                    </Col>
                    <Col md={9}>
                        <Stack direction='horizontal'>
                            <Select
                                name='project'
                                inputId='project-select'
                                htmlFor='project-select'
                                placeholder='Select Project'
                                options={roleOptions}
                                value={formData?.project}
                                onChange={(selectedOption) => handleSelectInputChange(selectedOption, 'project')}
                                error={errors?.project}
                                className='w-50'
                            />
                            <Button
                                type='button'
                                onClick={() => setShowAddProject(true)}
                                className='py-3 bg-transparent color-primary'
                            >
                                <FiPlus size={20} className='me-2' />
                                <span className='lh-1'>Add Project</span>
                            </Button>
                        </Stack>
                    </Col>
                </Row>
                {formData?.selectedJobType === 'onsite' && (
                    <Fragment>
                        <hr className='opacity-100 border-botticelli' />
                        <Row>
                            <Col md={3} className='basic-info__wrapper'>
                                <h6 className='title mb-0'>No of Provider & Location</h6>
                                <p className='subtitle'>Fill in the location of the job</p>
                            </Col>
                            <Col md={9}>
                                <Form.Check
                                    inline
                                    type='radio'
                                    name='location'
                                    id='exact-address'
                                    value='exact-address'
                                    label='Specify exact address'
                                    className='color-grey-500'
                                    onChange={handleLocationChange}
                                    defaultChecked
                                />
                                <Form.Check
                                    inline
                                    type='radio'
                                    name='location'
                                    id='saved-location'
                                    value='saved-location'
                                    label='Select saved location'
                                    className='color-grey-500'
                                    onChange={handleLocationChange}
                                />
                                <div className='mt-3 w-50'>
                                    <Select
                                        name='providerCount'
                                        inputId='provider-select'
                                        htmlFor='provider-select'
                                        placeholder='Select number of providers'
                                    />
                                    <p className='providerCount-info'>Indicate no of providers needed for this work order</p>
                                    <hr className='opacity-100 border-botticelli' />
                                    {formData?.selectedLocation === 'exact-address' ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <LocationScheduleDropdown setShowAddLocation={setShowAddLocation} />
                                        </>
                                    )}
                                    <CardWrapper>
                                        <Stack direction='horizontal' className='justify-content-between align-items-start'>
                                            <p className='color-grey-500 fw-medium'>
                                                Floor 6,<br />
                                                Snowhite Avenue <br />
                                                Birmingham, United Kingdom <br />
                                                1277738 <br />
                                            </p>
                                            <div>
                                                <button type='button' onClick={() => setShowAddLocation(true)} className='pdf-close__wrapper btn-edit__saved-location border-0 me-2'>
                                                    <HiOutlinePencilAlt size={20} />
                                                </button>
                                                <button type='button' className='pdf-close__wrapper border-0'>
                                                    <HiOutlineXMark strokeWidth={2} size={20} />
                                                </button>
                                            </div>
                                        </Stack>
                                        <hr className='opacity-100 border-grayscale-100 my-2' />
                                        <span className="card-tag__fixed font-12 rounded color-grey-900">2 Providers</span>
                                    </CardWrapper>
                                    <Button type='button' onClick={() => setShowAddLocation(true)} className='p-2 bg-transparent color-primary'>
                                        <FiPlus size={20} className='me-2' />
                                        <span className='lh-1'>Add Location</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                )}
                <hr className='opacity-100 border-botticelli' />
                <Row>
                    <Col md={3} className='basic-info__wrapper'>
                        <h6 className='title mb-0'>Schedule Options</h6>
                        <p className='subtitle'>Add required skills for the job</p>
                    </Col>
                    <Col md={9}>
                        <Form.Check
                            inline
                            type='radio'
                            name='schedule'
                            id='specific-date-time'
                            value='specific-date-time'
                            label='Specific Date & Time'
                            className='color-grey-500'
                            // onChange={handleLocationChange}
                            defaultChecked
                        />
                        <Form.Check
                            inline
                            type='radio'
                            name='schedule'
                            id='arrival-window'
                            value='arrival-window'
                            label='Set Arrival Window'
                            className='color-grey-500'
                        // onChange={handleLocationChange}
                        />
                        <div className='mt-3 w-50'>
                            <Row className='row-gap-2 mb-2'>
                                <Col md={6}>
                                    <DatePicker
                                        selected={formData?.startDate}
                                        onChange={(date) => handleDateChange(date, 'startDate')}
                                        dateFormat='dd/MM/yy'
                                        placeholderText='DD/MM/YY'
                                        customInput={<CustomDateInput label='Start Date' name='startDate' error={errors.startDate} />}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DatePicker
                                        selected={formData?.checkInTime}
                                        onChange={(date) => handleDateChange(date, 'checkInTime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        showTimeCaption={false}
                                        dateFormat='HH:mm'
                                        timeFormat='HH:mm'
                                        placeholderText='HH:MM'
                                        customInput={<Input label='Check-In Time' name='checkInTime' error={errors.checkInTime} />}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DatePicker
                                        selected={formData?.endDate}
                                        onChange={(date) => handleDateChange(date, 'endDate')}
                                        dateFormat='dd/MM/yy'
                                        placeholderText='DD/MM/YY'
                                        customInput={<CustomDateInput label='End Date' name='endDate' error={errors.endDate} />}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DatePicker
                                        selected={formData?.checkOutTime}
                                        onChange={(date) => handleDateChange(date, 'checkOutTime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        showTimeCaption={false}
                                        dateFormat='HH:mm'
                                        timeFormat='HH:mm'
                                        placeholderText='HH:MM'
                                        customInput={<Input label='Check-Out Time' name='checkOutTime' error={errors.checkOutTime} />}
                                    />
                                </Col>
                            </Row>
                            <Stack direction='horizontal' className='justify-content-between mb-3'>
                                <label htmlFor='check-io-switch' className='description-style'>Check-I/O: Providers must check-in and check-out.</label>
                                <Form.Check
                                    type="switch"
                                    id="check-io-switch"
                                    className='provider-checkbox'
                                />
                            </Stack>
                            <Stack direction='horizontal' className='justify-content-between mb-3'>
                                <label htmlFor='geo-fencing-switch' className='description-style'>Geo-fencing: Enables geo-fencing within a 2-mile radius.</label>
                                <Form.Check
                                    type="switch"
                                    id="geo-fencing-switch"
                                    className='provider-checkbox'
                                />
                            </Stack>
                            <Stack direction='horizontal' className='justify-content-between'>
                                <label htmlFor='fixed-schedule-switch' className='description-style'>Fixed Schedule: The schedule is fixed</label>
                                <Form.Check
                                    type="switch"
                                    id="fixed-schedule-switch"
                                    className='provider-checkbox'
                                />
                            </Stack>
                        </div>
                    </Col>
                </Row>
                <hr className='opacity-100 border-botticelli' />
                <Stack direction='horizontal' className='justify-content-between'>
                    <Button
                        type='button'
                        variant='outline'
                        className='fw-semibold px-3 color-ebony padded-border-button'
                    // onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Stack direction='horizontal' className='gap-3'>
                        <Button
                            type='button'
                            variant='outline'
                            className='fw-semibold px-3 color-ebony padded-border-button'
                            onClick={() => setShowSaveTemplate(true)}
                        >
                            Save as draft
                        </Button>
                        <Button
                            type='submit'
                            disabled={!isValid}
                            className='fw-semibold py-14px'
                        >
                            Continue
                        </Button>
                    </Stack>
                </Stack>
            </Form>
            <AddProject show={showAddProject} handleClose={() => setShowAddProject(false)} />
            <AddClient show={showAddClient} handleClose={() => setShowAddClient(false)} />
            <AddLocation show={showAddLocation} handleClose={() => setShowAddLocation(false)} />
            <SaveTemplate show={showSaveTemplate} handleClose={() => setShowSaveTemplate(false)} />
        </Fragment>
    )
}

const LocationScheduleDropdown = ({ setShowAddLocation }) => {
    return (
        <NavDropdown
            className='location-schedule__dropdown'
            title={
                <Select
                    name='location'
                    inputId='location-select'
                    htmlFor='location-select'
                    placeholder='Select location'
                    newStyles={{
                        menu: (provided) => ({
                            ...provided,
                            display: 'none'
                        })
                    }}
                    className='mb-2'
                />
            }
        >
            <ListGroup variant='flush'>
                <ListGroup.Item as={Stack} direction='horizontal' className='justify-content-between border-bottom-0'>
                    <p className='color-grey-500 font-14 fw-medium'>18th Malcolm Avenue</p>
                    <div>
                        <button type='button' onClick={() => setShowAddLocation(true)} className='pdf-close__wrapper btn-edit__saved-location border-0 me-2'>
                            <HiOutlinePencilAlt size={20} />
                        </button>
                        <button type='button' className='pdf-close__wrapper border-0'>
                            <HiOutlineXMark strokeWidth={2} size={20} />
                        </button>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className='text-center'>
                    <Button type='button' onClick={() => setShowAddLocation(true)} className='p-2 bg-transparent color-primary'>
                        <FiPlus size={20} className='me-2' />
                        <span className='lh-1'>Add Location</span>
                    </Button>
                </ListGroup.Item>
            </ListGroup>
        </NavDropdown>
    )
}