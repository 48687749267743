import axios from "axios";
import { API_URL } from "../config/API_URL.js";
import ReactDOMServer from "react-dom/server";

const PostRequest = async (
  path,
  data = {},
  options = { isMultiMedia: false, showLoader: false }
) => {
  const { showLoader = false, isMultiMedia = false } = options;

  const userType = window.localStorage.getItem("USER_TYPE") || "";

  const authorization = userType === "buyer" ? "Authorize-Buyer" : "Authorize";

  var oldButtonText = "";
  var targetElement = document.getElementById(showLoader);

  if (showLoader && targetElement) {
    oldButtonText = targetElement.innerHTML;
    targetElement.innerHTML = `<span> <i class="spin-do"> ${ReactDOMServer.renderToString(
      // <LuLoader2 />
      <div className="loader-button"></div>
    )} </i> Please wait... </span>`;
    targetElement.setAttribute("disabled", true);
  }

  try {
    const header = {
      "content-type": isMultiMedia
        ? "multipart/form-data"
        : "application/x-www-form-urlencoded",
      [authorization]: window.localStorage.getItem("TOKEN"),
    };
    const response = await axios.post(`${API_URL}${path}`, data, {
      headers: header,
      crossDomain: true,
    });

    // console.log("response",response.data);

    const responseData = response.data;

    if (responseData.code == 200) {
      return responseData.data;
    } else if (responseData.code == 401) {
      window.location.href = "/login";
      throw new Error(responseData.data);
    } else {
      throw new Error(responseData.data);
    }
  } catch (error) {
    throw new Error(error.message);
  } finally {
    if (showLoader && targetElement) {
      targetElement.innerHTML = oldButtonText;
      targetElement.removeAttribute("disabled");
    }
  }
};
export default PostRequest;
