import React from "react";
import { Button } from "../../../../components/common/button/Button";
import Underline from "../../../../components/buyer-provider/Underline";
import { USER } from "../../../../assets/images.assets";

export const WorkOrderDetails = ({ setWorkOrderDetails }) => {
  return (
    <div className="form-wrapper">
      <div>
        <h6 className="font-14 line-height-160 fw-600">Work Order Id</h6>

        <Underline className="mt-2" />
        <p className="mt-2 font-14 line-height-160 color-custom-grey-500">
          #777448824
        </p>
      </div>
      <div className="mt-3">
        <h6 className="font-14 line-height-160 fw-600">Work Order Title</h6>

        <Underline className="mt-2" />
        <p className="mt-2 font-14 line-height-160 color-custom-grey-500">
          Total Hours
        </p>
      </div>
      <div className="mt-3">
        <h6 className="font-14 line-height-160 fw-600">Description</h6>

        <Underline className="mt-2" />
        <p className="mt-2 font-14 line-height-160 color-custom-grey-500">
          Water purification and drilling of borehole for a 5 bedroom duplex,
          drilling of borehole for a 5 bedroom duplex, drilling of borehole for
          a 5 bedroom duplex
        </p>
      </div>
      <div className="mt-3">
        <h6 className="font-14 line-height-160 fw-600">Provider</h6>

        <Underline className="mt-2" />
        <div className="d-flex align-items-center gap-2 mt-3">
          <img src={USER} />
          <p className="font-12 line-height-160 fw-600">Johnson Nnamani </p>
        </div>
      </div>
      <div className="mt-3">
        <h6 className="font-14 line-height-160 fw-600">Location</h6>

        <Underline className="mt-2" />
        <p className="mt-2 font-14 line-height-160 color-custom-grey-500">
          Birmingham, United Kingdom
        </p>
      </div>
      <div className="mt-3">
        <h6 className="font-14 line-height-160 fw-600">Initiation Date</h6>

        <Underline className="mt-2" />
        <p className="mt-2 font-14 line-height-160 color-custom-grey-500">
          03/24/2024
        </p>
      </div>
      <div className="mt-3">
        <h6 className="font-14 line-height-160 fw-600">Completion Date</h6>

        <Underline className="mt-2" />
        <p className="mt-2 font-14 line-height-160 color-custom-grey-500">
          03/24/2024
        </p>
      </div>

      <div className="mt-5 color-bg-light-gray p-3">
        <div className="d-flex align-items-center justify-content-between">
          <p className="font-16 line-height-160 color-neutrals-80">Amount</p>
          <p className="font-16 line-height-160 fw-bold color-gray-800">
            $450.00
          </p>
        </div>
      </div>
      <div className="button-bottom-side d-flex">
        <Button
          type="button"
          variant="outline"
          className="fw-semibold px-3 color-ebony"
          style={{
            paddingBlock: 14,
            border: "1px solid #CCCCF5",
          }}
          onClick={() => setWorkOrderDetails(false)}
        >
          Cancel
        </Button>
        <Button
          //   disabled={!isFormValid()}
          type="submit"
          className="fw-semibold py-3 px-3"
          // onClick={() => setReviewDetails(true)}
        >
          Approve Payments
        </Button>
      </div>
    </div>
  );
};
