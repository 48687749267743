import { Fragment, useState } from 'react'
import { Col, Form, Row, Stack } from 'react-bootstrap'
import { Input } from '../../../../common/input/Input'
import { Select } from '../../../../common/select/Select'
import { Button } from '../../../../common/button/Button'
import { FiPlus } from 'react-icons/fi'
import './agreements.css'
import { PDFandCSVUploader } from '../PDFandCSVUploader'
import { SaveTemplate } from '../../modals/SaveTemplate'
import { DOCU_SIGN } from '../../../../../assets/images.assets'
import { DocuSign } from '../../modals/DocuSign'
import { formatFileSize, handleChange, handleSelectChange, isFormValid } from '../../../../../utils/utils'
import { employeeOptions } from '../../../../../data/custom-options'
import { HiOutlineXMark } from 'react-icons/hi2'
import { ReactComponent as PDF } from '../../../../../assets/images/PDF.svg'
import { RichTextEditor } from '../../../../common/rich-text-editor/RichTextEditor'

export const AgreementsForm = () => {

  const [selectedAgreement, setSelectedAgreement] = useState('create-new')

  const [formData, setFormData] = useState({
    nameOfAgreement: '',
    typeOfAgreement: null,
    agreementDetails: ''
  })

  const [errors, setErrors] = useState({
    nameOfAgreement: '',
    typeOfAgreement: '',
    agreementDetails: ''
  })

  const [acceptedFiles, setAcceptedFiles] = useState([])

  const [showSaveTemplate, setShowSaveTemplate] = useState(false)
  const [showDocuSign, setShowDocuSign] = useState(false)

  const handleAgreementChange = (e) => {
    setSelectedAgreement(e.target.value)
  }

  const validateField = (name, value) => {
    var error = ''

    if (name === 'nameOfAgreement' && !value) {
      if (!value.trim()) {
        error = 'Name of Agreement is required';
      } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
        error = 'Name of Agreement cannot contains special characters';
      }
    }
    if (name === 'typeOfAgreement' && !value) error = 'Type of Agreement is required'
    if (name === 'agreementDetails' && !value) {
      if (!value.trim()) {
        error = 'Agreement Details is required';
      } else if (!/^[A-Za-z0-9\s]+$/.test(value.trim())) {
        error = 'Agreement Details cannot contains special characters';
      }
    }

    return error
  }

  const handleInputChange = (e) => {
    handleChange(e, setFormData, setErrors, validateField)
  }

  const handleSelectInputChange = (selectedOption, name) => {
    handleSelectChange(selectedOption, name, setFormData, setErrors, validateField)
  }

  const onDrop = (files) => {
    console.log('Accepted Files:', files);
    setAcceptedFiles(files)
  };

  const clearFile = (fileName) => {
    const updatedFiles = acceptedFiles.filter(file => file.name !== fileName)
    setAcceptedFiles(updatedFiles)
  }

  const isAgreementFormValid = () => {
    var isValid = false;
    switch (selectedAgreement) {
      case 'create-new':
        isValid = isFormValid(errors, formData)
        break;
      case 'upload':
        isValid = acceptedFiles?.length > 0
        break;
      case 'templates':
        break;
      case 'use-docusign':
        break;
      default:
        console.log('Select agreement first')
        break;
    }
    return isValid
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const newErrors = {
      nameOfAgreement: '',
      typeOfAgreement: '',
      agreementDetails: ''
    }

    Object.entries(formData).forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) newErrors[key] = error;
    })

    setErrors(newErrors)

    if (isAgreementFormValid()) {
      const data = {}
      const formData = new FormData(e.target)

      formData.forEach((val, key) => data[key] = val)

      console.log('data', data)
    }
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <div className='basic-info__wrapper'>
          <h6 className='title mb-0'>Agreements</h6>
          <p className='subtitle'>Create agreements and terms & conditions of this work order</p>
        </div>
        <hr className='opacity-100 border-botticelli' />
        <Row>
          <Col md={3} className='basic-info__wrapper'>
            <h6 className='title mb-0'>Agreements</h6>
            <p className='subtitle'>Indicate your agreement for this work order</p>
          </Col>
          <Col md={9}>
            <Form.Check
              inline
              type='radio'
              name='agreements'
              id='create-new'
              value='create-new'
              label='Create new'
              className='color-grey-500'
              onChange={handleAgreementChange}
              defaultChecked
            />
            <Form.Check
              inline
              type='radio'
              name='agreements'
              id='upload'
              value='upload'
              label='Upload'
              className='color-grey-500'
              onChange={handleAgreementChange}
            />
            <Form.Check
              inline
              type='radio'
              name='agreements'
              id='templates'
              value='templates'
              label='Templates'
              className='color-grey-500'
              onChange={handleAgreementChange}
            />
            <Form.Check
              inline
              type='radio'
              name='agreements'
              id='use-docusign'
              value='use-docusign'
              label='Use DocuSign'
              className='color-grey-500'
              onChange={handleAgreementChange}
            />
            <div className='mt-3'>
              {selectedAgreement === 'create-new' ? (
                <>
                  <Input
                    label='Name of Agreement'
                    placeholder='Select Client'
                    className='w-50 mb-2'
                    name='nameOfAgreement'
                    value={formData.nameOfAgreement}
                    onChange={handleInputChange}
                    error={errors.nameOfAgreement}
                  />
                  <Select
                    label='Type of Agreement'
                    placeholder='Select Type'
                    className='w-50 mb-2'
                    name='typeOfAgreement'
                    options={employeeOptions}
                    value={formData.typeOfAgreement}
                    onChange={(selectedOption) => handleSelectInputChange(selectedOption, 'typeOfAgreement')}
                    error={errors.typeOfAgreement}
                  />
                  <div className='mb-2'>
                    <label className='form-label line-height-160'>Agreement Details</label>
                    <RichTextEditor placeholder='Enter agreement details' />
                  </div>
                  {/* <Input
                    multiline
                    rows={5}
                    label='Agreement Details'
                    placeholder='Enter job deliverables'
                    className='mb-2'
                    name='agreementDetails'
                    value={formData.agreementDetails}
                    onChange={handleInputChange}
                    error={errors.agreementDetails}
                  /> */}
                  <Button
                    type='button'
                    // onClick={() => setShowAddLocation(true)}
                    className='p-2 bg-transparent color-primary'
                  >
                    <FiPlus size={20} className='me-2' />
                    <span className='lh-1'>Add Another</span>
                  </Button>
                </>
              ) : selectedAgreement === 'upload' ? (
                <>
                  <PDFandCSVUploader onDrop={onDrop} />
                  {acceptedFiles.length > 0 && (
                    acceptedFiles.map((file, i) => {
                      return (
                        <Stack
                          direction='horizontal'
                          className='justify-content-between mt-3'
                        >
                          <PDF className='flex-shrink-0' />
                          <p className='pdf-title font-14 text-truncate ms-2' style={{ maxWidth: 300 }}>
                            {file?.name}
                          </p>
                          <span className='pdf-size ms-1 me-auto'>{formatFileSize(file?.size)}</span>
                          <button type='button' className='pdf-close__wrapper border-0' onClick={() => clearFile(file?.name)}>
                            <HiOutlineXMark size={20} />
                          </button>
                        </Stack>
                      )
                    })
                  )}
                </>
              ) : selectedAgreement === 'templates' ? (
                <Row>
                  {Array.from({ length: 3 }).map((_, i) => {
                    return (
                      <Col md={4} key={i}>
                        <img
                          src={require('../../../../../assets/documents/test1.png')}
                          className='template'
                          alt=''
                        />
                      </Col>
                    )
                  })}
                </Row>
              ) : (
                <div className='text-center py-3'>
                  <p className='font-14 text-black fw-medium mb-3'>You can create your document with required signature seamlessly</p>
                  <button type='button' onClick={() => setShowDocuSign(true)} className='btn-wrapper__docusign'>
                    <img src={DOCU_SIGN} alt="docusign" className='me-2' />
                    <span className='font-14 text-black fw-medium'>Click here to use DocuSign</span>
                  </button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <hr className='opacity-100 border-botticelli' />
        <Stack direction='horizontal' className='justify-content-between'>
          <Button
            type='button'
            variant='outline'
            className='fw-semibold px-3 color-ebony padded-border-button'
          // onClick={handleClose}
          >
            Cancel
          </Button>
          <Stack direction='horizontal' className='gap-3'>
            <Button
              type='button'
              variant='outline'
              className='fw-semibold px-3 color-ebony padded-border-button'
              onClick={() => setShowSaveTemplate(true)}
            >
              Save as draft
            </Button>
            <Button
              type='submit'
              className='fw-semibold py-14px'
              disabled={!isAgreementFormValid()}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </Form>
      <SaveTemplate show={showSaveTemplate} handleClose={() => setShowSaveTemplate(false)} />
      <DocuSign show={showDocuSign} handleClose={() => setShowDocuSign(false)} />
    </Fragment>
  )
}
