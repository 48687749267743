import { MainDefault } from "../../../components/common/Default";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import {
  BUYER_navITEMS,
  BUYER_tabPanes,
  dashboardFilterDropdownStyles,
} from "../../../data/constants";
import { JobUpdates } from "../../../components/dashboard/job-updates/JobUpdates";
import { WorkOrderSummary } from "../../../components/dashboard/analytics-summary/WorkOrderSummary";
import { FiChevronRight } from "react-icons/fi";
import "../../provider/dashboard/dashboard.css";
import "../../../components/dashboard/analytics-summary/analytics-summary.css";
import { getAuthBuyer } from "../../../utils/utils";
import { useEffect, useRef, useState } from "react";
import { DATE_FILTER_OPTIONS } from "../../../data/custom-options";
import { ProfileSetupModal } from "../../../components/profile/modals/profile-setup/ProfileSetupModal";
import { useProfileCompletion } from "../../../hooks/useProfileCompletion";
import { ProfileUpdateStatus } from "../../../components/dashboard/ProfileUpdateStatus";
import DatePicker from "react-datepicker";
import moment from "moment";
import { enGB } from "date-fns/locale";
import { LayoutSwitcher } from "../../../components/dashboard/job-updates/LayoutSwitcher";
import { WorkOrdersHeader } from "../../../components/dashboard/job-updates/WorkOrdersHeader";
import { Link } from "react-router-dom";

export const Dashboard = () => {
  const { parsedAuthData: parsedAuthBuyer } = getAuthBuyer();

  const { showProfileSetup, setShowProfileSetup } = useProfileCompletion();

  const [selectedOption, setSelectedOption] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const datePickerRef = useRef(null);

  const handleChange = (selected) => {
    // Reset date range when selecting a non-custom filter
    if (selected.value !== "custom") {
      setSelectedOption(selected);
      setStartDate(null);
      setEndDate(null);
    } else {
      setSelectedOption(null);
      setIsDatePickerOpen(true);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formattedStartDate = moment(start).format("MMM D");
      const formattedEndDate = moment(end).format("MMM D");
      setSelectedOption({
        label: `${formattedStartDate} - ${formattedEndDate}`,
        value: `${formattedStartDate} - ${formattedEndDate}`,
      });

      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowProfileSetup(true);
    }, 800); // Delay of 800ms
    return () => clearTimeout(timer); // Clean up on unmount
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Listen for outside clicks

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <MainDefault>
      <ProfileUpdateStatus onUpdateProfile={() => setShowProfileSetup(true)} />
      <div className="top-nav py-3">
        <h4 className="mb-0">Good morning, {parsedAuthBuyer?.firstName}</h4>
      </div>
      <div className="greeting__filter my-4">
        <p>
          Here is your job listings statistic report from July 19 - July 25.
        </p>
        <div className="position-relative w-150px">
          <ReactSelect
            menuPlacement="auto"
            isSearchable={false}
            value={selectedOption}
            onChange={handleChange}
            options={DATE_FILTER_OPTIONS}
            styles={dashboardFilterDropdownStyles}
            placeholder="Search"
          />
          {isDatePickerOpen && (
            <div
              ref={datePickerRef}
              className="position-absolute"
              style={{ right: "0.25rem", top: "2.75rem" }}
            >
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                locale={enGB}
                selectsRange
                inline
              />
            </div>
          )}
        </div>
      </div>
      <Row className="justify-content-between action">
        <Col as={Row} md={7} className="gap-4 px-0 justify-content-between">
          <Col md={5} className="in-progress__work-orders analytics">
            <h1 className="fw-bold">76</h1>
            <Link
              to='/work-orders'
              state={{ eventKey: 'in-progress' }}
              className="d-flex justify-content-between align-items-center color-inherit"
            >
              <h6 className="fw-medium">In-Progress Work Orders</h6>
              <FiChevronRight size={24} />
            </Link>
          </Col>
          <Col md={5} className="published__work-orders analytics">
            <h1 className="fw-bold">3</h1>
            <Link 
              to='/work-orders' 
              state={{ eventKey: 'published' }} 
              className="d-flex justify-content-between align-items-center color-inherit"
            >
              <h6 className="fw-medium">Published Work Order</h6>
              <FiChevronRight size={24} />
            </Link>
          </Col>
          <Col md={5} className="schedule analytics">
            <h1 className="fw-bold">23</h1>
            <Link 
              to='/providers'
              state={{ eventKey: 'my-provider-pool' }}
              className="d-flex justify-content-between align-items-center color-inherit"
            >
              <h6 className="fw-medium">Providers in Pool</h6>
              <FiChevronRight size={24} />
            </Link>
          </Col>
          <Col md={5} className="new-messages analytics">
            <h1 className="fw-bold">10</h1>
            <Link className="d-flex justify-content-between align-items-center color-inherit">
              <h6 className="fw-medium">New Messages</h6>
              <FiChevronRight size={24} />
            </Link>
          </Col>
        </Col>
        <WorkOrderSummary />
      </Row>
      <WorkOrdersHeader />
      <JobUpdates navItems={BUYER_navITEMS} tabPanes={BUYER_tabPanes}>
        <LayoutSwitcher />
      </JobUpdates>
      <ProfileSetupModal
        show={showProfileSetup}
        handleClose={() => setShowProfileSetup(false)}
      />
    </MainDefault>
  );
};
