import React, { useMemo } from "react";
import {
  Form,
  ListGroup,
  NavDropdown,
  Row,
  Col,
} from "react-bootstrap";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { MdOutlineStar } from "react-icons/md";
import { Table } from "../../common/table/Table";
import { DIAMOND, USER } from "../../../assets/images.assets";
import { LayoutSwitcher } from "../../dashboard/job-updates/LayoutSwitcher";
import { FilterButton } from "../../common/filter-button/FilterButton";
import "./applicants.css";
import { useSelector } from "react-redux";
import { JobInfoHeader } from "../JobInfoHeader";
import { UserInformationCard } from "../../buyer-provider/user-information-card/UserInformationCard";

export const Applicants = () => {
  const _activeTabLayout = useSelector((state) => state.buyerReducer.activeTab);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: () => "Name",
        cell: (info) => (
          <div className="d-flex align-items-center gap-2">
            <Form.Check type="checkbox" className="buyer-checkbox" id={`checkbox-${info?.row.id}`} />
            <img src={USER} className="applicant-profile" />
            <div className="d-flex align-items-center gap-1">
              <label htmlFor={`checkbox-${info?.row.id}`} className="applicant-name">Adeleke Fajuyi Olayiwola</label>
              <img src={DIAMOND} />
            </div>
          </div>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "emailAddress",
        header: () => "Email Address",
        cell: (info) => (
          <span className="about-applicant">adelekefajuyi@gmail.com</span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "rating",
        header: () => "Rating",
        cell: (info) => (
          <div className="d-flex align-items-center gap-1">
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="FB9905" />
            <MdOutlineStar size={16} color="D9D9D9" />
          </div>
        ),
      },
      {
        accessorKey: "level",
        header: () => "Level",
        cell: (info) => <span className="about-applicant">Professional</span>,
        // enableSorting: false
      },
      {
        accessorKey: "jobsCompleted",
        header: () => "Jobs Completed",
        cell: (info) => (
          <span className="about-applicant job-completed">40</span>
        ),
      },
      {
        id: "actions",
        header: () => "Action",
        cell: ({ row }) => {
          return (
            <NavDropdown title={<MdOutlineMoreHoriz size={24} />}>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Preferred Provider
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {}}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  Recommended
                </ListGroup.Item>
              </ListGroup>
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      name: "Plumber",
      emailAddress: "Birmingham, United Kingdom",
      rating: "Active",
      rating: " 5",
      jobsCompleted: "30/200",
      price: "$500",
    },
    {
      name: "Plumber",
      emailAddress: "Birmingham, United Kingdom",
      rating: "Active",
      rating: " 5",
      jobsCompleted: "30/200",
      price: "$500",
    },
  ];

  return (
    <div className="">
      <JobInfoHeader />

      {/* {_activeTabLayout == "list " ? ( */}
        <Table columns={columns} data={rows} />
      {/* // ) : (
      // <Row className="row-gap-4">
      //   <Col md={3}>
      //     <UserInformationCard />
      //   </Col>
      //   <Col md={3}>
      //     <UserInformationCard />
      //   </Col>
      //   <Col md={3}>
      //     <UserInformationCard />
      //   </Col>
      //   <Col md={3}>
      //     <UserInformationCard />
      //   </Col>
      // </Row>
      // )} */}
    </div>
  );
};
