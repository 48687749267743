import React from "react";
import "./scheduleDetailsCard.css";

export const ScheduleDetailsCard = ({ fieldIcon, fieldName, children }) => {
  return (
    <div className="d-flex gap-3">
      <div className="field-icon d-flex align-items-center justify-content-center">
        {fieldIcon}
      </div>
      <div>
        <div className="field-name">{fieldName}</div>
        {children}
      </div>
    </div>
  );
};
