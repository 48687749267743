import React from "react";
import "./dashboard-search-items.css";
import { COMMON_IMG } from "../../../assets/images.assets";
import moment from "moment";
const DashboardSearchItems = () => {
  return (
    <div className="search-item d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <img height={40} src={COMMON_IMG} alt="" />
        <div className="ms-3">
          <div>Lorrupting the services...</div>
          <span className="font-14 color-fiord fw-300">
            Atlanta, United States
          </span>
        </div>
      </div>
      <div className="fw-300 f-12 color-fiord">created 2 days ago</div>
    </div>
  );
};

export default DashboardSearchItems;
