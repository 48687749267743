import { ProfileWrapper } from '../../../../components/common/Default'
import { ProviderTaxInformationForm } from '../../../../components/authentication/profiling/TaxInformationForm'

const INFO = {
    title: 'Tax Information',
    description: 'Getting your account more personalized'
}

export const TaxInformation = () => {

    return (
        <ProfileWrapper isProvider email info={INFO}>
            <ProviderTaxInformationForm />
        </ProfileWrapper>
    )
}
