import { Col, Row } from 'react-bootstrap'
import { Button } from '../../common/button/Button'
import { ReactComponent as NotFound } from '../../../assets/images/not-found.svg'
import './not-found.css'

export const NotFoundCard = ({ title, subTitle, buttonText, onButtonClick }) => {
    return (
        <Row
            className='justify-content-center align-items-center overflow-y-auto not-found__card-wrapper'
        >
            <Col xs={11} sm={8} md={4} className='bg-white not-found__card p-3 text-center'>
                <NotFound className='mb-3' />
                <p className='fw-semibold'>{title}</p>
                <p
                    className='mb-3 mx-auto color-gray-700 not-found__subtitle'
                >
                    {subTitle}
                </p>
                <Button
                    onClick={onButtonClick}
                    className='w-100 py-3 rounded'
                >
                    {buttonText}
                </Button>
            </Col>
        </Row>
    )
}