import React from "react";
import { Button } from "../../../../components/common/button/Button";
import { IoLockClosed } from "react-icons/io5";

export const ProviderTwoFAEnabled = ({ setIsTwoFA }) => {
  return (
    <>
      <div className="text-center mt-5 pt-5">
        <IoLockClosed size={38} color="#920af2" />

        <div className="mt-5 enabled-authentication">
          <p>Allow done!</p>
          <p>Two-Factor Authentication Enabled</p>
        </div>
        <Button
          className="py-3 px-4 mt-4"
          onClick={() => setIsTwoFA("goToSettings")}
        >
          Go to settings
        </Button>
      </div>
    </>
  );
};
