import { MainDefault } from '../../../components/common/Default'
import { JobUpdates } from '../../../components/dashboard/job-updates/JobUpdates'
import { BUYER_REPORTS_navItems, BUYER_REPORTS_tabPanes } from '../../../data/constants'

export const Reports = () => {
    return (
        <MainDefault>
            <h4 className="mb-0 fw-bold topHead">
                Reports
            </h4>
            <JobUpdates navItems={BUYER_REPORTS_navItems} tabPanes={BUYER_REPORTS_tabPanes} />
        </MainDefault>
    )
}
