import { ProfileWrapper } from '../../../../components/common/Default'
import { BioForm } from '../../../../components/authentication/profiling/BioForm'

const INFO = {
    title: <>Tell the world about <br /> yourself with your bio</>,
    description: 'Help people to get to know you at a glance'
}

export const BioInputSection = () => {

    return (
        <ProfileWrapper isProvider email info={INFO}>
            <BioForm />
        </ProfileWrapper>
    )
}
