import React from "react";
import { CardWrapper } from "../common/Default";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const LocationCommon = () => {
  return (
    <CardWrapper>
      <h6>Location</h6>
      <div className="location mt-4">
        <p>11, Western Road,</p>
        <p>Birmingham, United Kingdom</p>
      </div>
      <div style={{ height: "360px", width: "100%" }}>
        MAP
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </CardWrapper>
  );
};

export default LocationCommon;
