import React, { useState } from "react";
import Underline from "../../../../components/buyer-provider/Underline";
import { Form, Offcanvas } from "react-bootstrap";
import { Button } from "../../../../components/common/button/Button";
import { RxCross1 } from "react-icons/rx";
import { RightOffcanvas } from "../../../../components/buyer-provider/RightOffcanvas";
import { FundsAdded } from "./FundsAdded";

export const ReviewDetails = ({ setReviewDetails, setInputYourAmount, setShowFundingMethod }) => {
  const [fundsAdded, setFundsAdded] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Form className="form-wrapper" onSubmit={handleSubmit}>
        <div>
          <h6 className="font-14 line-height-160 fw-600">Account Name</h6>

          <Underline className="mt-3" />
          <p className="mt-3 font-14 line-height-160 color-custom-grey-500">
            Total Hours
          </p>
        </div>
        <div className="mt-4">
          <h6 className="font-14 line-height-160 fw-600">Account Number</h6>

          <Underline className="mt-3" />
          <p className="mt-3 font-14 line-height-160 color-custom-grey-500">
            Total Hours
          </p>
        </div>
        <div className="mt-4">
          <h6 className="font-14 line-height-160 fw-600">Routing Number</h6>

          <Underline className="mt-3" />
          <p className="mt-3 font-14 line-height-160 color-custom-grey-500">
            Total Hours
          </p>
        </div>
        <div className="mt-4">
          <h6 className="font-14 line-height-160 fw-600">Amount</h6>

          <Underline className="mt-3" />
          <p className="mt-3 font-16 line-height-160 color-gray-800">$20,000</p>
        </div>

        <div className="button-bottom-side d-flex">
          <Button
            type="button"
            variant="outline"
            className="fw-semibold px-3 color-ebony"
            style={{
              paddingBlock: 14,
              border: "1px solid #CCCCF5",
            }}
            onClick={() => setReviewDetails(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="fw-semibold py-3 px-3"
            onClick={() => setFundsAdded(true)}
          >
            Deposit Funds
          </Button>
        </div>
      </Form>

      <RightOffcanvas show={fundsAdded} onHide={setFundsAdded}>
        <div className="p-3 d-flex justify-content-end">
          <RxCross1
            size={20}
            onClick={() => setFundsAdded(false)}
            className="cursor-pointer"
          />
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <FundsAdded handleClose={() => {
            setFundsAdded(false)
            setReviewDetails(false)
            setInputYourAmount(false)
            setShowFundingMethod(false)
          }} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </>
  );
};
