import React from 'react'
import { Stack } from 'react-bootstrap'
import { HiArrowLeft } from 'react-icons/hi2'
import { NewWOStepper } from './stepper/Stepper'
import { useNavigate } from 'react-router-dom'

export const NewWODefault = ({ children, mode }) => {

    const navigate = useNavigate()

    return (
        <>
            <Stack direction='horizontal' className='text-black gap-2'>
                <HiArrowLeft
                    size={32}
                    strokeWidth={2}
                    className='cursor-pointer'
                    onClick={() => navigate(-1, { replace: true })}
                />
                <h2 className='mb-0 fw-bold'>{mode === 'edit' ? 'Edit' : 'New'} Work Order</h2>
            </Stack>
            <NewWOStepper mode={mode} />
            {children}
        </>
    )
}
