import { ProfileWrapper } from '../../../../components/common/Default'
import { Link } from 'react-router-dom'
import { AddFundsForm } from '../../../../components/authentication/profiling/AddFundsForm'

const INFO = {
    title: 'Add Funds',
    description: 'Add funds to your wallet'
}

export const AddFunds = () => {

    return (
        <ProfileWrapper info={INFO}>
            <AddFundsForm />
            <div className='text-center fw-bold'>
                <Link
                    to='/invite-team-members'
                    className='text-decoration-none color-grayscale-900'
                >
                    Skip for now
                </Link>
            </div>
        </ProfileWrapper>
    )
}
