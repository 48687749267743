import { Col, Row, Stack } from 'react-bootstrap'
import { GrLocation } from "react-icons/gr";
import './stepper.css'
import { MdOutlineBusinessCenter } from 'react-icons/md';
import { HiOutlineDocumentText, HiOutlineUser } from 'react-icons/hi';
import { FiDollarSign } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

const STEPS = [
    {
        title: 'Basic Information',
        icon: MdOutlineBusinessCenter,
        url: '/work-orders/basic-information'
    },
    {
        title: 'Location & Schedule',
        icon: GrLocation,
        url: '/work-orders/location-schedule'
    },
    {
        title: 'Invite Provider',
        icon: HiOutlineUser,
        url: '/work-orders/invite-provider'
    },
    {
        title: 'Agreements',
        icon: HiOutlineDocumentText,
        url: '/work-orders/agreements'
    },
    {
        title: 'Pricing',
        icon: FiDollarSign,
        url: '/work-orders/pricing'
    }
]

export const NewWOStepper = ({ mode }) => {
    
    const navigate = useNavigate()
    const location = useLocation()
    const currentIndex = STEPS.findIndex(step => step.url === location.pathname);

    return (
        <Row
            className='align-items-center justify-content-center new-wo__stepper'
        >
            {STEPS.map((step, i) => {
                return (
                    <Col key={i}>
                        <Stack
                            direction='horizontal'
                            className='gap-2 cursor-pointer'
                            onClick={() => navigate(step.url, { state: { mode } })}
                        >
                            <div className={`icon__wrapper ${i <= currentIndex ? 'background-primary' : 'background-titanWhite'}`}>
                                <step.icon color={i <= currentIndex ? 'white' : '#667185'} size={18} />
                            </div>
                            <div>
                                <p className={i <= currentIndex ? 'color-primary' : 'color-grey-400'}>Step {i + 1}/5</p>
                                <p className={`fw-medium ${i <= currentIndex ? 'text-black' : 'color-grey-500'}`}>{step?.title}</p>
                            </div>
                        </Stack>
                    </Col>
                )
            })}
        </Row>
    )
}