import React from "react";
import { MainDefault } from "../../../../components/common/Default";
import { Form, Stack } from "react-bootstrap";
import { Button } from "../../../../components/common/button/Button";
import { selectStyles } from "../../../../data/constants";
import Creatable from "react-select/creatable";

const customStyles = {
  ...selectStyles,
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F8F8FD",
    padding: "4px",
    gap: 4,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#920AF2",
    padding: 0,
    paddingLeft: 0,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#920AF2",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "5px 5px 50px 0px rgba(26, 32, 44, 0.06)",
  }),
  menuList: (provided) => ({
    ...provided,
    scrollbarWidth: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#0000",
    color: state.isFocused ? "black" : "#667185",
    ":active": {
      backgroundColor: "#0000", // '#920AF2'
    },
  }),
};

export const MyResources = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <MainDefault>
      <div className="top-nav py-3 mb-4">
        <h4 className="mb-0">Resources</h4>
      </div>

      <Form onSubmit={handleSubmit}>
        {/* <div className="w-50">
          <div className="mb-2">
            <label
              className="form-label line-height-160"
              htmlFor="skills-select"
            >
              Skills
            </label>
            <Creatable
              name="skills"
              inputId="skills-select"
              placeholder="Start typing to search for skills"
              // options={SKILLS}
              // value={formData?.skills}
              // onChange={(selectedOptions) =>
              //   handleSelectChange(selectedOptions, "skills")
              // }
              // onCreateOption={(inputValue) => handleCreate(inputValue, "skills")}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles}
              isSearchable={true}
              menuPlacement="auto"
            />
           
          </div>
          <div className="mb-2">
            <label
              className="form-label line-height-160"
              htmlFor="skills-select"
            >
              Equipments
            </label>
            <Creatable
              name="equipments"
              inputId="equipments-select"
              placeholder="Start typing to search for equipments"
              // options={SKILLS}
              // value={formData?.equipments}
              // onChange={(selectedOptions) =>
              //   handleSelectChange(selectedOptions, "equipments")
              // }
              // onCreateOption={(inputValue) =>
              //   handleCreate(inputValue, "equipments")
              // }
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles}
              isSearchable={true}
              menuPlacement="auto"
            />
          </div>
          <div className="mb-3">
            <label
              className="form-label line-height-160"
              htmlFor="skills-select"
            >
              Tools
            </label>
            <Creatable
              name="tools"
              inputId="tools-select"
              placeholder="Start typing to search for tools"
              // options={SKILLS}
              // value={formData?.tools}
              // onChange={(selectedOptions) =>
              //   handleSelectChange(selectedOptions, "tools")
              // }
              // onCreateOption={(inputValue) => handleCreate(inputValue, "tools")}
              isMulti
              closeMenuOnSelect={false}
              styles={customStyles}
              isSearchable={true}
              menuPlacement="auto"
            />
          </div>
        </div> */}
        <hr className="border-botticelli opacity-100 mb-4 mt-4" />
        <Stack direction="horizontal" gap={4}>
          <Button
            //   onClick={() => {
            //     fromModal ? handleClose() : navigate(-1);
            //   }}
            type="button"
            variant="outline"
            className=" color-ebony border-ebony py-3"
          >
            Cancel
            {/* {fromModal ? "Close" : "Back"} */}
          </Button>
          <Button type="submit" id="skills-button" className=" py-3">
            Save Changes
            {/* {fromModal ? "Update" : "Continue"} */}
          </Button>
        </Stack>
      </Form>
    </MainDefault>
  );
};
