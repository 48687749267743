import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Input } from "../../../../../components/common/input/Input";
import { Button } from "../../../../../components/common/button/Button";
import { Select } from "../../../../../components/common/select/Select";

export const MyAddPayment = ({ setAddPayment }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("directDeposit");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const accountTypeOptions = [
    { label: "Checking", value: "checking" },
    { label: "Checking", value: "checking" },
    { label: "Checking", value: "checking" },
  ];
  return (
    <>
      <div className="d-flex gap-4 mb-4">
        <Form.Check
          type="radio"
          label="Direct Deposit"
          name="paymentMethod"
          value="directDeposit"
          checked={selectedPaymentMethod === "directDeposit"}
          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
        />
        <Form.Check
          type="radio"
          label="PayPal"
          name="paymentMethod"
          value="paypal"
          checked={selectedPaymentMethod === "paypal"}
          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
        />
      </div>
      <Form className="form-wrapper" onSubmit={handleSubmit}>
        {selectedPaymentMethod === "directDeposit" && (
          <div>
            {/* DIRECT DEPOSIT */}
            <Input
              label="Routing Number"
              type="number"
              placeholder="Enter the routing number"
              name="routingNumber"
              // value={formData.routingNumber}
              // onChange={handleChange}
              // error={errors.routingNumber}
              className="mb-3"
            />
            <Select
              label="Account Type"
              name="accountType"
              inputId="accountType"
              htmlFor="accountType"
              placeholder="Select one"
              options={accountTypeOptions}
              //   value={formData.accountType}
              //   error={errors.accountType}
              //   onChange={(selectedOption) =>
              //     handleSelectChange(selectedOption, "accountType")
              //   }
              className="mb-3"
            />
            <Input
              label="Account Number"
              type="number"
              placeholder="Enter the account number"
              name="accountNumber"
              // value={formData.accountNumber}
              // onChange={handleChange}
              // error={errors.accountNumber}
              className="mb-3"
            />
            <Input
              label="Account Name"
              type="text"
              placeholder="Enter the name"
              name="accountName"
              // value={formData.accountName}
              // onChange={handleChange}
              // error={errors.accountName}
              className="mb-3"
            />
            <Input
              label="Bank"
              type="number"
              placeholder="Bank will be populated after inserting acct. number"
              name="accNumber"
              // value={formData.accNumber}
              // onChange={handleChange}
              // error={errors.accNumber}
              className="mb-3"
            />

            <div className="button-bottom-side d-flex">
              <Button
                type="button"
                variant="outline"
                className="fw-semibold px-3 color-ebony"
                style={{
                  paddingBlock: 14,
                  border: "1px solid #CCCCF5",
                }}
                onClick={() => setAddPayment(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="fw-semibold py-3 px-3"
                // disabled={!isFormValid()}
              >
                Save Payment Information
              </Button>
            </div>
          </div>
        )}
        {selectedPaymentMethod === "paypal" && (
          <div>
            {/* PAYPAL */}
            <Input
              label="Email Address"
              type="number"
              placeholder="Enter Email"
              name="accNumber"
              // value={formData.accNumber}
              // onChange={handleChange}
              // error={errors.accNumber}
              className="mb-3"
            />

            <div className="button-bottom-side d-flex">
              <Button
                type="button"
                variant="outline"
                className="fw-semibold px-3 color-ebony"
                style={{
                  paddingBlock: 14,
                  border: "1px solid #CCCCF5",
                }}
                onClick={() => setAddPayment(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="fw-semibold py-3 px-3"
                // disabled={!isFormValid()}
              >
                AddPayPal
              </Button>
            </div>
          </div>
        )}
      </Form>
    </>
  );
};
