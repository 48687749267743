import React, { useMemo, useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { CommonHeader } from "../../../components/buyer-provider/common-header/CommonHeader";
import { Table } from "../../../components/common/table/Table";
import { JOB_ORDER_CONTACT } from "../../../assets/images.assets";
import { Modal } from "react-bootstrap";

export const MyAgreements = () => {
  const [jobOrderContact, setJobOrderContact] = useState(false);

  const Columns = useMemo(
    () => [
      {
        accessorKey: "agreementTitle",
        header: () => "Agreement Title",
        cell: (info) => (
          <span
            className="font-12 line-height-160 color-grayscale-900 cursor-pointer"
            onClick={() => setJobOrderContact(true)}
          >
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "contractType",
        header: () => "Contract Type",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "buyerSName",
        header: () => "Buyer's Name",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "date",
        header: () => "Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
    ],
    []
  );
  const Rows = [
    {
      agreementTitle: "Work Order Agreements",
      contractType: "Work Order",
      buyerSName: "Iristech Inc",
      date: "Feb 24, 2024",
    },
  ];
  return (
    <MainDefault>
      <div className="px-3 mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="font-24 line-height-130 fw-bold">Agreements</h6>
        </div>

        <div className="mt-3">
          <CommonHeader />
        </div>

        <div className="mt-3">
          <Table columns={Columns} data={Rows} />
        </div>
      </div>

      <Modal
        show={jobOrderContact}
        onHide={() => setJobOrderContact(false)}
        className="mt-5 pt-5"
      >
        <Modal.Body>
          <div className="">
            <img src={JOB_ORDER_CONTACT} height={"100%"} width={"100%"} />
          </div>
        </Modal.Body>
      </Modal>
    </MainDefault>
  );
};
