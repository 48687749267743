import { Stack } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { ReactComponent as Gallery } from '../../../../assets/images/gallery.svg'

export const PDFandCSVUploader = ({ name, className, ...rest }) => {
    return (
        <Dropzone
            accept={{
                'application/pdf': ['.pdf'],
                'text/csv': ['.csv'],
            }}
            {...rest}
        >
            {({ getRootProps, getInputProps }) => (
                <Stack gap={2} {...getRootProps({ className: `dropzone__wrapper cursor-pointer align-items-center ${className}` })}>
                    <input name={name} className='position-static' {...getInputProps()} />
                    <Gallery />
                    <span className='text-center dropzone__instruction'>
                        Drag and drop your files
                    </span>
                    <span className='text-center dropzone__accepted-formats'>
                        Accepted format: csv or pdf
                    </span>
                </Stack>
            )}
        </Dropzone>
    )
}
