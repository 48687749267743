import React from "react";
import { CardWrapper } from "../../common/Default";
import { Col, Form, ListGroup, NavDropdown, Row } from "react-bootstrap";
import { AVATAR } from "../../../assets/images.assets";
import { HiDotsVertical } from "react-icons/hi";
import { MdOutlineStar } from "react-icons/md";
import { WideButton } from "../../common/wideButton/WideButton";
import "./userInformationCard.css";
import Underline from "../Underline";

export const UserInformationCard = ({ 
  userName, 
  address, 
  dropdownItems=[], 
  isFormCheck = true, 
  isWideButton = true,
  index, onCheckboxChange
}) => {
  const jobCategories = [
    { jobCategory: "Plumbing" },
    { jobCategory: "Carpentry" },
    { jobCategory: "Build" },
    { jobCategory: "Hardwork" },
    { jobCategory: "Hardwork" },
    { jobCategory: "3 More" },
  ];

  return (
    <>
      <CardWrapper>
        {isFormCheck && <Form.Check type="checkbox" className="buyer-checkbox" onChange={onCheckboxChange ? (e) => onCheckboxChange(e, index) : null} />}

        <div className="d-flex justify-content-between ">
          <div className="user-profile d-flex align-items-center gap-3">
            <img src={AVATAR} alt="User Avatar" />
            <div>
              <h6>{userName}</h6>
              <p>{address}</p>
            </div>
          </div>

          <NavDropdown
            title={
              <HiDotsVertical
                className="color-grey-500 cursor-pointer mt-1"
                size={22}
              />
            }
          >
            <ListGroup variant="flush">
              {dropdownItems.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={item?.onClick}
                  className="cursor-pointer font-14 color-gray-700 border-bottom-0"
                >
                  {item.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </NavDropdown>
        </div>

        <div className="mt-4 d-flex justify-content-between align-items-center">
          <div className="job-status badge-status__active">AVAILABLE</div>
          <div className="d-flex align-items-center gap-1">
            <MdOutlineStar size={14} color="FB9905" />
            <MdOutlineStar size={14} color="FB9905" />
            <MdOutlineStar size={14} color="FB9905" />
            <MdOutlineStar size={14} color="FB9905" />
            <MdOutlineStar size={14} color="D9D9D9" />
          </div>
        </div>

        <Underline />
        <Row className="">
          {jobCategories.map((job, index) => (
            <Col sm={4} key={index} className="">
              <div
                className={`mt-3 ${
                  index !== jobCategories.length - 1
                    ? "job-category"
                    : "more-category"
                }`}
              >
                {job.jobCategory}
              </div>
            </Col>
          ))}
        </Row>
        {isWideButton && <div className="mt-4 mb-2">
          <WideButton btnName="40 Jobs Completed" />
        </div>}
      </CardWrapper>
    </>
  );
};
