import PostRequest from '../../../services/PostRequest';
import { showToaster } from '../../../utils/utils';

export const FETCH_ROLES_LOADING = 'FETCH_ROLES_LOADING'
export const FETCH_ROLES = 'FETCH_ROLES'
export const DELETE_ROLE = 'DELETE_ROLE'
export const ADD_ROLE = 'ADD_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'

export const fetchBuyerRoles = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ROLES_LOADING, value: true })
        try {
            const response = await PostRequest('/buyer/roles');
            dispatch({
                type: FETCH_ROLES,
                value: response
            })
        } catch (error) {
            console.log('error', error)
        } finally {
            dispatch({ type: FETCH_ROLES_LOADING, value: false })
        }
    }
}

export const addRole = (data, options) => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/buyer/role/add', data, options);
            dispatch({
                type: ADD_ROLE,
                value: response
            })
            showToaster('Role added successfully', 'success')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }
}

export const updateRole = (data, options) => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/buyer/role/update', data, options);
            dispatch({
                type: UPDATE_ROLE,
                value: response
            })
            showToaster('Role updated successfully', 'success')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }
}

export const deleteRole = (role) => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/buyer/role/delete', { role: role });
            dispatch({
                type: DELETE_ROLE,
                value: role
            })
            showToaster(response, 'success')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }
}