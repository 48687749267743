import React from "react";
import { BiSolidFilePdf } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";

export const UploadResume = ({ acceptedFiles, setAcceptedFiles }) => {
  const removeResume = (fileId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      const updatedFiles = acceptedFiles.filter((file) => file.id !== fileId);
      setAcceptedFiles(updatedFiles);
    }
  };

  return (
    <>
      {acceptedFiles.map((file) => (
        <div className="upload-resume w-50 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <BiSolidFilePdf className="documentation-icon" size={35} />
              <div>
                <p className="me-1 font-16 uploaded-file-name">{file.name}</p>
                <small className="text-muted ">
                  {(file.size / 1024).toFixed(0)} KB
                </small>
              </div>
            </div>
            <div className="remove-selected-resume cursor-pointer">
              <RiDeleteBinLine
                size={20}
                onClick={() => removeResume(file.id)}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
