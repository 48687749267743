import React, { useMemo, useState } from "react";
import { MainDefault } from "../../../components/common/Default";
import { Col, Form, Navbar, Offcanvas, Row } from "react-bootstrap";
import { TotalBalanceCard } from "../../../components/buyer-provider/TotalBalanceCard";
import { IoSearch } from "react-icons/io5";
import "./payments.css";
import { Button } from "../../../components/common/button/Button";
import { Downloader } from "../../../components/common/downloader/Downloader";
import { AiOutlineSetting } from "react-icons/ai";
import Underline from "../../../components/buyer-provider/Underline";
import { Table } from "../../../components/common/table/Table";
import { RightOffcanvas } from "../../../components/buyer-provider/RightOffcanvas";
import { RxCross1 } from "react-icons/rx";
import { WithDraw } from "./WithDraw";

export const Payments = () => {
  const [withdrawFunds, setWithdrawFunds] = useState(false);
  const paymentMethods = [
    {
      image: require("../../../assets/images/approvalP-past-dueP.png"),
      title: "Upcoming Transfers",
      description: "3 work orders payments",
      amount: "$4,360.00",
    },
    {
      image: require("../../../assets/images/current-payables.png"),
      title: "Next Transfers",
      description: "2 work order payment",
      amount: "$4,360.00",
    },
    {
      image: require("../../../assets/images/available-funds.png"),
      title: "Total Balance",
      description: "Your current balance",
      amount: "$4,360.00",
    },
  ];

  const months = [
    { mName: "January" },
    { mName: "February" },
    { mName: "March" },
    { mName: "April" },
    { mName: "May" },
    { mName: "June" },
    { mName: "July" },
    { mName: "August" },
    { mName: "September" },
    { mName: "October" },
    { mName: "November" },
    { mName: "December" },
  ];

  const Columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: () => "Date",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "workOrderId",
        header: () => "Work Order Id",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
        // enableSorting: false
      },
      {
        accessorKey: "projectTitle",
        header: () => "Project Title",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "description",
        header: () => "Description",
        cell: (info) => (
          <span className="font-12 line-height-160 color-grayscale-900">
            {info.renderValue()}
          </span>
        ),
      },
      {
        accessorKey: "amount",
        header: () => "Amount",
        cell: (info) => (
          <div className="d-flex align-items-center gap-2">
            <span className="font-12 line-height-160 color-grayscale-900 fw-600">
              {info.renderValue()}
            </span>
            <div className="rate">
              $500
              <span className="ps-2">
                Send
                <span>Robin</span>
              </span>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (info) => (
          <span className="font-10 line-height-160 badge-status__active badge-status__active_text">
            {info.renderValue()}
          </span>
        ),
      },
    ],
    []
  );

  const Rows = [
    {
      date: "Jan 24",
      workOrderId: "#U477489944",
      projectTitle: "Plumber",
      description: "Brooklyn, London",
      amount: "$3,000.00",
      status: "AVAILABLE",
    },
  ];

  return (
    <MainDefault>
      <div className="px-3">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="font-24 line-height-130 fw-bold">Payments</h6>

          <Navbar.Brand className="position-relative">
            <IoSearch className="form-control__search-icon color-grey-500" />
            <Form.Control
              type="text"
              placeholder="Search"
              className="form-control__search applicant-search-input"
            />
          </Navbar.Brand>
        </div>

        <Row className="mt-5">
          {paymentMethods.map((p, index) => (
            <Col sm={index < 2 ? 3 : 6} key={index}>
              <TotalBalanceCard
                image={p.image}
                title={p.title}
                description={p.description}
                amount={p.amount}
                className="balance-card"
                btn={index === 2}
              >
                <Button className="py-3" onClick={() => setWithdrawFunds(true)}>
                  Withdraw with SendRobin
                </Button>
              </TotalBalanceCard>
            </Col>
          ))}
        </Row>

        <div className="d-flex justify-content-between align-items-center mt-4">
          <p className="font-14 color-grey-900">Recent Transfers</p>
          <div className="d-flex align-items-center gap-3">
            <button
              type="button"
              // onClick={() => setShowFilters(true)}
              className="filterButton"
            >
              <AiOutlineSetting className="me-1" />
              <span>Filter</span>
            </button>
            <Downloader isCSV />
          </div>
        </div>
        <Underline className="mt-2 mb-3" />

        <Row>
          {months.map((m, index) => (
            <Col sm={1}>
              <label
                className={`font-14 months-tab ${
                  index == 0 ? "active-month" : ""
                }`}
              >
                {m.mName}
              </label>
            </Col>
          ))}
        </Row>

        <div className="mt-3">
          <Table columns={Columns} data={Rows} />
        </div>
      </div>
      <RightOffcanvas
        show={withdrawFunds}
        onHide={setWithdrawFunds}
        className="w-25"
      >
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="font-24 line-height-130 fw-bold color-grayscale-900">
              Withdraw with SendRobin
            </h6>
            <RxCross1
              size={20}
              onClick={() => setWithdrawFunds(false)}
              className="cursor-pointer"
            />
          </div>
        </div>

        <Offcanvas.Body className="d-flex flex-column">
          <WithDraw setWithdrawFunds={setWithdrawFunds} />
        </Offcanvas.Body>
      </RightOffcanvas>
    </MainDefault>
  );
};
